import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import { intlShape } from 'react-intl'

import ButtonBack from 'components/elements/ButtonBack'
import Chip from 'components/elements/Chip'

import './_index.scss'

const ControllerSelector = ({ controllers, onControllerChange, controllersLabels }, context) => {
  const [selectedControllers, setSelectedController] = useState([])

  useEffect(() => {
    if (onControllerChange) {
      const selectedControllersIds = selectedControllers.map(({ value }) => value)

      onControllerChange(selectedControllersIds)
    }
  }, [selectedControllers])

  const controllerChangeHandler = (controller) => {
    setSelectedController(controller)
  }

  const removeControllerhandler = (controllerId) => {
    const newControllerList = selectedControllers.filter(({ value: id }) => id !== controllerId)

    setSelectedController(newControllerList)
  }

  const controllerOptions = useMemo(
    () =>
      controllers.map(({ id: value, full_name: label }) => ({
        label,
        value
      })),
    [controllers]
  )

  return (
    <div className='controller-selector'>
      <div className='controller-selector__static'>
        <div className='controller-selector__static__back'>
          <ButtonBack defaultPath='/vehicles' className='button-back' />
        </div>
        <div className='controller-selector__static__dropdown'>
          <ReactSelect
            value={selectedControllers}
            className='select'
            classNamePrefix='select'
            options={controllerOptions}
            optionLabel={(options) => options.full_name}
            optionValue={(options) => options.id}
            onChange={controllerChangeHandler}
            isSearchable
            isMulti
            isClearable={false}
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            placeholder={context.intl.formatMessage({ id: 'vehicle.list.selectController', default: 'vehicle.list.selectController' })}
          />
        </div>
      </div>
      {selectedControllers.length > 0 && (
        <div className='controller-selector__selectedList'>
          <span className='controller-selector__selectedList__label'>
            {context.intl.formatMessage({ id: controllersLabels, default: controllersLabels })}:
          </span>
          {selectedControllers.map(({ value, label }) => {
            const clickHandler = () => {
              removeControllerhandler(value)
            }
            return <Chip key={value} label={label} onButtonClick={clickHandler} />
          })}
        </div>
      )}
    </div>
  )
}

ControllerSelector.contextTypes = {
  intl: intlShape
}

ControllerSelector.propTypes = {
  controllers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string,
      given_name: PropTypes.string,
      status: PropTypes.number,
      surname: PropTypes.string,
      type: PropTypes.number
    })
  ),
  controllersLabels: PropTypes.string.isRequired,
  onControllerChange: PropTypes.func
}

export default ControllerSelector
