import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Styles
import './_index.scss'

const ENTER_KEY = 13

const InputSearch = (props) => {
  const [value, setValue] = useState()

  const handleChange = (value) => {
    setValue(value.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      triggerChange()
    }
  }

  const triggerChange = () => {
    props.onChange(value)
  }

  const { className } = props

  const classes = classnames('input-search', className)

  return (
    <div className={classes}>
      <FormattedMessage id='common.placeholders.search' defaultMessage='common.placeholders.search'>
        {(_placeholder) => (
          <input
            className={classes}
            value={value}
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            type='text'
            placeholder={_placeholder}
          />
        )}
      </FormattedMessage>
      <i className='material-icons'>search</i>
    </div>
  )
}

InputSearch.propTypes = {
  className: PropTypes.string
}

InputSearch.defaultProps = {
  className: '',
  value: ''
}

export default InputSearch
