export const CONFIG = {
  LANG: '@@config/LANG',
  SCALE: '@@config/SCALE'
}

export const ALERT = {
  SHOW: '@@alert/SHOW',
  HIDE: '@@alert/HIDE'
}

export const LOADER = {
  SHOW: '@@loader/SHOW',
  HIDE: '@@loader/HIDE'
}

export const AUTH = {
  SIGNUP: '@@auth/SIGNUP',
  CLEAR: '@@vehicle/CLEAR'
}

export const BANNER = {
  GET: '@@banner/GET'
}

export const CONTROLLER = {
  GET: '@@controllers/GET',
  GET_VEHICLE_STATS: '@@controllers/GET_VEHICLE_STATS',
  DETAIL: '@@controllers/DETAIL',
  DELETE: '@@controllers/DELETE',
  SLIDER: '@@controllers/SLIDER',
  STAT: '@@controllers/STAT',
  NEXT_STAT: '@@controllers/NEXT_STAT',
  LIST: '@@controllers/LIST',
  LIST_DELETE: '@@controllers/LIST_DELETE'
}

export const PROFILE = {
  GET: '@@profile/GET',
  STAT: '@@profile/STAT',
  GET_SC: '@@profile/GET_SC',
  PAGEVIEW: '@@profile/PAGE_VIEW',
  CLICK: '@@profile/CLICK'
}

export const PROMOTION = {
  GET: '@@promotion/GET',
  DETAIL: '@@promotion/DETAIL',
  SLIDER: '@@promotion/SLIDER',
  VEHICLE: '@@promotion/VEHICLE'
}

export const COUPON = {
  GET: '@@coupon/GET',
  READ: '@@coupon/READ',
  SLIDER_SERVICE: '@@coupon/SLIDER_SERVICE',
  SLIDER_SERVICE_READ: '@@coupon/SLIDER_SERVICE_READ',
  SLIDER_CASH: '@@coupon/SLIDER_CASH',
  SLIDER_CASH_SET: '@@coupon/SLIDER_CASH_SET',
  SLIDER_CASH_READ: '@@coupon/SLIDER_CASH_READ',
  CLEAR_CASH_GROUP: '@@coupon/CLEAR_CASH_GROUP',
  UNMOUNT_CASH_GROUP: '@@coupon/UNMOUNT_CASH_GROUP',
  GET_DEALER_LIST: '@@coupon/GET_DEALER_LIST'
}

export const VEHICLE = {
  GET: '@@vehicle/GET',
  DETAIL: '@@vehicle/DETAIL',
  FAV: '@@vehicle/FAV',
  DELETE: '@@vehicle/DELETE',
  CHECK: '@@vehicle/CHECK',
  CHECK_ALL: '@@vehicle/CHECK_ALL',
  UNCHECK_ALL: '@@vehicle/UNCHECK_ALL',
  ASSIGN: '@@vehicle/ASSIGN',
  CLEAR: '@@vehicle/CLEAR',
  SEARCH: '@@vehicle/SEARCH',
  SLIDER: '@@vehicle/SLIDER',
  UPDATE_CONTROLLERS: '@@vehicle/UPDATE_CONTROLLERS',
  GET_SC: '@@vehicle/GET_SC',
  DELETE_IMAGE: '@@vehicle/DELETE_IMAGE',
  SET_COVER_IMAGE: '@@vehicle/SET_COVER_IMAGE',
  GET_INSURANCE: '@@vehicle/GET_INSURANCE',
  GET_LENGTH: '@@vehicle/GET_LENGTH'
}

export const MASTER = {
  GET: '@@master/GET',
  LOADING_SHOW: '@@master/LOADING_SHOW',
  LOADING_HIDE: '@@master/LOADING_HIDE'
}

export const DEALER = {
  GET: '@@dealer/GET'
}

export const FILTER = {
  VEHICLE: {
    VIEW: '@@filter/VEHICLE/VIEW',
    SORT: '@@filter/VEHICLE/SORT',
    ORDER: '@@filter/VEHICLE/ORDER',
    KEYWORD: '@@filter/VEHICLE/KEYWORD',
    TYPE: '@@filter/VEHICLE/TYPE',
    CONTROLLER: '@@filter/VEHICLE/CONTROLLER'
  },
  CONTROLLER: {
    SORT: '@@filter/CONTROLLER/SORT',
    ORDER: '@@filter/CONTROLLER/ORDER',
    ASSIGNMENT: '@@filter/CONTROLLER/ASSIGNMENT',
    STATUS: '@@filter/CONTROLLER/STATUS',
    KEYWORD: '@@filter/CONTROLLER/KEYWORD'
  },
  COUPON: {
    SORT: '@@filter/COUPON/SORT',
    ORDER: '@@filter/COUPON/ORDER',
    KEYWORD: '@@filter/COUPON/KEYWORD',
    CLEAR: '@@filter/COUPON/CLEAR'
  }
}
export const MUX = {
  IS_OWNER_LOADING: '@@mux/isOwner/loading',
  IS_OWNER_SUCCEEDED: '@@mux/isOwner/succeeded',
  IS_OWNER_FAILED: '@@mux/isOwner/failed'
}

export const CLEAR_THIS_STATE = '@@system/CLEAR_THIS_STATE'

export const LOGIN_BANNER = {
  GET: '@@login-banner/GET'
}
export const ISP_VEHICLES = {
  GET: '@@isp/GET',
  DETAIL: '@@isp/DETAIL'
}
export const REPAIR_HISTORY = {
  GET: '@@repair-history/GET',
  DETAIL: '@@repair-history/DETAIL',
  CLEAR: '@@repair-history/CLEAR'
}
export const COMPANY = {
  GET: '@@company/GET',
  DETAIL: '@@company/DETAIL'
}

export const TIL_REFINANCE = {
  GET: '@@til-refinance/GET',
  REGISTER: '@@til-refinance/REGISTER',
  ERROR_GET: '@@til-refinance/ERROR_GET',
  ERROR_REGISTER: '@@til-refinance/ERROR_REGISTER'
}

export const INDIVIDUAL_CUSTOMER_END_OF_SUPPORT_BANNER = {
  GET: '@@individual-customer-end-of-support-banner/GET'
}
