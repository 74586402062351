/* eslint-disable no-unused-vars */
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Actions
import { deleteController } from 'store/actions/controllers'

// Components
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'

// Styles
import './_index.scss'

// Methods
import { formatNumber } from 'utils/format'
import { checkError } from 'utils/helper'

const ControllerItem = (props) => {
  const deleteControllerRef = useRef()

  const deleteController = async () => {
    try {
      await props.deleteController([{ id: props.detail.id }])
    } catch (err) {
      checkError(err)
    }
  }

  const itemClickHandler = () => {
    const { isCreate, history, detail } = props
    if (isCreate !== true) {
      history.push(`/controller/${detail.id}`)
    }
  }
  const { detail, isPlaceholder, isCreate, staticContext, isSlider, className, ischecked, userType, ...otherProps } = props

  const classes = classnames('controller-item', { 'controller-item--placeholder': isPlaceholder })

  const list = isPlaceholder ? (
    <div className={classes} {...otherProps}>
      <div className='controller-item__name'>
        <div className='controller-item__name__fullname'></div>
        <div className='controller-item__name__username'></div>
      </div>
      <div className='controller-item__units'></div>
    </div>
  ) : (
    <div className={classes} {...otherProps} onClick={itemClickHandler}>
      <Modal
        mode={2}
        title={'controller.delete.title'}
        content={'controller.delete.description'}
        onConfirmed={deleteController}
        ref={deleteControllerRef}
      />
      <div className='controller-item__name'>
        <div className='controller-item__name__fullname'>{detail.full_name === 'null null' ? '-' : detail.full_name}</div>
        <div className='controller-item__name__username'>{detail.username}</div>
        <div className={`controller-item__name__status--${detail.status}`}>
          {detail.status === 201 ? (
            <FormattedMessage id='controller.detail.active' defaultMessage='controller.detail.active' />
          ) : (
            <FormattedMessage id='controller.detail.inactive' defaultMessage='controller.detail.inactive' />
          )}
        </div>
      </div>
      {isCreate !== true && (
        <div className='controller-item__units'>
          {isSlider && detail.number_of_vehicles.next_maintenance + ' of '}
          {detail && detail.number_of_vehicles && formatNumber(detail.number_of_vehicles.active)}
          {!isSlider && <FormattedMessage id='controller.list.units' defaultMessage='controller.list.units' />}
        </div>
      )}
      {isCreate === true && (
        <div className='controller-item__delete'>
          <Button
            className='controller-item__delete--btn'
            iconOnly
            icon='delete'
            type='delete'
            onClick={() => deleteControllerRef.current.openModal()}
          />
        </div>
      )}
    </div>
  )

  return list
}

ControllerItem.propTypes = {
  className: PropTypes.string,
  isSlider: PropTypes.bool,
  isCreate: PropTypes.bool
}

ControllerItem.defaultProps = {
  className: '',
  detail: {},
  isPlaceholder: false,
  isSlider: false,
  isCreate: false
}
const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        deleteController
      },
      dispatch
    )

export default /* istanbul ignore next */ connect(null, mapDispatchToProps)(withRouter(ControllerItem))
