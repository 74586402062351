import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import logo from 'assets/images/logo/logo.svg'

import Button from 'components/elements/Button'
import Navigation from 'domain/Navigation'
import NavItem from 'components/elements/NavItem'

import { USER_TYPE } from 'config/constant'
import { profileDesktop, profileMenu, addVehicleMenu, settingMenu, controllerMenu, addCompanyMenu, logoutMenu } from 'config/icon'

import { authSignout } from 'store/actions/auth'

import './_index.scss'
import * as dim from 'utils/breakpoint'
import { isMobileApp } from 'utils/validation'
import { checkAuth } from 'utils/auth'
import { checkError } from 'utils/helper'

const Top = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile) || {}
  const [dimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  let classname = 'top'
  if (location.pathname === '/signin') {
    classname = 'top-signin'
  } else if (location.pathname === '/signup') {
    classname = 'top top--signup'
  }

  const signoutHandler = useCallback(() => {
    dispatch(authSignout()).catch((err) => checkError(err))
  }, [dispatch, authSignout])

  const notShowWithPath = () => {
    return !(location.pathname === '/signup' && location.pathname === '/create-account' && location.pathname === '/signin')
  }

  return (
    <div className={classname}>
      <div className='top-wrapper'>
        <div className='top__logo'>
          <div className='top__logo__image'>
            <img src={logo} alt='my-ISUZU' id='isuzu-logo' />
          </div>
        </div>
        {!isMobileApp() && checkAuth() && notShowWithPath() && !(dim.desktop(dimension) || dim.tablet(dimension)) && (
          <>
            <div className='top__menu__nav__navigation'>
              <div className='navigation__main'>
                <NavItem
                  currentLocation={history.location.pathname}
                  target='/dealer-search'
                  isNewIcon
                  icon='contact'
                  id='contact-nav-bar'
                />
              </div>
            </div>
          </>
        )}
        {location.pathname !== '/signup' && location.pathname !== '/create-account' && (
          <div className='top__menu'>
            <div className='top__menu__nav'>
              <Navigation />
            </div>

            {profile.id && location.pathname !== '/invite-controllers' && (
              <div className='top__profile'>
                <div className='top__profile__icon'>
                  <i className='material-icons' id='account-icon'>
                    {profileDesktop}
                  </i>
                  <div className='top__profile__menu'>
                    <div className='top__profile__menu__nav'>
                      <div className='top__profile__name' id='account-name-text'>
                        {profile.type === USER_TYPE.CORPORATE ? (
                          <div className='top__profile__name__corporate'>
                            <span>{profile.corporate.company_name}</span>
                            <span>{profile.full_name}</span>
                          </div>
                        ) : (
                          <span>{profile.full_name}</span>
                        )}
                      </div>
                      <Button
                        label='menus.myProfile'
                        className='button--top-nav'
                        icon={profileMenu}
                        onClick={() => history.push('/profile')}
                      />
                      {profile.type !== USER_TYPE.CONTROLLER && (
                        <Button
                          label='menus.addVehicle'
                          className='button--top-nav'
                          id='add-vehicle-menu'
                          icon={addVehicleMenu}
                          onClick={() => history.push('/vehicles/search')}
                        />
                      )}
                      {profile.type === USER_TYPE.CORPORATE && (
                        <Button
                          label='menus.controller'
                          className='button--top-nav'
                          id='controller-driver-menu'
                          icon={controllerMenu}
                          onClick={() => history.push('/controllers')}
                        />
                      )}
                      {profile.type === USER_TYPE.CORPORATE && (
                        <Button
                          label='menus.manageCompany'
                          className='button--top-nav'
                          id='manage-company-menu'
                          icon={addCompanyMenu}
                          onClick={() => history.push('/company/list')}
                        />
                      )}
                      <Button
                        label='myProfile.labels.settings'
                        className='button--top-nav'
                        id='setting-menu'
                        icon={settingMenu}
                        onClick={() => history.push('/setting')}
                      />
                      <Button
                        label='myProfile.labels.logout'
                        className='button--top-nav'
                        id='logout'
                        icon={logoutMenu}
                        onClick={signoutHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Top
