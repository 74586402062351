import { PROFILE, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE.STAT:
      return Object.assign({}, action.profileStat)

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
