// Methods
import {
  email,
  contInvite_email,
  contInvite_username,
  contInvite_username_min6,
  contInvite_username_alphanumeric,
  contInvite_mobile_phone,
  contInvite_mobile_need
} from 'utils/validation'

export const setPlaceholder = (value) => {
  let label
  switch (value) {
    case 'email':
      label = 'controller.placeholders.emailInput'
      break
    case 'sms':
      label = 'controller.placeholders.mobileInput'
      break
    case 'username':
      label = 'common.placeholders.inputUsername'
      break
    default:
      label = ''
  }
  return label
}

export const setRoleRadioLabel = (value) => {
  let label

  switch (value) {
    case 'controller':
      label = 'controller.invite.controller'
      break
    case 'driver':
      label = 'controller.invite.driver'
      break
    default:
      label = ''
  }
  return label
}

export const setChannelRadioLabel = (value) => {
  let label

  switch (value) {
    case 'email':
      label = 'common.labels.email'
      break
    case 'sms':
      label = 'common.labels.sms'
      break
    default:
      label = ''
  }
  return label
}

export const setValidation = (value) => {
  let validate
  switch (value) {
    case 'email':
      validate = [email, contInvite_email]
      break
    case 'sms':
      validate = [contInvite_mobile_phone, contInvite_mobile_need]
      break
    case 'username':
      validate = [contInvite_username, contInvite_username_min6, contInvite_username_alphanumeric]
      break
    default:
      validate = []
  }
  return validate
}
