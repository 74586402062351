import { isApolloError } from '@apollo/client'

export const getServerErrorCode = (error) => {
  if (isApolloError(error)) {
    const firstError = error.graphQLErrors[0]

    if (firstError && firstError.extensions.code) {
      return firstError.extensions.code
    }
  }

  return undefined
}

export const isAuthenticationError = (error) => {
  const errorCode = getServerErrorCode(error)

  return ['UNAUTHENTICATED', 'FORBIDDEN'].includes(errorCode)
}

export const getUserErrorCode = (field) => (data) => {
  if (!data || typeof data !== 'object') {
    return
  }

  if (field in data && 'code' in data[field]) {
    return data[field].code
  }
}
