import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ListPager from 'components/elements/ListPager'

import { ispPackageFormat } from 'utils/format'
import { checkError } from 'utils/helper'

// SWR Concepts
import axios from 'axios'
import useSWR from 'swr'
import queryString from 'query-string'
import { VEHICLE, ISP_VEHICLES } from '../../../../store/definitions'
import env from 'config/environment'

import classnames from 'classnames'
import Cookies from 'js-cookie'

import './_index.scss'

// SWR Concepts
const baseUrl = env.api
const fetcher = (url) =>
  axios
    .get(url)
    .then((res) => res)
    .catch(checkError)

const PAGE_LIMIT = 50

const PopupVehicle = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(0)

  const { data: dataVehicle } = useSWR(baseUrl + '/vehicles?' + queryString.stringify(true, '', 1, offset, PAGE_LIMIT, 0, 'LCV'), fetcher)

  const { data: dataISP } = useSWR(baseUrl + '/isp/vehicle-list/?', fetcher)

  let dataVehicleList, totalVehicleCount, hasOnlyCV
  if (dataVehicle) {
    totalVehicleCount = parseInt(dataVehicle.headers['x-total-count']) || dataVehicle.data.length
    hasOnlyCV = (dataVehicle.headers['has-only-cv'] === 'true' ? true : false) || null

    dataVehicleList = dataVehicle.data.map((d) => {
      d.is_checked = true
      return d
    })
  }

  let totalISPCount, ispVehicleList
  if (dataISP) {
    const dataISPList = dataISP.data
    totalISPCount = parseInt(dataISP.headers['x-total-count']) || dataISP.data.length
    ispVehicleList = { ...dataISPList }
  }

  useEffect(() => {
    if (dataVehicle && dataISP) {
      dispatch({
        type: VEHICLE.GET,
        total_count: totalVehicleCount,
        has_only_cv: hasOnlyCV,
        data: dataVehicleList,
        isReload: false
      })
      dispatch({
        type: ISP_VEHICLES.GET,
        ispVehicle: ispVehicleList,
        total_count: totalISPCount
      })
    }
  }, [dataVehicle, dataISP])

  const handlePageChange = (_offset) => {
    setOffset(_offset)
    // force this function return "true" to make reduxActionGet of ListPager working correctly
    return true
  }

  const handleClickItem = (dataId) => {
    if (props.isp) {
      Cookies.set('ispId', dataId)
      if (props.selectList) {
        history.push(`/isp-selection/${dataId}`)
      } else {
        history.push(`/isp/${dataId}`)
      }
    }

    if (props.clickClose) {
      props.close()
    }
  }

  const vehicleWithISP =
    props.ispList &&
    props.ispList.map((data) => (
      <div key={data.hash_id} className='modal__body__content__privilege__item' onClick={() => handleClickItem(data.hash_id)}>
        <div className='modal__body__content__privilege__item__img'>
          <img src={data.vehicle_image} alt={data.vehicle_name} />
        </div>
        <div className='modal__body__content__privilege__item__info'>
          <span className='modal__body__content__privilege__item__info__name'>{data.vehicle_name}</span>
          <span className='modal__body__content__privilege__item__info__license'>{data.license_plate}</span>
          <span>{ispPackageFormat(data.isp_year, data.isp_mileage)}</span>
        </div>
      </div>
    ))

  const classes = classnames('popup-vehicles')
  return (
    <div className={classes}>
      <div>
        <ListPager limit={PAGE_LIMIT} total={vehicleWithISP.length} reduxActionGet={handlePageChange}></ListPager>
        <div className='popup-vehicles__list-wrapper'>{vehicleWithISP}</div>
      </div>
    </div>
  )
}

export default PopupVehicle
