import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import DetailIsp from 'domain/Isp/components/DetailIsp'

// Actions
import { getVehicleDetail, getVehicles } from 'store/actions/vehicles'
import { postPageView } from 'store/actions/profile'
import { getISPVehicles } from 'store/actions/ispVehicles'
import { getISPDetail } from 'store/actions/ispDetail'
import { getProfile } from 'store/actions/profile'

// Utils
import { checkError } from 'utils/helper'

const IspDetail = ({
  getVehicleDetail,
  getVehicles,
  postPageView,
  getISPVehicles,
  getISPDetail,
  match,
  history,
  vehicleDetail,
  vehicleList,
  ispDetail,
  ispVehicle,
  lang,
  profile
}) => {
  const [pathname] = useState(history.location.pathname)

  useEffect(() => {
    Promise.all([getVehicles(true, '', 1, 0, 2000, 0, 'LCV'), postPageView(), getISPVehicles()]).catch((err) => {
      checkError(err)
    })
  }, [])

  useEffect(() => {
    if (vehicleList.total_count > 0) {
      Promise.all([getVehicleDetail(match.params.id), getISPDetail(match.params.id)]).catch((err) => {
        checkError(err)
      })
    }
  }, [match.params.id, vehicleList.total_count])

  return (
    <div className='isp-detail'>
      {pathname !== '/isp/no-vehicle' && pathname !== '/isp-about' && (
        <div className='isp-detail__header'>
          <DetailHeader showBack type='basic' title={isMobile ? 'ispPackage.ispHeader' : ''} />
        </div>
      )}

      <DetailIsp detail={vehicleDetail} list={vehicleList} ispList={ispVehicle} ispDetail={ispDetail} lang={lang} profile={profile} />
    </div>
  )
}

IspDetail.propTypes = {
  getVehicles: PropTypes.func,
  getVehicleDetail: PropTypes.func,
  vehicleDetail: PropTypes.object,
  getISPVehicles: PropTypes.func,
  getISPDetail: PropTypes.func,
  ispVehicle: PropTypes.object,
  ispDetail: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

export const mapStateToProps = (state) => ({
  vehicleDetail: state.vehicleDetail,
  vehicleList: state.vehicles,
  ispVehicle: state.ispVehicle,
  ispDetail: state.ispDetail,
  lang: state.config.lang,
  profile: state.profile
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getVehicleDetail,
        getVehicles,
        postPageView,
        getISPVehicles,
        getISPDetail,
        getProfile
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IspDetail))
