import { facebookIcon, instagramIcon, lineIcon, youtubeIcon } from 'config/icon'
import fb1 from 'assets/images/fb1.jpg'
import { FormattedMessage } from 'react-intl'
import fb2 from 'assets/images/fb2.jpg'
import fb3 from 'assets/images/fb3.jpg'
import React from 'react'

const SocialConnect = () => (
  <div className='social-media'>
    <div className='social-media__item social-media__item--facebook'>
      <i className='social-media__item__facebook'>{facebookIcon}</i>
      <div className='social-media__item__sub'>
        <a
          href='https://www.facebook.com/ALLNEWISUZUDMAX/'
          className='social-media__item__sub__item'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='social-media__item__sub__item__image'>
            <img src={fb1} alt='' />
          </div>
          <div className='social-media__item__sub__item__desc'>
            <FormattedMessage id='chat.dmax' defaultMessage='chat.dmax' />
          </div>
        </a>
        <a
          href='https://www.facebook.com/isuzumuxthailand/'
          className='social-media__item__sub__item'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='social-media__item__sub__item__image'>
            <img src={fb2} alt='' />
          </div>
          <div className='social-media__item__sub__item__desc'>
            <FormattedMessage id='chat.mux' defaultMessage='chat.mux' />
          </div>
        </a>
        <a
          href='https://www.facebook.com/IsuzuTrucksThailandOfficial/'
          className='social-media__item__sub__item'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='social-media__item__sub__item__image'>
            <img src={fb3} alt='' />
          </div>
          <div className='social-media__item__sub__item__desc'>
            <FormattedMessage id='chat.facebookofficial' defaultMessage='chat.facebookofficial' />
          </div>
        </a>
      </div>
    </div>
    <div className='social-media__item'>
      <a href='https://line.me/R/ti/p/%40isuzuthai' className='social-media__item__line' target='_blank' rel='noopener noreferrer'>
        {lineIcon}
      </a>
    </div>
    <div className='social-media__item'>
      <a
        href='https://www.youtube.com/channel/UCmTP28q4oZsMCyx16PiPJwA'
        className='social-media__item__youtube'
        target='_blank'
        rel='noopener noreferrer'
      >
        {youtubeIcon}
      </a>
    </div>
    <div className='social-media__item'>
      <a
        href='https://www.instagram.com/isuzuthailandofficial/'
        className='social-media__item__instagram'
        target='_blank'
        rel='noopener noreferrer'
      >
        {instagramIcon}
      </a>
    </div>
  </div>
)

export default SocialConnect
