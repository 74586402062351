import { ALERT } from '../definitions'

/**
 * showAlert 
 * @param {*} alert 
 * {
 *  type: 'success',
    message: 'inbox.deleteSuccess'
 * }
 */
const showAlert = (alert) => {
  return (dispatch) => {
    dispatch({
      type: ALERT.SHOW,
      alert
    })
  }
}

/**
 * hideAlert
 */
const hideAlert = () => {
  return (dispatch) => {
    dispatch({
      type: ALERT.HIDE
    })
  }
}

export { showAlert, hideAlert }
