import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
// import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Modal from 'components/elements/Modal'

import './_index.scss'

const CompanyListTable = ({ childCompany }) => {
  const [isOpen] = useState(false)

  const modalRef = useRef()

  useEffect(() => {
    if (isOpen && modalRef && modalRef.current) {
      modalRef.current.openModal()
    }
  }, [isOpen])

  const contentModal = <FormattedMessage id='company.modal.delete.content' defaultMessage='company.modal.delete.content' />

  return (
    <div>
      <div className='company-list__content'>
        {/* เดียวทำเป็น list */}
        <div className='company-list__title'>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <div>
                <FormattedMessage id='company.table.company' defaultMessage='company.table.company' />
              </div>
            </Grid>
            <Grid item xs={5}>
              <FormattedMessage id='company.table.taxNumber' defaultMessage='company.table.taxNumber' />
            </Grid>
          </Grid>
        </div>
        <div className='company-list__list'>
          {childCompany.map((data) => (
            <div className='company-list__list__company'>
              <Grid container spacing={3}>
                <Grid item xs={5} sm={5}>
                  <div>
                    <span>{data.company_name}</span>
                  </div>
                </Grid>
                <Grid item xs={5} sm={6} md={6}>
                  <span>{data.company_reg_id}</span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </div>

      <Modal mode={13} header='company.modal.delete.header' content={contentModal} ref={modalRef} />
    </div>
  )
}

CompanyListTable.propTypes = {
  isOpen: PropTypes.bool
}

export const mapStateToProps = (state) => ({})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompanyListTable)
