import { ISP_VEHICLES, CLEAR_THIS_STATE } from '../definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case ISP_VEHICLES.DETAIL:
      return Object.assign({}, ...action.detail)

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
