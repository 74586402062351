import React, { useEffect, useRef } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { getPromotions } from 'store/actions/promotions'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Components
import PromotionItem from 'domain/Promotions/components/PromotionItem'
import List from 'components/elements/List'
import ListPager from 'components/elements/ListPager'
import ContentHeader from 'components/layouts/ContentHeader'
import DetailHeader from 'components/layouts/DetailHeader'

// Styles
import './_index.scss'
import { postPageView } from 'store/actions/profile'

import { checkError } from 'utils/helper'

const limit = 10

const Promotions = ({ promotionDetail }) => {
  const profile = useSelector((state) => state.profile)
  const promotions = useSelector((state) => state.promotions)
  const listPagingRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPromotions(16, 0, 0, limit)).catch((err) => {
      checkError(err)
    })
    dispatch(postPageView())
  }, [])

  const listPagingChangePage = (offset) => {
    return dispatch(getPromotions(16, 0, offset, limit))
  }

  const classes = classnames('promotions')
  return (
    <div className={classes}>
      <div className='promotions__header'>
        <ContentHeader title={'promotion.title'} subTitle={'promotion.subTitle'} />
        <div className='promotions__header__pager'>
          <DetailHeader showBack defaultPath='privilege' type='basic' detail={promotionDetail} userType={profile.type} />

          {!promotions.data.some((e) => e.is_checked) && (
            <ListPager limit={limit} total={promotions.total_count} ref={listPagingRef} reduxActionGet={listPagingChangePage} />
          )}
        </div>
      </div>
      <div className='promotions__body'>
        <List
          component={PromotionItem}
          userType={profile.type}
          data={promotions.data}
          totalCount={promotions.total_count}
          height={100}
          isList
          adaptable
        />
      </div>
    </div>
  )
}

Promotions.propTypes = {
  getPromotions: PropTypes.func,
  promotions: PropTypes.object,
  profile: PropTypes.object
}

export default Promotions
