import { CONTROLLER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  vehicleStats: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTROLLER.DETAIL: {
      return {
        ...state,
        ...action.controllerDetail
      }
    }

    case CONTROLLER.GET_VEHICLE_STATS: {
      return {
        ...state,
        vehicleStats: {
          ...action.payload
        }
      }
    }

    case CLEAR_THIS_STATE: {
      return state
    }

    default:
      return state
  }
}
