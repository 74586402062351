import { useState, useEffect } from 'react'

const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine)

  useEffect(() => {
    const handleOnline = () => {
      setNetwork(true)
    }

    const handleOffline = () => {
      setNetwork(false)
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  if (!window.navigator.onLine) return false

  return isOnline
}

export default useNetwork
