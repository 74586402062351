import React, { useEffect, useState } from 'react'

import { Grid, withStyles } from '@material-ui/core'

// Router
import { useHistory } from 'react-router-dom'

//component
import { FormattedMessage } from 'react-intl'

import { headArrow } from 'config/icon'

import './_index.scss'

const styles = {}

const MenuList = (props) => {
  let history = useHistory()

  const [classes, setClasses] = useState('menu-list_content')

  useEffect(() => {
    props.isActive ? setClasses('menu-list_content_active') : setClasses(classes)
  })

  const navigation = (page) => {
    page ? history.push(page) : props.onClick()
  }

  const indendMenu = (indend) => {
    return indend ? indend + 'rem' : '0rem'
  }

  const borderTop = () => {
    return props.borderTop ? '1px solid #C3C3C3' : 'unset'
  }

  /*
  for sign out button because navigation footer bar is overlap
  */
  const marginBottom = () => {
    return props.marginBottom ? '6.7rem' : 'unset'
  }

  const showList = props.isTitle ? (
    <div className='menu-list' style={{ borderTop: borderTop() }}>
      <div className={classes + '_title'}>
        {/* <Grid container spacing={3}> */}
        <Grid container xs={9}>
          <FormattedMessage id={props.label} defaultMessage={props.label} />
        </Grid>
        {/* </Grid> */}
      </div>
    </div>
  ) : (
    <div className='menu-list' style={{ borderTop: borderTop(), marginBottom: marginBottom() }}>
      <div className={classes} onClick={() => navigation(props.path)}>
        {/* <Grid container spacing={3}> */}
        <Grid container xs={10} style={{ marginLeft: indendMenu(props.indend) }}>
          <FormattedMessage id={props.label} defaultMessage={props.label} />
        </Grid>
        <Grid container justify='flex-end' xs={2}>
          {props.showIcon && headArrow}
        </Grid>
        {/* </Grid> */}
      </div>
    </div>
  )
  return <>{showList}</>
}

export default withStyles(styles)(MenuList)
