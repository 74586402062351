import { TIL_REFINANCE } from '../definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case TIL_REFINANCE.GET:
      return Object.assign({}, action.refinanceDetail)

    case TIL_REFINANCE.REGISTER:
      return Object.assign({}, action.registerResult)

    case TIL_REFINANCE.ERROR_GET:
      return Object.assign({}, action.errorGet)

    case TIL_REFINANCE.ERROR_REGISTER:
      return Object.assign({}, action.errorRegister)

    default:
      return state
  }
}
