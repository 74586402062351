import React, { useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/elements/Button'
import ButtonBack from 'components/elements/ButtonBack'
import ContentHeader from 'components/layouts/ContentHeader'
import DeleteVehicleReasonModal from 'domain/Vehicle/components/DeleteVehicleReasonModal'
import VehicleItem from 'domain/Vehicle/components/VehicleItem'

import { USER_TYPE, USER_TYPE_RESOLVER } from 'config/constant'

import { deleteVehicles } from 'store/actions/vehicles'

import { FormattedMessage } from 'react-intl'
import './_index.scss'
import { PROFILE } from 'store/definitions'

import { checkError } from 'utils/helper'

const DeleteVehicles = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedVehicles = useSelector((state) => state.vehicles.selectedVehicles)

  const [vehicles, setVehicles] = useState(selectedVehicles)
  const [shouldModalOpen, setShouldModalOpen] = useState(false)
  dispatch({
    type: PROFILE.PAGEVIEW
  })
  useEffect(() => {
    if (selectedVehicles.length === 0 && history) {
      history.replace('/vehicles')
    }
  }, [selectedVehicles])

  const modalConfirmHandler = async (values) => {
    const vehicleIds = vehicles.map(({ id }) => id)

    try {
      await dispatch(deleteVehicles(vehicleIds, values))

      goBackHandler()
      // eslint-disable-next-line no-empty
    } catch (err) {
      checkError(err)
    }

    modalCloseHandler()
  }

  const confirmDeleteHandler = () => {
    setShouldModalOpen(true)
  }

  const modalCloseHandler = () => {
    setShouldModalOpen(false)
  }

  const goBackHandler = () => {
    if (history) {
      history.goBack()
    }
  }

  const checkClickHandler = (vehicleId) => {
    const newVehicles = [...vehicles]
    const index = newVehicles.findIndex(({ id }) => id === vehicleId)

    if (index > -1) {
      newVehicles[index] = { ...newVehicles[index], is_checked: !newVehicles[index].is_checked }
    }

    setVehicles(newVehicles)
  }

  const atleastVehicleChecked = useMemo(() => vehicles.some((vehicle) => vehicle.is_checked), [vehicles])

  return (
    <div className='delete-vehicle'>
      <ContentHeader title='vehicle.deleteVehicle.title' subTitle='vehicle.deleteVehicle.subtitle' />
      <div className='delete-vehicle__body'>
        <div className='delete-vehicle__body__container'>
          <div className='delete-vehicle__body__container__controllers'>
            <div className='delete-vehicle__body__container__controllers__content'>
              <ButtonBack defaultPath='/vehicles' className='button-back' />
            </div>
          </div>
          <div className='delete-vehicle__body__container__vehicles-list'>
            <div className={'vehicles__body__list--header ' + USER_TYPE_RESOLVER[USER_TYPE.CORPORATE].toLowerCase()}>
              <div className='vehicles__body__list--header__item name'>
                <FormattedMessage id='vehicle.list.listName' defaultMessage='vehicle.list.listName' />
              </div>
              <div className='vehicles__body__list--header__item assignment-column'>
                <FormattedMessage id='vehicle.list.assignTo' defaultMessage='vehicle.list.assignTo' />
              </div>
              <div className='vehicles__body__list--header__item last-maintenance-date'>
                <FormattedMessage id='vehicle.list.lastMaintenanceDate' defaultMessage='vehicle.list.lastMaintenanceDate' />
              </div>
              <div className='vehicles__body__list--header__item next-main'>
                <FormattedMessage id='vehicle.list.listMaintenance' defaultMessage='vehicle.list.listMaintenance' />
              </div>
              <div className='vehicles__body__list--header__item age'>
                <FormattedMessage id='vehicle.list.listAge' defaultMessage='vehicle.list.listAge' />
              </div>
              <div className='vehicles__body__list--header__item type'>
                <FormattedMessage id='vehicle.list.vehicåleType' defaultMessage='vehicle.list.vehicleType' />
              </div>
              <div className='vehicles__body__list--header__item driver-name'>
                <FormattedMessage id='vehicle.list.driverName' defaultMessage='vehicle.list.driverName' />
              </div>
              <div className='vehicles__body__list--header__item fav'>&nbsp;</div>
            </div>
            <div className='list'>
              {vehicles.map((row) => (
                <VehicleItem
                  key={row.id}
                  detail={row}
                  isRemoveVehicle
                  userType={USER_TYPE.CORPORATE}
                  isChecked={row.is_checked}
                  isList={true}
                  onCheckClick={checkClickHandler}
                  fromPage='Delete Vehicle'
                />
              ))}
            </div>
          </div>
          <div className='delete-vehicle__body__container__actions'>
            <Button
              type='submit'
              color='primary'
              label='vehicle.deleteVehicle.confirm'
              onClick={confirmDeleteHandler}
              disabled={!atleastVehicleChecked}
            />
            <Button type='button' color='dark' label='vehicle.deleteVehicle.cancel' onClick={goBackHandler} />
          </div>
        </div>
      </div>
      <DeleteVehicleReasonModal isOpen={shouldModalOpen} onConfirm={modalConfirmHandler} onClose={modalCloseHandler} />
    </div>
  )
}

export default DeleteVehicles
