import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import './index.scss'
import Button from 'components/elements/Button'
import { lineIcon, phoneIcon } from 'config/icon'
import moment from 'moment'
import 'moment/locale/th'
import 'config/_theme.scss'

const tpisLineContact = (lineId, message) => {
  window.open(`https://line.me/R/oaMessage/${lineId}/?${message}`)
}

const dateFormatTH = (date) => {
  return moment(date).locale('th').add(543, 'years').format('DD MMMM YYYY')
}

const dateFormatMsg = (date) => {
  return moment(date).add(543, 'years').format('DD/MM/YYYY')
}

const VehicleDetailInsurance = (props) => {
  const insuranceInfo = props.vehicleDetail.insuranceInfo
  const {
    insurance_plan: insurancePlan,
    insurance_end_date: insuranceEndDate,
    insurance_remind: insuranceRemind,
    insurance_name: insuranceName,
    insurance_tel: insuranceTel,
    insurance_tel_display: insuranceTelDisplay,
    renew_info: reNewInfo
  } = insuranceInfo
  const vin = props.vehicleDetail.vin
  const licensePlate = props.vehicleDetail.license_plate

  let presetMsg = `ลูกค้า ${licensePlate ? 'ทะเบียน ' + licensePlate : '-'} ต้องการทราบข้อมูลสิทธิพิเศษการต่อประกันภัย ${
    '[' + vin + ']'
  } ${dateFormatMsg(insuranceEndDate)} _From my-ISUZU`

  const encodeMsg = encodeURI(presetMsg)

  return (
    <div className='detail-insurance-wrapper__details__body__details'>
      <div className='detail-vehicle-wrapper__details__body__details__section-name'>
        <FormattedMessage id='common.labels.insuranceInfo' defaultMessage='common.labels.insuranceInfo' />
      </div>
      <div className='detail-vehicle-wrapper__details__body__details__field'>
        <div className='label'>
          <FormattedMessage id='vehicleInsurance.labels.insurancePlan' defaultMessage='vehicleInsurance.labels.insurancePlan' />
        </div>
        <div className='value'>{insuranceInfo ? insurancePlan : ''}</div>
      </div>

      <div className='detail-vehicle-wrapper__details__body__details__field'>
        <div className='label'>
          <FormattedMessage id='vehicleInsurance.labels.insuranceExpire' defaultMessage='vehicleInsurance.labels.insuranceExpire' />
        </div>
        <div className='value' style={{ color: insuranceRemind ? 'primary' : undefined }}>
          {dateFormatTH(insuranceEndDate)}
        </div>
      </div>

      <div className='detail-vehicle-wrapper__details__body__details__group-field'>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage
              id='vehicleInsurance.labels.insuranceCompanyName'
              defaultMessage='vehicleInsurance.labels.insuranceCompanyName'
            />
          </div>
          <div className='value'>{insuranceName}</div>
        </div>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage id='vehicleInsurance.labels.insuranceContactNo' defaultMessage='vehicleInsurance.labels.insuranceContactNo' />
          </div>
          <div className='value'>
            <a className='calling-insurance-company' id='calling-insurance-company' href={insuranceInfo && 'tel:' + insuranceTel}>
              {insuranceInfo && insuranceTelDisplay}
            </a>
          </div>
        </div>
      </div>

      <div className='detail-vehicle-wrapper__details__body__line'></div>

      {reNewInfo && (
        <div className='detail-insurance-wrapper__details__body__details__field detail-insurance__body__contact-tpis'>
          <div className='detail-insurance-wrapper__details__body__line'></div>
          <div className='detail-insurance-wrapper__details__body__details__field detail-insurance__body__contact-tpis__title'>
            <FormattedHTMLMessage id='vehicleInsurance.contactTitle' defaultMessage='vehicleInsurance.contactTitle' />
          </div>
          <div className='detail-insurance-wrapper__details__body__details__field detail-insurance__body__contact-tpis__btn'>
            <Button
              type='button'
              color='primary'
              label='vehicleInsurance.button.line'
              className='contact-tpis-btn contact-with-tpis-line'
              id='contact-with-tpis-line'
              customIcon={lineIcon}
              onClick={() => tpisLineContact(reNewInfo && reNewInfo.broker_sales_line_id, encodeMsg)}
              disabled={props.isDemoUser}
            />

            <Button
              type='button'
              color='primary'
              label='vehicleInsurance.button.tel'
              className='contact-tpis-btn contact-with-tpis-phone'
              id='contact-with-tpis-phone'
              customIcon={phoneIcon}
              onClick={() => window.location.assign(reNewInfo && 'tel:' + reNewInfo.broker_sales_contact_number)}
              disabled={props.isDemoUser}
            />
          </div>
          <div className='detail-vehicle-wrapper__details__body__line'></div>
        </div>
      )}
    </div>
  )
}

export default VehicleDetailInsurance
