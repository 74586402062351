import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import th from 'date-fns/locale/th'
import es from 'date-fns/locale/en-US'
import moment from 'moment'
import 'moment/locale/th'

// Methods
import { FormattedMessage } from 'react-intl'
import { checkLanguage } from 'utils/languages'

// Components
import Select from 'components/elements/Select'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'

// Constants
import { serviceTypeOptions, timeTHOptions, timeENOptions, USER_TYPE } from '../../../../config/constant'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, Form, reduxForm } from 'redux-form'

// Proptypes
import PropTypes from 'prop-types'

// Action
import { getVehicles } from 'store/actions/vehicles'
import { getProfile } from 'store/actions/profile'
import { updateDealerAppointment } from 'store/actions/dealers'

// Utils
import { checkError } from 'utils/helper'
import { isLINEBrowser, isMobileApp } from 'utils/validation'

// Styles
import './_index.scss'
import 'react-datepicker/dist/react-datepicker.css'

const DealerAppointment = (props) => {
  const [licenseState, _setLicense] = useState(props.vehicleDetail.license_plate ? props.vehicleDetail.license_plate : '')
  const [licenseInput, setLicenseInput] = useState('')
  const [timeState, _setTime] = useState('')
  const [serviceType, setServiceType] = useState('')
  const [serviceInput, setServiceInput] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [validate, setValidate] = useState(true)

  useEffect(() => {
    props.getVehicles().catch((err) => {
      checkError(err)
    })
  }, [])

  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container')
    Array.from(datePickers).forEach((el) => el.childNodes[0].setAttribute('readOnly', true))
  })

  const handleDatePicker = (date) => {
    setStartDate(date)
  }

  const setLicense = (data) => {
    _setLicense(data.label)
  }

  const setTime = (time) => {
    _setTime(time)
  }

  const setService = (type) => {
    setServiceType(type.label)
  }

  const licenseField = (event) => {
    setLicenseInput(event.target.value)
  }

  const serviceField = (event) => {
    setServiceInput(event.target.value)
  }

  const getSourceChannel = () => {
    if (isMobileApp()) {
      return 'mobile_app'
    }
    if (isLINEBrowser(navigator)) {
      return 'line_browser'
    }
    return 'web'
  }

  const submit = async () => {
    let date = startDate.toDateString()
    date = moment(date).format('yyyy-MM-DD')
    const license = licenseState

    let vehicle = props.vehicles.filter(function (vehicle) {
      return vehicle.vehicle.license_plate === license
    })

    const vehicle_hash_id = vehicle.length > 0 ? vehicle[0].vehicle.id : null

    const payload = {
      vehicle_hash_id: vehicle_hash_id && licenseInput === '' ? vehicle_hash_id : null,
      vehicle_other: licenseInput !== '' ? licenseInput.toString() : null,
      service_center_code: props.serviceCenter,
      appointment_date: startDate ? date : null,
      appointment_time: timeState.label ? timeState.value.toString() : null,
      job_type: serviceType ? serviceType : '',
      job_type_other: serviceInput !== '' ? serviceInput : null,
      source_channel: getSourceChannel()
    }

    if (licenseState !== '' && timeState !== '' && serviceType !== '') {
      try {
        await props.updateDealerAppointment(payload)
      } catch (err) {
        checkError(err)
      }
    }
  }

  const submitToLine = () => {
    let msg = ''
    const full_name = props.profile.given_name ? props.profile.given_name : ''
    const company_name = props.profile.corporate ? props.profile.corporate.company_name : ''
    const mobile = props.profile.mobile_phone ? props.profile.mobile_phone : ''
    const license = licenseState ? licenseState : ''
    let date = startDate ? startDate.toDateString() : ''
    const time = timeState ? timeState.label.toString() : ''
    let service = serviceType ? serviceType : ''

    if (checkLanguage() === 'en') {
      if (serviceType.includes('Maintenance')) {
        service = 'เช็คตามระยะ'
      } else if (serviceType.includes('General')) {
        service = 'ซ่อมทั่วไป'
      } else {
        service = serviceInput
      }
    }

    date = moment(date).local('th').format('dddd, DD MMMM YYYY')

    if (props.profile.type === USER_TYPE.INDIVIDUAL) {
      msg = `คุณ ${full_name} `
    } else {
      msg = `${company_name} `
    }

    if (license !== '' && license !== 'Other' && license !== 'อื่นๆ') {
      msg += `(${license})`
    } else if ((license === 'Other' || license === 'อื่นๆ') && licenseInput !== '') {
      msg += `(${licenseInput})`
    }

    msg += `
ต้องการนัดหมาย `
    if (service !== '' && service !== 'Other' && service !== 'อื่นๆ') {
      msg += `“${service}`
      if (serviceInput !== '') {
        msg += ` (${serviceInput})” `
      } else {
        msg += `” `
      }
    } else if ((service === 'Other' || service === 'อื่นๆ') && serviceInput !== '') {
      msg += `“${serviceInput}” `
    }

    msg += `ที่ ${props.branchName}
ในวันที่ ${date} เวลา ${time}
กรุณาติดต่อลูกค้าเพื่อยืนยันการนัดหมาย `
    if (mobile !== '') {
      msg += `ที่ ${mobile}`
    }
    if (service === 'อื่นๆ' && serviceInput === '') {
      setValidate(false)
    } else if ((license !== '' && time !== '' && service !== '') || props.vehicles.length === 1) {
      setValidate(true)
      if (props.line === null) {
        window.open(`https://line.me/R/oaMessage/@isuzuthai/?${encodeURI(msg)}`)
      } else {
        window.open(`https://line.me/R/oaMessage/@${props.line}/?${encodeURI(msg)}`)
      }
    } else {
      setValidate(false)
    }
  }

  const lang = checkLanguage() === 'th' ? 'th' : 'en'

  const otherEN = {
    label: 'Other',
    value: 'other'
  }

  const otherTH = {
    label: 'อื่นๆ',
    value: 'other'
  }

  let vehicles = props.vehicles
    .map(function (record) {
      return record.vehicle
    })
    .filter(function (vehicle) {
      return vehicle.license_plate != null
    })

  let licenseOptions = vehicles.map(
    (vehicle) =>
      vehicle.license_plate !== null && {
        label: vehicle.license_plate,
        value: vehicle.license_plate
      }
  )

  licenseOptions[licenseOptions.length] = lang === 'th' ? otherTH : otherEN
  return (
    <div className='modal__content'>
      <Form onSubmit={props.handleSubmit(submitToLine)} className='detail-vehicle-form__form'>
        <div className='search-dealer__selection'>
          <div className='body'>
            {props.vehicles && props.vehicles.length > 0 && (
              <div className='select__license'>
                <div className='select__header'>
                  <FormattedMessage
                    id={'dealerAppointment.dropdown.licensePlate'}
                    defaultMessage={'dealerAppointment.dropdown.licensePlate'}
                  />
                </div>
                <Select
                  id='select-license'
                  options={licenseOptions}
                  placeholder='dealerAppointment.dropdown.placeholders.licensePlate'
                  value={
                    props.vehicleDetail.license_plate
                      ? props.vehicleDetail.license_plate
                      : vehicles.length === 1
                      ? vehicles[0].license_plate
                      : ''
                  }
                  onChange={setLicense}
                  noMapping
                />
                {vehicles.length !== 1 && licenseState === '' && validate === false ? (
                  <div className='select__remark'>
                    <FormattedMessage id={'dealerAppointment.validate.license'} defaultMessage={'dealerAppointment.validate.license'} />
                  </div>
                ) : (
                  ''
                )}
                {(licenseState === 'Other' || licenseState === 'อื่นๆ') && (
                  <Field
                    component={Input}
                    name='license-remark'
                    type='text'
                    label='dealerAppointment.dropdown.others'
                    placeholder='dealerAppointment.dropdown.placeholders.others'
                    onChange={licenseField}
                    value={licenseInput}
                    isClearable
                  />
                )}
              </div>
            )}
            <div className='select__header'>
              <FormattedMessage id={'dealerAppointment.dropdown.dateTime'} defaultMessage={'dealerAppointment.dropdown.dateTime'} />
            </div>
            <div className='select__date'>
              <div className='select__header'>
                <FormattedMessage id={'dealerAppointment.dropdown.date'} defaultMessage={'dealerAppointment.dropdown.date'} />
              </div>
              <div className='date-contain'>
                <DatePicker
                  minDate={new Date()}
                  selected={startDate}
                  onChange={handleDatePicker}
                  locale={lang === 'th' ? th : es}
                  className='select__date-picker'
                  dateFormat='EEEE, dd MMM yyyy'
                  disabledKeyboardNavigation
                />
              </div>
            </div>
            <div className='select__time'>
              <div className='select__header'>
                <FormattedMessage id={'dealerAppointment.dropdown.time'} defaultMessage={'dealerAppointment.dropdown.time'} />
              </div>
              {lang === 'th' ? (
                <div className='time-selection'>
                  <Select
                    id='select-time'
                    options={timeTHOptions}
                    placeholder='dealerAppointment.dropdown.placeholders.time'
                    onChange={setTime}
                    noMapping
                  />
                </div>
              ) : (
                <div className='time-selection'>
                  <Select
                    id='select-time'
                    options={timeENOptions}
                    placeholder='dealerAppointment.dropdown.placeholders.time'
                    onChange={setTime}
                    noMapping
                  />
                </div>
              )}
              {timeState === '' && validate === false && (
                <div className='select__remark'>
                  <FormattedMessage id={'dealerAppointment.validate.time'} defaultMessage={'dealerAppointment.validate.time'} />
                </div>
              )}
            </div>
            <div className='select__service'>
              <div className='select__header'>
                <FormattedMessage id={'dealerAppointment.dropdown.serviceType'} defaultMessage={'dealerAppointment.dropdown.serviceType'} />
              </div>
              <Select
                id='select-service'
                options={serviceTypeOptions}
                placeholder='dealerAppointment.dropdown.placeholders.serviceType'
                // onChange={ (selected) =>
                //   { this.setState({
                //     serviceType: this.context.intl.formatMessage({
                //       id: selected.label.props.id,
                //       defaultMessage: selected.label.props.id })
                //     })
                //   }}
                onChange={setService}
                noMapping
              />
              {serviceType === '' && validate === false && (
                <div className='select__remark'>
                  <FormattedMessage id={'dealerAppointment.validate.service'} defaultMessage={'dealerAppointment.validate.service'} />
                </div>
              )}
              <Field
                name='service-remark'
                component={Input}
                type='text'
                label='dealerAppointment.dropdown.detail'
                isClearable
                placeholder='dealerAppointment.dropdown.placeholders.others'
                onChange={serviceField}
                value={serviceInput}
              />
              {serviceType === 'อื่นๆ' && serviceInput === '' && validate === false && (
                <div className='select__remark'>
                  <FormattedMessage
                    id={'dealerAppointment.validate.serviceRemark'}
                    defaultMessage={'dealerAppointment.validate.serviceRemark'}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='remark'>
            <FormattedMessage id={'dealerAppointment.remark'} defaultMessage={'dealerAppointment.remark'} />
          </div>
          <Button type='submit' color='primary' label='dealerAppointment.button' onClick={submit} />
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
  vehicles: state.vehicles.data,
  profile: state.profile
})

const mapDispatchToProps /*istanbul ignore next*/ = (dispatch) =>
  bindActionCreators(
    {
      getVehicles,
      getProfile,
      updateDealerAppointment
    },
    dispatch
  )

DealerAppointment.propTypes = {
  getVehicles: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateDealerAppointment: PropTypes.func
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'dealerAppointment'
    })(DealerAppointment)
  )
)
