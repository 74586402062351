import Cookies from 'js-cookie'

/**
 *
 */
export const checkScale = () => {
  const scale = Cookies.get('scale') ? Cookies.get('scale') : 'medium'
  return scale
}

/**
 *
 */
export const setScale = (value) => {
  Cookies.set('scale', value)
}

/**
 *
 */
// export const onScaleChanged =
// /* istanbul ignore next */
// (scale) => {
//   if (scale.value) {
//     store.dispatch(changeScale(scale.value))
//     Cookies.set('scale', scale.value)
//   }
// }
