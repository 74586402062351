import React, { useEffect } from 'react'

import DetailHeader from 'components/layouts/DetailHeader'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import CompanyListTable from 'domain/Company/components/CompanyListTable'

import { getProfile } from 'store/actions/profile'
import { getCompanyList } from 'store/actions/company'

// Utils
import { checkError } from 'utils/helper'

import './_index.scss'

const CompanyList = (props) => {
  useEffect(() => {
    props
      .getCompanyList()
      .then(() => {
        if (props.profile.type && props.profile.type !== 2) {
          props.history.push('/')
        }
      })
      .catch((err) => {
        checkError(err)
      })
  }, [])

  const childCompany = []

  if (props.profile.corporate && props.company) {
    childCompany.push({
      company_reg_id: props.profile.corporate.company_reg_id ? props.profile.corporate.company_reg_id : '',
      company_name: props.profile.corporate.company_name ? props.profile.corporate.company_name : ''
    })

    props.company.data.forEach((company) => {
      childCompany.push(company)
    })
  }

  return (
    <div className='company-list'>
      <div className='company-list__header'>
        <DetailHeader showBack type={'company-list'} />
      </div>
      <CompanyListTable childCompany={childCompany} />
    </div>
  )
}

export const mapStateToProps = (state) => ({
  profile: state.profile,
  company: state.company
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        getProfile,
        getCompanyList
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList)
