import { ISP_VEHICLES } from '../definitions'
import { hideLoader } from 'store/actions/loader'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'
import api from 'api/'

const getISPVehicles = (loader = true) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      api
        .getISPVehicles()
        .then((res) => {
          let data = res.data
          const total_count = parseInt(res.headers['x-total-count']) || res.data.length
          const ispVehicle = { ...data }
          dispatch({
            type: ISP_VEHICLES.GET,
            ispVehicle,
            total_count
          })
          if (loader) dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

export { getISPVehicles }
