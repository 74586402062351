/* eslint-disable */
import React, { useEffect } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import PrivilegesConditions from 'domain/Excellency/components/PrivilegesConditions'

// Actions
import { getVehicleDetail } from 'store/actions/vehicles'

// Styles
import './_index.scss'

import { postPageView } from 'store/actions/profile'

import { checkError } from 'utils/helper'

const ExcellencyConditions = ({ getVehicleDetail, postPageView, match, vehicleDetail }) => {
  useEffect(() => {
    getVehicleDetail(match.params.id).catch((err) => {
      checkError(err)
    })
    postPageView()
  }, [])

  return (
    <div className='excellency-conditions'>
      <DetailHeader showBack type='basic' />

      {vehicleDetail && vehicleDetail.vehicle && vehicleDetail.vehicle.excellency && (
        <PrivilegesConditions detail={vehicleDetail.vehicle.excellency} isNseries={vehicleDetail.vehicle.is_nseries} />
      )}
    </div>
  )
}

ExcellencyConditions.propTypes = {
  getVehicleDetail: PropTypes.func,
  vehicleDetail: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

const mapStateToProps =
  /*istanbul ignore next*/
  (state) => ({
    profile: state.profile,
    vehicleDetail: state.vehicleDetail
  })

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getVehicleDetail,
        postPageView
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExcellencyConditions))
