import { REPAIR_HISTORY, CLEAR_THIS_STATE } from '../definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case REPAIR_HISTORY.DETAIL:
      return Object.assign({}, action.repairDetail)

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
