import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'components/elements/Button'

import './_index.scss'
import api from 'api/'

import moment from 'moment'
import { myIsuzuIcon, closeIcon } from 'config/icon'
import { checkError } from 'utils/helper'

const Add2Home = () => {
  const [isShow, setIsShow] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const isLineBrowser = navigator.userAgent.indexOf('Line/') > -1

    const lastTimeStamp = isLastTimeDiff()

    let a2hsStatus = localStorage.getItem('a2hs_status')

    if (a2hsStatus == null) {
      localStorage.setItem('a2hs_status', '101')
      a2hsStatus = localStorage.getItem('a2hs_status')
    }

    if (!isSafari && !isLineBrowser && a2hsStatus !== '201') {
      if (lastTimeStamp != null && lastTimeStamp < 1) {
        setIsShow(false)
      } else {
        if (a2hsStatus === '101') {
          setIsShow(true)
        } else if (a2hsStatus === '202') {
          setIsShow(true)
          api.addToHomeScreen({ status: 101 }).catch((error) => {
            checkError(error)
          })
          localStorage.setItem('a2hs_status', '101')
        }
      }
    } else {
      setIsShow(false)
    }

    if (isMobile()) {
      if (!navigator.userAgent.match(/Android/i)) {
        setIsShow(false)
      }
    }
  }, [])

  useEffect(() => {
    isShow && location.pathname === '/'
      ? document.body.classList.add('install-app-bar-visible')
      : document.body.classList.remove('install-app-bar-visible')
  })

  const isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }

  const isLastTimeDiff = () => {
    const lastTimeStamp = localStorage.getItem('lastTimeStamp')
    if (lastTimeStamp != null) {
      let timestamp = moment(lastTimeStamp).format('YYYY-MM-DD hh:mm:ss')
      let now = moment().format('YYYY-MM-DD hh:mm:ss')
      return moment(now).diff(moment(timestamp), 'months')
    } else {
      return null
    }
  }

  const closeAddToHome = () => {
    setIsShow(false)
    api.addToHomeScreen({ status: 202 }).catch((error) => {
      checkError(error)
    })
    localStorage.setItem('a2hs_status', '202')

    const now = moment().format('YYYY-MM-DD hh:mm:ss')
    localStorage.setItem('lastTimeStamp', now)
  }

  const addToHome = () => {
    let promptEvent = window.deferredPrompt

    if (!promptEvent) {
      // The deferred prompt isn't available.
      return
    }
    // Show the install prompt.
    promptEvent.prompt()
    // Log the result
    return promptEvent.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setIsShow(false)
          api.addToHomeScreen({ status: 201 }).catch((error) => {
            checkError(error)
          })
          localStorage.setItem('a2hs_status', '201')
        } else {
          setIsShow(true)
          api.addToHomeScreen({ status: 101 }).catch((error) => {
            checkError(error)
          })
          localStorage.setItem('a2hs_status', '101')
        }
        promptEvent = null
      })
      .then(() => {
        window.deferredPrompt = undefined
        return Promise.resolve()
      })
  }

  return (
    <div className='add2home' style={{ display: isShow && location.pathname === '/' ? 'block' : 'none' }}>
      {isMobile() === true ? (
        <div className='add2home__wrapper'>
          <div className='install__app md:justify-center'>
            <p>App my-Isuzu บนหน้าจอติดตั้ง</p>
            <Button color='primary' label='common.button.install' onClick={addToHome} />
          </div>
          <Button className='add2home__install__app__close__btn' icon={closeIcon} onClick={closeAddToHome} />
        </div>
      ) : (
        <div className='add2home__wrapper'>
          <div className='install__app md:justify-center'>
            <p className='hidden md:block'>เพื่อความสะดวกสามารถติดตั้ง</p>
            <i className='hidden md:block'>{myIsuzuIcon}</i>
            <p>App my-ISUZU บนหน้าจอได้</p>
            <Button color='primary' label='common.button.install' onClick={addToHome} />
          </div>
          <Button className='add2home__install__app__close__btn' icon={closeIcon} onClick={closeAddToHome} />
        </div>
      )}
    </div>
  )
}

export default Add2Home
