import React from 'react';
import { useEffect } from 'react';

export function InternalTestError() {
  useEffect(() => {
    throw new Error('Error while rendering the page');
  }, []);

  return <div>This component should crash before it render this element.</div>;
}
