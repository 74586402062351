import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Components
import ReactSelect from 'react-select'

// Methods
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'

import filterIcon from 'assets/images/icons/filter.png'

// Styles
import './_index.scss'

// Map Material icon with custom icon
const CUSTOM_ICONS = {
  filter_list: filterIcon
}

const Select = (props) => {
  const mapOption = () => {
    let newOptions = props.options
    if (!props.noMapping) {
      newOptions = props.options.map((d) => ({
        label: props.optionLabel(d),
        value: props.optionValue(d)
      }))
    }

    if (props.noSelect) newOptions.unshift({ label: props.placeholder || 'Select Option', value: null })

    return newOptions
  }

  const newOptions = mapOption

  const [options, setOptions] = useState(newOptions)
  const [originalOption, setOriginalOption] = useState(props.options)
  const [selectedOption, setSelectedOption] = useState(_find(newOptions, { value: props.value }))

  useEffect(() => {
    if (!_isEqual(originalOption, props.options)) {
      const newOptions = mapOption
      setSelectedOption(_find(newOptions, { value: props.value }))
      setOptions(newOptions)
      setOriginalOption(props.options)
    }
  }, [props.options])

  useEffect(() => {
    setSelectedOption(undefined)
    setOptions([])
    setOriginalOption([])
  }, [])

  const changeSelect = (selectedOption) => {
    setSelectedOption(selectedOption)
    props.onChange(selectedOption)
  }

  const { id, placeholder, isDisabled, isSearchable, prefixIcon, className, isFlipIcon, onIconClick, ...restProps } = props

  const classes = classnames('select', { 'select__has--prefix-icon': prefixIcon }, className)
  const prefixIconClasses = classnames('material-icons select__prefix-icon', { 'is-flipped': isFlipIcon, 'is-clickable': !!onIconClick })

  const PrefixIcon = () => {
    const foundIcon = Object.keys(CUSTOM_ICONS).find((key) => key === prefixIcon)

    if (foundIcon) {
      return <img className={prefixIconClasses} src={CUSTOM_ICONS[foundIcon]} alt='' />
    }

    return (
      <i className={prefixIconClasses} onClick={onIconClick}>
        {prefixIcon}
      </i>
    )
  }

  return (
    <div className={classes}>
      {prefixIcon && <PrefixIcon />}
      <ReactSelect
        classNamePrefix='select'
        id={id}
        placeholder={placeholder && props.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
        isSearchable={isSearchable}
        options={options}
        {...restProps}
        value={selectedOption}
        isDisabled={isDisabled}
        onChange={changeSelect}
      />
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isFlipIcon: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefixIcon: PropTypes.string,
  onIconClick: PropTypes.func
}

Select.defaultProps = {
  className: '',
  options: [],
  value: '',
  placeholder: '',
  isSearchable: false,
  isFlipIcon: false,
  prefixIcon: '',
  onIconClick: null
}

export default injectIntl(Select)
