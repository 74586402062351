import Treasure from 'td-js-sdk'
import Cookies from 'js-cookie'
import moment from 'moment'

const test_myi_database = 'test_myisuzu'
const test_myi_key = ''

let td = undefined

if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
  if (process.env.NODE_ENV === 'development') {
    td = new Treasure({
      database: test_myi_database,
      writeKey: test_myi_key,
      useServerSideCookie: true,
      startInSignedMode: true
    })
  } else {
    td = new Treasure({
      database: process.env.REACT_APP_TD_DB_NAME,
      writeKey: process.env.REACT_APP_TD_API_KEY,
      useServerSideCookie: true,
      startInSignedMode: true
    })
  }

  td.setSignedMode()
  var successCallback = function (serverSideId) {
    // celebrate()
    td.set('pageviews', { td_ssc_id: serverSideId })
    td.trackPageview('pageviews')
  }
  var errorCallback = function () {
    // cry()
    td.trackPageview('pageviews')
  }
  td.fetchServerCookie(successCallback, errorCallback)
}
export const pageview = (hash_id) => {
  if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
    const td_ssc_id = Cookies.get('_td_ssc_id')
    if (!td) {
      if (process.env.NODE_ENV === 'development') {
        td = new Treasure({
          database: test_myi_database,
          writeKey: test_myi_key,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      } else {
        td = new Treasure({
          database: process.env.REACT_APP_TD_DB_NAME,
          writeKey: process.env.REACT_APP_TD_API_KEY,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      }
      td.setSignedMode()
      td.fetchServerCookie(successCallback, errorCallback)
    }

    hash_id && td.set('pageviews', { user_hash_id: hash_id })
    hash_id && td.set('clicks', { user_hash_id: hash_id })
    td_ssc_id && td.set('pageviews', { td_ssc_id: td_ssc_id })

    td.trackPageview('pageviews')
  }
}
export const trackClick = (hash_id) => {
  if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
    if (!td) {
      if (process.env.NODE_ENV === 'development') {
        td = new Treasure({
          database: test_myi_database,
          writeKey: test_myi_key,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      } else {
        td = new Treasure({
          database: process.env.REACT_APP_TD_DB_NAME,
          writeKey: process.env.REACT_APP_TD_API_KEY,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      }
      td.setSignedMode()
    }

    hash_id && td.set('clicks', { user_hash_id: hash_id })

    td.trackClicks()
  }
}

export const resetHashId = () => {
  if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
    if (!td) {
      if (process.env.NODE_ENV === 'development') {
        td = new Treasure({
          database: test_myi_database,
          writeKey: test_myi_key,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      } else {
        td = new Treasure({
          database: process.env.REACT_APP_TD_DB_NAME,
          writeKey: process.env.REACT_APP_TD_API_KEY,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      }
      td.setSignedMode()
    }

    td.set('pageviews', { user_hash_id: null })
    td.set('clicks', { user_hash_id: null })
    td.trackPageview('pageviews')
    td.trackClicks()
  }
}

export const trackRefinanceEvent = (vin, is_til) => {
  if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
    const timestamp = moment().utcOffset(7).format('YYYY-MM-DD HH:mm:ss')

    if (!td) {
      if (process.env.NODE_ENV === 'development') {
        td = new Treasure({
          database: test_myi_database,
          writeKey: test_myi_key,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      } else {
        td = new Treasure({
          database: process.env.REACT_APP_TD_DB_NAME,
          writeKey: process.env.REACT_APP_TD_API_KEY,
          useServerSideCookie: true,
          startInSignedMode: true
        })
      }
      td.setSignedMode()
    }

    if (vin && typeof is_til === 'boolean') {
      td.trackEvent('refinance_event', { vin, is_til, timestamp })
    }
  }
}
