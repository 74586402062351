import React from 'react'

import { TextField, withStyles } from '@material-ui/core'

import { css, styles } from './css.js'

import './index.scss'

const RenderTextField = ({ label, input, classes, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    label={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
    id='outlined-basic'
    variant='outlined'
    InputLabelProps={{
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
        outlined: classes.outlined,
        shrink: classes.shrink
      },
      style: css.input
    }}
    InputProps={{
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
        error: classes.error
      },
      style: css.inputLabel
    }}
  />
)

export default withStyles(styles)(RenderTextField)
