import React from 'react'

// Components
import Button from 'components/elements/Button'

// Constants
import { activityTypes } from 'config/constant'

// APIs
import api from 'api/'

export const RegisButton = (ispDetail, ispList, user_type, history) => {
  return (
    <Button
      type='red'
      label='ispPackage.button.register'
      onClick={(e) => {
        e.stopPropagation()
        history.push({
          pathname: `/isp-selection/${ispDetail.hash_id}`,
          state: {
            ispDetail: ispDetail,
            ispList: ispList
          }
        })
        api.logActivities({
          type: activityTypes.ISP,
          description: `เลือกรถเพื่อสมัคร ISP|${ispDetail.vin ? ispDetail.vin : ''}|${
            ispDetail.license_plate ? ispDetail.license_plate : ''
          }|||${user_type}|`
        })
      }}
    />
  )
}
