import { useEffect, useState } from 'react'

/**
 * @description Get the environment from the hostname
 * @returns {'DEV' | 'QARP' | 'UAT' | undefined}
 */
export const useEnvironment = () => {
  const [environment, setEnvironment] = useState(undefined)

  useEffect(() => {
    setEnvironment(
      window.location.hostname.includes('dev')
        ? 'DEV'
        : window.location.hostname.includes('qarp')
        ? 'QARP'
        : window.location.hostname.includes('uat')
        ? 'UAT'
        : undefined
    )
  }, [])

  return { environment }
}
