import React, { useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Router
import { withRouter, useHistory } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Components
import Modal from 'components/elements/Modal'
import CouponItemModal from './CouponItemModal'
import newEN from 'assets/images/new-en.png'
import newTH from 'assets/images/new-th.png'

// Method
import * as format from 'utils/format'
import { checkLanguage } from 'utils/languages'
import * as helper from 'utils/helper'
import isEmpty from 'lodash/isEmpty'
import api from 'api/'
import { checkError } from 'utils/helper'

// Actions
import { readServiceCouponsSlider, setCashCouponGroup, readGroupCashCoupons, getServiceCouponsSlider } from 'store/actions/coupons'
import { getVehicleDetail } from 'store/actions/vehicles'

// Styles
import './_index.scss'

// Constants
import { activityTypes } from 'config/constant'

const CouponItem = (props) => {
  const history = useHistory()
  const modalRef = useRef()

  useEffect(() => {
    const { detail } = props
    if (detail && detail.onDidMountOpenModal) {
      setTimeout(openModalCoupon, 500)
    }
  }, [])

  /**
   * Only open modal if coupon status is active or defered
   */
  const openModalCoupon = () => {
    const { detail, readServiceCouponsSlider, getServiceCouponsSlider, getVehicleDetail, readGroupCashCoupons } = props

    if (!(detail.status === 302 || detail.status === 201)) {
      modalRef.current.openModal()

      /*istanbul ignore else*/
      if (!detail.is_read) {
        /*istanbul ignore else*/
        if (detail.coupon_info.coupon_category === 'SC') {
          if (detail.coupon_info.from_home) {
            readServiceCouponsSlider(detail.campaign_group, detail.vehicle.vin)
              .then(getServiceCouponsSlider)
              .catch((err) => checkError(err))
          } else if (detail.coupon_info.from_vehicleDetail) {
            readServiceCouponsSlider(detail.campaign_group, detail.vehicle.vin)
              .then(() => {
                getVehicleDetail(detail.vehicle.user_vehicle.id, history, false)
              })
              .catch((err) => checkError(err))
          }
        } else if (detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.from_vehicleDetail && readGroupCashCoupons) {
          let param = [detail.campaign_code, detail.valid_until, detail.value]
          if (isEmpty(detail.user)) {
            param.push(detail.vehicle.id)
          }
          readGroupCashCoupons(...param)
            .then(() => {
              getVehicleDetail(detail.vehicle.user_vehicle.id, history, false)
            })
            .catch((err) => checkError(err))
        }
      }
    }
  }

  const onCouponClick = () => {
    if (props.detail.coupon_info.from_home && props.detail.coupon_info.coupon_category === 'CC') {
      props.setCashCouponGroup(
        props.detail.coupon_info.coupon_category,
        props.detail.value,
        props.detail.coupon_info.campaign_code,
        props.detail.vehicle.id,
        props.detail.valid_until,
        props.detail.coupon_info.ids
      )
      api
        .logActivities({
          type: activityTypes.CASH_COUPON,
          description: `Click cash coupon|${props.detail.coupon_info.name || ''}|${props.detail.vehicle.vin || ''}|${
            props.detail.vehicle.license_plate || ''
          }|${props.detail.campaign_code || ''}|${props.detail.cash_coupon_number || ''}|${props.fromPage || ''}`
        })
        .catch((err) => checkError(err))

      props.history.push('/coupons')
    } else {
      openModalCoupon()
      if (props.detail.coupon_info.coupon_category === 'CC') {
        api
          .logActivities({
            type: activityTypes.CASH_COUPON,
            description: `Click cash coupon|${props.detail.coupon_info.name || ''}|${props.detail.vehicle.vin || ''}|${
              props.detail.vehicle.license_plate || ''
            }|${props.detail.campaign_code || ''}|${props.detail.cash_coupon_number || ''}|${props.fromPage || ''}`
          })
          .catch((err) => checkError(err))
      } else {
        api
          .logActivities({
            type: activityTypes.SERVICE_COUPON,
            description: `Click service coupon|${props.detail.coupon_info.name || ''}|${props.detail.vehicle.vin || ''}|${
              props.detail.vehicle.license_plate || ''
            }|${props.detail.detail.campaign_code || ''}|${props.detail.detail.coupon_kind || ''}|${props.fromPage || ''}`
          })
          .catch((err) => checkError(err))
      }
    }
  }
  const {
    detail,
    isPlaceholder,
    isSlider,
    userType,
    couponType,
    isList,
    isCreate,
    fromHome,
    readServiceCouponsSlider,
    footer,
    staticContext,
    getVehicleDetail,
    getServiceCouponsSlider,
    setCashCouponGroup,
    readGroupCashCoupons,
    fromPage,
    isChecked,
    ...otherProps
  } = props

  const classes = classnames(
    'coupon-item',
    { 'coupon-item--expired': detail.status === 302 },
    { 'coupon-item--expired': detail.status === 201 },
    { 'coupon-item--defered': detail.status === 301 },
    { 'coupon-item--defered': detail.status === 301 },
    { 'coupon-item--service': detail.coupon_info.coupon_category === 'SC' },
    { 'coupon-item--cash': detail.coupon_info.coupon_category === 'CC' },
    { 'coupon-item--group': detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.amt > 1 },
    { 'coupon-item--placeholder': isPlaceholder }
  )

  const list = isPlaceholder ? (
    <div className={classes} {...otherProps}>
      <div className='coupon-item__info'>
        <div className='coupon-item__info__icon'></div>
        <div className='coupon-item__info__expiry'></div>
      </div>

      <div className='coupon-item__separator'></div>

      <div className='coupon-item__desc'>
        <div className='coupon-item__desc__type'></div>
        <div className='coupon-item__desc__name'></div>
        <div className='coupon-item__desc__vehicle'></div>
      </div>
    </div>
  ) : (
    <div>
      <Modal
        mode={3}
        title='coupon.detail.title'
        content={CouponItemModal}
        /** MYI-317 remove download coupon */
        // footer={this.state.modalAction}
        detail={detail}
        ref={modalRef}
        fromPage={props.fromPage}
      />

      <div className={classes} onClick={onCouponClick} {...otherProps}>
        <div className='coupon-item__info'>
          <div className='coupon-item__info__icon'>
            <img alt='logo' src={detail.coupon_info.coupon_logo}></img>
            {detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.value !== 0 && detail.coupon_info.value !== null && (
              <div className='coupon-item__info__value'>
                <span className={`coupon-item__info__value__${format.getNumberDigits(detail.coupon_info.value)}`}>
                  {format.getDisplayNumber(detail.coupon_info.value)}
                  {detail.type.cash_coupon_type === 'DCP' ? '%' : '.-'}
                </span>
              </div>
            )}
          </div>
          <div className='coupon-item__info__expiry'>
            <span>
              <FormattedMessage
                id='coupon.detail.expiry'
                defaultMessage='coupon.detail.expiry'
                values={{ value: detail.coupon_info.coupon_category === 'SC' && detail.coupon_info.asterisk === true ? '*' : '' }}
              />
            </span>
            <div className='coupon-item__info__expiry__date'>{format.showDate(detail.valid_until)}</div>
          </div>
        </div>

        <div className='coupon-item__separator'></div>

        <div className='coupon-item__desc'>
          <div className='coupon-item__desc__name'>{detail.coupon_info.name}</div>

          {detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.from_home && (
            <div className='coupon-item__desc__cash-amt'>
              {detail.coupon_info.is_unlimited ? (
                <FormattedMessage
                  values='coupon.detail.unlimitedCashCoups'
                  id='coupon.detail.unlimitedCashCoups'
                  defaultMessage='coupon.detail.unlimitedCashCoups'
                />
              ) : (
                <FormattedMessage
                  values={{ value: detail.coupon_info.amt }}
                  id='coupon.detail.cashCoups'
                  defaultMessage='coupon.detail.cashCoups'
                />
              )}
            </div>
          )}
          {detail.coupon_info.coupon_category === 'CC' && !detail.coupon_info.from_home && (
            <div className='coupon-item__desc__cash-amt'>
              {detail.is_unlimited_usage ? (
                <FormattedMessage
                  values='coupon.detail.unlimitedCashCoups'
                  id='coupon.detail.unlimitedCashCoups'
                  defaultMessage='coupon.detail.unlimitedCashCoups'
                />
              ) : (
                <>
                  {detail.remaining > 0 ? (
                    <FormattedMessage values={{ value: detail.remaining }} id='coupon.detail.usage' defaultMessage='coupon.detail.usage' />
                  ) : (
                    <br />
                  )}
                </>
              )}
            </div>
          )}

          {detail.coupon_info.cash_coupon_number && (
            <div className='coupon-item__desc__number'>{detail.coupon_info.cash_coupon_number}</div>
          )}

          {detail.coupon_info.coupon_category === 'SC' && <div className='coupon-item__desc__title'>{detail.coupon_info.title}</div>}

          {helper.isHTMLTag(detail.coupon_info.benefits) ? (
            <div className='coupon-item__desc__benefits' dangerouslySetInnerHTML={{ __html: detail.coupon_info.benefits }}></div>
          ) : (
            <div className='coupon-item__desc__benefits'>{detail.coupon_info.benefits}</div>
          )}
          <div className='coupon-item__desc__license-plate'>
            {/* {!isEmpty(detail.user) ? detail.coupon_info.license_plate : format.maskOwnerId(helper.getOwnerId(detail.user))} */}
            {detail.coupon_info.license_plate ? detail.coupon_info.license_plate : format.maskOwnerId(detail.coupon_info.owner_id)}
          </div>
          {detail.status === 302 || detail.status === 201 ? (
            ''
          ) : (
            <div className='coupon-item__desc__more'>
              <FormattedMessage id='coupon.detail.more' defaultMessage='coupon.detail.more' />
            </div>
          )}
          {detail.status === 301 && (
            <div className='coupon-item__desc__deferred'>
              <FormattedMessage id='coupon.detail.deferInfo' defaultMessage='coupon.detail.deferInfo' />
            </div>
          )}
          {((Object.prototype.hasOwnProperty.call(detail.coupon_info, 'is_read') && !detail.coupon_info.is_read) ||
            (!Object.prototype.hasOwnProperty.call(detail.coupon_info, 'is_read') && !detail.is_read)) && (
            <div className='coupon-item__desc__new-badge'>
              <img alt='badge' src={checkLanguage() === 'en' ? newEN : /*istanbul ignore else*/ newTH}></img>
            </div>
          )}
          {detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.marketing_logo && (
            <div className='coupon-item__desc__mark-logo'>
              <img alt='logo' src={detail.coupon_info.marketing_logo}></img>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return list
}

CouponItem.propTypes = {
  isPlaceholder: PropTypes.bool,
  readServiceCouponsSlider: PropTypes.func,
  setCashCouponGroup: PropTypes.func,
  footer: PropTypes.any
}

CouponItem.defaultProps = {
  footer: null,
  detail: {
    valid_until: null,
    status: null,
    coupon_info: {
      name: null,
      type: 1,
      campaign_code: 1,
      coupon_logo: null,
      title: null,
      benefits: null,
      terms: null
    },
    vehicle: {
      vehicle_info: {},
      model: {
        marketing_name: null
      },
      user_vehicle: {
        name: null
      },
      vin: null,
      engine_no: null,
      chassis_no: null,
      license_plate: null
    },
    onDidMountOpenModal: undefined
  },
  isPlaceholder: false
}
const mapDispatchToProps /* istanbul ignore next */ = (dispatch) =>
  bindActionCreators(
    {
      readServiceCouponsSlider,
      readGroupCashCoupons,
      setCashCouponGroup,
      getServiceCouponsSlider,
      getVehicleDetail
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(withRouter(CouponItem))
