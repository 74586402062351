import React, { useEffect } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import DetailVehicleForm from 'domain/Vehicle/components/DetailVehicleForm'

// Styles
import './_index.scss'

import { postPageView } from 'store/actions/profile'

const VehicleAdd = ({ vehicleDetail, history }) => {
  useEffect(() => {
    if (!(vehicleDetail && vehicleDetail.id)) {
      history.push('/vehicles/search')
    }
    postPageView()
  }, [])

  return (
    <div className='vehicle-add'>
      <div className='vehicle-add__header'>
        <DetailHeader showBack defaultPath='vehicles/search' type='basic' />
      </div>
      <DetailVehicleForm />
    </div>
  )
}

VehicleAdd.propTypes = {
  vehicleDetail: PropTypes.object,
  history: PropTypes.object
}

export const mapStateToProps = (state) => ({
  vehicleDetail: state.vehicleDetail
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        postPageView
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehicleAdd))
