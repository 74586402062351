import { CONTROLLER } from '../definitions'
import api from 'api/'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'
import { destroy, reset } from 'redux-form'
import alert from 'utils/alert'

/**
 * get controllers list used in select option for assignment
 */
const getControllersList = (q = '', ob = 23, off = 0, lt = 1000) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .getControllers({ q, ob, off, lt, st: '201' })
        .then((res) => {
          const total_count = parseInt(res.headers['x-total-count']) || /*istanbul ignore next 2019-11-22*/ res.data.length
          const data = res.data

          dispatch({
            type: CONTROLLER.LIST,
            total_count,
            data
          })
          resolve()
        })
        .catch(reject)
    })
  }
}

/**
 *
 */
const getControllersSlider = () => {
  return (dispatch) => {
    return api.getControllers({ q: '', ob: 23, off: 0, lt: 3000, include_vehicles: true }).then((res) => {
      let total_count = 0
      let data = []
      /*istanbul ignore else 2019-11-22*/
      if (Array.isArray(res.data)) {
        const filteredControllers = res.data.filter((c) => {
          return c.number_of_vehicles.next_maintenance > 0
        })
        /*istanbul ignore else 2019-11-22*/
        if (filteredControllers.length > 0) {
          total_count = filteredControllers.length
          data = filteredControllers
        }
      }

      dispatch({
        type: CONTROLLER.SLIDER,
        total_count,
        data
      })
    })
  }
}

/**
 * invite controlller
 */
const inviteController = (payload) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      const { form } = getState()

      api
        .inviteController(payload)
        .then((res) => {
          return dispatch(getControllerDetail(res.data.id))
        })
        .then(() => {
          return dispatch(getControllersList())
        })
        .then(() => {
          if (form.controller) dispatch(reset('controller'))
          if (form.controllerInvite) dispatch(reset('controllerInvite'))
          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code)
                : /*istanbul ignore next 2019-11-22*/ alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * resend controller the invitation email
 * @param {*} id
 */

const resendControllerInvitation = (id) => async (dispatch) => {
  dispatch(showLoader())

  try {
    await api.resendControllerInvitation(id)
    dispatch(
      showAlert({
        type: 'success',
        message: 'controller.invite.resendSuccess'
      })
    )
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
  } finally {
    dispatch(hideLoader())
  }
}

/**
 * get controller statistic
 *
 * @returns {Function} Dispatch function
 */
const getControllerStats = () => async (dispatch) => {
  dispatch(showLoader())

  try {
    const res = await api.getControllerStats()

    dispatch({
      type: CONTROLLER.STAT,
      stats: res.data
    })
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
  } finally {
    dispatch(hideLoader())
  }
}

/**
 * get next initial username for controllers
 *
 * @returns {Function} Dispatch function
 */
const getNextControllerStats = () => async (dispatch) => {
  dispatch(showLoader())

  try {
    const res = await api.getNextControllerStats()

    dispatch({
      type: CONTROLLER.NEXT_STAT,
      controllerStats: res.data
    })
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
  } finally {
    dispatch(hideLoader())
  }
}

/**
 * getControllers - use to get controllers list data
 *
 * @param {boolean} [isReload=true] - is reload data
 * @param {string} [q=''] - query
 * @param {number} [ob=23] - orderBy
 * @param {number} [off=0] - offset
 * @param {number} [lt=30] - limit
 * @param {boolean} [include_vehicles=true] - to get full detail
 * @param {string | number} st - activation status value
 * @param {string | number} as - assignment status value
 * @returns {Promise} - response data
 */
const getControllers =
  (isReload = true, q = '', ob = 23, off = 0, lt = 30, include_vehicles = true, st = '', as = '') =>
  async (dispatch) => {
    if (isReload) dispatch(showLoader())

    let payload = {
      q,
      ob,
      off,
      lt,
      include_vehicles,
      st,
      as
    }

    const enhanceStatusPayload = (statusKey) => {
      // if statusKey value is 'all' we don't have to send anything to api

      if (payload[statusKey] === 'all') {
        payload[statusKey] = ''
      }
    }

    enhanceStatusPayload('st')
    enhanceStatusPayload('as')

    try {
      const res = await api.getControllers(payload)

      const total_count = parseInt(res.headers['x-total-count']) || res.data.length
      const data = res.data

      dispatch({
        type: CONTROLLER.GET,
        total_count,
        data,
        isReload
      })
      dispatch(hideLoader())
    } catch (err) {
      dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
      dispatch(hideLoader())
      throw err
    }
  }

/**
 *
 * @param {*} d
 */
const deleteController = (d) => {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      await api.deleteController(d[0].id)

      dispatch({
        type: CONTROLLER.DELETE,
        id: d[0].id
      })
      dispatch({
        type: CONTROLLER.LIST_DELETE,
        id: d[0].id
      })
      dispatch(hideLoader())
      dispatch(
        showAlert({
          type: 'success',
          message: 'controller.delete.success'
        })
      )
    } catch (err) {
      dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
      dispatch(hideLoader())
    }
  }
}

/**
 *
 * @param {*} id
 */
const getControllerDetail = (id) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getControllerDetail(id)
        .then((res) => {
          dispatch({
            type: CONTROLLER.DETAIL,
            controllerDetail: res.data
          })

          dispatch(hideLoader())
          resolve(res.data)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} id
 * @param {*} identifier
 */
const editController = (id, identifier) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      const { form } = getState()
      var payload = Object.assign({}, form.controllerEdit.values)

      /* istanbul ignore if [03/22] */
      // if (identifier === 'national_id') {
      //   payload.passport_no = null
      // } else {
      //   payload.national_id = null
      // }

      api
        .editController(id, payload)
        .then((res) => {
          return dispatch(getControllerDetail(res.data.id))
        })
        .then(() => {
          return dispatch(getControllersList())
        })
        .then(() => {
          dispatch(hideLoader())
          dispatch(destroy('controllerEdit'))
          resolve()
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * get vehicle stats that belong to this controller
 *
 * @param {string} id - controller ID
 * @returns {Function} dispatch function
 */
const getControllerVehicleStats = (id) => async (dispatch) => {
  dispatch(showLoader())

  try {
    const { data } = await api.getControllerVehicleStats(id)

    dispatch({
      type: CONTROLLER.GET_VEHICLE_STATS,
      payload: data
    })
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
    throw err
  } finally {
    dispatch(hideLoader())
  }
}

export {
  getControllers,
  getControllerDetail,
  deleteController,
  editController,
  inviteController,
  getControllerStats,
  getControllersList,
  getControllersSlider,
  getControllerVehicleStats,
  getNextControllerStats,
  resendControllerInvitation
}
