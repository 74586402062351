import React from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Checkbox
import ReactCheckbox from '@material/react-checkbox'
import './_index.scss'

const Checkbox = (props) => {
  const { className, ...otherProps } = props

  const classes = classnames('checkbox', className)

  return <ReactCheckbox className={classes} {...otherProps} />
}

Checkbox.propTypes = {
  className: PropTypes.string
}

export default Checkbox
