export const REMARK =
  'หากท่านมีข้อสงสัยเกี่ยวกับโปรแกรม บริการพิเศษ“อีซูซุสมาร์ทโพรเทคชั่น” โปรดติดต่อบริษัทฯ หรือ ผู้จำหน่ายที่ท่านสมัครโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”'

export const TERM_CONDITION = [
  {
    title: '1. นิยาม',
    content: [
      '1) “บริษัท” หมายถึง บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด',
      '2) “ท่าน” หมายถึง ลูกค้า ผู้ปรากฏชื่อระบุในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
      '3) “รถยนต์” หมายถึง รถปิคอัพและรถเอนกประสงค์อีซูซุที่ผลิตโดยบริษัท อีซูซุมอเตอร์ (ประเทศไทย) จำกัด และจัดจำหน่ายโดย บริษัทตรีเพชรอีซูซุเซลส์ จำกัด',
      '4) “ผู้จำหน่าย” หมายถึง ผู้จำหน่ายรถยนต์อีซูซุที่ได้รับการแต่งตั้งจาก บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด'
    ]
  },
  {
    title: '2. เอกสารในโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    description: 'เอกสารในโปรแกรม บริการพิเศษ“อีซูซุสมาร์ทโพรเทคชั่น”ระหว่างท่านและบริษัท ประกอบด้วย',
    content: [
      '1) แบบคำขอรับบริการในโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น” (ยกเว้นลูกค้าที่ได้รับโปรแกรมการบริการพิเศษอีซูซุสมาร์ทโพรเทคชั่นฟรีตามเงื่อนไขจากการเช่าซื้อรถยนต์กับบริษัท ตรีเพชรอีซูซุลิสซิ่งจำกัด จะไม่ได้รับแบบคำขอรับบริการโปรแกรมนี้)',
      '2) เงื่อนไขและข้อตกลงโปรแกรมบริการพิเศษ“อีซูซุสมาร์ทโพรเทคชั่น”รวมถึงแบบคำขอรับบริการและเงื่อนไขและข้อตกลงฉบับแก้ไข (ถ้ามี)'
    ]
  },
  {
    title: '3. ขอบเขตการให้บริการ โปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    content: [
      '1) บริษัทจะซ่อมแซมความเสียหาย รวมถึง จัดหา ปรับแต่ง หรือ เปลี่ยนชิ้นส่วนประกอบของรถยนต์ที่พบความบกพร่องของวัสดุหรือฝีมือการประกอบตามรายการการให้บริการใน<strong>โปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”</strong> แนบท้ายเงื่อนไขการให้บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”นี้ ภายในระยะเวลาของโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”ตามข้อ 4 โดยไม่คิดราคาเปลี่ยนชิ้นส่วนประกอบและค่าบริการเพิ่มเติม เว้นแต่กรณีที่ข้อยกเว้นในเงื่อนไขการให้บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”นี้',
      '2) บริษัทจะเป็นผู้พิจารณาและตัดสินใจในขั้นสุดท้ายในการซ่อมแซมความเสียหาย รวมถึง จัดหา ปรับแต่ง หรือ เปลี่ยนชิ้นส่วนประกอบของรถยนต์ตามโปรแกรม บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น” นี้ โดยพิจารณาใช้ชิ้นส่วนประกอบและอะไหล่ตามมาตรฐานที่บริษัทกำหนด รวมทั้ง <strong>อะไหล่ที่บริษัทรับรอง</strong> ตามที่บริษัทเห็นสมควร',
      '3) ชิ้นส่วนและอะไหล่ที่ทุกชิ้นที่ถูกถอดเปลี่ยนจากรถยนต์ในการให้บริการโปรแกรม บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”นี้ ท่านและบริษัทตกลงให้เป็นกรรมสิทธิ์ของบริษัท',
      '4) รถยนต์ที่จะรับบริการตามโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”ต้องเป็นรถยนต์ที่ใช้ในประเทศไทยเท่านั้น ในการให้บริการตามโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น” ท่านสามารถรับบริการได้ที่ศูนย์บริการมาตรฐานอีซูซุทั่วประเทศ'
    ]
  },
  {
    title: '4. ระยะเวลาของ โปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    content: [
      'บริษัทจะให้บริการ ตามโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”กับรถยนต์และประกันการให้บริการ ตามโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”ดังกล่าว โดยขยายระยะเวลารับประกันจาก 3 ปี/100,000 กิโลเมตร (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) เป็นระยะเวลา  5 ปี/100,000 กิโลเมตร หรือ 5 ปี/160,000 กิโลเมตร หรือ 7 ปี/160,000 กิโลเมตร (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) ตามที่ตกลงกันในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”โดยเริ่มนับระยะเวลาหรือระยะทางต่อจากวันที่การรับประกันคุณภาพรถยนต์ตามสมุดรับประกันสิ้นสุดลง'
    ]
  },
  {
    title: '5. ข้อปฏิบัติเพื่อรักษาสิทธิ์การรับโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    content: [
      '1) กรณีรถยนต์อยู่ในระยะเวลารับประกันคุณภาพรถใหม่ 3 ปี หรือระยะทางที่รถวิ่ง 100,000 กิโลเมตร แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน ท่านต้องนำรถยนต์เข้ารับบริการตรวจเช็กตามระยะและบำรุงรักษาตามคำแนะนำในสมุดคู่มือผู้ใช้รถยนต์และสมุดคู่มือรับประกันของบริษัท โดยเข้ารับบริการตรวจเช็กและบำรุงรักษาตามระยะกับศูนย์บริการของผู้จำหน่ายที่ได้รับการแต่งตั้งและเลือกใช้ชิ้นส่วนประกอบและอะไหล่มาตรฐานที่บริษัทกำหนด',
      '2) กรณีรถยนต์อยู่ในระยะเวลารับประกันตามโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น” ท่านต้องนำรถยนต์เข้ารับบริการตรวจเช็กตามระยะและบำรุงรักษาตามคำแนะนำในสมุดคู่มือผู้ใช้รถยนต์และสมุดคู่มือรับประกันของบริษัท โดยเข้ารับบริการตรวจเช็กและบำรุงรักษาตามระยะกับศูนย์บริการของผู้จำหน่ายที่ได้รับการแต่งตั้งและเลือกใช้ชิ้นส่วนประกอบและอะไหล่มาตรฐานที่บริษัทกำหนด <strong>รวมทั้งอะไหล่ที่บริษัทรับรอง</strong>',
      '3) กรณีหากท่านไม่ปฏิบัติตามข้อปฏิบัติเพื่อรักษาสิทธิ์การรับบริการ หรือกรณีข้อยกเว้นโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”บริษัทสงวนสิทธิ์ในการปฏิเสธหรือบอกเลิกโปรแกรม บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”ของท่านทันที'
    ]
  },
  {
    title: '6. ข้อยกเว้นโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    content: [
      '1)  รถยนต์ที่ไม่ได้เข้ารับบริการตรวจเช็กจากศูนย์บริการอีซูซุที่มีรายชื่ออยู่ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ เมื่อถึงกำหนดระยะบริการและบำรุงรักษารถยนต์ที่บริษัทฯได้ระบุไว้ในคู่มือดังกล่าวตลอดระยะเวลาของโปรแกรมบริการพิเศษ "อีซูซุสมาร์ทโพรเทคชั่น"',
      '2)  ความเสียหายในกรณีมีการซ่อมรถยนต์โดยบุคคลอื่น ซึ่งไม่ใช่พนักงานซ่อมรถยนต์ของศูนย์บริการมาตรฐานอีซูซุที่มีรายชื่ออยู่ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
      '3)  ความเสียหายในกรณีมีการดัดแปลง เปลี่ยนแปลง หรือต่อเติมรถยนต์หลังจากรับมอบจากบริษัทฯ โดยมิได้รับอนุญาตเป็นลายลักษณ์อักษรจากบริษัทฯ',
      '4)  ความเสียหายในกรณีมีการไม่ใช้อะไหล่แท้ที่มีเครื่องหมายการค้าอีซูซุซึ่งจัดจำหน่ายโดยบริษัทฯและผู้จำหน่ายของอีซูซุ หรือไม่ใช่<strong>อะไหล่ที่บริษัทรับรอง</strong>',
      '5)  ความเสียหายในกรณีมีการใช้น้ำมันเชื้อเพลิง น้ำมันหล่อลื่นหรือสารเคมีที่ผิดไปจากข้อกำหนดในสมุดคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
      '6)  ความเสียหายในกรณีมีการปฏิบัติไม่ถูกต้องตามคำแนะนำที่ให้ไว้ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
      '7)  ความเสียหายในกรณีมีการใช้ความเร็ว หรือใช้บรรทุกนํ้าหนักเกินกว่าที่กฎหมาย หรือระเบียบข้อบังคับที่ได้กำหนดไว้',
      '8)  ความเสียหายในกรณีมีการขับรถยนต์ในสถานที่ ซึ่งโดยปกติไม่ใช้เป็นที่ขับรถยนต์',
      '9)  การเสื่อมสภาพของรถยนต์ที่เกิดจากการล่วงไปของเวลา ซึ่งโดยปกติไม่ถือว่าเป็นการเสื่อมสภาพที่เกิดจากความชำรุดบกพร่องของวัสดุหรือฝีมือการประกอบ เช่น รถที่จอดทิ้งไว้เป็นระยะเวลานานโดยขาดการบำรุงรักษา เป็นต้น',
      '10) ความเสียหายของส่วนประกอบของชิ้นส่วน หรืออุปกรณ์รถยนต์ที่เสื่อมสภาพจากการใช้งาน และค่าใช้จ่ายในการบำรุงรักษาซ่อมแซมชิ้นส่วน หรืออุปกรณ์ดังกล่าว เช่น ค่าใช้จ่ายในการเปลี่ยนหัวฉีดนํ้ามันเชื้อเพลิง ลูกปืน หลอดไฟ ฟิวส์ แปรงถ่านของมอเตอร์สตาร์ต และออลเตอร์เนเตอร์ ไส้กรองต่างๆ ผ้าเบรก แผ่นคลัตช์ ชิ้นส่วนประกอบที่ทำด้วยยางหรือสารสังเคราะห์ เช่น ปะเก็น(ยกเว้นปะเก็นฝาสูบ) ใบปัดน้ำฝน ท่อยาง สายพานต่างๆ และบุ๊ชต่างๆ น้ำยาหม้อน้ำ สารหล่อลื่น การปรับตั้งศูนย์ล้อ และการถ่วงล้อ เป็นต้น',
      '11) ความเสียหายของส่วนประกอบที่เป็นกระจก เช่น กระจกบังลมหน้า กระจกบังลมหลัง และกระจกหน้าต่าง เป็นต้น',
      '12) เสียงดัง การสั่นสะเทือนตามปกติ ซึ่งไม่มีผลกระทบต่อคุณภาพ และสมรรถนะของรถยนต์',
      '13) ตราประทับ(หมายเลขเครื่องยนต์ และ / หรือ หมายเลขตัวถัง) ซึ่งได้ประทับไว้ก่อนส่งมอบรถยนต์ถูกทำลาย หรือโยกย้ายไป',
      '14) ความเสียหายที่เกิดแก่รถยนต์ ในกรณีมีอุบัติเหตุ การโจรกรรม หรือเหตุอื่นที่อยู่นอกเหนือการควบคุมของบริษัท',
      '15) ความเสียหายซึ่งเกิดจากภัยธรรมชาติ ควัน สารเคมี มูลนก เกลือ น้ำยางต้นไม้ ฯลฯ',
      '16) รถยนต์ที่มีการเปลี่ยนแปลงเลขกิโลเมตรบนหน้าปัดของมิเตอร์ระยะทาง สายมิเตอร์ความเร็วถูกถอดออก หรือขาด',
      '17) ค่าใช้จ่ายต่างๆ เช่น ที่พัก ค่าโทรศัพท์ ค่าน้ำมันเชื้อเพลิง ค่าเสียเวลา ค่าลากจูง รวมทั้งค่าเสียหายเนื่องจากขาดความสะดวก หรือธุรกิจเสียหาย',
      '18) ความเสียหายของส่วนประกอบและอุปกรณ์ตกแต่งรถยนต์ ที่ติดตั้งเพิ่มเติมจากส่วนประกอบและอุปกรณ์มาตรฐานของรถยนต์  ',
      '19) ความเสียหายของเครื่องเสียง วิทยุ ดีวีดี ระบบนำทางเนวิเกเตอร์ของรถยนต์ ',
      '20) ค่าแรงในการบำรุงรักษาตามปกติ เช่นการตรวจเช็กตามระยะทางตามรายการมาตรฐาน การตรวจเช็กเครื่องยนต์ การทำความสะอาดระบบปรับอากาศ ระบบหล่อเย็น หรือระบบเชื้อเพลิง การปรับตั้งเบรก คลัตช์ สายพาน ถ่วงยาง ศูนย์ล้อ เป็นต้น ',
      '21) รถยนต์ซึ่งเข้าร่วมการแข่งขันความเร็ว แรลลี่ หรือการแข่งขันใดๆ  '
    ]
  },
  {
    title: '7. การบอกเลิก โปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
    content: [
      '1) เมื่อท่านได้ชำระเงินตามมูลค่าของโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”แล้ว ท่านไม่สามารถบอกเลิกโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”นี้ได้',
      '2) บริษัทสงวนสิทธิ์ในการปฏิเสธหรือบอกเลิกโปรแกรม บริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”โดยไม่คืนเงินที่ท่านชำระไว้ หากว่าท่านไม่ปฏิบัติตามเงื่อนไขของโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”หรือในกรณีท่านปลอมแปลง ปกปิด ข้อเท็จจริงหรือข้อมูล ส่วนหนึ่งส่วนใดในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”',
      '3) กรณีที่บริษัทเห็นสมควร บริษัทมีสิทธิบอกเลิกโปรแกรมบริการพิเศษ “อีซูซุสมาร์ทโพรเทคชั่น”ก่อนหมดระยะเวลาตามโปรแกรม โดยคืนเงินท่านเต็มจำนวน',
      '4) กรณีที่ลูกค้าเป็นนิติบุคคล  บริษัทขอสงวนสิทธิ์ในการยกเลิกสัญญา หากท่านไม่ชำระเงินภายในเวลาที่กำหนด'
    ]
  }
]
