// Redux Auth Wrapper
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

// Method
import queryString from 'query-string'
import { checkAuth, setLoginUserType, getLoginUserType } from './auth'
import moment from 'moment'

//constant
import { USER_TYPE } from 'config/constant'

const locationHelper = locationHelperBuilder({})

const search = queryString.parse(window.location.search)
const type = search.type
const loginType = getLoginUserType()
if (type) {
  setLoginUserType(type)
} else if (!loginType) {
  setLoginUserType(USER_TYPE.INDIVIDUAL)
}
/**
 * wrap component to make it accessable only if user is authenticated
 * checkAuth should return true
 */
export const authenticated = connectedRouterRedirect({
  redirectPath: '/signin',
  authenticatedSelector: /*istanbul ignore next*/ () => checkAuth()
  // authenticatedSelector: /*istanbul ignore next*/ () => { pageview(); return checkAuth() }
})

/**
 * wrap component to make it accessable only if user is not authenticated
 * checkAuth should return false
 */
export const notAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: /*istanbul ignore next*/ () => !checkAuth()
})

/**
 * Only show vehicle that reach next maintenance
 * @param {*} arr
 * Array of vehicles
 */
export const filterVehicleReachNextMaintenance = (arr) => {
  if (Array.isArray(arr) && arr.length > 0) {
    let until30 = []
    let overdue = []
    let homeVehicle = []

    arr.forEach((c) => {
      if (moment(c.vehicle.next_maintenance_date).isBetween(moment(), moment().add(30, 'days'), 'day', '[]')) {
        until30.push(c)
      } else if (c.vehicle.is_overdue.date || c.vehicle.is_overdue.mileage) {
        overdue.push(c)
      }
    })

    if (overdue.length > 0) {
      homeVehicle = homeVehicle.concat(overdue)
    }

    if (until30.length > 0) {
      homeVehicle = homeVehicle.concat(until30)
    }

    if (homeVehicle.length > 0) {
      return homeVehicle
    } else {
      return []
    }
  } else {
    return []
  }
}
