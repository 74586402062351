import React from 'react'

import { FormattedMessage } from 'react-intl'

// Images
import noRepairIcon from 'assets/images/norepair.svg'

const RepairHistoryNoItem = () => {
  return (
    <div className='repair-history__body-no-repair'>
      <div className='repair-history__body-no-repair__content'>
        <div className='repair-history__body-no-repair__content__img'>
          <img src={noRepairIcon} alt='' />
        </div>
        <div className='repair-history__body-no-repair__content__text'>
          <FormattedMessage id='RepairHistory.noRepairHist' defaultMessage='common.labels.noCoupon' />
        </div>
      </div>
    </div>
  )
}

export default RepairHistoryNoItem
