import _ from 'lodash'
import { REPAIR_HISTORY, CLEAR_THIS_STATE } from '../definitions'

import { removeDuplicates } from '../../utils/removeDuplicates'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case REPAIR_HISTORY.GET:
      action.repairHistory.limit = action.limit
      action.repairHistory.offset = action.offset

      let hasMoreRecords = true
      if (action.repairHistory.service_order.length === 0) {
        hasMoreRecords = false
      }
      if (_.isEmpty(state)) {
        return Object.assign({}, action.repairHistory)
      } else {
        const newList = [...state.service_order, ...action.repairHistory.service_order]
        const newUniqueList = removeDuplicates(newList, 'alternate_id')
        return Object.assign({}, state, {
          ...state,
          service_order: newUniqueList,
          limit: action.limit,
          offset: action.offset,
          hasMoreRecords: hasMoreRecords
        })
      }

    case CLEAR_THIS_STATE:
      return initialState

    case REPAIR_HISTORY.CLEAR:
      return Object.assign({}, {})

    default:
      return state
  }
}
