import React, { useState, useEffect } from 'react'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Actions
import { toggleFavorite as toggleFavoriteVehicle } from 'store/actions/vehicles'

// Styles
import './_index.scss'
const ButtonFav = (props) => {
  const [favorite, setFavorite] = useState(props.isFavorite)

  useEffect((prevProps) => {
    if (props !== prevProps) setFavorite(props.isFavorite)
  }, [])

  const toggleFav = () => {
    if (props.type === 'vehicle') props.toggleFavoriteVehicle(props.id, !props.isFavorite)
    setFavorite(!favorite)
  }

  const { className, isFavorite, toggleFavoriteVehicle, ...otherProps } = props

  const classes = classnames('button-fav', { 'button-fav--favorite': favorite }, className)

  return (
    <button
      onClick={(e) => {
        e.stopPropagation()
        toggleFav()
      }}
      className={classes}
      {...otherProps}
    >
      {favorite && <i className='material-icons'>favorite</i>}
      {!favorite && <i className='material-icons'>favorite_border</i>}
    </button>
  )
}

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        toggleFavoriteVehicle,
      },
      dispatch
    )

ButtonFav.propTypes = {
  className: PropTypes.string,
  isFavorite: PropTypes.bool,
  type: PropTypes.string,
  toggleFavoriteVehicle: PropTypes.func,
  id: PropTypes.string
}

ButtonFav.defaultProps = {
  id: null,
  className: '',
  isFavorite: false,
  type: ''
}

export default connect(null, mapDispatchToProps)(ButtonFav)
