import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { setChannelRadioLabel } from '../Label/format'

const ChannelInput = ({ setChannel, channel }) => {
  return (
    <React.Fragment>
      <div className='controller-form__form__channel-selection'>
        <FormattedHTMLMessage id='controller.invite.inviteChannel' defaultMessage='controller.invite.inviteChannel' />
        <RadioGroup aria-label='selected_id' value={channel}>
          {getRadio(setChannel, 'email')}
          {getRadio(setChannel, 'sms')}
        </RadioGroup>
      </div>
    </React.Fragment>
  )
}

const getRadio = (setRadio, value) => {
  let label = setChannelRadioLabel(value)

  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      onClick={() => setRadio(value)}
      label={<FormattedMessage id={label} defaultMessage={label} />}
    />
  )
}

export default ChannelInput
