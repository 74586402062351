import { PROFILE, CLEAR_THIS_STATE } from 'store/definitions'

import { pageview, trackClick } from 'utils/treasureData'

const initialState = {
  loadProfileComplete: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE.GET:
      return Object.assign({}, action.profile)

    case CLEAR_THIS_STATE:
      return initialState

    case PROFILE.PAGEVIEW:
      !!state.id && pageview(state.id)
      return state

    case PROFILE.CLICK:
      !!state.id && trackClick(state.id)
      return state

    default:
      return state
  }
}
