import React from 'react'
import { FormattedMessage } from 'react-intl'

// Icons
import { repairIcon, arrowBtn } from 'config/icon'

const RepairHistoryCard = (value, index, history, params) => {
  return (
    <React.Fragment className='repair-history-detail' id='repair-history-detail'>
      <div key={index} className='repair-history__body__bg'>
        <div
          className='repair-history__body__card repair-history-detail'
          onClick={() =>
            history.push(`/repair-history/${params.id}/detail/${value.alternate_id}`)
          }
        >
          <div className='repair-history__body__card__info'>
            <div className='repair-history__body__card__info__header'>
              <i>{repairIcon}</i>
              <span>{value.date_in}</span>
            </div>
            <div className='repair-history__body__card__info__detail'>
              <FormattedMessage id='RepairHistory.serviceCenter' defaultMessage='RepairHistory.serviceCenter' />
              <span className='service-center'>{value.service_center_name}</span>
            </div>
            <div className='repair-history__body__card__info__detail'>
              <FormattedMessage id='RepairHistory.mileage' defaultMessage='RepairHistory.mileage' />
              <span className='mileage'>
                {value.mileage} <FormattedMessage id='RepairHistory.kilometer' defaultMessage='RepairHistory.kilometer' />
              </span>
            </div>
          </div>
          <div className='repair-history__body__card__arrow'>{arrowBtn}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RepairHistoryCard
