import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { showNumber } from 'utils/format'

// Redux
import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'
import ReactModal from 'react-modal'

import { checkError } from 'utils/helper'
import { required } from 'utils/validation'

import api from 'api/'

// Actions
import { getISPDetail, ispRegistration } from 'store/actions/ispDetail'
import { getISPVehicles } from 'store/actions/ispVehicles'

// Proptypes
import PropTypes from 'prop-types'

// Router
import { withRouter } from 'react-router-dom'

// Components
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import PopupISPVehicle from 'domain/Isp/components/PopupISPVehicle'
import { Form, reduxForm } from 'redux-form'
import DetailHeader from 'components/layouts/DetailHeader'
import Term from 'domain/Isp/components/ISPTerm'
import Checkbox from 'components/elements/Checkbox'
import { withStyles } from '@material-ui/core'

// Constant
import { activityTypes } from 'config/constant'
import { vehicleModalIcon } from 'config/icon'

// CSS
import './index.scss'

const styles = {
  radio: {
    margin: 'auto 0 auto 1.5rem',
    '& > label': {
      margin: '0'
    }
  }
}

const ISPSelection = ({ invalid, classes, match, ispRegistration, handleSubmit }) => {
  const ispDetail = useSelector((state) => state.ispDetail)
  const ispVehicle = useSelector((state) => state.ispVehicle)
  const profile = useSelector((state) => state.profile)
  const history = useHistory()
  const dispatch = useDispatch()

  const [selectedPackage, setSelectedPackage] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [termAccepted, setTermAccepted] = useState(false)
  const [ispYear, setIspYear] = useState('')
  const [ispMileage, setIspMileage] = useState('')
  const [ispPrice, setIspPrice] = useState('')
  const [description, setDescription] = useState('')
  const [user_type, setUserType] = useState('')

  const modalRef = useRef()

  useEffect(() => {
    dispatch(getISPVehicles()).catch((err) => {
      checkError(err)
    })
    setUserType(profile.type === 1 ? 'บุคคลทั่วไป' : profile.type === 2 ? 'นิติบุคคล' : 'ผู้ดูแลรถ')
  }, [])

  useEffect(() => {
    dispatch(getISPDetail(match.params.id)).catch((err) => {
      checkError(err)
    })
  }, [match.params.id, ispVehicle.total_count])

  const closeModal = () => modalRef.current.closeModal()

  const changeChannelRadio = (event) => {
    setSelectedPackage(event.target.value)
  }

  const confirmRegistration = async () => {
    const payload = {
      hash_id: match.params.id,
      price_id: selectedPackage
    }
    api.logActivities({
      type: activityTypes.ISP,
      description:
        `ยืนยันเงื่อนไขการสมัคร ISP|${ispDetail.vin ? ispDetail.vin : ''}|` +
        `${ispDetail.license_plate ? ispDetail.license_plate : ''}|` +
        `${description ? description : ''}|` +
        `${ispPrice ? ispPrice : ''}|` +
        `${user_type}|`
    })
    await ispRegistration(payload)
    history.push(`/isp/${match.params.id}`)
  }

  const openTermModal = () => {
    let packageDetail =
      ispDetail.ISPAvailablePackage &&
      Object.values(ispDetail.ISPAvailablePackage).filter(function (p) {
        return p.price_id === selectedPackage || undefined
      })
    setIsModalOpen(true)
    setIspYear(packageDetail[0].isp_year && packageDetail[0].isp_year)
    setIspMileage(packageDetail[0].isp_mileage && packageDetail[0].isp_mileage)
    setIspPrice(packageDetail[0].price && packageDetail[0].price)
    setDescription(packageDetail[0].description && packageDetail[0].description)

    api.logActivities({
      type: activityTypes.ISP,
      description:
        `เลือกแพ็กเกจเพื่อสมัคร ISP|${ispDetail.vin ? ispDetail.vin : ''}|` +
        `${ispDetail.license_plate ? ispDetail.license_plate : ''}|` +
        `${packageDetail[0].description ? packageDetail[0].description : ''}|` +
        `${packageDetail[0].price ? packageDetail[0].price : ''}|` +
        `${user_type}|`
    })
  }

  const closeTermModal = () => {
    setIsModalOpen(false)
    setTermAccepted(false)
    api.logActivities({
      type: activityTypes.ISP,
      description:
        `ยกเลิกเงื่อนไขการสมัคร ISP|${ispDetail.vin ? ispDetail.vin : ''}|` +
        `${ispDetail.license_plate ? ispDetail.license_plate : ''}|` +
        `${description ? description : ''}|` +
        `${ispPrice ? ispPrice : ''}|` +
        `${user_type}|`
    })
  }

  const toggleCheckbox = () => {
    setTermAccepted(!termAccepted)
  }

  const ispList = ispVehicle
  const allVehicles = Object.values(ispList.data)

  const modalConsent = (
    <div>
      <Form onSubmit={handleSubmit(confirmRegistration)}>
        <div className='consent-header'>
          <FormattedMessage id='ispPackage.term.title' defaultMessage='ispPackage.term.title' />
        </div>
        <div className='consent_content'>
          <div className='isp-pack__header'>
            <div className='isp-pack__header__image'>
              <img src='https://cdndev.my.isuzu-tis.com/assets/contents/isp_widget.jpg' alt='' />
            </div>
            <div className='isp-pack__header__label'>
              <FormattedHTMLMessage
                id='ispPackage.term.headerTerm'
                values={{
                  license_plate: ispDetail.license_plate,
                  year: ispYear,
                  mileage: showNumber(ispMileage),
                  price: showNumber(parseInt(ispPrice))
                }}
                defaultMessage='ispPackage.term.headerTerm'
              />
            </div>
          </div>
          <Term />
          <div className='checkbox-section'>
            <Checkbox
              nativeControlId='create-account__form__section__agreement'
              checked={termAccepted}
              onClick={() => {
                toggleCheckbox()
              }}
              validate={[required]}
              required
            />
            <label>
              <FormattedMessage id='ispPackage.term.agreeStatement' defaultMessage='ispPackage.term.agreeStatement' />
            </label>
          </div>
        </div>
        <div className='consent_footer'>
          <Button type='submit' label='ispPackage.term.confirm' color='primary' disabled={invalid || !termAccepted} />
          <Button
            type='cancel'
            label='ispPackage.term.cancel'
            onClick={() => {
              closeTermModal()
            }}
          />
        </div>
      </Form>
    </div>
  )

  return (
    <div className='isp-selection'>
      {isModalOpen ? (
        <ReactModal isOpen={isModalOpen} overlayClassName='modal-term-overlay' className='modal-term' closeTimeoutMS={500}>
          {modalConsent}
        </ReactModal>
      ) : (
        <Modal
          mode={8}
          title={'privilege.vehicleModal'}
          content={
            <PopupISPVehicle
              vehicles={ispVehicle}
              ispList={allVehicles}
              close={closeModal}
              isp={true}
              clickClose={true}
              ispDetailPage={true}
            />
          }
          ref={modalRef}
        />
      )}

      <DetailHeader showBack type='basic' />
      {allVehicles.length > 1 && (
        <Button
          className='isp-btn'
          icon={vehicleModalIcon}
          label='privilege.vehicleModal'
          type='vehicle'
          onClick={() => {
            modalRef.current.openModal()
          }}
        />
      )}
      <div className='isp-selection__body'>
        <div className='isp-selection__vehicle-detail'>
          <div className='isp-selection__title'>
            <FormattedMessage id='ispPackage.vehicle.title' value='ispPackage.vehicle.title' />
          </div>
          <FormattedMessage id='ispPackage.field.licensePlate' value='ispPackage.field.licensePlate' />
          <div className='isp-selection__field'>
            <span>{ispDetail.license_plate}</span>
          </div>
        </div>

        <div className='isp-selection__title'>
          <FormattedMessage id='ispPackage.title' value='ispPackage.title' />
        </div>

        <div className='isp-pack__header'>
          <div className='isp-pack__header__label'>
            <FormattedMessage id='ispPackage.ispContent.contentHeader' value='ispPackage.ispContent.contentHeader' />
          </div>
          <div className='isp-pack__header__image'>
            <img src='https://cdndev.my.isuzu-tis.com/assets/contents/isp_widget.jpg' alt='' />
          </div>
        </div>

        {ispDetail.ISPAvailablePackage &&
          ispDetail.ISPAvailablePackage.map((value, key) => (
            <div className='isp-pack'>
              <RadioGroup aria-label='selected_package' value={selectedPackage} onChange={changeChannelRadio} className={classes.radio}>
                <FormControlLabel value={value.price_id} control={<Radio />} />
              </RadioGroup>
              <div className='isp-pack__isp'>
                <div className='isp-pack__isp__img'>
                  <img src={value.package_selection_image_url} alt='' />
                </div>
                <div className='isp-pack__isp__content'>
                  <FormattedHTMLMessage
                    id='ispPackage.ispContent.contentDetail'
                    values={{
                      years: showNumber(value.isp_year),
                      mileages: showNumber(value.isp_mileage),
                      price: showNumber(parseInt(value.price))
                    }}
                    defaultMessage='ispPackage.ispContent.contentDetail'
                  />
                </div>
              </div>
            </div>
          ))}

        <div className='isp-selection__confirm' id='bottom'>
          <Button type='add-new' label='ispPackage.button.confirm' disabled={selectedPackage === ''} onClick={openTermModal} />
        </div>
      </div>
    </div>
  )
}

ISPSelection.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  }),
  handleSubmit: PropTypes.func,
  ispRegistration: PropTypes.func
}

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        ispRegistration
      },
      dispatch
    )

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(
    withStyles(styles)(
      reduxForm({
        form: 'ispRegistration'
      })(ISPSelection)
    )
  )
)
