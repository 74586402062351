import { PROMOTION, CLEAR_THIS_STATE } from '../definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROMOTION.DETAIL:
      return Object.assign({}, action.promotionDetail)

    case PROMOTION.VEHICLE:
      return Object.assign({}, state, {
        ...state,
        target_vehicles: action.data
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
