import React, { useEffect, useState } from 'react'
// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Router
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ContentHeader from 'components/layouts/ContentHeader'
import DetailHeader from 'components/layouts/DetailHeader'
import { change, Field, Form, reduxForm } from 'redux-form'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { REGISTER_SCREEN, USER_TYPE } from 'config/constant'

import PDPA from '../../components/elements/PDPA'

// Methods
import { FormattedMessage, injectIntl } from 'react-intl'

// Utils
import { checkError } from 'utils/helper'

// Actions
import {
  authRegistration,
  authRegistrationResend,
  authRegistrationSignup,
  authRegistrationVerify,
  authCorporateRegistration,
  authSignup,
  clearAuth
} from 'store/actions/auth'

import {
  createAcc_email_max100,
  createAcc_home_max9,
  createAcc_home_min9,
  createAcc_home_number,
  createAcc_mobile,
  createAcc_mobile_max10,
  createAcc_mobile_min10,
  createAcc_mobile_number,
  createAcc_name,
  createAcc_nameAlpha,
  createAcc_natId,
  createAcc_natId_max13,
  createAcc_natId_min13,
  createAcc_natId_numbers,
  createAcc_office_ext_max5,
  createAcc_office_ext_number,
  createAcc_office_max9,
  createAcc_office_min9,
  createAcc_office_number,
  createAcc_passport,
  createAcc_passport_alphanumeric,
  createAcc_passport_min5,
  createAcc_password,
  createAcc_pwmin8,
  createAcc_surname,
  createAcc_surnameAlpha,
  createAcc_username,
  createAcc_username_alphanumeric,
  createAcc_username_min6,
  email,
  forgotpw_otp,
  forgotpw_otp_numeric,
  forgotpw_otpmax6,
  forgotpw_otpmin6,
  passwordsMustMatch,
  required
} from 'utils/validation'

// Styles
import './_index.scss'
import Checkbox from 'components/elements/Checkbox'
import { postPageView } from 'store/actions/profile'

import queryString from 'query-string'
import { isMobileApp } from 'utils/validation'

const CreateAccount = ({
  intl,
  history,
  invalid,
  authSignup,
  isLoading,
  postPageView,
  authRegistration,
  authRegistrationSignup,
  authCorporateRegistration,
  authRegistrationResend,
  handleSubmit,
  authUserType,
  auth
}) => {
  const [termAccepted, setTermAccepted] = useState(false)
  const [pageMode, setPageMode] = useState(REGISTER_SCREEN.REGISTRATION_FORM)
  const [selectedId, setSelectedId] = useState('id')
  const [seconds] = useState('00')
  const [minutes] = useState('05')
  const [retries] = useState(-1)

  const search = queryString.parse(window.location.search)

  useEffect(() => {
    if (!authUserType && !search.token) {
      return history.push('/signup')
    }
    if (search.token && !isLoading) {
      authSignup(authUserType || USER_TYPE.CONTROLLER, undefined, search.token)
        .then(() => {
          change('username', auth.username)
          change('email', auth.email)
          change('mobile_phone', auth.mobile_phone)
        })
        .catch((err) => {
          checkError(err)
          if (authUserType !== USER_TYPE.INDIVIDUAL) {
            history.push('/create-account/expire')
          } else {
            history.push('/signup')
          }
        })
    }
    postPageView()
  }, [])

  useEffect(() => {
    // FIXME: Please stop using inline styles.
    if (pageMode === REGISTER_SCREEN.TERM_AND_CONDITION) {
      document.querySelector('.top').style.position = 'fixed'
      if (window.innerWidth >= 768 && window.innerWidth <= 1199) {
        document.querySelector('.top').style.width = '54rem'
      } else if (window.innerWidth >= 1200) {
        document.querySelector('.top').style.width = '64rem'
      }
    } else {
      document.querySelector('.top').style.width = '100%'
      if (window.innerWidth < 768) {
        document.querySelector('.top').style.position = 'fixed'
      } else {
        document.querySelector('.top').style.position = 'static'
      }
    }

    return () => {
      // The above inline styles will break the home page layout. Need to clean
      // these things up before we redirect away from this page.
      document.querySelector('.top').style = {}
    }
  }, [pageMode])

  const createAccount = async () => {
    if (pageMode === REGISTER_SCREEN.REGISTRATION_FORM) {
      if (authUserType === USER_TYPE.CORPORATE) {
        try {
          await authCorporateRegistration()
          setPageMode(REGISTER_SCREEN.TERM_AND_CONDITION)
        } catch (err) {
          checkError(err)
        }
      } else {
        if (authRegistration) {
          try {
            await authRegistration()
            setPageMode(REGISTER_SCREEN.TERM_AND_CONDITION)
          } catch (err) {
            checkError(err)
          }
        }
      }
    } else if (pageMode === REGISTER_SCREEN.TERM_AND_CONDITION) {
      if (authUserType === USER_TYPE.CORPORATE) {
        if (authRegistration) {
          try {
            await authRegistration()
            history.push('/')
          } catch (err) {
            checkError(err)
          }
        }
      } else {
        if (authRegistrationSignup) {
          try {
            await authRegistrationSignup()
            history.push('/')
          } catch (err) {
            checkError(err)
          }
        }
      }
    }
  }

  /**
   * Change selected radio
   */
  const changeRadio = (event) => setSelectedId(event.target.value)

  /**
   *  Toggle state of remember of checkbox clicked
   */
  const toggleCheckbox = () => {
    setTermAccepted(!termAccepted)
  }

  const classes = classnames('create-account', { 'create-account__PDPA': pageMode === REGISTER_SCREEN.TERM_AND_CONDITION })

  return (
    <div className={classes}>
      {pageMode === REGISTER_SCREEN.TERM_AND_CONDITION ? (
        !isMobileApp() && <DetailHeader showBack type='grey-fixed' title='createAccount.labels.PDPA' />
      ) : (
        <ContentHeader title={'createAccount.labels.title'} subTitle={'createAccount.validation.subTitle'} />
      )}
      <Form onSubmit={handleSubmit(createAccount)} className='create-account__form'>
        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && authUserType === USER_TYPE.CONTROLLER && (
          <div className='signup__form__radio'>
            <RadioGroup aria-label='selected_id' value={selectedId} onChange={changeRadio}>
              <FormControlLabel
                value='id'
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'createAccount.labels.nationalid',
                  defaultMessage: 'National ID'
                })}
              />
              <FormControlLabel
                value='passport'
                control={<Radio />}
                label={intl.formatMessage({
                  id: 'createAccount.labels.passport',
                  defaultMessage: 'National ID'
                })}
              />
            </RadioGroup>
          </div>
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM &&
          ((authUserType === USER_TYPE.CONTROLLER && selectedId === 'id') ||
            (authUserType === USER_TYPE.INDIVIDUAL && auth.national_id)) && (
            <Field
              component={Input}
              name='national_id'
              type='text'
              label='createAccount.labels.nationalid'
              isRequired
              placeholder='createAccount.placeholders.nationalid'
              id='national_id'
              disabled={authUserType === USER_TYPE.INDIVIDUAL}
              validate={
                authUserType === USER_TYPE.CONTROLLER
                  ? [createAcc_natId, createAcc_natId_numbers, createAcc_natId_min13, createAcc_natId_max13]
                  : []
              }
            />
          )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM &&
          ((authUserType === USER_TYPE.CONTROLLER && selectedId === 'passport') ||
            (authUserType === USER_TYPE.INDIVIDUAL && auth.passport)) && (
            <Field
              component={Input}
              name='passport'
              type='text'
              label='createAccount.labels.passportNo'
              isRequired
              placeholder='createAccount.placeholders.passport'
              id='passport'
              disabled={authUserType === USER_TYPE.INDIVIDUAL}
              validate={
                authUserType === USER_TYPE.CONTROLLER ? [createAcc_passport, createAcc_passport_min5, createAcc_passport_alphanumeric] : []
              }
            />
          )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && authUserType === REGISTER_SCREEN.OTP && (
          <React.Fragment>
            <Field
              component={Input}
              name='company_display_name'
              type='text'
              label='createAccount.labels.companyName'
              isRequired
              placeholder='createAccount.labels.companyName'
              id='company_display_name'
              disabled
            />

            <Field
              component={Input}
              name='company_reg_id'
              type='text'
              label='createAccount.labels.companyReg'
              isRequired
              placeholder='createAccount.placeholders.companyReg'
              id='company_reg_id'
              disabled
            />
          </React.Fragment>
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (authUserType === USER_TYPE.CORPORATE || authUserType === USER_TYPE.CONTROLLER) && (
          <React.Fragment>
            <Field
              component={Input}
              name='given_name'
              type='text'
              label={authUserType === USER_TYPE.CORPORATE ? 'common.labels.adminName' : 'common.labels.givenName'}
              placeholder='common.placeholders.inputGivenName'
              id='given_name'
              validate={[createAcc_name, createAcc_nameAlpha]}
              isRequired
            />
            <Field
              component={Input}
              name='surname'
              type='text'
              label={authUserType === USER_TYPE.CORPORATE ? 'common.labels.adminSurname' : 'common.labels.surname'}
              placeholder='common.placeholders.inputSurname'
              id='surname'
              validate={[createAcc_surname, createAcc_surnameAlpha]}
              isRequired
            />
          </React.Fragment>
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (
          <Field
            component={Input}
            name='mobile_phone'
            id='mobile_phone'
            type='text'
            isRequired
            label='common.labels.mobilePhone'
            placeholder='common.placeholders.inputMobilePhone'
            validate={[createAcc_mobile, createAcc_mobile_min10, createAcc_mobile_max10, createAcc_mobile_number]}
            isClearable
          />
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && authUserType === USER_TYPE.INDIVIDUAL && (
          <Field
            component={Input}
            name='home_phone'
            type='text'
            placeholder='common.placeholders.inputHomePhone'
            label='common.labels.homePhone'
            id='home_phone'
            validate={[createAcc_home_min9, createAcc_home_max9, createAcc_home_number]}
            isClearable
          />
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (authUserType === USER_TYPE.CORPORATE || authUserType === USER_TYPE.CONTROLLER) && (
          <React.Fragment>
            <div className='create-account__form__phone'>
              <Field
                component={Input}
                name='office_phone'
                type='text'
                label='common.labels.officePhone'
                placeholder='common.placeholders.inputOfficePhone'
                id='office_phone'
                validate={[createAcc_office_min9, createAcc_office_max9, createAcc_office_number]}
                isClearable
              />
            </div>
            <div className='create-account__form__phone'>
              <Field
                component={Input}
                name='office_phone_ext'
                type='text'
                label='common.labels.officePhoneExt'
                placeholder='common.placeholders.inputOfficePhoneExt'
                id='office_phone_ext'
                validate={[createAcc_office_ext_number, createAcc_office_ext_max5]}
                isClearable
              />
            </div>
          </React.Fragment>
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (
          <Field
            component={Input}
            name='email'
            type='email'
            label='common.labels.email'
            placeholder='common.placeholders.inputEmail'
            validate={authUserType === USER_TYPE.CORPORATE ? [required, createAcc_email_max100, email] : []}
            id='email'
            isRequired={authUserType === USER_TYPE.CORPORATE}
          />
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (
          <Field
            name='username'
            component={Input}
            type='text'
            label='common.labels.username'
            isRequired={authUserType !== 1}
            placeholder='common.placeholders.inputUsername'
            id='username'
            validate={
              authUserType === USER_TYPE.INDIVIDUAL
                ? [createAcc_username_min6, createAcc_username_alphanumeric]
                : [createAcc_username, createAcc_username_min6, createAcc_username_alphanumeric]
            }
            isClearable={authUserType !== USER_TYPE.CONTROLLER}
            disabled={authUserType === USER_TYPE.CONTROLLER}
          />
        )}

        {pageMode === REGISTER_SCREEN.REGISTRATION_FORM && (
          <React.Fragment>
            <Field
              name='password'
              component={Input}
              type='password'
              label='common.labels.password'
              isRequired
              placeholder='common.placeholders.inputPassword'
              id='password'
              validate={[createAcc_password, createAcc_pwmin8]}
              isClearable
              togglePassword
            />
            <Field
              name='password_repeated'
              component={Input}
              type='password'
              label='common.labels.confirmPassword'
              isRequired
              isClearable
              togglePassword
              placeholder='common.placeholders.repeatPassword'
              validate={[createAcc_password, createAcc_pwmin8, passwordsMustMatch]}
              id='password_repeat'
            />
          </React.Fragment>
        )}

        {pageMode === REGISTER_SCREEN.OTP && (
          <div className='create-account__form__otp'>
            <div className='create-account__form__otp'>
              <div className='create-account__form__otp__title'>
                <h5>
                  <FormattedMessage id='createAccount.title.otp' defaultMessage='createAccount.title.otp' />
                </h5>
                <p>
                  <FormattedMessage id='signIssue.password.otpSmsDescription' defaultMessage='signIssue.password.otpSmsDescription' />
                </p>
              </div>
              <div className='create-account__form__otp__phone'>
                <div className='create-account__form__otp__phone__number'>
                  <span>{auth.identifier}</span>
                </div>
                <div className='create-account__form__otp__phone__action'>
                  {retries < 2 && (
                    <Button
                      color='transparent'
                      label='signIssue.password.resend'
                      onClick={async (e) => {
                        e.preventDefault()
                        await authRegistrationResend()
                        // this.resetCountDown()
                      }}
                      type='button'
                    />
                  )}
                </div>
              </div>

              <div className='create-account__form__otp__instruction'>
                {retries === 2 && minutes === '00' && seconds === '00' ? (
                  <span>
                    {' '}
                    <FormattedMessage id='signIssue.password.otpMax' defaultMessage='signIssue.password.otpMax' />
                  </span>
                ) : retries > 0 && retries <= 2 ? (
                  <span>
                    <FormattedMessage
                      id='signIssue.password.otpInfo'
                      defaultMessage='signIssue.password.otpInfo'
                      values={{ value: retries }}
                    />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage id='signIssue.password.otpResendInfo' defaultMessage='signIssue.password.otpResendInfo' />
                  </span>
                )}
              </div>

              <div className='create-account__form__otp__timer'>
                <span>
                  {minutes}:{seconds}
                </span>
              </div>

              <div className='create-account__form__otp__input'>
                <Field
                  name='otp'
                  type='otp'
                  label={'common.labels.otp'}
                  validate={[forgotpw_otp, forgotpw_otp_numeric, forgotpw_otpmax6, forgotpw_otpmin6]}
                  component={Input}
                  id='otp'
                />
              </div>
            </div>
          </div>
        )}

        {pageMode === REGISTER_SCREEN.TERM_AND_CONDITION && (
          <React.Fragment style={{ 'background-color': 'white' }}>
            <div className='create-account__form__action__PDPA'>
              <div className='create-account__form__action__section'>
                <div className='create-account__form__action__text'>
                  <PDPA />
                </div>
              </div>
            </div>
            <div className='create-account__form__action'>
              <div className='create-account__acception'>
                <div className='create-account__form__action__section'>
                  <Checkbox
                    nativeControlId='create-account__form__section__agreement'
                    checked={termAccepted}
                    onClick={toggleCheckbox}
                    validate={[required]}
                    required
                  />
                  <label className='create-account__form__section__agreement' htmlFor='create-account__form__section__agreement'>
                    <FormattedMessage id='createAccount.labels.agreeStatement' defaultMessage='createAccount.labels.agreeStatement' />
                  </label>
                </div>
                <div className='create-account__form__action__section__remark'>
                  <FormattedMessage id='createAccount.labels.acceptionMsg' defaultMessage='createAccount.labels.acceptionMsg' />
                  <a href='/terms-conditions' target='_blank' rel='noopener noreferrer'>
                    <FormattedMessage id='createAccount.labels.acceptionLink' defaultMessage='createAccount.labels.acceptionLink' />
                  </a>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className='create-account__form__action'>
          <div className='create-account__form__action__section create-account__form__action__section--half'>
            {pageMode < REGISTER_SCREEN.TERM_AND_CONDITION && (
              <Button type='submit' color='primary' disabled={invalid} label='common.button.next' />
            )}

            {pageMode === REGISTER_SCREEN.TERM_AND_CONDITION && (
              <Button
                type='submit'
                color='primary'
                disabled={invalid || !termAccepted}
                label='createAccount.button.createAccount'
                style={{ 'border-radius': 30 }}
              />
            )}
          </div>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = /*istanbul ignore next*/ (state, ownProps) => ({
  authUserType: state.auth.type || ownProps.userType,
  auth: state.auth,
  initialValues: {
    national_id: state.auth.national_id || '',
    passport: state.auth.passport || '',
    company_display_name: state.auth.company_display_name || '',
    company_reg_id: state.auth.company_reg_id || '',
    username: state.auth.username || '',
    mobile_phone: state.auth.mobile_phone || '',
    office_phone: state.auth.office_phone || '',
    office_phone_ext: state.auth.office_phone_ext || '',
    email: state.auth.email || '',
    given_name: state.auth.given_name || '',
    surname: state.auth.surname || ''
  },
  createAccountForm: state.form.createAccount,
  search: state.router.location.search,
  isLoading: state.loader,
  lang: state.config.lang
})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      authSignup,
      authRegistration,
      authRegistrationVerify,
      authRegistrationSignup,
      authRegistrationResend,
      authCorporateRegistration,
      clearAuth,
      postPageView
    },
    dispatch
  )

CreateAccount.propTypes = {
  search: PropTypes.string,
  authSignup: PropTypes.func,
  authRegistration: PropTypes.func,
  authRegistrationVerify: PropTypes.func,
  authRegistrationSignup: PropTypes.func,
  authRegistrationResend: PropTypes.func,
  authCorporateRegistration: PropTypes.func,
  clearAuth: PropTypes.func,
  history: PropTypes.object,
  auth: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  invalid: PropTypes.bool,
  lang: PropTypes.string
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm(
      {
        form: 'createAccount',
        enableReinitialize: true
      },
      mapStateToProps
    )(injectIntl(CreateAccount))
  )
)
