import { COUPON } from 'store/definitions'

const initialState = {
  set: false,
  ids: [],
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COUPON.SLIDER_CASH_SET:
      return Object.assign({}, state, {
        ...state,
        set: action.set,
        ids: action.ids,
        data: action.data
      })

    case COUPON.CLEAR_CASH_GROUP:
      return {
        set: true,
        ids: [],
        data: []
      }

    case COUPON.UNMOUNT_CASH_GROUP:
      return initialState
    default:
      return state
  }
}
