import React from 'react'
import PropTypes from 'prop-types'

import './_index.scss'
import { FormattedMessage } from 'react-intl'

const VehicleControllerItem = ({ id, name, isChecked, controllers, vehicle: { model, license_plate }, newControllers, onCheckClick }) => {
  const updatedController = isChecked ? newControllers : controllers

  const checkboxHandler = () => {
    onCheckClick(id)
  }

  return (
    <div className='vehicle-item vehicle-item--is-corporate vehicle-item--list'>
      <input className='vehicle-item__check' type='checkbox' id={id} value={id} onChange={checkboxHandler} checked={!!isChecked} />
      <label className='vehicle-item__label' htmlFor={id}>
        <i className='material-icons'>check</i>
      </label>
      <div className='vehicle-item__wrapper--list corporate'>
        <div className='vehicle-item__data name'>
          <h3 className='vehicle-item__wrapper__data__desc__name'>{name || model.marketing_name}</h3>
          <h3 className='vehicle-item__wrapper__data__desc__plate'>{license_plate}</h3>
        </div>
        <div className='vehicle-item__data current-assignment-column'>
          <span>
            {controllers.length === 0 && <FormattedMessage id='vehicle.list.unassigned' defaultMessage='vehicle.list.unassigned' />}
            {controllers.length > 0 && controllers.map(({ full_name }) => full_name).join(', ')}
          </span>
        </div>
        <div className='vehicle-item__data new-assignment-column'>
          <span>
            {updatedController.length === 0 && <FormattedMessage id='vehicle.list.unassigned' defaultMessage='vehicle.list.unassigned' />}
            {updatedController.length > 0 && updatedController.map(({ full_name }) => full_name).join(', ')}
          </span>
        </div>
      </div>
    </div>
  )
}

VehicleControllerItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  vehicle: PropTypes.shape({
    controller: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        full_name: PropTypes.string,
        given_name: PropTypes.string,
        status: PropTypes.number,
        surname: PropTypes.string,
        type: PropTypes.number
      })
    )
  })
}

export default VehicleControllerItem
