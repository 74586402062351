import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { isMobile } from 'react-device-detect'

import { connect } from 'react-redux'
import { intlShape } from 'react-intl'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import TabBar from 'components/elements/TabBar'
import Slider from 'components/elements/Slider'
import GuideItem from 'components/sections/GuideItem'
import Collapsible from 'react-collapsible'
import ug1 from 'assets/images/manual_timeline.png'
import ug2 from 'assets/images/manual_vehicle.png'
import ug3 from 'assets/images/manual_inbox.png'
import ug4 from 'assets/images/manual_coupon.png'
import ug5 from 'assets/images/manual_appointment.png'
import ug6 from 'assets/images/manual_privilege.png'
import { Grid, withStyles } from '@material-ui/core'

// classnames
import classnames from 'classnames'

// Styles
import './_index.scss'

// Methods
import { FormattedHTMLMessage } from 'react-intl'
import { postPageView } from 'store/actions/profile'
import Menu from 'components/elements/Menu'
import { isMobileApp } from 'utils/validation'

const styles = {}

const HelpPage = ({ postPageView, profile }, context) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    postPageView()
  }, [])

  const handleActiveIndexUpdate = (activeIndex) => setActiveIndex(activeIndex)

  const faqEnd = 55
  let faqSection = []

  const classes = classnames('user-guide')

  const guides = [{ url: ug1 }, { url: ug2 }, { url: ug3 }, { url: ug4 }, { url: ug5 }, { url: ug6 }]

  for (let faqStart = 1; faqStart <= faqEnd; faqStart++) {
    faqSection.push(
      <Collapsible
        key={faqStart}
        className='accordion__header'
        trigger={context.intl.formatMessage({
          id: `myProfile.help.faq.${faqStart}.q`,
          defaultMessage: `myProfile.help.faq.${faqStart}.q`
        })}
      >
        <div className='accordion__body'>
          <FormattedHTMLMessage id={`myProfile.help.faq.${faqStart}.a`} defaultMessage={`myProfile.help.faq.${faqStart}.a`} />
        </div>
      </Collapsible>
    )
  }

  const content = (
    <>
      <TabBar tabs={['myProfile.help.userGuide.title', 'myProfile.help.faq.title']} onActiveIndexChange={handleActiveIndexUpdate} />

      <div className='user-guide__content'>
        {activeIndex === 0 && (
          <Slider title='myProfile.help.howto' component={GuideItem} data={guides} userType={profile.type} noMargin forceNumber={1} />
        )}
        {activeIndex === 1 && <div className='accordion'>{faqSection}</div>}
      </div>
    </>
  )
  return (
    <div className={classes}>
      {!isMobileApp() && <DetailHeader showBack={isMobile} defaultPath='profile' type='profile-setting' />}
      {isMobile ? (
        <>{content}</>
      ) : (
        <div className='content-setting'>
          <Grid container className={'container-setting'}>
            {!isMobileApp() && (
              <Grid sm={3}>
                <Menu mode={0} tabActive={'help'} />
              </Grid>
            )}
            <Grid justify={'center'} xs={12} sm={9}>
              {content}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

HelpPage.propTypes = {
  profile: PropTypes.object
}

HelpPage.contextTypes = {
  intl: intlShape
}

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        postPageView
      },
      dispatch
    )
const mapStateToProps =
  /* istanbul ignore next */
  (state) => ({
    profile: state.profile
  })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HelpPage))
