import { FILTER } from '../definitions'

/**
 *
 * @param {*} keyword
 */
const setKeyword = (keyword) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.KEYWORD,
      keyword
    })
  }
}

/**
 *
 * @param {*} orderBy
 */
const setOrderBy = (orderBy) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.ORDER,
      orderBy
    })
  }
}

const setType = (vehicleType) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.TYPE,
      vehicleType
    })
  }
}

const setController = (controller) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.CONTROLLER,
      controller
    })
  }
}

/**
 *
 */
const toggleSortBy = () => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.SORT
    })
  }
}

/**
 *
 */
const toggleView = () => {
  return (dispatch) => {
    dispatch({
      type: FILTER.VEHICLE.VIEW
    })
  }
}

export { setKeyword, setOrderBy, setType, setController, toggleSortBy, toggleView }
