// Datadog
import { datadogRum } from '@datadog/browser-rum'

import { isErrorType, hasInUrl, hasErrorMessage, isSourceConsole, compose } from './handler-error-datadog'

export const init = () => {
  console.log(`VERSION : ${process.env.REACT_APP_COMMIT_ID}`)
  datadogRum.init({
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'myi-web',
    env: process.env.REACT_APP_DD_ENV,
    // We have no plan to support `access-control-request-headers: traceparent`
    // anytime soon. Leaving it in the request header will break browser CORS
    // policy.
    // See: https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#apm-integration
    // See: https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum#opentelemetry-support
    // See: https://github.com/DataDog/browser-sdk/issues/1639
    allowedTracingUrls: [
      {
        match: /https:\/\/.*\.isuzu-tis\.com/,
        propagatorTypes: ['datadog']
      },
      {
        match: /https:\/\/.*\.amplifyapp\.com/,
        propagatorTypes: ['datadog']
      }
    ],
    beforeSend: (event) => {
      // Non-error events are irrelevant to us. We will leave them as they are.
      if (!isErrorType(event)) {
        return true
      }

      if (isAxiosError(event)) {
        event.context = { ...event.context, ignoreError: 'true' }
      }
      // TODO: Remove mute error when this https://github.com/react-component/swipeout/issues/58 is close
      if (isRcSwipeOutInInboxPageError(event) || isSwiperIsTouchedError(event)) {
        return false
      }

      if (isScrollingInterventionError(event)) {
        return false
      }

      // See: https://tripetch.atlassian.net/browse/LEGO-7361?focusedCommentId=63411
      if (
        hasErrorMessage('Unexpected identifier')(event) ||
        hasErrorMessage('processRandomSelector is not defined')(event) ||
        hasErrorMessage(`Unexpected token 'function'`)(event) ||
        hasErrorMessage('runCustomize is not defined')(event)
      ) {
        return false
      }
    },
    version: process.env.REACT_APP_COMMIT_ID,
    sessionSampleRate: +process.env.REACT_APP_SAMPLE_RATE,
    sessionReplaySampleRate: 0,
    trackResources: false,
    trackLongTasks: false,
    trackUserInteractions: true
  })
}

const isRcSwipeOutInInboxPageError = compose(
  isErrorType,
  hasInUrl('/inbox'),
  hasErrorMessage('intervention: Unable to preventDefault inside passive event listener due to target being treated as passive.')
)

// See: https://tripetch.atlassian.net/browse/MIM-223?focusedCommentId=38240
const isSwiperIsTouchedError = compose(isErrorType, hasErrorMessage("Cannot read properties of undefined (reading 'isTouched')"))

const isAxiosError = compose(isErrorType, hasErrorMessage('isAxiosError'), isSourceConsole)

/**
 * Covers touchend, touchstart, touchmove intervention errors
 */
const isScrollingInterventionError = compose(isErrorType, hasErrorMessage('scrolling is in progress and cannot be interrupted.'))

/**
 * Set customer info to datadog
 * @param {string} id
 * @param {number} type
 * @param {number|null} subtype
 * @param {boolean} isDemoUser
 */
export const setCustomer = (id, type, subtype, isDemoUser) => {
  datadogRum.setUser({
    id,
    type,
    subtype,
    isDemoUser
  })
}

/**
 * Clear customer info ie. logout
 */
export const clearCustomer = () => {
  datadogRum.clearUser()
}
