import React, { useEffect } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import DetailExcellency from 'domain/Excellency/components/DetailExcellency'

// Actions
import { getVehicleDetail, getVehicles } from 'store/actions/vehicles'
import { getProfile } from 'store/actions/profile'

// Styles
import './_index.scss'
import { postPageView } from 'store/actions/profile'

// Utils
import { checkError } from 'utils/helper'

const ExcellencyDetail = ({ getVehicleDetail, getVehicles, postPageView, location, vehicleDetail, vehicleList, match }) => {
  useEffect(() => {
    Promise.all([
      getVehicleDetail(match.params.id),
      getVehicles(true, '', 1, 0, 2000, 0, 0, undefined, 'all', false, true),
      postPageView()
    ]).catch((err) => {
      checkError(err)
    })
  }, [])

  useEffect(() => {
    getVehicleDetail(match.params.id).catch((err) => {
      checkError(err)
    })
    window.previousLocation = location
  }, [getVehicleDetail, location])

  return (
    <div className='excellency-detail'>
      <div className='excellency-detail__header'>
        <DetailHeader showBack type='basic' />
      </div>
      <DetailExcellency detail={vehicleDetail} list={vehicleList} excellency={vehicleDetail.vehicle.excellency} />
    </div>
  )
}

ExcellencyDetail.propTypes = {
  getVehicles: PropTypes.func,
  getVehicleDetail: PropTypes.func,
  vehicleDetail: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

const mapStateToProps =
  /* istanbul ignore next */
  (state) => ({
    vehicleDetail: state.vehicleDetail,
    vehicleList: state.vehicles,
    profile: state.profile
  })

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        getVehicleDetail,
        getVehicles,
        postPageView,
        getProfile
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExcellencyDetail))
