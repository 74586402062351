import { PROFILE } from '../definitions'
import { replace } from 'connected-react-router'
import api from 'api/'
import { destroy } from 'redux-form'
import { authSignout } from './auth'
import {
  getLineState,
  setToken,
  forceLogOut,
  getRefreshToken,
  removeRefreshToken,
  getTime,
  setCurrentUserType,
  setLoginUserType
} from 'utils/auth'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'

import moment from 'moment'
import 'moment/locale/th'

/**
 *
 */
const getProfile = () => {
  return (dispatch) => {
    return api.getProfile().then((res) => {
      setCurrentUserType(res.data.type)
      setLoginUserType(res.data.type)
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const refreshToken = getRefreshToken()

      /* get current date */
      let currentdate = moment()

      /* check valid until */
      let allowanceUntil = getTime()

      if (!isSafari && allowanceUntil < currentdate) {
        return api
          .refreshToken({ refreshToken: refreshToken })
          .then((res) => res.json())
          .then((res) => {
            if (res.token !== undefined) {
              setToken(res.token, new Date(res.valid_until))
            }
          })
          .then(() => {
            dispatch({
              type: PROFILE.GET,
              profile: {
                loadProfileComplete: true,
                ...res.data
              }
            })
          })
      }
      dispatch({
        type: PROFILE.GET,
        profile: {
          loadProfileComplete: true,
          ...res.data
        }
      })
    })
  }
}

const skipCustomerProfileLoading = (loadProfileComplete) => {
  return (dispatch) => {
    dispatch({
      type: PROFILE.GET,
      profile: {
        loadProfileComplete
      }
    })
  }
}

/**
 *
 */
const editProfile = (type) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()
    var payload = Object.assign({}, form.profileEdit.values)

    if (type === 1) {
      delete payload.office_phone
      delete payload.office_phone_ext
      /*istanbul ignore else*/
      if (!payload.username) payload.username = null
    }
    if (type === 2 || type === 3) delete payload.home_phone
    /*istanbul ignore else*/
    if (!payload.national_id || type === 1 || type === 2) delete payload.national_id
    /*istanbul ignore else*/
    if (!payload.passport_no || type === 1 || type === 2) delete payload.passport_no

    return api
      .editProfile(payload)
      .then((res) => {
        dispatch(showAlert(alert(res.status ? res.status : 200, 'profile-save-success')))
        dispatch(hideLoader())
      })
      .then(() => {
        return dispatch(getProfile())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 */
const changePassword = () => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()
    var payload = Object.assign({}, form.changePassword.values)
    delete payload.password_repeated

    return api
      .editProfile(payload)
      .then((res) => {
        dispatch(showAlert(alert(res.status ? res.status : 200, 'profile-password_change')))
        dispatch(destroy('changePassword'))
        dispatch(authSignout(true))
      })
      .catch((err) => {
        dispatch(
          showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'profile-password_change'))
        )
        dispatch(hideLoader())
      })
  }
}

/**
 *
 * @param {*} optIn
 */
const profileOptIn = (optIn) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api
      .editProfile({ is_opt_out_promotion_message: optIn })
      .then((res) => {
        dispatch(showAlert(alert(res.status ? res.status : 200, 'profile-settings')))
        return dispatch(getProfile())
      })
      .then(() => dispatch(hideLoader()))
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'profile-settings')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 * @param {*} payload
 */
const lineConnect = (payload) => {
  return (dispatch) => {
    dispatch(showLoader())
    if (getLineState() !== payload.state) {
      dispatch(showAlert(alert(401, 'lineConnect')))
      dispatch(hideLoader())
      return Promise.reject(new Error(`LINE login state mismatched. Incoming: ${payload.state}, Local: ${getLineState()}`))
    }

    return api
      .lineConnect({ provider: 'line', authorization_code: payload.code })
      .then(async (res) => {
        await dispatch(getProfile())

        return res
      })
      .then((res) => {
        dispatch(replace('/'))
        dispatch(hideLoader())
        dispatch(showAlert(alert(res.status ? res.status : 200, 'profile-line_connect')))
      })
      .catch((err) => {
        dispatch(replace('/'))
        dispatch(hideLoader())
        dispatch(
          showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'profile-line_connect'))
        )
      })
  }
}

/**
 *
 */
const getProfileServiceCenter = () => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getProfileServiceCenters()
        .then((res) => {
          dispatch({
            type: PROFILE.GET_SC,
            total_count: res.data.length,
            data: res.data
          })
          dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 */
const getProfileStat = () => {
  return (dispatch) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    return api.getProfileStat().then((res) => {
      const refreshToken = getRefreshToken()
      /* get current date */
      let currentdate = moment()

      /* check valid until */
      let allowanceUntil = getTime()

      /* Check if rememberMe is true and valid date is less than current date. */
      if (allowanceUntil <= currentdate && refreshToken !== null && !isSafari) {
        api
          .refreshToken({ refreshToken: refreshToken })
          .then((res) => res.json())
          .then((res) => {
            if (res.token !== undefined && res.token !== null && res.token !== '') {
              setToken(res.token, new Date(res.valid_until))
            } else {
              removeRefreshToken()
              forceLogOut()
            }
          })
      }

      if (res !== undefined) {
        return dispatch({
          type: PROFILE.STAT,
          profileStat: res.data
        })
      }
    })
  }
}

/**
 *
 */
const postPageView = () => {
  return (dispatch) => {
    dispatch({
      type: PROFILE.PAGEVIEW
    })
  }
}
/**
 *
 */
const postClick = () => {
  return (dispatch) => {
    dispatch({
      type: PROFILE.CLICK
    })
  }
}
export {
  getProfile,
  editProfile,
  changePassword,
  profileOptIn,
  lineConnect,
  getProfileServiceCenter,
  getProfileStat,
  postPageView,
  postClick,
  skipCustomerProfileLoading
}
