import { gql } from '@apollo/client'

export const MYI_PRIVACY_POLICY_QUERY = gql`
  query MyiPrivacyPolicy {
    cmsNamespace {
      myiPrivacyPolicy {
        htmlContentEn
        htmlContentTh
      }
    }
  }
`
