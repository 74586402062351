import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { unassignControllers } from 'store/actions/vehicles'

import ContentHeader from 'components/layouts/ContentHeader'
import Button from 'components/elements/Button'
import ControllerSelector from 'domain/Controllers/components/ControllerSelector'
import { VehicleControllerItem } from 'domain/Vehicle/components/VehicleItem'

import { isEmpty } from 'lodash'

import './_index.scss'
import { PROFILE } from 'store/definitions'

import { checkError } from 'utils/helper'

const UnassignController = ({ selectedVehicles, history }) => {
  const [vehicles, setVehicles] = useState(selectedVehicles)
  const [selectedControllerIds, setSelectedControllerIds] = useState([])
  const [controllers, setControllers] = useState([])
  const [checkedCount, setCheckCount] = useState(selectedVehicles.length)
  const dispatch = useDispatch()

  dispatch({
    type: PROFILE.PAGEVIEW
  })

  useEffect(() => {
    if (isEmpty(selectedVehicles) && history) {
      history.replace('/vehicles')
    }

    const distinctControllers = selectedVehicles.reduce((controllers, { controllers: _controllers }) => {
      const newController = { ...controllers }

      _controllers.forEach((controller) => {
        newController[controller.id] = controller
      })

      return newController
    }, {})

    setControllers(Object.values(distinctControllers))
  }, [selectedVehicles, history])

  const confirmHandler = async () => {
    const vehicleIds = vehicles.filter(({ is_checked }) => is_checked).map(({ id }) => id)

    try {
      await dispatch(unassignControllers(selectedControllerIds, vehicleIds))

      goBackHandler()
    } catch (err) {
      checkError(err)
    }
  }

  const goBackHandler = useCallback(() => {
    if (history) {
      history.goBack()
    }
  }, [history])

  const controllerChangeHandler = (_selectedControllerId) => {
    setSelectedControllerIds(_selectedControllerId)
  }

  const getDiffController = (vehicleController) => vehicleController.filter(({ id }) => !selectedControllerIds.includes(id))

  const checkClickHandler = (vehicleId) => {
    const newVehicles = [...vehicles]
    const index = newVehicles.findIndex(({ id }) => id === vehicleId)

    if (index > -1) {
      newVehicles[index] = { ...newVehicles[index], is_checked: !newVehicles[index].is_checked }
    }

    //TODO: Optimise counting
    const checkCount = newVehicles.reduce((count, { is_checked }) => (is_checked ? count + 1 : count), 0)

    setVehicles(newVehicles)
    setCheckCount(checkCount)
  }

  return (
    <div className='unassign-controller'>
      <ContentHeader title='vehicle.unassignController.title' />
      <div className='unassign-controller__body'>
        <div className='unassign-controller__body__container'>
          <div className='unassign-controller__body__container__controllers'>
            <div className='unassign-controller__body__container__controllers__content'>
              <ControllerSelector
                controllers={controllers}
                onBackButtonClick={goBackHandler}
                onControllerChange={controllerChangeHandler}
                controllersLabels='vehicle.unassignController.unassignedController'
              />
            </div>
          </div>
          <div className='unassign-controller__body__container__vehicles-list'>
            <div className='unassign-controller__body__container__vehicles-list--header'>
              <div className='unassign-controller__body__container__vehicles-list--header__item name'>
                <FormattedMessage id='vehicle.list.listName' defaultMessage='vehicle.list.listName' />
              </div>
              <div className='unassign-controller__body__container__vehicles-list--header__item current-assignment-column'>
                <FormattedMessage id='vehicle.list.currentController' defaultMessage='vehicle.list.currentController' />
              </div>
              <div className='unassign-controller__body__container__vehicles-list--header__item new-assignment-column'>
                <FormattedMessage id='vehicle.list.newController' defaultMessage='vehicle.list.newController' />
              </div>
            </div>
            <div className='list'>
              {vehicles &&
                vehicles.map(({ id, name, is_checked, vehicle, controllers }) => {
                  const newControllers = getDiffController(controllers)

                  return (
                    <VehicleControllerItem
                      key={id}
                      id={id}
                      isChecked={is_checked}
                      name={name}
                      vehicle={vehicle}
                      controllers={controllers}
                      newControllers={newControllers}
                      onCheckClick={checkClickHandler}
                      fromPage='Unassign Controller'
                    />
                  )
                })}
            </div>
          </div>
          <div className='unassign-controller__body__container__actions'>
            <Button
              type='submit'
              color='primary'
              label='vehicle.assignController.confirm'
              onClick={confirmHandler}
              disabled={selectedControllerIds.length === 0 || checkedCount === 0}
            />
            <Button type='button' color='dark' label='vehicle.assignController.cancel' onClick={goBackHandler} />
          </div>
        </div>
      </div>
    </div>
  )
}

UnassignController.propTypes = {
  history: PropTypes.object,
  selectedVehicles: PropTypes.array,
  controllers: PropTypes.array
}

const mapStateToProps = ({ vehicles: { selectedVehicles } }) => ({
  selectedVehicles
})

export default withRouter(connect(mapStateToProps)(UnassignController))
