import { TIL_REFINANCE } from '../definitions'
import api from 'api/'
import { showLoader } from 'store/actions/loader'

/**
 *
 * @param {*} apk
 * @param {*} vin
 * @param {*} rfBrand
 * @param {*} rfType
 * @param {*} rfModelName
 * @param {*} rfModelCode
 * @param {*} rfGear
 * @param {*} rfYear
 */
const getRefinanceDetail = (apk, vin, rfBrand, rfType, rfModelName, rfModelCode, rfGear, rfYear) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getRefinanceDetail({
          apk,
          vin,
          rfBrand,
          rfType,
          rfModelName,
          rfModelCode,
          rfGear,
          rfYear
        })
        .then((res) => {
          dispatch({
            type: TIL_REFINANCE.GET,
            refinanceDetail: res.data
          })
          resolve(res.data)
        })
        .catch((err) => {
          dispatch({
            type: TIL_REFINANCE.ERROR_GET,
            errorGet: err
          })
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} apk
 * @param {*} flag
 * @param {*} contractNo
 * @param {*} name
 * @param {*} lastName
 * @param {*} idCard
 * @param {*} phoneNo
 * @param {*} phoneNo2
 * @param {*} email
 * @param {*} from
 * @param {*} to
 * @param {*} rfPrice
 * @param {*} rfCampaignId
 * @param {*} rfBrand
 * @param {*} rfType
 * @param {*} rfModelName
 * @param {*} rfModelCode
 * @param {*} rfGear
 * @param {*} rfYear
 * @param {*} vin
 */
const registerTransactionContractRf = (
  apk,
  flag,
  contractNo,
  name,
  lastName,
  idCard,
  phoneNo,
  phoneNo2,
  email,
  from,
  to,
  rfPrice,
  rfCampaignId,
  rfBrand,
  rfType,
  rfModelName,
  rfModelCode,
  rfGear,
  rfYear,
  vin
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .registerTransactionContractRf({
          apk,
          flag,
          contractNo,
          name,
          lastName,
          idCard,
          phoneNo,
          phoneNo2,
          email,
          from,
          to,
          rfPrice,
          rfCampaignId,
          rfBrand,
          rfType,
          rfModelName,
          rfModelCode,
          rfGear,
          rfYear,
          vin
        })
        .then((res) => {
          dispatch({
            type: TIL_REFINANCE.REGISTER,
            registerResult: res.data
          })
          resolve(res.data)
        })
        .catch((err) => {
          dispatch({
            type: TIL_REFINANCE.ERROR_REGISTER,
            errorRegister: err
          })
          reject(err)
        })
    })
  }
}

export { getRefinanceDetail, registerTransactionContractRf }
