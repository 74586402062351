import { VEHICLE, CLEAR_THIS_STATE } from 'store/definitions'
import _Filter from 'lodash/filter'

import _ from 'lodash'
import { removeDuplicates } from '../../utils/removeDuplicates'

const initialState = {
  total_count: 0,
  selectedVehicles: [],
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE.GET: {
      let hasMoreRecords = true
      let newList

      const newData = [...action.data].map((item) => {
        const is_checked = !!state.selectedVehicles.find((selectedVehicle) => selectedVehicle.id === item.id)

        return {
          ...item,
          is_checked
        }
      })

      if (action.offset > 0) {
        newList = [...state.data, ...newData]
      } else {
        newList = [...newData]
      }
      const newUniqueList = removeDuplicates(newList, 'id')

      if (action.data.length < action.limit) {
        hasMoreRecords = false
      } else {
        hasMoreRecords = true
      }

      if (_.isEmpty(state)) {
        return Object.assign({}, action.data)
      } else {
        return Object.assign({}, state, {
          ...state,
          total_count: action.total_count,
          data: newUniqueList,
          has_only_cv: action.has_only_cv,
          hasMoreRecords: hasMoreRecords,
          limit: action.limit,
          offset: action.offset
        })
      }
    }

    case VEHICLE.CHECK: {
      const newData = [...state.data]
      const index = newData.findIndex((item) => item.id === action.id)

      if (index > -1) {
        newData[index].is_checked = !newData[index].is_checked
        let newSelectedVehicles = [...state.selectedVehicles]
        const selectedIndex = newSelectedVehicles.findIndex((selectedItem) => selectedItem.id === newData[index].id)
        if (selectedIndex > -1) {
          // Remove exist item
          newSelectedVehicles.splice(selectedIndex, 1)
        } else {
          // Add new checked
          newSelectedVehicles = [...newSelectedVehicles, newData[index]]
        }

        return Object.assign({}, state, {
          ...state,
          data: newData,
          selectedVehicles: newSelectedVehicles
        })
      } else {
        return state
      }
    }

    case VEHICLE.UNCHECK_ALL: {
      return Object.assign({}, state, {
        ...state,
        data: [...state.data].map((item) => {
          item.is_checked = false
          return item
        }),
        selectedVehicles: initialState.selectedVehicles
      })
    }

    case VEHICLE.CHECK_ALL: {
      const newData = [...state.data]
      let newSelectedVehicles = [...state.selectedVehicles]

      newData.forEach((item) => {
        item.is_checked = true
        const selectedIndex = newSelectedVehicles.findIndex((selectedItem) => selectedItem.id === item.id)
        if (selectedIndex === -1) {
          // Add new checked
          newSelectedVehicles = [...newSelectedVehicles, item]
        }
      })

      return Object.assign({}, state, {
        ...state,
        data: newData,
        selectedVehicles: newSelectedVehicles
      })
    }

    case VEHICLE.DELETE: {
      const newData2 = _Filter(state.data, (d) => {
        return d.id !== action.id
      })

      return Object.assign({}, state, {
        ...state,
        total_count: state.total_count - 1,
        data: newData2
      })
    }

    case VEHICLE.FAV: {
      const newDataList = [...state.data]
      const indexVehicle = newDataList.findIndex((item) => item.id === action.id)
      if (indexVehicle > -1) {
        newDataList[indexVehicle].is_favorite = action.value
        return Object.assign({}, state, {
          ...state,
          data: newDataList
        })
      } else {
        return state
      }
    }

    case CLEAR_THIS_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}
