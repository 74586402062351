import React, { forwardRef, useState, useImperativeHandle } from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ReactModal from 'react-modal'
import Button from 'components/elements/Button'

// Methods
import { FormattedMessage } from 'react-intl'
import * as format from 'utils/format'

// Styles
import './_index.scss'

ReactModal.setAppElement('#root')

const Modal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModal(),
      closeModal: () => closeModal()
    }
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (typeof props.onClosed === 'function') {
      props.onClosed()
    }
  }
  const confirm = () => {
    setIsOpen(false)
    props.onConfirmed()
  }

  const classes = classnames(
    'modal',
    { 'modal--coupon-vehicle': props.mode === 3 && props.detail.coupon_info.coupon_category === 'SC' },
    {
      'modal--coupon-owner': props.mode === 3 && props.detail.coupon_info.owner_id && props.detail.coupon_info.coupon_category === 'CC'
    },
    {
      'modal--coupon-vehicle':
        props.mode === 3 && props.detail.coupon_info.license_plate && props.detail.coupon_info.coupon_category === 'CC'
    },
    { 'modal--coupon': props.mode === 3 },
    { 'modal--el': props.mode === 5 },
    { 'modal--privilege': props.mode === 8 },
    { 'modal--dealer': props.mode === 9 },
    { 'modal--delete': props.mode === 10 },
    { 'modal--promotion': props.mode === 11 },
    { 'modal--menu-vehciel-image': props.mode === 12 },
    { 'modal--company': props.mode === 13 },
    { 'modal--blurLicence': props.mode === 14 },
    { 'modal--basic': props.mode === 15 },
    props.className
  )

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={classes}
      overlayClassName='modal-overlay'
      shouldFocusAfterRender={false}
      htmlOpenClassName='ReactModal__Html--open'
    >
      {props.mode !== 12 && (
        <div className='modal__header'>
          <div className='modal__header__text'>
            {props.mode === 3 ? (
              <div className='modal__header__image'>
                <img alt='logo' src={props.detail.coupon_info.coupon_logo}></img>
                {props.detail.coupon_info.coupon_category === 'CC' &&
                  props.detail.coupon_info.value !== 0 &&
                  props.detail.coupon_info.value !== null && (
                    <div className='modal__header__value'>
                      <span className={`modal__header__value__${format.getNumberDigits(props.detail.coupon_info.value)}`}>
                        {format.getDisplayNumber(props.detail.coupon_info.value)}
                        {props.detail.type.cash_coupon_type === 'DCP' ? '%' : '.-'}
                      </span>
                    </div>
                  )}
              </div>
            ) : (
              <div className='title'>
                {props.title ? (
                  <FormattedMessage id={props.title} defaultMessage={props.title} />
                ) : props.inputTitle ? (
                  <p>{props.inputTitle}</p>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          <Button iconOnly type='button-close' icon='clear' onClick={closeModal} />
        </div>
      )}
      <div className='modal__body'>
        {(props.mode === 1 || props.mode === 2 || props.mode === 7) && (
          <div className='modal__body__content'>
            <FormattedMessage id={props.content} defaultMessage={props.content} />
          </div>
        )}

        {props.mode === 3 && <props.content detail={props.detail} />}

        {props.mode === 5 && <div className='modal__body__content'>{props.content}</div>}

        {props.mode === 6 && (
          <div className='modal__body__content'>
            <div>
              <FormattedMessage id={props.content} defaultMessage={props.content} />
            </div>
          </div>
        )}

        {[8, 11].includes(props.mode) && <div className='modal__body__content__privilege'>{props.content}</div>}

        {(props.mode === 9 || props.mode === 10 || props.mode === 15) && <div className='modal__body__content'>{props.content}</div>}

        {props.mode === 12 && <div className='menuEditVehicleImage'>{props.content}</div>}

        {props.mode === 13 && (
          <div className='modal__body__content__company'>
            <div className='title'>
              <FormattedMessage id={props.header} defaultMessage={props.header} />
            </div>
            <div className='content'>{props.content}</div>
            <div className='confirm'>
              <Button color='primary' label={props.label} onClick={() => props.onClick()} />
            </div>
          </div>
        )}
        {props.mode === 14 && (
          <div className='modal__body__content__blur__licence'>
            <div className='title'>
              <FormattedMessage id={props.header} defaultMessage={props.header} />
            </div>
            <div className='content'>{props.content}</div>
            <div className='confirm'>
              <Button color='primary' label={props.label} onClick={confirm} />
            </div>
          </div>
        )}
      </div>
      <div className='modal__action'>
        {(props.mode === 1 || props.mode === 2 || props.mode === 10) && (
          <Button color='transparent' type='modal-close' label='common.button.cancel' onClick={closeModal} />
        )}

        {props.mode === 6 && <Button color='transparent' type='modal-close' label='common.button.close' onClick={closeModal} />}

        {props.mode === 7 && <Button color='transparent' type='modal-close' label='login.button.login' onClick={closeModal} />}

        {props.mode === 2 && <Button color='transparent' type='modal-confirm' label='common.button.confirm' onClick={confirm} />}

        {props.mode === 10 && (
          <Button
            color='transparent'
            type='modal-confirm'
            label='vehicle.delete.send'
            onClick={confirm}
            disabled={!props.reason || (props.reason === '6' && !props.remarks)}
          />
        )}

        {props.mode === 7 && props.confirm && (
          <Button color='transparent' type='modal-confirm' label='login.button.createAccount' onClick={confirm} />
        )}

        {/** MYI-317 remove download coupon */}
        {/* {this.props.mode === 3 && <this.props.footer detail={this.props.detail} fromPage={this.props.fromPage} />} */}
      </div>
    </ReactModal>
  )
})

Modal.propTypes = {
  mode: PropTypes.number,
  content: PropTypes.any,
  title: PropTypes.string,
  footer: PropTypes.any,
  detail: PropTypes.any,
  onConfirmed: PropTypes.func,
  onClosed: PropTypes.func
}

Modal.defaultProps = {
  mode: 1
}

export default Modal
