import React from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Styles
import './_index.scss'

const BannerItem = (props) => {
  const { url, title, image_url } = props.detail

  return (
    <BannerItemLink
      url={url}
      title={title}
      imageUrl={image_url}
      isBannerTimeline={props.isBannerTimeline}
      isEndOfSupportBanner={props.isEndOfSupportBanner}
    />
  )
}

const BannerItemLink = ({ url, title, imageUrl, isBannerTimeline, isEndOfSupportBanner }) => {
  const classes = classnames(
    isBannerTimeline ? 'banner-timeline-item' : 'banner-item',
    isEndOfSupportBanner && 'terminate-myisuzu-web-banner'
  )

  return (
    <a className={classes} href={url} target='_blank' rel='noopener noreferrer'>
      <img src={imageUrl} alt={title} />
    </a>
  )
}

BannerItem.propTypes = {
  detail: PropTypes.object
}

BannerItem.defaultProps = {
  detail: {
    image_url: undefined,
    title: ''
  }
}

export default BannerItem
