import React from 'react'

import { TERM_CONDITION, REMARK } from './information'

// Styles
import './index.scss'

const ISPTerm = () => {
  return (
    <div className='isp-section'>
      <div className='isp-condition'>
        {TERM_CONDITION.map((value) => (
          <div>
            <strong>{value.title}</strong>
            {value.content.map((content) => (
              <p dangerouslySetInnerHTML={{ __html: content }} />
            ))}
          </div>
        ))}
        <p>{REMARK}</p>
      </div>
    </div>
  )
}

export default ISPTerm
