import React, { useCallback, useEffect } from 'react'

// Classnames
import classnames from 'classnames'

// Router
import { useHistory, useLocation } from 'react-router-dom'

// Components
import Button from 'components/elements/Button'

// Constants
import { USER_TYPE } from 'config/constant'

// i18n
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Proptypes
import PropTypes from 'prop-types'

// Actions
import { getControllerStats, getNextControllerStats } from 'store/actions/controllers'
import { downloadVehicles } from 'store/actions/vehicles'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Styles
import './_index.scss'

import { greenLineIcon, downloadIcon } from 'config/icon'

const ContentHeader = ({ title, mobileTitle, subTitle, type, userType, given_name, lineConnect, env }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const controllerStats = useSelector((state) => state.controllers.stats)
  const location = useLocation()

  const downloadVehiclesHandler = useCallback(() => {
    dispatch(downloadVehicles())
  }, [dispatch])

  useEffect(() => {
    if (type === 'controllers') {
      dispatch(getControllerStats())
      dispatch(getNextControllerStats())
    }
  }, [dispatch, type])

  const classes = classnames('content-header', { 'content-header-home': type === 'home' })

  const isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes}>
      <div className='content-header-wrapper'>
        <div className='content-header-wrapper__label'>
          {title &&
            (isMobile() === true && location.pathname === '/' ? (
              <h6>
                <FormattedHTMLMessage id={mobileTitle} defaultMessage={mobileTitle} values={{ username: given_name, env: env }} />
              </h6>
            ) : (
              <h4>
                <FormattedHTMLMessage id={title} defaultMessage={title} values={{ username: given_name, env: env }} />
              </h4>
            ))}
          {subTitle && (
            <p>
              <FormattedMessage id={subTitle} defaultMessage={subTitle} />
            </p>
          )}
        </div>

        {type === 'controllers' && (
          <div className='content-header-wrapper__controllers'>
            <div className='content-header-wrapper__controllers__card'>
              <div className='content-header-wrapper__controllers__card--number'>{controllerStats.total}</div>
              <div className='content-header-wrapper__controllers__card--label'>
                <FormattedMessage id='controller.statistic.total' defaultMessage='controller.statistic.total' />
              </div>
            </div>
            <div className='content-header-wrapper__controllers__card'>
              <div className='content-header-wrapper__controllers__card--number'>{controllerStats.assigned}</div>
              <div className='content-header-wrapper__controllers__card--label'>
                <FormattedMessage id='controller.statistic.assign' defaultMessage='controller.statistic.assign' />
              </div>
            </div>
            <div className='content-header-wrapper__controllers__card'>
              <div className='content-header-wrapper__controllers__card--number'>{controllerStats.active}</div>
              <div className='content-header-wrapper__controllers__card--label'>
                <FormattedMessage id='controller.statistic.active' defaultMessage='controller.statistic.active' />
              </div>
            </div>
            <div className='content-header-wrapper__controllers__card'>
              <div className='content-header-wrapper__controllers__card--number'>{controllerStats.in_active}</div>
              <div className='content-header-wrapper__controllers__card--label'>
                <FormattedMessage id='controller.statistic.inactive' defaultMessage='controller.statistic.inactive' />
              </div>
            </div>
          </div>
        )}

        <div className='button-group'>
          {type === 'vehicles' && (userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) && (
            <Button
              className='button-group--download'
              color='primary'
              icon={downloadIcon}
              label='vehicle.list.download'
              onClick={downloadVehiclesHandler}
            />
          )}

          {type === 'controllers' && (
            <Button type='add-new' icon='add' label='controller.list.addController' onClick={() => history.push('/controller/add')} />
          )}

          {!!lineConnect && (
            <div className='content-header__social-media'>
              <Button type='line-home' label='myProfile.edit.connectLine' customIcon={greenLineIcon} onClick={lineConnect} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContentHeader.propTypes = {
  given_name: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  userType: PropTypes.number
}

ContentHeader.defaultProps = {
  given_name: '',
  subTitle: '',
  title: '',
  type: '',
  userType: null
}

export default ContentHeader
