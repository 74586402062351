import React from 'react'
import animationData from '../../../config/lotties/horizontal-loading.json'
import Lottie from 'react-lottie'
import { FormattedHTMLMessage } from 'react-intl'

// Method
import { formatNumber } from 'utils/format'

// Icon
import { rfErrorIcon, rfResultIcon } from 'config/icon'

const TILRefinanceBox = ({ refinanceDetail, requestForRefinanceDetail, waiting, error }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  }

  return (
    <React.Fragment>
      {!!waiting && (
        <div className='til-refinance__body__box'>
          <div className='til-refinance__body__box__icon'>
            <div className='til-refinance__body__box__icon__lottie'>
              <Lottie options={defaultOptions} height={50} width={50} />
            </div>
          </div>
          <div>
            <FormattedHTMLMessage id='tilRefinance.waiting' defaultMessage='tilRefinance.waiting' />
          </div>
        </div>
      )}
      {!waiting && !error && refinanceDetail && (
        <div className='til-refinance__body__box'>
          <div className='til-refinance__body__box__icon'>{rfResultIcon}</div>
          <div>
            <FormattedHTMLMessage id='tilRefinance.estimatedAmount1' defaultMessage='tilRefinance.estimatedAmount1' />
          </div>
          <div className='til-refinance__body__box__amount' id='refinance-amount-text'>
            <FormattedHTMLMessage
              id='tilRefinance.estimatedAmount2'
              values={{
                amount: formatNumber(+refinanceDetail.financialAmount)
              }}
              defaultMessage='tilRefinance.estimatedAmount2'
            />
          </div>
        </div>
      )}
      {!waiting && error && (
        <div className='til-refinance__body__box'>
          <div className='til-refinance__body__box__icon' id='refinance-amount-error-icon'>
            {rfErrorIcon}
          </div>
          <div id='refinance-amount-error-text'>
            <FormattedHTMLMessage id='tilRefinance.error' defaultMessage='tilRefinance.error' />
          </div>
          <button id='refinance-reamount-btn' className='til-refinance__body__box__link' onClick={requestForRefinanceDetail}>
            <FormattedHTMLMessage id='tilRefinance.tryAgain' defaultMessage='tilRefinance.tryAgain' />
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

export default TILRefinanceBox
