import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

// Redux
import { useDispatch, useSelector } from 'react-redux'

import { Grid, withStyles } from '@material-ui/core'

// Classnames
import classnames from 'classnames'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import Select from 'components/elements/Select'
import Button from 'components/elements/Button'
import Menu from 'components/elements/Menu'

// Constants
import { languageOptions, fontSizes } from 'config/constant'

// Actions
import { changeLang, changeScale } from 'store/actions/config'
import { postPageView } from 'store/actions/profile'

// Styles
import './_index.scss'

const styles = {}

const ProfileSettings = () => {
  const lang = useSelector((state) => state.config.lang)
  const scale = useSelector((state) => state.config.scale)

  const [language, setLanguage] = useState(lang)
  const [scaleState, _setScale] = useState(scale)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(postPageView)
  }, [])

  const saveSettings = () => {
    dispatch(changeLang(language))
    dispatch(changeScale(scaleState))
  }

  const setScale = (scale) => {
    _setScale(scale.value)
  }

  const classes = classnames('profile-settings')

  const content = (
    <div className='profile-settings-wrapper'>
      <div className='profile-settings-wrapper__form'>
        <div className='profile-settings__lang'>
          <div className='label'>
            <FormattedMessage id='myProfile.settings.language' defaultMessage='myProfile.settings.language' />
          </div>
          <div className='profile-settings__options'>
            <div className='profile-settings__options__sub-label'>
              <span>
                <FormattedMessage id='myProfile.settings.changeLanguage' defaultMessage='myProfile.settings.changeLanguage' />
              </span>
            </div>
            <Select options={languageOptions} value={lang} onChange={(selected) => setLanguage(selected.value)} noMapping />
          </div>
        </div>

        <div className='profile-settings__lang'>
          <div className='label'>
            <FormattedMessage id='myProfile.settings.display' defaultMessage='myProfile.settings.display' />
          </div>
          <div className='profile-settings__options'>
            <div className='profile-settings__options__sub-label'>
              <span>
                <FormattedMessage id='myProfile.settings.changeFont' defaultMessage='myProfile.settings.changeFont' />
              </span>
            </div>
            <Select options={fontSizes} value={scaleState} onChange={setScale} noMapping />
          </div>
        </div>

        <Button
          type='submit'
          color='primary'
          label='myProfile.settings.save'
          onClick={saveSettings}
          disabled={language === lang && scaleState === scale}
        />
      </div>
    </div>
  )
  return (
    <div className={classes}>
      <DetailHeader showBack={isMobile} defaultPath='profile' type='profile-setting' />
      {isMobile ? (
        <>{content}</>
      ) : (
        <div className={'content-setting'}>
          <Grid container className={'container-setting'}>
            <Grid conatiner sm={3}>
              <Menu mode={0} tabActive={'changeLanguage'} />
            </Grid>
            <Grid justify={'center'} xs={12} sm={9}>
              {content}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ProfileSettings)
