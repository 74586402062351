import React, { useState, forwardRef, useImperativeHandle } from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ReactModal from 'react-modal'
import Button from 'components/elements/Button'

// Methods
import { FormattedHTMLMessage } from 'react-intl'

import './index.scss'

ReactModal.setAppElement('#root')

const ErrorModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModal(),
      closeModal: () => closeModal()
    }
  })
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (typeof props.onClosed === 'function') {
      props.onClosed()
    }
  }
  const classes = classnames('error', props.className)

  const { label, labelId, buttonId, confirm } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={classes}
      overlayClassName='modal-overlay'
      shouldFocusAfterRender={false}
      htmlOpenClassName='ReactModal__Html--open'
    >
      <div className={`${classes}__header`}>
        <FormattedHTMLMessage id='common.modal.header.errorTitle' defaultMessage='common.modal.header.errorTitle' />
      </div>
      <div className={'error__body'}>
        <div className={'error__body__content'} id={labelId}>
          <FormattedHTMLMessage id={label} defaultMessage={label} />
        </div>
      </div>

      <div className={'error__action'}>
        <Button id={buttonId} color='primary' label='common.modal.button.confirmErr' className={'error__error-ok-btn'} onClick={confirm} />
      </div>
    </ReactModal>
  )
})

ErrorModal.propTypes = {
  onConfirmed: PropTypes.func,
  onClosed: PropTypes.func
}

export default ErrorModal
