import React from 'react'

// Constant
import { REMARK } from '../info'

// CSS
import './index.scss'

const PackageRemark = () => (
  <div className='package-remark'>
    <p>{REMARK.title}</p>
    {REMARK.detail.map((data) => (
      <div className='package-remark--detail'>
        <li>{data}</li>
      </div>
    ))}
  </div>
)

export default PackageRemark
