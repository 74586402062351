import React from 'react'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Styles
import './index.scss'

// Method
import { formatNumberWithDecimal } from 'utils/format'

const DetailLayout = (props) => {
  return (
    <div className='item-detail'>
      <div className='item-detail__header'>
        <li>
          <FormattedMessage
            id={`RepairHistory.RepairDetail.subList.${props.subList}`}
            defaultMessage={`RepairHistory.RepairDetail.subList.${props.subList}`}
            className='values'
          />
        </li>
        {props.icare ? (
          <FormattedMessage id='RepairHistory.RepairDetail.noFee' defaultMessage='RepairHistory.RepairDetail.noFee' className='values' />
        ) : (
          <FormattedHTMLMessage
            id='RepairHistory.RepairDetail.price'
            values={{
              price: formatNumberWithDecimal(props.price, 2)
            }}
            defaultMessage='RepairHistory.RepairDetail.price'
          />
        )}
      </div>
      <div className='item-detail__list'>
        {props.values &&
          props.values.map((v, index) => (
            <ul style={{ listStyleType: 'square' }} key={index}>
              <li>{v.name}</li>
            </ul>
          ))}
      </div>
      <div className='repair-detail__body__line'></div>
    </div>
  )
}

export default DetailLayout
