import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import th from 'react-intl/locale-data/th'
import locale_en from './locales/en/en.json'
import locale_th from './locales/th/th.json'

addLocaleData([...en, ...th])

export const messages = {
  en: flattenMessages(locale_en),
  th: flattenMessages(locale_th)
}

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}
