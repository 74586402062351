import React, { useState, useImperativeHandle, forwardRef } from 'react'

// Environment
import { tilWebsiteUrl } from 'config/environment'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ReactModal from 'react-modal'
import Button from 'components/elements/Button'

// Methods
import { FormattedHTMLMessage } from 'react-intl'

import './index.scss'

// Icons
import findCar from 'assets/images/find-car.svg'
// import { lineCircleIcon } from 'config/icon'

ReactModal.setAppElement('#root')

const NoVehicleModal = forwardRef(({ className, history, redirectUrl, onConfirmed }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModal(),
      closeModal: () => closeModal(),
      confirm: () => confirm()
    }
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    history.push(redirectUrl)
  }

  const confirm = () => {
    setIsOpen(false)
    onConfirmed()
  }

  const classes = classnames('refinance', className)

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={classes}
      overlayClassName='modal-overlay'
      shouldFocusAfterRender={false}
      htmlOpenClassName='ReactModal__Html--open'
    >
      <Button iconOnly type='button-close' icon='clear' className={`${classes}__close`} onClick={closeModal} id='close-popup' />
      <div className={`${classes}__body`}>
        <div className={`${classes}__body__image`}>
          <img id='vehicle-icon' src={findCar} alt='' />
        </div>
        <div className={`${classes}__body__content`} id='refinance-not-found-text'>
          <FormattedHTMLMessage id='tilRefinance.modal.noVehicle' defaultMessage='tilRefinance.modal.noVehicle' />
        </div>
      </div>

      <div className={`${classes}__action`}>
        {getButton('connect-leasing-web-btn', history, tilWebsiteUrl, redirectUrl)}
        {/* {getButton('connect-leasing-app-btn')}
          <Button
            id='connect-line-btn'
            icon={lineCircleIcon}
            label='tilRefinance.button.tilLine'
            className={`${classes}__til-line`} /> */}
      </div>
    </ReactModal>
  )
})

NoVehicleModal.propTypes = {
  onConfirmed: PropTypes.func,
  onClosed: PropTypes.func
}

const getButton = (id, history, popupUrl, redirectUrl) => {
  let label
  let classname
  let color
  const name = 'refinance'

  const onClick = () => {
    window.open(popupUrl, '_blank')
    history.push(redirectUrl)
  }

  switch (id) {
    case 'connect-leasing-web-btn':
      label = 'tilRefinance.button.tilWeb'
      classname = `${name}__til-web-btn`
      color = 'primary'
      break
    case 'connect-leasing-app-btn':
      label = 'tilRefinance.button.tilApp'
      classname = `${name}__til-app-btn`
      color = 'transparent'
      break
    default:
      break
  }

  return <Button id={id} label={label} color={color} className={classname} onClick={onClick} />
}

export default NoVehicleModal
