import { CONTROLLER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTROLLER.NEXT_STAT:
      return Object.assign({}, action.controllerStats)

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
