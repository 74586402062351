import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Methods
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

// Utils
import { ispPackageFormat, formatNumber, showIspDate } from 'utils/format'

// SCSS
import './index.scss'

// Constants
import { USER_TYPE, activityTypes } from 'config/constant'

// Components
import Button from 'components/elements/Button'
import { RegisButton } from '../ISPRegisButton'

// APIs
import api from 'api/'

// Assests
import logo from 'assets/images/isp-logo.svg'
import { isInWarranty } from 'utils/helper'

const ISPVehicleDetail = ({ ispList, ispDetail, vehicle }) => {
  const history = useHistory()
  const profile = useSelector((state) => state.profile)
  const inWarranty = isInWarranty(vehicle && vehicle)

  const user_type = profile.type === 1 ? 'บุคคลทั่วไป' : profile.type === 2 ? 'นิติบุคคล' : 'ผู้ดูแลรถ'

  return (
    <React.Fragment>
      <div className='isp-vehicle'>
        <div className='isp-vehicle__header'>
          <FormattedMessage id='ispPackage.ispHeader' defaultMessage='ispPackage.ispHeader' />
        </div>
        <div className='isp-vehicle__logo'>
          <img src={logo} alt='' />
        </div>
        <div className='isp-vehicle__detail'>
          <div className='isp-vehicle__detail__img'>
            <img src={ispDetail.vehicle_image} alt='' />
          </div>
          <div className='isp-vehicle__detail__text'>
            {textFormat('vehicleName', ispDetail.vehicle_name || '-')}
            {textFormat('licensePlate', ispDetail.license_plate || 'ยังไม่มี')}

            {packageBox(
              <FormattedMessage id='privilege.warranty' defaultMessage='privilege.warranty' />,
              !ispDetail.is_in_isp && !inWarranty ? (
                <FormattedHTMLMessage id='privilege.outOfWarranty' defaultMessage='privilege.outOfWarranty' />
              ) : ispDetail.is_in_isp ? (
                ispPackageFormat(ispDetail.isp_year, ispDetail.isp_mileage)
              ) : (
                ispPackageFormat(ispDetail.warranty_year, ispDetail.warranty_mileage)
              )
            )}

            {(ispDetail.is_in_isp || inWarranty) &&
              packageBox(
                <FormattedMessage id='privilege.endOfWarranty' defaultMessage='privilege.endOfWarranty' />,
                <div>
                  {ispDetail.is_in_isp
                    ? ispFormat(ispDetail.isp_end_date, ispDetail.isp_mileage)
                    : vehicle && ispFormat(vehicle.warranty.warranty_end, vehicle.warranty.warranty_mileage)}
                  <div className='warranty-reamark'>
                    <FormattedMessage id='privilege.remark' defaultMessage='privilege.remark' />
                  </div>
                </div>
              )}
          </div>
          {regisButton(ispList, ispDetail, profile.type, user_type, history)}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ISPVehicleDetail

const textFormat = (type, value) => {
  return (
    <div>
      <FormattedHTMLMessage
        id={`ispPackage.vehicle.detail.${type}`}
        defaultMessage={`ispPackage.vehicle.detail.${type}`}
        values={{
          value: value
        }}
      />
    </div>
  )
}

const packageBox = (name, value) => {
  return (
    <div className='package__detail'>
      <div className='package__detail__box'>
        <div className='package__detail__box__name'>{name}</div>
        <div className='package__detail__box__value'>{value}</div>
      </div>
    </div>
  )
}

const ispFormat = (date, mileage) => {
  return (
    <FormattedHTMLMessage
      id='privilege.warrantyFormat'
      defaultMessage='privilege.warrantyFormat'
      values={{
        date: showIspDate(date, true),
        mileage: formatNumber(mileage)
      }}
    />
  )
}

const regisButton = (ispList, ispDetail, type, user_type, history) => {
  return (
    <React.Fragment>
      {ispList.total_count > 0 && (
        <div className='isp-vehicle__btn'>
          {(type === USER_TYPE.CORPORATE || (type === USER_TYPE.INDIVIDUAL && !ispDetail.is_corporate_vehicle)) &&
          ispDetail.ISPAvailablePackage !== null ? (
            <div>
              <div>{RegisButton(ispDetail, ispList, user_type, history)}</div>
              <div>
                <Button
                  type='outline-red'
                  label='ispPackage.button.info'
                  onClick={(e) => {
                    e.stopPropagation()
                    history.push({
                      pathname: '/isp-about',
                      state: {
                        ispDetail: ispDetail,
                        ispList: ispList
                      }
                    })
                    api.logActivities({
                      type: activityTypes.ISP,
                      description: `เกี่ยวกับ ISP|${ispDetail.vin ? ispDetail.vin : ''}|${
                        ispDetail.license_plate ? ispDetail.license_plate : ''
                      }|||${user_type}|`
                    })
                  }}
                />
              </div>
            </div>
          ) : (
            <Button type='red' label='ispPackage.button.notValidCond' disabled />
          )}
        </div>
      )}
    </React.Fragment>
  )
}
