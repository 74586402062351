import React, { useEffect, useRef } from 'react'

import { FormattedHTMLMessage } from 'react-intl'

// Redux
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, Form, reduxForm } from 'redux-form'

import classnames from 'classnames'

import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import Button from 'components/elements/Button'
import ConfirmModal from 'components/elements/ConfirmModal'
import TextField from 'components/elements/TextField'

// Actions
import { editProfile, getProfile, lineConnect } from 'store/actions/profile'

// Utils
import { uriRedirect } from 'utils/auth'
import {
  email,
  profileEdit_email,
  profileEdit_email_max100,
  profileEdit_home_max9,
  profileEdit_home_min9,
  profileEdit_home_number,
  profileEdit_mobile,
  profileEdit_mobile_max10,
  profileEdit_mobile_min10,
  profileEdit_mobile_number,
  profileEdit_name,
  profileEdit_nameAlpha,
  profileEdit_office_ext_max5,
  profileEdit_office_ext_number,
  profileEdit_office_max9,
  profileEdit_office_min9,
  profileEdit_office_number,
  profileEdit_surname,
  profileEdit_surnameAlpha,
  profileEdit_username,
  profileEdit_username_alphanumeric,
  profileEdit_username_min6
} from 'utils/validation'

import queryString from 'query-string'
import _isEmpty from 'lodash/isEmpty'
import { USER_TYPE } from 'config/constant'

import './_index.scss'
import { postPageView } from 'store/actions/profile'
import { issueLineSignin } from 'utils/line'

const ProfileEdit = ({ type: userType, handleSubmit, invalid, pristine, type, line_uid, isLoading, search, postPageView, editProfile }) => {
  const modalRef = useRef()

  useEffect(() => {
    if (typeof line_uid !== 'string' && !isLoading && !_isEmpty(queryString.parse(search))) {
      lineConnect({
        ...queryString.parse(search)
      })
    }
    postPageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const lineConnect = /* istanbul ignore next */ () => {
    issueLineSignin({ redirectUri: new URL('/profile/edit', uriRedirect) })
  }

  const _editProfile = () => {
    editProfile(type)
  }

  const classes = classnames('profile-edit')

  return (
    <div className={classes}>
      <ConfirmModal
        mode={2}
        title='myProfile.edit.confirmChange'
        content={'myProfile.edit.description'}
        ref={modalRef}
        onConfirmed={_editProfile}
      />
      <DetailHeader showBack defaultPath='profile' type='profile-edit' />
      <div className='profile-edit-wrapper'>
        <Form onSubmit={handleSubmit(() => modalRef.current.openModal())} className='profile-edit-wrapper__form'>
          {userType !== USER_TYPE.INDIVIDUAL && (
            <div className='profile-edit-wrapper__form__field'>
              <Field
                name='given_name'
                component={TextField}
                type='text'
                label={
                  <FormattedHTMLMessage id='myProfile.edit.requireField.givenName' defaultMessage='myProfile.edit.requireField.givenName' />
                }
                isRequired
                isClearable
                validate={[profileEdit_name, profileEdit_nameAlpha]}
                id='profile_name'
              />
            </div>
          )}
          {userType !== USER_TYPE.INDIVIDUAL && (
            <div className='profile-edit-wrapper__form__field'>
              <Field
                name='surname'
                component={TextField}
                type='text'
                label={
                  <FormattedHTMLMessage id='myProfile.edit.requireField.surname' defaultMessage='myProfile.edit.requireField.surname' />
                }
                isRequired
                isClearable
                validate={[profileEdit_surname, profileEdit_surnameAlpha]}
                id='profile_surname'
              />
            </div>
          )}
          {userType !== USER_TYPE.CONTROLLER && (
            <div className='profile-edit-wrapper__form__field'>
              <Field
                name='username'
                component={TextField}
                type='text'
                label={
                  userType === USER_TYPE.INDIVIDUAL ? (
                    <FormattedHTMLMessage id='common.labels.username' defaultMessage='common.labels.username' />
                  ) : (
                    <FormattedHTMLMessage id='myProfile.edit.requireField.username' defaultMessage='myProfile.edit.requireField.username' />
                  )
                }
                isRequired={userType !== USER_TYPE.INDIVIDUAL}
                isClearable
                validate={
                  userType === USER_TYPE.INDIVIDUAL
                    ? [profileEdit_username_min6, profileEdit_username_alphanumeric]
                    : [profileEdit_username, profileEdit_username_min6, profileEdit_username_alphanumeric]
                }
                id='profile_username'
              />
            </div>
          )}
          {userType === USER_TYPE.INDIVIDUAL && (
            <div className='profile-edit-wrapper__form__field'>
              <Field
                name='home_phone'
                component={TextField}
                type='text'
                label={<FormattedHTMLMessage id='common.labels.homePhone' defaultMessage='common.labels.homePhone' />}
                isClearable
                validate={[profileEdit_home_min9, profileEdit_home_max9, profileEdit_home_number]}
                id='profile_home_phone'
              />
            </div>
          )}
          {userType !== USER_TYPE.INDIVIDUAL && (
            <React.Fragment>
              <div className='profile-edit-wrapper__form__field'>
                <div className='profile-edit-wrapper__form__phone'>
                  <Field
                    label={<FormattedHTMLMessage id='common.labels.officePhone' defaultMessage='common.labels.officePhone' />}
                    name='office_phone'
                    type='text'
                    component={TextField}
                    validate={[profileEdit_office_min9, profileEdit_office_max9, profileEdit_office_number]}
                    isClearable
                  />
                </div>
              </div>
              <div className='profile-edit-wrapper__form__field'>
                <div className='profile-edit-wrapper__form__phone'>
                  <Field
                    label={<FormattedHTMLMessage id='common.labels.officePhoneExt' defaultMessage='common.labels.officePhoneExt' />}
                    name='office_phone_ext'
                    type='text'
                    component={TextField}
                    validate={[profileEdit_office_ext_max5, profileEdit_office_ext_number]}
                    isClearable
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className='profile-edit-wrapper__form__field'>
            <Field
              name='mobile_phone'
              component={TextField}
              type='text'
              label={
                <FormattedHTMLMessage
                  id='myProfile.edit.requireField.mobilePhone'
                  defaultMessage='myProfile.edit.requireField.mobilePhone'
                />
              }
              isRequired
              isClearable
              validate={[profileEdit_mobile, profileEdit_mobile_min10, profileEdit_mobile_max10, profileEdit_mobile_number]}
              id='profile_mobile_phone'
            />
          </div>

          <div className='profile-edit-wrapper__form__field'>
            <Field
              label={
                userType === USER_TYPE.INDIVIDUAL ? (
                  <FormattedHTMLMessage id='common.labels.email' defaultMessage='common.labels.email' />
                ) : (
                  <FormattedHTMLMessage id='myProfile.edit.requireField.email' defaultMessage='myProfile.edit.requireField.email' />
                )
              }
              name='email'
              type='text'
              component={TextField}
              isRequired={userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER}
              validate={
                userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER
                  ? [profileEdit_email, profileEdit_email_max100, email]
                  : [profileEdit_email_max100, email]
              }
              isClearable
            />
          </div>

          <div className='profile-edit-wrapper__form__action'>
            <Button disabled={pristine || invalid} type='submit' color='primary' label='myProfile.edit.updateAccount' />
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  initialValues: {
    given_name: state.profile.given_name || '',
    surname: state.profile.surname || '',
    mobile_phone: state.profile.mobile_phone || '',
    office_phone: state.profile.office_phone || '',
    office_phone_ext: state.profile.office_phone_ext || '',
    home_phone: state.profile.home_phone || '',
    email: state.profile.email || '',
    username: state.profile.username || ''
  },
  type: state.profile.type,
  line_uid: state.profile.line_uid,
  search: state.router.location.search,
  isLoading: state.loader,
  profile: state.profile
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getProfile,
        editProfile,
        lineConnect,
        postPageView
      },
      dispatch
    )

ProfileEdit.propTypes = {
  search: PropTypes.string,
  line_uid: PropTypes.string,
  isLoading: PropTypes.bool,
  lineConnect: PropTypes.func,
  history: PropTypes.object,
  editProfile: PropTypes.func,
  handleSubmit: PropTypes.func,
  type: PropTypes.number,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  profile: PropTypes.object
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'profileEdit',
      enableReinitialize: true
    })(ProfileEdit)
  )
)
