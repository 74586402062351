import React from 'react'
import Lottie from 'react-lottie'

// Proptypes
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'

// Styles
import './_index.scss'

// Configs
import animationData from 'config/lotties/loading.json'

import { isMobileApp } from 'utils/validation'

const Loader = (props) => {
  const loader =
    props.forceLoad || props.loader ? (
      <div className='loader-container synthetic-test___loader synthetic-test___loader--loading'>
        {isMobileApp() ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData
            }}
            height={100}
            width={100}
          />
        ) : (
          <div className='loader'></div>
        )}
      </div>
    ) : (
      <div className='synthetic-test___loader synthetic-test___loader--loaded' />
    )
  return loader
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  loader: state.loader
})

Loader.propTypes = {
  loader: PropTypes.bool
}

export default connect(mapStateToProps)(Loader)
