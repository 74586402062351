import { MASTER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MASTER.GET:
      return Object.assign(
        {},
        {
          ...initialState,
          ...action.masters
        }
      )

    case MASTER.LOADING_SHOW:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: true
        }
      )

    case MASTER.LOADING_HIDE:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: false
        }
      )

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
