import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import Lottie from 'react-lottie'

import VehicleItem from '../components/VehicleItem'

// config
import animationData from 'config/lotties/loading.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
}

const VehicleShowItems = (listData, userType, isList, hasMore, fetchMoreData) => (
  <div
    id='scrollableDiv'
    style={{
      height: '100vh'
    }}
  >
    <InfiniteScroll
      dataLength={listData.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={
        <div className={'predownload'}>
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      }
    >
      {listData.map((data, index) => (
        <VehicleItem
          key={`vehicle-item-${data.id}-${index}`}
          detail={data}
          userType={userType}
          isList={isList}
          isChecked={data.is_checked}
          fromPage='Vehicle List'
        />
      ))}
    </InfiniteScroll>
  </div>
)

export default VehicleShowItems
