import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, withStyles } from '@material-ui/core'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts//DetailHeader'

import Privacy from 'domain/PrivacyPolicy/components/PrivacyPolicy'

// Styles
import './_index.scss'

// Methods
import { FormattedMessage } from 'react-intl'
import { postPageView } from 'store/actions/profile'
import { isMobileApp } from 'utils/validation'
import Menu from 'components/elements/Menu'

const styles = {}

const PrivacyPolicy = ({ postPageView, lang }) => {
  useEffect(() => {
    postPageView()
  }, [])

  const content = (
    <div className='privacy-policy__wrapper'>
      <h4>
        <FormattedMessage id='common.labels.privacypolicy' defaultMessage='common.labels.privacypolicy' />
      </h4>
      <Privacy lang={lang} />
    </div>
  )
  return (
    <div className='privacy-policy'>
      {!isMobileApp() && <DetailHeader showBack={isMobile} defaultPath='profile' type='profile-setting' />}
      {isMobile ? (
        <>{content}</>
      ) : (
        <div className='content-setting'>
          <Grid container className={'container-setting'}>
            {!isMobileApp() && (
              <Grid sm={3}>
                <Menu mode={0} tabActive={'privacyPolicy'} />
              </Grid>
            )}
            <Grid justify={'center'} xs={12} sm={9}>
              {content}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        postPageView
      },
      dispatch
    )
PrivacyPolicy.propTypes = {
  profile: PropTypes.object,
  lang: PropTypes.string
}

const mapStateToProps =
  /* istanbul ignore next */
  (state) => ({
    profile: state.profile,
    lang: state.config.lang
  })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrivacyPolicy))
