import React, { useRef, useState } from 'react'
import ReactTimeout from 'react-timeout'
import { FormattedMessage } from 'react-intl'
// Proptypes
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Components
import FullImage from 'domain/Vehicle/components/FullImage'
import Modal from 'components/elements/Modal'

import { reduxForm } from 'redux-form'
import { isMobile } from 'react-device-detect'
import DetailVehicleFormContent from './DetailVehicleFormContent'

import MenuModal from './Modal'

// Actions
import { activateVehicle, editVehicleDetail, deleteVehicleImage, setCoverImage } from 'store/actions/vehicles'
import { getProfile } from 'store/actions/profile'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'

import { checkError } from 'utils/helper'

// Styles
import _filter from 'lodash/filter'

import { styles } from './css.js'
import './_index.scss'

const DetailVehicleForm = ({
  invalid,
  profile,
  context,
  editVehicleDetail,
  detail,
  history,
  vehicleEdit,
  showLoader,
  hideLoader,
  showAlert,
  activateVehicle,
  deleteVehicleImage,
  handleSubmit,
  setCoverImage: apiSetCoverImage
}) => {
  const modalRef = useRef()

  const [inCorrectFile] = useState(false)
  const [focusImage, setFocusImage] = useState('')
  const [fullImage, setFullImage] = useState(false)
  const [isCover, setIsCover] = useState(false)
  const [userVehicleId, setUserVehicleId] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [coverImage, setCoverImage] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [isOfficial, setIsOfficial] = useState(false)

  const updateVehicle = async () => {
    if (!vehicleEdit.values) {
      return
    }
    try {
      const params = {
        ...vehicleEdit.values
      }

      await editVehicleDetail(detail.id, { ...params })
    } catch (err) {
      checkError(err)
    } finally {
      history.push(`/vehicle/${detail.id}`)
    }
  }

  const submit = () => {
    if (detail.status === 202) {
      activateVehicle(detail.id).then(() => updateVehicle())
    } else {
      updateVehicle()
    }
  }

  const deleteImage = async (id, image_url) => {
    try {
      await deleteVehicleImage(id, { image_url: image_url })

      // Reset the focus image to the cover image
      setFocusImage(_coverImage[0].image_url || (detail && detail.vehicle && detail.vehicle.model.marketing_image_url))
    } catch (err) {
      checkError(err)
    }
  }

  const imageOnFocus = (value, isCover, isOfficial) => {
    setFocusImage(value)
    setIsCover(isCover)
    setIsOfficial(isOfficial)

    if (isMobile) {
      displayFullScreen()
    }
  }

  const displayFullScreen = () => {
    setFullImage(true)
  }

  const closeFullImage = () => {
    setFullImage(false)
  }

  const _setCoverImage = async (id, image_url) => {
    try {
      await apiSetCoverImage(id, { image_url: image_url })
      setConfirm(false)
    } catch (err) {
      checkError(err)
    }
  }

  const openModal = () => modalRef.current.openModal()

  const closeModal = () => {
    closeSecondModal()
    modalRef.current.closeModal()
  }

  const closeSecondModal = () => {
    setConfirm(false)
  }

  const image_list = detail.vehicle.vehicle_images

  const _coverImage = _filter(image_list, (d) => {
    return d.is_cover === true
  })

  const _image =
    focusImage !== ''
      ? focusImage
      : (_coverImage[0] && _coverImage[0].image_url) || (detail && detail.vehicle && detail.vehicle.model.marketing_image_url)

  const image_count = image_list && image_list.length

  const imageDom = (
    <div className='detail-vehicle-form__body__image'>
      <p>
        <FormattedMessage id='vehicle.edit.vehiclePhoto' defaultMessage='vehicle.edit.vehiclePhoto' />
      </p>
      <div className='detail-vehicle-form__body__image__result' onClick={displayFullScreen.bind(this)}>
        <img src={_image} alt='body_image_result' />
      </div>
    </div>
  )

  const modalContent = (
    <MenuModal
      state={{ coverImage, userVehicleId, imageUrl }}
      setState={{ setConfirm: setConfirm }}
      setCoverImage={_setCoverImage}
      imageCount={image_count}
      closeModal={closeModal}
    />
  )

  return (
    <>
      {fullImage === true ? (
        <FullImage
          image={_image}
          isCover={isCover}
          onClose={closeFullImage}
          isDelete={image_count > 1 || detail.is_deleted_official_image}
          isOfficial={isOfficial}
        />
      ) : (
        <DetailVehicleFormContent
          handleSubmit={handleSubmit}
          submit={submit}
          imageDom={imageDom}
          userType={profile.type}
          image_list={image_list}
          image_count={image_count}
          setState={{ setUserVehicleId, setImageUrl, setCoverImage }}
          openModal={openModal}
          invalid={invalid}
          detail={detail}
          imageOnFocus={imageOnFocus}
          inCorrectFile={inCorrectFile}
        />
      )}
      {!confirm === true ? (
        <Modal mode={12} content={modalContent} ref={modalRef} onClosed={() => closeSecondModal()} />
      ) : (
        <Modal
          mode={2}
          className='full-image__confirm'
          title='vehicle.fullImage.modalTitle'
          content='vehicle.fullImage.modalContent'
          ref={modalRef}
          onConfirmed={() => {
            // We disallow uploading additional vehicle images. So to prevent
            // accidental deletion of the cover image, we only allow deletion
            // if there is more than 1 image.
            if (image_count > 1) {
              deleteImage(userVehicleId, imageUrl)
            }
            closeSecondModal()
          }}
          onClosed={() => {
            closeSecondModal()
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = /*istanbul ignore next*/ ({ profile: { ...profile }, form: { vehicleEdit }, vehicleDetail: detail }) => ({
  vehicleEdit,
  detail,
  initialValues: {
    name: detail.name ? detail.name : detail.vehicle && detail.vehicle.model ? detail.vehicle.model.marketing_name : '',
    given_name: (detail.driver && detail.driver.given_name) || '',
    surname: (detail.driver && detail.driver.surname) || '',
    mobile: (detail.driver && detail.driver.mobile) || ''
  },
  profile
})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      activateVehicle,
      editVehicleDetail,
      showAlert,
      deleteVehicleImage,
      setCoverImage,
      getProfile,
      showLoader,
      hideLoader
    },
    dispatch
  )

DetailVehicleForm.propTypes = {
  activateVehicle: PropTypes.func,
  editVehicleDetail: PropTypes.func,
  showAlert: PropTypes.func,
  detail: PropTypes.object,
  history: PropTypes.object,
  invalid: PropTypes.bool,
  vehicleEdit: PropTypes.object,
  handleSubmit: PropTypes.func,
  deleteVehicleImage: PropTypes.func,
  setCoverImage: PropTypes.func
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'vehicleEdit'
    })(withStyles(styles)(ReactTimeout(DetailVehicleForm)))
  )
)
