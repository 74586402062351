import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

// Component
import { Form, Field } from 'redux-form'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import Checkbox from 'components/elements/Checkbox'
import Select from 'components/elements/Select'

// Method
import {
  contEdit_name,
  contEdit_nameAlpha,
  contEdit_surname,
  contEdit_surnameAlpha,
  contEdit_mobile,
  contEdit_mobile_min10,
  contEdit_mobile_max10,
  contEdit_mobile_number
} from 'utils/validation'

const TILRefinanceForm = ({
  handleSubmit,
  startTimeOptions,
  startTime,
  setStartTime,
  endTimeOptions,
  endTime,
  setEndTime,
  termAccepted,
  toggleCheckbox,
  submitted,
  isError
}) => {
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} className='til-refinance__body__form'>
        <div className='til-refinance__body__title'>
          <FormattedHTMLMessage id='tilRefinance.requestTitle' defaultMessage='tilRefinance.requestTitle' />
        </div>
        <Field
          name='name'
          component={Input}
          type='text'
          label={'common.labels.name'}
          isRequired
          isClearable
          placeholder={'common.placeholders.inputName'}
          validate={[contEdit_name, contEdit_nameAlpha]}
          id='firstname-input'
        />
        <Field
          name='surname'
          component={Input}
          type='text'
          label={'common.labels.surname'}
          isRequired
          isClearable
          placeholder={'common.placeholders.inputSurname'}
          validate={[contEdit_surname, contEdit_surnameAlpha]}
          id='lastname-input'
        />
        <Field
          name='mobilePhone'
          component={Input}
          type='text'
          label={'common.labels.mobilePhone'}
          isRequired
          isClearable
          placeholder={'common.placeholders.inputMobilePhone'}
          validate={[contEdit_mobile, contEdit_mobile_min10, contEdit_mobile_max10, contEdit_mobile_number]}
          id='phone-number-input'
        />
        <div className='til-refinance__body__form__require-available-time'>
          <FormattedHTMLMessage id='tilRefinance.requireAvailableTime' defaultMessage='tilRefinance.requireAvailableTime' />
        </div>
        <div className='til-refinance__body__form__dropdown'>
          <Select
            options={startTimeOptions}
            onChange={setStartTime}
            value={String(startTime)}
            placeholder={'common.placeholders.selectStartTime'}
            noMapping
            id='time-start-dropdown'
          />
          <Select
            options={endTimeOptions}
            onChange={setEndTime}
            value={String(endTime)}
            placeholder={'common.placeholders.selectEndTime'}
            isDisabled={!startTime}
            noMapping
            id='time-end-dropdown'
          />
        </div>
        <div className='til-refinance__body__form__consent'>
          <div className='til-refinance__body__form__check-consent'>
            <Checkbox checked={termAccepted} onClick={toggleCheckbox} id='consent-checkbox' />
          </div>
          <div className='til-refinance__body__form__consent-message'>
            <FormattedHTMLMessage id='tilRefinance.consent' defaultMessage='tilRefinance.consent' />
          </div>
        </div>
        <div className='til-refinance__body__form__action'>
          <Button
            id='send-refinance-btn'
            type='submit'
            disabled={!!isError || !startTime || !endTime || startTime.value >= endTime.value || !termAccepted || submitted}
            color='primary'
            label='tilRefinance.sendRequest'
          />
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TILRefinanceForm
