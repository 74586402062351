import React from 'react'

// Components
import Button from 'components/elements/Button'

export const Information = (setTooltip) => {
  return (
    <div>
      <p className='info-detail'>
        รายการสรุปค่าใช้จ่ายนี้ มีวัตถุประสงค์เพื่อ แสดงข้อมูลค่าใช้จ่ายจาก การบำรุงรักษาตามระยะ (Maintenance) และการซ่อมทั่วไป (General
        Service) เท่านั้น โดยจะไม่แสดงรายละเอียด ในส่วนการเคลม (ไม่มีค่าใช้จ่าย) งานซ่อมสีและตัวถัง อุปกรณ์ตกแต่ง งานประดับยนต์ งานตรอ.
        ทั้งนี้ รายการสรุปค่าใช้จ่ายนี้ไม่ใช่ใบเสร็จรับเงิน โดยลูกค้าสามารถศึกษารายละเอียดได้จากใบสรุป ยอดเงิน
        และใบเสร็จรับเงินที่ได้รับจากศูนย์บริการฯ
      </p>
      <Button iconOnly type='button-close' icon='clear' className='close' onClick={() => setTooltip} />
    </div>
  )
}
