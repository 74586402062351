import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { openMyISUZUApp } from 'utils/helper'

const AppLanding = () => {
  const profile = useSelector((state) => state.profile)

  useEffect(() => {
    if (profile.loadProfileComplete) {
      openMyISUZUApp(profile.line_uid ? `/signin/line/${profile.line_uid}` : '')
    } else {
      openMyISUZUApp()
    }
  }, [profile])
  return <div></div>
}

export default AppLanding
