import React from 'react'

// Redux
import { useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Methods
import * as format from 'utils/format'
import _find from 'lodash/find'

// Actions
import { logUserInteractionAtHome } from 'store/actions/promotions'

// Styles
import './_index.scss'

const PromotionItem = ({ detail, isPlaceholder, isList, isSlider, staticContext, className, isCreate, userType, ...otherProps }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const classes = classnames(
    'promotion-item',
    { 'promotion-item--placeholder': isPlaceholder },
    { 'promotion-item--is-list': isList },
    { 'promotion-item--slider': isSlider },
    className
  )

  const thumbnail = _find(detail.media, ['is_primary', true])

  const list = isPlaceholder ? (
    <div className={classes} {...otherProps}>
      <div className='promotion-item__img'></div>
      <div className='promotion-item__period'></div>
      <div className='promotion-item__name'></div>
    </div>
  ) : (
    <div
      className={classes}
      {...otherProps}
      onClick={(e) => {
        e.stopPropagation()
        history.push(`/promotion/${detail.id}`)
        if (isSlider) dispatch(logUserInteractionAtHome(detail.name))
      }}
    >
      <div className='promotion-item__list'>
        <div className='promotion-item__list__img'>
          <img src={thumbnail ? thumbnail.url : ''} alt={detail.name} />
        </div>
        <div className='promotion-item__list__desc'>
          <h6>
            {format.showDate(detail.start_date, false)} - {format.showDate(detail.end_date, false)}
          </h6>
          <p>{detail.name}</p>
        </div>
      </div>
    </div>
  )

  return list
}

PromotionItem.propTypes = {
  className: PropTypes.string,
  isSlider: PropTypes.bool,
  isPlaceholder: PropTypes.bool
}

PromotionItem.defaultProps = {
  className: '',
  detail: {},
  isSlider: false,
  isPlaceholder: false
}

export default PromotionItem
