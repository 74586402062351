import React from 'react'
import { FormattedMessage } from 'react-intl'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Method
import { toggleCheck } from 'store/actions/vehicles'
import * as format from 'utils/format'
import * as helper from 'utils/helper'
import api from 'api/'

// Components
import ButtonFav from 'components/elements/ButtonFav'
import './_index.scss'

// Constants
import { activityTypes, USER_TYPE, USER_TYPE_RESOLVER } from 'config/constant'

import { isEmpty } from 'lodash'

const VehicleItem = ({
  detail,
  history,
  fromPage,
  isList,
  isPlaceholder,
  isSlider,
  master,
  isNew,
  userType,
  withCheck,
  isRemoveVehicle,
  onCheckClick,
  toggleCheck,
  staticContext,
  isBannerTimeline,
  ...otherProps
}) => {
  const checkboxHandler = () => {
    if (onCheckClick) {
      onCheckClick(detail.id)
      return
    }

    toggleCheck(detail.id)
  }

  const handleItemClick = (event) => {
    const { vehicle } = detail

    if (isRemoveVehicle) {
      return
    }

    event.stopPropagation()

    history.push({
      pathname: `/vehicle/${detail.id}`,
      state: {
        fromPage: fromPage
      }
    })
    api.logActivities({
      type: activityTypes.VEHICLE,
      description: `Click to see details of vehicles ${vehicle.vin} (${vehicle.license_plate}) from ${fromPage}`
    })
  }
  const { vehicle = {}, controllers = [], driver = {} } = detail

  const classes = classnames(
    'vehicle-item',
    { 'vehicle-item--is-placeholder': isPlaceholder },
    { 'vehicle-item--is-corporate': userType === USER_TYPE.CORPORATE },
    { 'vehicle-item--is-new': isNew },
    { 'vehicle-item--list': isList },
    { 'vehicle-item--card': isSlider }
  )

  const ControllerNameList = () => {
    if (isEmpty(controllers)) {
      return <FormattedMessage id='vehicle.list.unassigned' defaultMessage='vehicle.list.unassigned' />
    }

    return <span className='vehicle-item__wrapper__assignment__name'>{format.joinList(controllers, 'full_name')}</span>
  }

  const Badges = () => (
    <React.Fragment>
      {vehicle.vehicle_type === 'LCV' && (
        <div className='vehicle-item__wrapper__data__desc__badges'>
          {vehicle.has_isi_package && (
            <img className='vehicle-item__wrapper__data__desc__badges__isi' src={master && helper.getISILogo(2)} alt='' />
          )}
          {!isEmpty(vehicle.excellency) && vehicle.excellency.current_status !== 'Old' && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__excellency'
              alt=''
              src={
                master &&
                helper.getAssets(
                  1,
                  1,
                  helper.getExcellencyStatus(
                    vehicle.excellency && vehicle.excellency.current_status ? vehicle.excellency.current_status : undefined
                  ),
                  'vehicle_list'
                )
              }
            />
          )}

          {!helper.isISP(vehicle) && helper.isInWarranty(vehicle) && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__isp'
              src={master && helper.getAssets(2, 1, helper.getISPStatus(undefined), 'vehicle_list')}
              alt=''
            />
          )}

          {helper.isISP(vehicle) && !helper.ISPExpired(vehicle) && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__isp'
              src={
                master &&
                helper.getAssets(
                  2,
                  3,
                  helper.getISPStatus(
                    vehicle.isp_information && vehicle.isp_information.isp_package ? vehicle.isp_information.isp_package_code : undefined
                  ),
                  'vehicle_list'
                )
              }
              alt=''
            />
          )}
          {vehicle.icare.in_icare === true && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__isuzu_care'
              src={helper.getAssets(11, 1, 'icare_badge', 'vehicle_detail')}
              alt=''
            />
          )}
        </div>
      )}

      {vehicle.vehicle_type === 'CV' && (
        <div className='vehicle-item__wrapper__data__desc__badges'>
          {vehicle.warranty_badge && !isEmpty(vehicle.warranty_badge) && (
            <img className='vehicle-item__wrapper__data__desc__badges__isuzu_care' src={vehicle.warranty_badge.warranty_badge_url} alt='' />
          )}
          {vehicle.icare.in_icare === true && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__isuzu_care'
              src={helper.getAssets(11, 1, 'icare_badge', 'vehicle_detail')}
              alt=''
            />
          )}

          {!isEmpty(vehicle.excellency) && vehicle.excellency.current_status !== 'Old' && (
            <img
              className='vehicle-item__wrapper__data__desc__badges__excellency'
              src={
                master &&
                helper.getAssets(
                  1,
                  1,
                  helper.getExcellencyStatus(
                    vehicle.excellency && vehicle.excellency.current_status ? vehicle.excellency.current_status : undefined
                  ),
                  'vehicle_list'
                )
              }
              alt=''
            />
          )}
        </div>
      )}
    </React.Fragment>
  )

  const ListView = () => (
    <div
      className={'vehicle-item__wrapper--list ' + USER_TYPE_RESOLVER[userType].toLowerCase()}
      id='item-wrapper-list'
      onClick={handleItemClick}
    >
      <div className={'vehicle-item__data name'}>
        <h3 className='vehicle-item__wrapper__data__name'>{detail.name || vehicle.model.marketing_name}</h3>
        <h3 className='vehicle-item__wrapper__data__plate'>{vehicle.license_plate}</h3>
        {!isNew && <Badges />}
      </div>
      {userType === USER_TYPE.CORPORATE && (
        <div className='vehicle-item__data assignment-column'>
          <div className='vehicle-item__wrapper__data__assignment'>
            <ControllerNameList />
          </div>
        </div>
      )}
      {(userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) && (
        <div className='vehicle-item__data last-maintenance-date'>
          <div className='vehicle-item__wrapper__data__last-maintenance-date'>{format.showDate(vehicle.last_maintenance_service_date)}</div>
        </div>
      )}
      <div className='vehicle-item__data next-main'>
        <div className='vehicle-item__wrapper__data__next-main'>{format.showDate(vehicle.next_maintenance_date)}</div>
      </div>
      <div className='vehicle-item__data age'>
        <div className='vehicle-item__wrapper__data__age'>{format.showAge(vehicle.delivery_date)}</div>
      </div>
      {(userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) && (
        <React.Fragment>
          <div className='vehicle-item__data type'>
            <div className='vehicle-item__wrapper__data__vehicle-type'>{vehicle.vehicle_model || '-'}</div>
          </div>
          <div className='vehicle-item__data driver-name'>
            <div className='vehicle-item__wrapper__data__driver-name'>{format.fullName(driver.given_name, driver.surname)}</div>
          </div>
        </React.Fragment>
      )}
      <div className='vehicle-item__data fav'>
        <ButtonFav type='vehicle' id={detail.id} isFavorite={detail.is_favorite}></ButtonFav>
      </div>
    </div>
  )

  const GridView = () => (
    <div className='vehicle-item__wrapper' onClick={handleItemClick}>
      <div className='vehicle-item__wrapper__data'>
        <div className='vehicle-item__wrapper__data__desc'>
          <h3 className='vehicle-item__wrapper__data__desc__name'>{detail.name || vehicle.model.marketing_name}</h3>
          <h3 className='vehicle-item__wrapper__data__desc__plate'>{vehicle.license_plate}</h3>
          {isNew && <h3 className='vehicle-item__wrapper__data__desc__vin'>{vehicle.vin}</h3>}

          {!isNew && <Badges />}

          {!isNew && (
            <React.Fragment>
              <div className='vehicle-item__wrapper__data__desc__label-group vehicle-item__wrapper__data__desc__label-group__next-maintenance'>
                <div className='vehicle-item__wrapper__data__desc__label-group__label'>
                  {(detail.vehicle.next_maintenance_date || detail.vehicle.next_maintenance_mileage) && (
                    <FormattedMessage id='vehicle.list.nextMaintenance' defaultMessage='vehicle.list.nextMaintenance' />
                  )}
                  {!detail.vehicle.next_maintenance_date && !detail.vehicle.next_maintenance_mileage && (
                    <span className='maintenance__label__hide' />
                  )}
                </div>
                <div className='vehicle-item__wrapper__data__desc__label-group__detail'>
                  {detail.vehicle.next_maintenance_date && format.showDate(detail.vehicle.next_maintenance_date)}
                  {detail.vehicle.next_maintenance_mileage && detail.vehicle.next_maintenance_date && <span> / </span>}
                  {detail.vehicle.next_maintenance_mileage && (
                    <FormattedMessage
                      id='vehicle.list.nextMileage'
                      values={{ value: format.showNumber(detail.vehicle.next_maintenance_mileage) }}
                      defaultMessage='vehicle.list.nextMileage'
                    />
                  )}
                  {((vehicle.is_overdue && vehicle.is_overdue.date) || (vehicle.is_overdue && vehicle.is_overdue.mileage)) && (
                    <span className='overdue'>
                      <FormattedMessage id='vehicle.list.overdue' defaultMessage='vehicle.list.overdue' />
                    </span>
                  )}
                </div>
              </div>

              {(userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) && (
                <React.Fragment>
                  <div className='vehicle-item__wrapper__data__desc__label-group last-maintenance-date'>
                    <div className='vehicle-item__wrapper__data__desc__label-group__label'>
                      <FormattedMessage id='vehicle.list.lastMaintenanceDate' defaultMessage='vehicle.list.lastMaintenanceDate' />
                    </div>
                    <div className='vehicle-item__wrapper__data__desc__label-group__detail'>
                      {format.showDate(vehicle.last_maintenance_service_date)}
                    </div>
                  </div>
                  <div className='vehicle-item__wrapper__data__desc__label-group type'>
                    <div className='vehicle-item__wrapper__data__desc__label-group__label'>
                      <FormattedMessage id='vehicle.list.vehicleType' defaultMessage='vehicle.list.vehicleType' />
                    </div>
                    <div className='vehicle-item__wrapper__data__desc__label-group__detail'>{vehicle.vehicle_model || '-'}</div>
                  </div>
                </React.Fragment>
              )}

              {userType === USER_TYPE.CORPORATE && (
                <div className='vehicle-item__wrapper__data__desc__label-group controller-name'>
                  <div className='vehicle-item__wrapper__data__desc__label-group__label'>
                    <FormattedMessage id='vehicle.list.controllerName' defaultMessage='vehicle.list.controllerName' />
                  </div>
                  <div className='vehicle-item__wrapper__data__desc__label-group__detail'>
                    <div className='vehicle-item__wrapper__assignment'>
                      <div className='assignment'>
                        <ControllerNameList />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}

          {isNew && (
            <div className='vehicle-item__wrapper__data__desc__label-group'>
              <div className='vehicle-item__wrapper__data__desc__label-group__label'>
                <FormattedMessage id='vehicle.firstLogin.datePurchase' defaultMessage='vehicle.firstLogin.datePurchase' />
              </div>
              <div className='vehicle-item__wrapper__data__desc__label-group__detail'>
                {format.showDate(detail.vehicle.next_maintenance_date)}
              </div>
            </div>
          )}
        </div>

        <div className='vehicle-item__wrapper__data__info'>
          {isNew && <div className='vehicle-item__wrapper__data__info__age'>{vehicle.model.marketing_name}</div>}

          {!isNew && (
            <div className='vehicle-item__wrapper__data__info__age'>
              {format.showAge(vehicle.delivery_date, true)}
              <i className='material-icons'>calendar_today</i>
            </div>
          )}
          <div className='vehicle-item__wrapper__data__info__image'>
            <img src={detail.image_url || vehicle.model.marketing_image_url} alt={detail.name || vehicle.model.marketing_name} />
          </div>
          {!isNew && <ButtonFav type='vehicle' id={detail.id} isFavorite={detail.is_favorite}></ButtonFav>}
        </div>
      </div>
    </div>
  )

  const viewItem = isPlaceholder ? (
    <div className={classes} {...otherProps}>
      <div className='vehicle-item__wrapper'>
        <div className='vehicle-item__wrapper__data'>
          <div className='vehicle-item__wrapper__data__desc'>
            <div className='vehicle-item__wrapper__data__desc__name'></div>
            <div className='vehicle-item__wrapper__data__desc__plate'></div>
            <div className='vehicle-item__wrapper__data__label-group'>
              <div className='vehicle-item__wrapper__data__label-group__label'></div>
              <div className='vehicle-item__wrapper__data__label-group'></div>
            </div>
          </div>
          <div className='vehicle-item__wrapper__data__info'>
            <div className='vehicle-item__wrapper__data__info__age'></div>
            <div className='vehicle-item__wrapper__data__info__image'></div>
          </div>
        </div>
        {userType === USER_TYPE.CORPORATE && <div className='vehicle-item__wrapper__assignment'></div>}
      </div>
    </div>
  ) : (
    <div className={classes} {...otherProps}>
      <input
        className='vehicle-item__check'
        type='checkbox'
        id={detail.id}
        value={detail.id}
        onChange={checkboxHandler}
        checked={!!detail.is_checked}
      />

      {((userType === USER_TYPE.CORPORATE && !isSlider) || isNew) && withCheck && (
        <label
          className='vehicle-item__label'
          htmlFor={detail.id}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <i className='material-icons'>check</i>
        </label>
      )}
      {isList ? <ListView /> : <GridView />}
    </div>
  )

  return viewItem
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  master: state.master
})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      toggleCheck
    },
    dispatch
  )

VehicleItem.propTypes = {
  isSlider: PropTypes.bool,
  isNew: PropTypes.bool,
  isRemoveVehicle: PropTypes.bool,
  withCheck: PropTypes.bool,
  onCheckClick: PropTypes.func,
  fromPage: PropTypes.string
}

VehicleItem.defaultProps = {
  userType: 1,
  withCheck: true,
  isSlider: false,
  isPlaceholder: false,
  isRemoveVehicle: false,
  detail: {
    controller: {},
    id: '',
    image_url: null,
    is_favorite: false,
    name: null,
    status: null,
    is_checked: false,
    vehicle: {
      delivery_date: null,
      vehicle_model: null,
      license_plate: null,
      engine_no: null,
      chassis_no: null,
      vin: null,
      model: {
        marketing_name: null
      },
      last_maintenance_service_date: null,
      last_maintenance_service_mileage: null,
      excellency: {
        current_status: null,
        next_status: null,
        effective_date: null,
        expiry_date: null,
        remaining_service: null,
        last_result_of_maintenance: null
      },
      has_isi_package: false,
      next_maintenance_mileage: null
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleItem))
