import { PROFILE, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  total_count: 0,
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE.GET_SC:
      return Object.assign({}, state, {
        ...state,
        total_count: action.total_count,
        data: action.data
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
