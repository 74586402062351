import React from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Component
import ReactPlayer from 'react-player'

// Styles
import './_index.scss'

const FeaturedItem = (props) => {
  const classes = classnames('featured-item')

  const renderItem = props.detail.content_type.includes('video') ? (
    <ReactPlayer url={props.detail.url} width='100%' height='auto' controls={true} />
  ) : (
    <img className={classes} src={props.detail.url} alt='' />
  )

  return renderItem
}

FeaturedItem.propTypes = {
  detail: PropTypes.object
}

FeaturedItem.defaultProps = {
  detail: {}
}

export default FeaturedItem
