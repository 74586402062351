export const PROTECTION = {
  title: 'ความคุ้มครอง',
  detail:
    'คุ้มครองครอบคลุม ค่าอะไหล่และค่าแรงที่เกิดจากความเสียหาย<br> ของชิ้นส่วน โดยไม่มีค่าใช้จ่าย พร้อมดูแลรถคุณ<br>ทุกศูนย์บริการทั่วประเทศ',
  icons: [
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/car.svg',
      label: 'เครื่องยนต์<br>ระบบควบคุมเครื่องยนต์'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/gear.svg',
      label: 'ระบบเกียร์<br>ระบบเพลาขับเคลื่อน'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/gas-pump.svg',
      label: 'ระบบจ่ายน้ำมัน<br>เชื้อเพลิง'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/flash_on.svg',
      label: 'ระบบไฟฟ้า'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/wheel-steel.svg',
      label: 'ช่วงล่าง<br>ระบบบังคับเลี้ยว'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/settings.svg',
      label: 'ชุดเฟืองท้าย'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/AC.svg',
      label: 'ระบบปรับอากาศ'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/brake.svg',
      label: 'ระบบเบรก'
    },
    {
      image: 'https://cdndev.my.isuzu-tis.com/assets/contents/ISP/icons/key.svg',
      label: 'อุปกรณ์เสริม เช่น<br>กุญแจอัจฉริยะ<br>ระบบเซนเซอร์'
    }
  ]
}

export const FAQ = {
  header: 'คำถามที่พบบ่อย',
  items: [
    {
      title: 'รถยนต์คันใดที่สามารถซื้อแพ็กเกจ ISP ได้บ้าง?',
      detail: [
        {
          lists: [
            'รถอีซูซุดีแมคซ์ และอีซูซุมิว-เอ็กซ์ ซึ่งยังอยู่ในช่วงรับประกันรถใหม่ 3 ปี หรือ 100,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) หรืออยู่ในช่วงรับประกันตามแพ็กเกจในโปรแกรมอีซูซุสมาร์ทโพรเทคชั่นที่ได้สมัครไว้ ได้แก่ แพ็กเกจ 5 ปี หรือ 100,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) หรือ แพ็กเกจ 5 ปี หรือ 160,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน)'
          ]
        }
      ]
    },
    {
      title: 'หากสนใจแพ็กเกจ ISP สามารถติดต่อซื้อแพ็กเกจอย่างไร?',
      detail: [
        {
          subTitle: 'ผู้ใช้งานสามารถติดต่อศูนย์บริการเพื่อแสดงความสนใจแพ็กเกจ ISP โดย',
          listNumber: [
            '1. เข้าสู่เว็บแอปพลิเคชัน my-ISUZU',
            '2. ไปที่เมนู สิทธิพิเศษ',
            '3. เลือกปุ่ม ISUZU SMART PROTECTION',
            '4. หากผู้ใช้งานมีรถยนต์หลายคัน ให้เลือกรถยนต์ที่สนใจแพ็กเกจ ISP',
            '5. เลือกปุ่ม คลิกเพื่อสมัคร',
            '6. เลือกแพ็กเกจที่ต้องการสมัคร',
            '7. เลือกปุ่ม ยืนยันการสมัคร',
            '8. ระบบจะแสดงหน้าจอข้อตกลงและเงื่อนไขโปรแกรมอีซูซุสมาร์ทโพรเทคชั่น หลังจากอ่านเงื่อนไขแล้วให้ติ๊กที่กล่องหน้าข้อความ "ข้าพเจ้าได้อ่าน รับทราบและเข้าใจข้อตกลงและเงื่อนไขข้างต้นทุกประการ" จากนั้นกดปุ่ม ยืนยัน',
            '9. ระบบจะแสดงข้อความรายละเอียดแพ็กเกจและราคาที่สมัคร ผู้ใช้งานสามารถเข้าไปชำระค่าบริการและตรวจสอบรถยนต์ที่ทำการสมัครได้ที่ศูนย์บริการมาตรฐานอีซูซุทั่วประเทศ'
          ]
        }
      ]
    }
  ]
}

export const TERM_CONDITION = {
  header: 'ข้อกำหนดและเงื่อนไข',
  items: [
    {
      title: 'เงื่อนไขในการรักษาสิทธิการรับประกันตามโปรแกรมอีซูซุสมาร์ทโพรเทคชั่น',
      detail: [
        {
          lists: [
            'จะต้องนำรถเข้าเช็กระยะตามกำหนดทุกๆ 6 เดือน หรือ 10,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) ที่ศูนย์บริการมาตรฐานอีซูซุอย่างต่อเนื่อง และตรงตามเงื่อนไขที่บริษัทฯ กำหนด'
          ]
        }
      ]
    },
    {
      title: 'รถยนต์ที่สามารถเข้ารับบริการโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
      detail: [
        {
          lists: [
            'จะต้องนำรถเข้าเช็กระยะตามกำหนดทุกๆ 6 เดือน หรือ 10,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) ที่ศูนย์บริการมาตรฐานอีซูซุอย่างต่อเนื่อง และตรงตามเงื่อนไขที่บริษัทฯ กำหนด',
            'จะต้องเข้ารับบริการตรวจเช็กระยะจากศูนย์บริการมาตรฐานอีซูซุที่มีรายชื่ออยู่ในคู่มือการใช้ และการบำรุงรักษารถยนต์อีซูซุ เมื่อถึงกำหนดระยะบริการและบำรุงรักษารถยนต์ที่บริษัทฯ ได้ระบุไว้ในคู่มือดังกล่าว ตลอดระยะเวลาของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
            'จะต้องเป็นรถที่จำหน่ายโดยผู้จำหน่ายอีซูซุและต้องเป็นรถยนต์ที่ใช้ในประเทศไทยเท่านั้น',
            'จะต้องเป็นรถที่อยู่ในการรับประกันคุณภาพรถใหม่ (3 ปี หรือ 100,000 กม. แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) หรืออยู่ในช่วงรับประกันตามแพ็กเกจในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นที่ได้สมัครไว้ ได้แก่ แพ็กเกจ 5 ปี หรือ 100,000 กม.(แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) หรือ แพ็กเกจ 5 ปี หรือ 160,000 กม.(แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน)'
          ]
        }
      ]
    },
    {
      title: 'ตัวอย่างรายการอะไหล่ที่คุ้มครอง',
      table: {
        columnName: ['การรับประกันปกติ', 'อีซูซุสมาร์ทโพรเทคชั่น', 'รายการอะไหล่'],
        records: [
          { row: ['✓', '✓', 'ฝาครอบวาล์ว'] },
          { row: ['✓', '✓', 'ฝาสูบ'] },
          { row: ['✓', '✓', 'เสื้อสูบ'] },
          { row: ['✓', '✓', 'อ่างเครื่อง'] },
          { row: ['✓', '✓', 'เพลาลูกเบี้ยว เสื้อเพลาลูกเบี้ยว และกระเดื่องกดวาล์ว'] },
          { row: ['✓', '✓', 'วาล์วไอดี วาล์วไอเสีย สปริงกดวาล์ว และซีลปลอกวาล์ว'] },
          { row: ['✓', '✓', 'เพลาข้อเหวี่ยง และแบริ่ง (ชาร์ฟอก)'] },
          { row: ['✓', '✓', 'ฟลายวีล'] },
          { row: ['✓', '✓', 'ลูกสูบ แหวนลูกสูบ สลักก้านสูบ'] },
          { row: ['✓', '✓', 'ก้านสูบ และแบริ่ง (ชาร์ฟก้าน)'] },
          { row: ['✓', '✓', 'โซ่เฟืองไทมิ่ง และชุดกลไกปรับตั้งความตึงโซ่'] },
          { row: ['✓', '✓', 'ชุดวาล์วปีกผีเสื้อ'] },
          { row: ['✓', '✓', 'เทอร์โบชาร์จเจอร์'] },
          { row: ['✓', '✓', 'ปั๊มน้ำมันเชื้อเพลิง'] },
          { row: ['✓', '✓', 'เซนเซอร์ FRP'] },
          { row: ['✓', '✓', 'ชุดระบายความร้อนน้ำมันเครื่อง'] },
          { row: ['✓', '✓', 'วาล์ว EGR'] },
          { row: ['✓', '✓', 'เจเนอเรเตอร์'] },
          { row: ['✓', '✓', 'กล่องควบคุมระบบเครื่องยนต์ ECU'] },
          { row: ['✓', '✓', 'เซนเซอร์ MAP'] },
          { row: ['✓', '✓', 'เซนเซอร์ MAT'] },
          { row: ['✓', '✓', 'เซนเซอร์ MAF'] },
          { row: ['✓', '✓', 'เซนเซอร์ CKP'] },
          { row: ['✓', '✓', 'เซนเซอร์ CMP'] },
          { row: ['✓', '✓', 'เซนเซอร์ TPS'] },
          { row: ['✓', '✓', 'เซนเซอร์ FRP'] },
          { row: ['✓', '✓', 'เซนเซอร์ GPT'] },
          { row: ['✓', '✓', 'เซนเซอร์ แบตเตอรี่'] },
          { row: ['✓', '✓', 'ชุดขาคันเร่ง'] },
          { row: ['✓', '✓', 'หม้อน้ำ'] },
          { row: ['✓', '✓', 'ถังน้ำมันเชื้อเพลิง'] },
          { row: ['✓', '✓', 'ชุดลูกลอยน้ำมันเชื้อเพลิง'] },
          { row: ['✓', '✓', 'ชุดขาคลัตช์'] },
          { row: ['✓', '✓', 'ปั๊มคลัตช์ตัวล่าง'] },
          { row: ['✓', '✓', 'ปั๊มคลัตช์ตัวบน'] },
          { row: ['✓', '✓', 'เสื้อ เฟือง เพลา และลูกปืนเกียร์และทราสเฟอร์'] },
          { row: ['✓', '✓', 'สวิตช์เกียร์ว่าง'] },
          { row: ['✓', '✓', 'สวิตช์เกียร์ถอย'] },
          { row: ['✓', '✓', 'ชุดกลไกควบคุมการเปลี่ยนเกียร์'] },
          { row: ['✓', '✓', 'ทอร์กคอนเวอร์เตอร์'] },
          { row: ['✓', '✓', 'อ่างน้ำมันเกียร์ และชุดควบคุมเกียร์ออโตเมติก'] },
          { row: ['✓', '✓', 'คาลิเปอร์ดิสก์เบรก'] },
          { row: ['✓', '✓', 'กระบอกเบรก'] },
          { row: ['✓', '✓', 'ชุดขาเบรก'] },
          { row: ['✓', '✓', 'สวิตช์ไฟเบรก'] },
          { row: ['✓', '✓', 'แม่ปั๊มเบรก และกระปุกน้ำมันเบรก'] },
          { row: ['✓', '✓', 'หม้อลมเบรก'] },
          { row: ['✓', '✓', 'ปั๊มไฮดรอลิกสำหรับระบบเบรก ABS'] },
          { row: ['✓', '✓', 'เซนเซอร์ ABS'] },
          { row: ['✓', '✓', 'เหล็กกันโคลง'] },
          { row: ['✓', '✓', 'แขนข้อต่อระบบช่วงล่าง'] },
          { row: ['✓', '✓', 'เพลาขับ และเสื้อเพลา'] },
          { row: ['✓', '✓', 'เสื้อเฟืองท้าย ชุดเฟืองบายศรี-เดือยหมู และลูกปืนเฟืองท้าย'] },
          { row: ['✓', '✓', 'ปีกนกบน และปีกนกล่าง'] },
          { row: ['✓', '✓', 'ชุดแกนพวงมาลัย'] },
          { row: ['✓', '✓', 'ปั๊มน้ำมันพวงมาลัยเพาเวอร์'] },
          { row: ['✓', '✓', 'ชุดเฟืองยกกระจกประตู และมอเตอร์'] },
          { row: ['✓', '✓', 'ชุดเซนทรัลล็อก และสายเคเบิ้ล'] },
          { row: ['✓', '✓', 'สวิตช์ปรับโหมด 4WD'] },
          { row: ['✓', '✓', 'สวิตช์คอพวงมาลัย'] },
          { row: ['✓', '✓', 'กล่องควบคุมเกียร์ออโตเมติก'] },
          { row: ['✓', '✓', 'กล่องควบคุมเกียร์ทรานส์เฟอร์'] },
          { row: ['✓', '✓', 'กล่องควบคุม BCM'] },
          { row: ['✓', '✓', 'กล่องควบคุมเซนทรัลล็อก'] },
          { row: ['✓', '✓', 'เซนเซอร์ความเร็วรถ'] },
          { row: ['✓', '✓', 'เซนเซอร์ AIR BAG'] },
          { row: ['✓', '✓', 'ชุดถุงลมนิรภัย'] },
          { row: ['✓', '✓', 'กล่องควบคุมชุดถุงลมนิรภัย'] },
          { row: ['✓', '✓', 'กล่องควบคุม KEYLESS ENTRY'] },
          { row: ['✓', '✓', 'ตัวรับสัญญาณ KEYLESS ENTRY'] },
          { row: ['✓', '✓', 'ชุดควบคุมระบบกันขโมย'] },
          { row: ['✓', '✓', 'ชุดควบคุมระบบกันขโมย'] },
          { row: ['✓', '✓', 'คอยล์เย็น'] },
          { row: ['✓', '✓', 'เทอร์โมสตัท'] },
          { row: ['✓', '✓', 'คอมเพรสเซอร์แอร์'] },
          { row: ['✓', '✓', 'มอเตอร์พัดลมแอร์'] },
          { row: ['✓', '✓', 'ชุดสายเข็มขัดนิรภัย'] },
          { row: ['✓', '✓', 'ชุดหัวล็อกสายเข็มขัดนิรภัย'] },
          { row: ['✓', '-', 'ชิ้นส่วนตัวถัง เช่น ประตู แก้ม ฝากระโปรง ฝาท้าย'] },
          { row: ['✓', '-', 'ล้อ ฝาครอบดุมล้อ และฝาครอบล้อ'] },
          { row: ['✓', '-', 'ยางขอบประตู'] },
          { row: ['✓', '-', 'ยางรถยนต์'] },
          { row: ['✓', '-', 'แบตเตอรี่'] },
          { row: ['✓', '-', 'แผ่นคลัตช์ ลูกปืนกดคลัตช์'] },
          { row: ['✓', '-', 'โช้กอัพ'] },
          { row: ['✓', '-', 'จานเบรกดรัมเบรก ผ้าเบรก'] },
          { row: ['✓', '-', 'ระบบไอเสีย (ท่อไอเสียหม้อพักไอเสีย แคตาโลติก)'] },
          { row: ['✓', '-', 'อุปกรณ์ชุดเครื่องเสียงและความบันเทิง'] },
          { row: ['-', '-', 'หลอดไฟ ฟิวส์'] },
          { row: ['-', '-', 'ผ้าเบรก และก้ามเบรก'] },
          { row: ['-', '-', 'แผ่นคลัตช์'] },
          { row: ['-', '-', 'ชิ้นส่วนอะไหล่ที่ผ่านการดัดแปลง'] }
        ]
      }
    },
    {
      title: 'เงื่อนไขและข้อตกลงโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
      detail: [
        {
          subTitle: 'นิยาม',
          lists: [
            'บริษัท หมายถึง บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด',
            'ท่าน หมายถึง ลูกค้า ผู้ปรากฏชื่อระบุในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
            'รถยนต์ หมายถึง รถปิคอัพและรถเอนกประสงค์อีซูซุที่ผลิตโดยบริษัท อีซูซุมอเตอร์ (ประเทศไทย) จำกัด และจัดจำหน่ายโดย บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด',
            'ผู้จำหน่าย หมายถึง ผู้จำหน่ายรถยนต์อีซูซุที่ได้รับการแต่งตั้งจาก บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด'
          ]
        },
        {
          subTitle: 'เอกสารในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'แบบคำขอรับบริการในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น (ยกเว้นลูกค้าที่ได้รับโปรแกรมการบริการพิเศษอีซูซุสมาร์ทโพรเทคชั่นฟรีตามเงื่อนไขจากการเช่าซื้อรถยนต์กับบริษัท ตรีเพชรอีซูซุลิสซิ่ง จำกัด จะไม่ได้รับแบบคำขอรับบริการโปรแกรมนี้)',
            'เงื่อนไขและข้อตกลงโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นรวมถึงแบบคำขอรับบริการและเงื่อนไขและข้อตกลงฉบับแก้ไข (ถ้ามี)'
          ]
        },
        {
          subTitle: 'ขอบเขตการให้บริการโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'บริษัทจะซ่อมแซมความเสียหาย รวมถึง จัดหา ปรับแต่ง หรือ เปลี่ยนชิ้นส่วนประกอบของรถยนต์ที่พบความบกพร่องของวัสดุหรือฝีมือการประกอบตามรายการการให้บริการในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น แนบท้ายเงื่อนไขการให้บริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นนี้ ภายในระยะเวลาของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น โดยไม่คิดราคาเปลี่ยนชิ้นส่วนประกอบและค่าบริการเพิ่มเติม เว้นแต่กรณีที่ข้อยกเว้นในเงื่อนไขการให้บริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นนี้',
            'บริษัทจะเป็นผู้พิจารณาและตัดสินใจในขั้นสุดท้ายในการซ่อมแซมความเสียหาย รวมถึง จัดหา ปรับแต่ง หรือ เปลี่ยนชิ้นส่วนประกอบของรถยนต์ตามโปรแกรม บริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น นี้ โดยพิจารณาใช้ชิ้นส่วนประกอบและอะไหล่ตามมาตรฐานที่บริษัทกำหนด รวมทั้ง อะไหล่ที่บริษัทรับรอง ตามที่บริษัทเห็นสมควร',
            'ชิ้นส่วนและอะไหล่ที่ทุกชิ้นที่ถูกถอดเปลี่ยนจากรถยนต์ในการให้บริการโปรแกรม บริการพิเศษอีซูซุสมาร์ทโพรเทคชั่นนี้ ท่านและบริษัทตกลงให้เป็นกรรมสิทธิ์ของบริษัท',
            'รถยนต์ที่จะรับบริการตามโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นต้องเป็นรถยนต์ที่ใช้ในประเทศไทยเท่านั้น ในการให้บริการตามโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น ท่านสามารถรับบริการได้ที่ศูนย์บริการมาตรฐานอีซูซุทั่วประเทศ'
          ]
        },
        {
          subTitle: 'ระยะเวลาของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'บริษัทจะให้บริการ ตามโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นกับรถยนต์และประกันการให้บริการ ตามโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นดังกล่าว โดยขยายระยะเวลารับประกันจาก 3 ปี/100,000 กิโลเมตร (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) เป็นระยะเวลา  5 ปี/100,000 กิโลเมตร หรือ 5 ปี/160,000 กิโลเมตร หรือ 7 ปี/160,000 กิโลเมตร (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) ตามที่ตกลงกันในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นโดยเริ่มนับระยะเวลาหรือระยะทางต่อจากวันที่การรับประกันคุณภาพรถยนต์ตามสมุดรับประกันสิ้นสุดลง'
          ]
        },
        {
          subTitle: 'ข้อปฏิบัติเพื่อรักษาสิทธิ์การรับโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'กรณีรถยนต์อยู่ในระยะเวลารับประกันคุณภาพรถใหม่ 3 ปี หรือระยะทางที่รถวิ่ง 100,000 กิโลเมตร แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน ท่านต้องนำรถยนต์เข้ารับบริการตรวจเช็กตามระยะและบำรุงรักษาตามคำแนะนำในสมุดคู่มือผู้ใช้รถยนต์และสมุดคู่มือรับประกันของบริษัท โดยเข้ารับบริการตรวจเช็กและบำรุงรักษาตามระยะกับศูนย์บริการของผู้จำหน่ายที่ได้รับการแต่งตั้งและเลือกใช้ชิ้นส่วนประกอบและอะไหล่มาตรฐานที่บริษัทกำหนด',
            'กรณีรถยนต์อยู่ในระยะเวลารับประกันตามโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น ท่านต้องนำรถยนต์เข้ารับบริการตรวจเช็กตามระยะและบำรุงรักษาตามคำแนะนำในสมุดคู่มือผู้ใช้รถยนต์และสมุดคู่มือรับประกันของบริษัท โดยเข้ารับบริการตรวจเช็กและบำรุงรักษาตามระยะกับศูนย์บริการของผู้จำหน่ายที่ได้รับการแต่งตั้งและเลือกใช้ชิ้นส่วนประกอบและอะไหล่มาตรฐานที่บริษัทกำหนด รวมทั้งอะไหล่ที่บริษัทรับรอง',
            'กรณีหากท่านไม่ปฏิบัติตามข้อปฏิบัติเพื่อรักษาสิทธิ์การรับบริการ หรือกรณีข้อยกเว้นโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นบริษัทสงวนสิทธิ์ในการปฏิเสธหรือบอกเลิกโปรแกรม บริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นของท่านทันที'
          ]
        },
        {
          subTitle: 'ข้อยกเว้นโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'รถยนต์ที่ไม่ได้เข้ารับบริการตรวจเช็กจากศูนย์บริการอีซูซุที่มีรายชื่ออยู่ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ เมื่อถึงกำหนดระยะบริการและบำรุงรักษารถยนต์ที่บริษัทฯได้ระบุไว้ในคู่มือดังกล่าวตลอดระยะเวลาของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
            'ความเสียหายในกรณีมีการซ่อมรถยนต์โดยบุคคลอื่น ซึ่งไม่ใช่พนักงานซ่อมรถยนต์ของศูนย์บริการมาตรฐานอีซูซุที่มีรายชื่ออยู่ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
            'ความเสียหายในกรณีมีการดัดแปลง เปลี่ยนแปลง หรือต่อเติมรถยนต์หลังจากรับมอบจากบริษัทฯ โดยมิได้รับอนุญาตเป็นลายลักษณ์อักษรจากบริษัทฯ',
            'ความเสียหายในกรณีมีการไม่ใช้อะไหล่แท้ที่มีเครื่องหมายการค้าอีซูซุซึ่งจัดจำหน่ายโดยบริษัทฯและผู้จำหน่ายของอีซูซุ หรือไม่ใช่อะไหล่ที่บริษัทรับรอง',
            'ความเสียหายในกรณีมีการใช้น้ำมันเชื้อเพลิง น้ำมันหล่อลื่นหรือสารเคมีที่ผิดไปจากข้อกำหนดในสมุดคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
            'ความเสียหายในกรณีมีการปฏิบัติไม่ถูกต้องตามคำแนะนำที่ให้ไว้ในคู่มือการใช้และการบำรุงรักษารถยนต์อีซูซุ',
            'ความเสียหายในกรณีมีการใช้ความเร็ว หรือใช้บรรทุกนํ้าหนักเกินกว่าที่กฎหมาย หรือระเบียบข้อบังคับที่ได้กำหนดไว้',
            'ความเสียหายในกรณีมีการขับรถยนต์ในสถานที่ ซึ่งโดยปกติไม่ใช้เป็นที่ขับรถยนต์',
            'การเสื่อมสภาพของรถยนต์ที่เกิดจากการล่วงไปของเวลา ซึ่งโดยปกติไม่ถือว่าเป็นการเสื่อมสภาพที่เกิดจากความชำรุดบกพร่องของวัสดุหรือฝีมือการประกอบ เช่น รถที่จอดทิ้งไว้เป็นระยะเวลานานโดยขาดการบำรุงรักษา เป็นต้น',
            'ความเสียหายของส่วนประกอบของชิ้นส่วน หรืออุปกรณ์รถยนต์ที่เสื่อมสภาพจากการใช้งาน และค่าใช้จ่ายในการบำรุงรักษาซ่อมแซมชิ้นส่วน หรืออุปกรณ์ดังกล่าว เช่น ค่าใช้จ่ายในการเปลี่ยนหัวฉีดนํ้ามันเชื้อเพลิง ลูกปืน หลอดไฟ ฟิวส์ แปรงถ่านของมอเตอร์สตาร์ต และออลเตอร์เนเตอร์ ไส้กรองต่างๆ ผ้าเบรก แผ่นคลัตช์ ชิ้นส่วนประกอบที่ทำด้วยยางหรือสารสังเคราะห์ เช่น ปะเก็น (ยกเว้นปะเก็นฝาสูบ) ใบปัดน้ำฝน ท่อยาง สายพานต่างๆ และบุ๊ชต่างๆ น้ำยาหม้อน้ำ สารหล่อลื่น การปรับตั้งศูนย์ล้อ และการถ่วงล้อ เป็นต้น',
            'ความเสียหายของส่วนประกอบที่เป็นกระจก เช่น กระจกบังลมหน้า กระจกบังลมหลัง และกระจกหน้าต่าง เป็นต้น',
            'เสียงดัง การสั่นสะเทือนตามปกติ ซึ่งไม่มีผลกระทบต่อคุณภาพ และสมรรถนะของรถยนต์',
            'ตราประทับ (หมายเลขเครื่องยนต์ และ/หรือ หมายเลขตัวถัง) ซึ่งได้ประทับไว้ก่อนส่งมอบรถยนต์ถูกทำลาย หรือโยกย้ายไป',
            'ความเสียหายที่เกิดแก่รถยนต์ ในกรณีมีอุบัติเหตุ การโจรกรรม หรือเหตุอื่นที่อยู่นอกเหนือการควบคุมของบริษัท',
            'ความเสียหายซึ่งเกิดจากภัยธรรมชาติ ควัน สารเคมี มูลนก เกลือ น้ำยางต้นไม้ ฯลฯ',
            'รถยนต์ที่มีการเปลี่ยนแปลงเลขกิโลเมตรบนหน้าปัดของมิเตอร์ระยะทาง สายมิเตอร์ความเร็วถูกถอดออก หรือขาด',
            'ค่าใช้จ่ายต่างๆ เช่น ที่พัก ค่าโทรศัพท์ ค่าน้ำมันเชื้อเพลิง ค่าเสียเวลา ค่าลากจูง รวมทั้งค่าเสียหายเนื่องจากขาดความสะดวก หรือธุรกิจเสียหาย',
            'ความเสียหายของส่วนประกอบและอุปกรณ์ตกแต่งรถยนต์ ที่ติดตั้งเพิ่มเติมจากส่วนประกอบและอุปกรณ์มาตรฐานของรถยนต์',
            'ความเสียหายของเครื่องเสียง วิทยุ ดีวีดี ระบบนำทางเนวิเกเตอร์ของรถยนต์',
            'ค่าแรงในการบำรุงรักษาตามปกติ เช่นการตรวจเช็กตามระยะทางตามรายการมาตรฐาน การตรวจเช็กเครื่องยนต์ การทำความสะอาดระบบปรับอากาศ ระบบหล่อเย็น หรือระบบเชื้อเพลิง การปรับตั้งเบรก คลัตช์ สายพาน ถ่วงยาง ศูนย์ล้อ เป็นต้น',
            'รถยนต์ซึ่งเข้าร่วมการแข่งขันความเร็ว แรลลี่ หรือการแข่งขันใดๆ'
          ]
        },
        {
          subTitle: 'การบอกเลิก โปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
          lists: [
            'เมื่อท่านได้ชำระเงินตามมูลค่าของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นแล้ว ท่านไม่สามารถบอกเลิกโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นนี้ได้',
            'บริษัทสงวนสิทธิ์ในการปฏิเสธหรือบอกเลิกโปรแกรม บริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นโดยไม่คืนเงินที่ท่านชำระไว้  หากว่าท่านไม่ปฏิบัติตามเงื่อนไขของโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นหรือในกรณีท่านปลอมแปลง ปกปิด ข้อเท็จจริง หรือข้อมูล ส่วนหนึ่งส่วนใดในแบบคำขอรับบริการในโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่น',
            'กรณีที่บริษัทเห็นสมควร บริษัทมีสิทธิบอกเลิกโปรแกรมบริการพิเศษ อีซูซุสมาร์ทโพรเทคชั่นก่อนหมดระยะเวลาตามโปรแกรม โดยคืนเงินท่านเต็มจำนวน'
          ]
        }
      ]
    }
  ]
}

export const REMARK = {
  title: 'หมายเหตุ',
  detail: [
    'ราคาแพ็กเกจอีซูซุสมาร์ทโพรเทคชั่นข้างต้นเป็นราคารวมภาษีมูลค่าเพิ่ม ณ วันที่ 26 มีนาคม 2566',
    'คูปองส่วนลด 2,000 บาท สำหรับใช้ในศูนย์บริการมาตรฐานอีซูซุ เมื่อสิ้นสุดการขยายการรับประกัน เป็นสิทธิพิเศษสำหรับลูกค้าอีซูซุสมาร์ทโพรเทคชั่นแพ็กเกจ 5 ปี หรือ 160,000 กม. (แล้วแต่อย่างใดอย่างหนึ่งจะถึงก่อน) เฉพาะการสมัครตั้งแต่วันที่ 1 พฤษภาคม 2563 เป็นต้นไป/ สำหรับลูกค้าที่สมัครเข้าร่วมโปรแกรมอีซูซุสมาร์ทโพรเทคชั่นก่อนวันที่ 1 พฤษภาคม 2563 ยังคงสามารถใช้สิทธิพิเศษก่อนหน้านี้ได้ตามปกติ เช่น อีซูซุสมาร์ทเช็ก (การตรวจเช็กรถฟรี) สามารถใช้ได้ก่อนหมดการขยายการรับประกันอีซูซุสมาร์ทโพรเทคชั่น/ไม่สามารถแลกสิทธิพิเศษต่างๆ คืนเป็นเงินสดได้ /ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายหรือแคมเพจ์นอื่นๆ ได้',
    'สำหรับลูกค้าที่ซื้อแพ็กเกจอีซูซุสมาร์ทโพรเทคชั่น ตั้งแต่ 1 มกราคม 2566 ถึง 31 ธันวาคม 2566 สามารถแบ่งชำระค่าบริการด้วยอัตราดอกเบี้ย 0% ผ่อนได้ 3 เดือน เฉพาะบัตรเครดิตที่ร่วมรายการ โดยธนาคารที่ร่วมรายการของแต่ละศูนย์บริการอาจแตกต่างกัน โปรดสอบถามเจ้าหน้าที่ก่อนเข้ารับบริการ เงื่อนไขเป็นไปตามที่ธนาคารและบริษัทฯกำหนด',
    'สอบถามรายละเอียดและเงื่อนไขได้ที่ ศูนย์บริการมาตรฐานอีซูซุ',
    'เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด'
  ]
}
