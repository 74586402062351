import React, { useEffect } from 'react'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Styles
import './_index.scss'

import { setLoginUserType } from 'utils/auth'
import { showLoader, hideLoader } from 'store/actions/loader'
// Methods
import queryString from 'query-string'
import { postPageView } from 'store/actions/profile'

//Constant
import { USER_TYPE } from 'config/constant'
import { generateLineRedirectUri, issueLineSignin } from 'utils/line'

const AutoLogin = (props) => {
  useEffect(() => {
    const search = queryString.parse(window.location.search)
    const type = search.type || USER_TYPE.INDIVIDUAL

    setLoginUserType(type)

    props.showLoader()
    props.postPageView()

    issueLineSignin({ redirectUri: generateLineRedirectUri(type) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className='auto-login'></div>
}

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        showLoader,
        hideLoader,
        postPageView
      },
      dispatch
    )

AutoLogin.propTypes = {
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func
}

export default connect(null, mapDispatchToProps)(AutoLogin)
