import { PROMOTION } from '../definitions'
import api from 'api/'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'
import { activityTypes } from 'config/constant'

/**
 *
 */
const logUserInteractionAtHome = (promotionName) => {
  return () => {
    api.logActivities({
      type: activityTypes.PROMOTION,
      description: 'Lastest promotion in home screen ' + promotionName
    })
  }
}

/**
 *
 */
const getPromotions = (ob = 16, feat = 0, off = 0, lt = 30) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api.getPromotions({ ob, feat, off, lt }).then((res) => {
      const total_count = parseInt(res.headers['x-total-count']) || res.data.length
      const data = res.data

      dispatch({
        type: PROMOTION.GET,
        total_count,
        data
      })
      dispatch(hideLoader())
    })
  }
}

/**
 *
 * @param {*} id
 */
const getPromotionDetail = (id) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api
      .getPromotionDetail(id)
      .then((res) => {
        dispatch({
          type: PROMOTION.DETAIL,
          promotionDetail: res.data
        })
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 * @param {*} id
 */
const getPromotionTarget = (id) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getTargetVehicles(id)
        .then((res) => {
          dispatch({
            type: PROMOTION.VEHICLE,
            data: res.data
          })
          dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} ob
 * @param {*} feat
 * @param {*} off
 * @param {*} lt
 */
const getPromotionsSlider = (ob = 16, feat = 0, off = 0, lt = 3000) => {
  return (dispatch) => {
    return api.getPromotions({ ob, feat, off, lt }).then((res) => {
      const total_count = parseInt(res.headers['x-total-count']) || res.data.length
      const data = res.data

      dispatch({
        type: PROMOTION.SLIDER,
        total_count,
        data
      })
    })
  }
}

export { getPromotions, getPromotionDetail, logUserInteractionAtHome, getPromotionTarget, getPromotionsSlider }
