import api from 'api/'
import { ISP_VEHICLES } from 'store/definitions'

import { showLoader, hideLoader } from 'store/actions/loader'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'

const getISPDetail = (id) => {
  return async (dispatch) => {
    const response = await api.getISPDetail(id)

    dispatch({
      type: ISP_VEHICLES.DETAIL,
      detail: response.data
    })
  }
}

const ispRegistration = (payload) => {
  return (dispatch) => {
    dispatch(showLoader())
    api
      .ispRegistration(payload)
      .then((res) => {
        dispatch(hideLoader())
        dispatch(getISPDetail(payload.hash_id))
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

const cancelRegistration = (payload, id) => {
  return (dispatch) => {
    dispatch(showLoader())
    api
      .cancelRegistration(payload)
      .then((res) => {
        dispatch(hideLoader())
        return dispatch(getISPDetail(id))
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
      .finally(() => {
        console.log('cancel done')
      })
  }
}

const autoCancel = (payload, id) => {
  return (dispatch) => {
    dispatch(showLoader())
    api
      .ispAutoCancel(payload)
      .then((res) => {
        dispatch(hideLoader())
        return dispatch(getISPDetail(id))
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
      .finally(() => {
        console.log('auto cancel done')
      })
  }
}

export { getISPDetail, ispRegistration, cancelRegistration, autoCancel }
