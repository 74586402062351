import React from 'react'
import { FormattedMessage } from 'react-intl'

export const activityTypes = {
  ACCOUNT: 1,
  COMMUNICATION: 2,
  SERVICE_COUPON: 3,
  NOTIFICATION: 4,
  PROMOTION: 5,
  VEHICLE: 6,
  EXCELLENCY: 7,
  ISP: 8,
  CASH_COUPON: 9,
  ERROR: 10
}

export const vehicleOptions = [
  {
    value: 1,
    label: <FormattedMessage id='vehicle.list.nextMaintenance' defaultMessage='vehicle.list.nextMaintenance' />
  },
  {
    value: 3,
    label: <FormattedMessage id='vehicle.list.vehicleAging' defaultMessage='vehicle.list.vehicleAging' />
  },
  {
    value: 5,
    label: <FormattedMessage id='vehicle.detail.licensePlate' defaultMessage='vehicle.detail.licensePlate' />
  }
]

export const couponOptions = [
  { value: 31, label: <FormattedMessage id='coupon.placeholders.default' defaultMessage='coupon.placeholders.default' /> },
  {
    value: 7,
    label: <FormattedMessage id='coupon.placeholders.expiryDate' defaultMessage='coupon.placeholders.expiryDate' />
  },
  {
    value: 5,
    label: <FormattedMessage id='coupon.placeholders.licensePlate' defaultMessage='coupon.placeholders.licensePlate' />
  },
  {
    value: 25,
    label: <FormattedMessage id='coupon.placeholders.couponName' defaultMessage='coupon.placeholders.couponName' />
  },
  { value: 29, label: <FormattedMessage id='coupon.placeholders.couponValue' defaultMessage='coupon.placeholders.couponValue' /> }
]

export const inboxOptions = [
  {
    value: 19,
    label: <FormattedMessage id='inbox.placeholders.receivedDate' defaultMessage='inbox.placeholders.receivedDate' />
  }
]

export const controllerOptions = [
  {
    value: 21,
    label: <FormattedMessage id='controller.placeholders.vehicleAssignment' defaultMessage='controller.placeholders.vehicleAssignment' />
  },
  {
    value: 23,
    label: <FormattedMessage id='controller.placeholders.fullName' defaultMessage='controller.placeholders.fullName' />
  }
]

export const fontSizes = [
  {
    value: 'small',
    label: <FormattedMessage id='myProfile.settings.fontSize_small' defaultMessage='myProfile.settings.fontSize_small' />
  },
  {
    value: 'medium',
    label: <FormattedMessage id='myProfile.settings.fontSize_medium' defaultMessage='myProfile.settings.fontSize_medium' />
  },
  {
    value: 'large',
    label: <FormattedMessage id='myProfile.settings.fontSize_big' defaultMessage='myProfile.settings.fontSize_big' />
  }
]

export const languageOptions = [
  {
    value: 'en',
    label: <FormattedMessage id='common.labels.english' defaultMessage='common.labels.english' />
  },
  {
    value: 'th',
    label: <FormattedMessage id='common.labels.thai' defaultMessage='common.labels.thai' />
  }
]

export const deleteReasons = [
  {
    value: '1',
    label: <FormattedMessage id='vehicle.delete.family' defaultMessage='vehicle.delete.family' />
  },
  {
    value: '2',
    label: <FormattedMessage id='vehicle.delete.friend' defaultMessage='vehicle.delete.friend' />
  },
  {
    value: '3',
    label: <FormattedMessage id='vehicle.delete.tent' defaultMessage='vehicle.delete.tent' />
  },
  {
    value: '4',
    label: <FormattedMessage id='vehicle.delete.otherPeople' defaultMessage='vehicle.delete.otherPeople' />
  }
]

export const vehicleTypeOptions = [
  {
    value: 'all',
    label: <FormattedMessage id='vehicle.type.all' defaultMessage='vehicle.type.all' />
  },
  {
    value: 'pickup',
    label: <FormattedMessage id='vehicle.type.pickup' defaultMessage='vehicle.type.pickup' />
  },
  {
    value: 'ppv',
    label: <FormattedMessage id='vehicle.type.ppv' defaultMessage='vehicle.type.ppv' />
  },
  {
    value: 'truck',
    label: <FormattedMessage id='vehicle.type.truck' defaultMessage='vehicle.type.truck' />
  }
]

export const controllerStatusOptions = [
  {
    value: 'all',
    label: <FormattedMessage id='controller.status.all' defaultMessage='controller.status.all' />
  },
  {
    value: 'unassigned',
    label: <FormattedMessage id='controller.status.unassigned' defaultMessage='controller.status.unassigned' />
  }
]

export const controllerActivationStatusOptions = [
  {
    value: 'all',
    label: <FormattedMessage id='controller.activation.all' defaultMessage='controller.activation.all' />
  },
  {
    value: 201,
    label: <FormattedMessage id='controller.activation.activated' defaultMessage='controller.activation.activated' />
  },
  {
    value: 101,
    label: <FormattedMessage id='controller.activation.inactivated' defaultMessage='controller.activation.inactivated' />
  }
]

export const controllerAssignmentStatusOptions = [
  {
    value: 'all',
    label: <FormattedMessage id='controller.status.allStatus' defaultMessage='controller.status.allStatus' />
  },
  {
    value: 101,
    label: <FormattedMessage id='controller.status.assign' defaultMessage='controller.status.assign' />
  },
  {
    value: 102,
    label: <FormattedMessage id='controller.status.unassigned' defaultMessage='controller.status.unassigned' />
  }
]

export const USER_TYPE = Object.freeze({
  INDIVIDUAL: 1,
  CORPORATE: 2,
  CONTROLLER: 3
})

export const USER_TYPE_RESOLVER = Object.freeze({
  [USER_TYPE.INDIVIDUAL]: 'INDIVIDUAL',
  [USER_TYPE.CORPORATE]: 'CORPORATE',
  [USER_TYPE.CONTROLLER]: 'CONTROLLER'
})

export const REGISTER_SCREEN = Object.freeze({
  REGISTRATION_FORM: 1,
  OTP: 2,
  TERM_AND_CONDITION: 3
})

export const serviceTypeOptions = [
  {
    value: 'Maintenance',
    label: 'เช็กตามระยะ'
  },
  {
    value: 'General',
    label: 'ซ่อมทั่วไป'
  },
  {
    value: 'Other',
    label: 'อื่นๆ'
  }
]

export const timeENOptions = [
  {
    value: '08:00',
    label: '08:00'
  },
  {
    value: '09:00',
    label: '09:00'
  },
  {
    value: '10:00',
    label: '10:00'
  },
  {
    value: '11:00',
    label: '11:00'
  },
  {
    value: '13:00',
    label: '13:00'
  },
  {
    value: '14:00',
    label: '14:00'
  },
  {
    value: '15:00',
    label: '15:00'
  }
]

export const timeTHOptions = [
  {
    value: '08:00',
    label: '08:00 น.'
  },
  {
    value: '09:00',
    label: '09:00 น.'
  },
  {
    value: '10:00',
    label: '10:00 น.'
  },
  {
    value: '11:00',
    label: '11:00 น.'
  },
  {
    value: '13:00',
    label: '13:00 น.'
  },
  {
    value: '14:00',
    label: '14:00 น.'
  },
  {
    value: '15:00',
    label: '15:00 น.'
  }
]

export const ISP_STATUS = {
  MYI_DRAFT: 101,
  SENT_DRAFT_TO_IT10: 102,
  SENT_DRAFT_ERROR: 103,
  CREATE_DRAFT_ERROR: 104,
  IT10_DRAFT: 201,
  MYI_CANCEL_DRAFT: 202,
  SENT_CANCEL_TO_IT10: 203,
  APPLICATION_CANCELLED: 204,
  SENT_CANCEL_ERROR: 205,
  CANCEL_ERROR: 206,
  APPLICATION_AUTO_CANCEL: 207,
  APPLICATION_SUBMITTED: 301,
  APPLICATION_APPROVED: 302,
  APPLICATION_REJECTED: 303
}

export const MAX_REMAINING = {
  BRONZE: 3,
  SILVER: 3,
  GOLD: 3,
  PLATINUM: 3,
  WHITE: 3,
  RED: 3
}

export const EXCELLENCY_STATUS = {
  BRONZE: 'bronze',
  SILVER: 'silver',
  GOLD: 'gold',
  PLATINUM: 'platinum',
  WHITE: 'white',
  RED: 'red',
  BLACK: 'black'
}

export const VEHICLE_TYPE = {
  LCV: 'LCV',
  CV: 'CV'
}

export const BOOLEAN = {
  TRUE: 'true',
  FALSE: 'false'
}

export const ISP_MASTER_NON_EXY = {
  headerTitle: 'โปรโมชันตั้งแต่วันที่ 26 มี.ค. 2566 - 31 ธ.ค. 2566',
  dmaxPackage: {
    dmaxPackageList: [
      {
        discountPrice: 6000,
        fullPrice: 10000
      }
    ],
    name: 'ดีแมคซ์'
  },
  muxPackage: {
    muxPackageList: [
      {
        discountPrice: 7000,
        fullPrice: 11000
      }
    ],
    name: 'มิว-เอ็กซ์'
  },
  priceTitle: 'ราคาพิเศษ',
  privilegeList: {
    detail: [
      {
        detailTitle: 'สิทธิพิเศษ',
        item: ['คูปองส่วนลด 2,000 บาท (หลังสิ้นสุดการขยายระยะรับประกัน)', 'ผ่อนจ่ายได้ 0% นาน 3 เดือน']
      },
      {
        detailTitle: 'ขยายแพ็กเกจเป็น 7 ปี หรือ 160,000 กม. สำหรับสมาชิก "อีซูซุ ยิ่งเข้า ยิ่งคุ้ม" ระดับ ซิลเวอร์ ขึ้นไป',
        item: ['ลูกค้าจ่ายเพิ่ม 3,000 บาท']
      }
    ]
  },
  suggestionTitle: 'เหมาะสำหรับลูกค้าที่ใช้รถมาก<br>แถมได้คูปองส่วนลด 2,000 บาท',
  tabTitle: 'แพ็กเกจ 5 ปี หรือ<br>160,000 กม.'
}

export const ISP_MASTER_BRONZE = {
  headerTitle: 'สำหรับสมาชิก "อีซูซุ ยิ่งเข้า ยิ่งคุ้ม" ระดับ บรอนซ์ ขึ้นไป',
  dmaxPackage: {
    dmaxPackageList: [
      {
        discountPrice: 2000,
        fullPrice: 0
      }
    ],
    name: 'ดีแมคซ์'
  },
  muxPackage: {
    muxPackageList: [
      {
        discountPrice: 3000,
        fullPrice: 0
      }
    ],
    name: 'มิว-เอ็กซ์'
  },
  priceTitle: 'ราคาพิเศษ',
  privilegeList: {
    detail: [
      {
        detailTitle: 'สิทธิพิเศษ',
        item: ['ผ่อนจ่ายได้ 0% นาน 3 เดือน']
      },
      {
        detailTitle: 'ขยายแพ็กเกจเป็น 7 ปี หรือ 160,000 กม. สำหรับสมาชิก "อีซูซุ ยิ่งเข้า ยิ่งคุ้ม" ระดับ ซิลเวอร์ ขึ้นไป',
        item: ['ลูกค้าจ่ายเพิ่ม 3,000 บาท']
      }
    ]
  },
  suggestionTitle: 'เหมาะสำหรับลูกค้าที่ใช้รถน้อย<br>จ่ายง่าย สบายกระเป๋า',
  tabTitle: 'แพ็กเกจ 5 ปี หรือ<br>100,000 กม.'
}

export const ISP_MASTER_SILVER = {
  headerTitle: 'สำหรับสมาชิก "อีซูซุ ยิ่งเข้า ยิ่งคุ้ม" ระดับ ซิลเวอร์ ขึ้นไป',
  dmaxPackage: {
    dmaxPackageList: [
      {
        discountPrice: 5000,
        fullPrice: 6000
      }
    ],
    name: 'ดีแมคซ์'
  },
  muxPackage: {
    muxPackageList: [
      {
        discountPrice: 6000,
        fullPrice: 7000
      }
    ],
    name: 'มิว-เอ็กซ์'
  },
  priceTitle: 'ราคาพิเศษ',
  privilegeList: {
    detail: [
      {
        detailTitle: 'สิทธิพิเศษ',
        item: ['ผ่อนจ่ายได้ 0% นาน 3 เดือน']
      }
    ]
  },
  suggestionTitle: 'เหมาะสำหรับลูกค้าใช้รถมาก<br>เพิ่มความคุ้มครองสูงสุด',
  tabTitle: 'แพ็กเกจ 7 ปี หรือ<br>160,000 กม.'
}

export const ERROR = {
  ERROR_OTP: 20019
}
