import api from 'api/'
import { MUX } from 'store/definitions'

const getMuxOwner = () => {
  return (dispatch) => {
    dispatch({
      type: MUX.IS_OWNER_LOADING
    })

    return api
      .getMuxOwner()
      .then((res) => {
        dispatch({
          type: MUX.IS_OWNER_SUCCEEDED,
          is_owner: res.data.result
        })
      })
      .catch(() => {
        dispatch({
          type: MUX.IS_OWNER_FAILED
        })
      })
  }
}

export { getMuxOwner }
