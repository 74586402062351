import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Method
import * as format from 'utils/format'
import { formatNumber, showIspDate } from 'utils/format'
import { isInWarranty, ISPExpired, isISP } from 'utils/helper'

// Constant
import { USER_TYPE } from 'config/constant'

// Components
import VehicleDetailsController from 'domain/Vehicle/components/VehicleDetailsControllers'
import CouponItem from 'domain/Coupons/components/CouponItem'
import Button from 'components/elements/Button'
import Slider from 'components/elements/Slider'
import FullImage from 'domain/Vehicle/components/FullImage'
import VehicleDetailsInsurance from 'domain/Vehicle/components/VehicleDetailsInsurance'
import NextMaintainace from '../VehicleNextMaintainace'
import DeleteVehicleReasonModal from 'domain/Vehicle/components/DeleteVehicleReasonModal'
import Badge from './Badge'

// Actions
import { assignControllers, unassignControllers, deleteVehicles, addController, clearController } from 'store/actions/vehicles'

// Styles
import './_index.scss'

// Icons
import { repairIcon, refinanceBtn, ownerManualIcon } from 'config/icon'

import ImageGallery from 'react-image-gallery'

export const DetailVehicle = (props) => {
  const history = useHistory()
  // const [toggleAssign, setToggleAssign] = useState(false)
  // const [selectedAssignment, setSelectedAssignment] = useState(props.detail.controller ? props.detail.controller.id : undefined)
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false)
  const [fullImage, setFullImage] = useState(false)
  const [currentIndex, setCurrentIndex] = useState('')
  // const [reason, setReason] = useState()
  const _imageGallery = useRef()
  const deleteButtonClickHandler = () => {
    setShouldShowDeleteModal(true)
  }

  const deleteVehicleModalCloseHandler = () => {
    setShouldShowDeleteModal(false)
  }

  const deleteF = async (deleteReason) => {
    const { detail, deleteVehicles, history } = props

    await deleteVehicles([detail.id], deleteReason)

    setShouldShowDeleteModal(false)
    if (deleteReason) {
      history.replace('/vehicles')
    }
  }

  const clear = async (controllerId) => {
    const {
      unassignControllers,
      clearController,
      detail: { id: vehicleId }
    } = props
    try {
      await unassignControllers([controllerId], [vehicleId])
      clearController(controllerId)
    } catch (err) {
      // TODO: add additional action if there is an error
    }
  }

  const assign = async (controllerId) => {
    const {
      assignControllers,
      addController,
      detail: { id: vehicleId }
    } = props
    try {
      await assignControllers([controllerId], [vehicleId])
      addController(controllerId)
    } catch (err) {
      // TODO: add additional action if there is an error
    }
  }

  // const changeSelected = selected => {
  //   setSelectedAssignment(selected.value)
  // }

  // const toggleSelect = () => {
  //   setToggleAssign(!toggleAssign)
  // }

  // const changeRadio = event => {
  //   setReason(event.target.value)
  // }

  useEffect(() => {
    if (props.location.state && props.location.state.vehicleId === props.match.params.id) {
      const elem = document.querySelector('#infographic')
      elem.scrollIntoView()
    }
  }, [props.location.state, props.match.params.id])

  const displayFullScreen = () => {
    const currentIndex = _imageGallery.current.getCurrentIndex()
    setCurrentIndex(currentIndex)
    setFullImage(true)
  }

  const closeFullImage = () => {
    setFullImage(false)
  }

  const {
    userType,
    detail,
    detail: { vehicle, driver, controllers },
    controllers: controllersList
  } = props

  const {
    model,
    license_plate,
    delivery_date,
    vin,
    interval_check_master,
    warranty_badge,
    serviceCoupons,
    cashCoupons,
    vehicle_type,
    warranty,
    isp_information
  } = vehicle

  const classes = classnames('detail-vehicle')

  const image_list = detail.vehicle.vehicle_images

  let images = []
  let startIndex
  image_list &&
    image_list.forEach((image, index) => {
      images.push({ original: image.image_url, thumbnail: image.image_url })
      if (image.is_cover) {
        startIndex = index
      }
    })

  const image_count = image_list && image_list.length
  const OwnerManualLink = getOwnerManualLink(detail.vehicle.vehicle_model, detail.vehicle.model_year)

  return (
    <>
      {fullImage === true ? (
        <FullImage
          image={images[currentIndex].original}
          isCover={detail.vehicle.vehicle_images[currentIndex].is_cover}
          onClose={closeFullImage}
          isDelete={image_count > 1 || detail.is_deleted_official_image}
          isOfficial={detail.vehicle.vehicle_images[currentIndex].is_official}
        />
      ) : (
        <div className={classes}>
          <div className='detail-vehicle-wrapper'>
            <div className='detail-vehicle-wrapper__details'>
              <div className='detail-vehicle-wrapper__details__image'>
                <ImageGallery
                  items={images}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                  startIndex={startIndex}
                  ref={_imageGallery}
                  onClick={displayFullScreen}
                />
              </div>
              <div className='detail-vehicle-wrapper__details__body'>
                <div className='detail-vehicle-wrapper__details__body__top'>
                  <div className='detail-vehicle-wrapper__details__body__top__detail'>
                    <div className='detail-vehicle-wrapper__details__body__name'>{detail.name || model.marketing_name}</div>
                    <div className='detail-vehicle-wrapper__details__body__license-plate'>{license_plate}</div>
                  </div>
                  <div className='detail-vehicle-wrapper__details__body__top__btn'>
                    <Button
                      id='service-appointment'
                      className='service-appointment'
                      color='primary'
                      label='dealerAppointment.appointment'
                      onClick={(e) => {
                        e.stopPropagation()
                        window.previousLocation = '/vehicle-detail'
                        history.push('/dealer-search')
                      }}
                      disabled={props.isDemoUser}
                    />
                  </div>
                </div>

                {/* Badge Position */}
                <Badge detail={detail} vehicle={vehicle} />
                <div className='detail-vehicle-wrapper__details__button'>
                  {userType !== USER_TYPE.INDIVIDUAL && !vehicle.hide_repair_history && (
                    <Button
                      label='vehicle.detail.repairHist'
                      color='transparent-primary'
                      id='repair-history'
                      className='repair-history'
                      icon={repairIcon}
                      onClick={() => history.push(`/repair-history/${detail.id}`)}
                      disabled={props.isDemoUser}
                    />
                  )}

                  {detail.vehicle.show_til_refinance && (
                    <Button
                      id='refinance-btn'
                      label='vehicle.detail.tilRefinance'
                      color='transparent-primary'
                      icon={refinanceBtn}
                      onClick={() => {
                        history.push(`/til-refinance/${detail.id}`)
                      }}
                      disabled={props.isDemoUser}
                    />
                  )}
                  {detail.vehicle.vehicle_type === 'LCV' && Math.floor(detail.vehicle.model_year) >= 2023 && (
                    <Button
                      id='owner-manual-btn'
                      label='vehicle.detail.ownerManual'
                      color='transparent-primary'
                      icon={ownerManualIcon}
                      onClick={() => {
                        window.open(OwnerManualLink, '_blank')
                      }}
                      disabled={props.isDemoUser}
                    />
                  )}
                </div>

                <div className='detail-vehicle-wrapper__details__body__line'></div>
                <div className='detail-vehicle-wrapper__details__body__details'>
                  <div className='detail-vehicle-wrapper__details__body__details__section-name'>
                    <div className='detail-vehicle-wrapper__details__body__details__section-name__header'>
                      <FormattedMessage id='common.labels.vehicleInfo' defaultMessage='common.labels.vehicleInfo' />
                    </div>
                    {
                      //update condition for T2H button to always appear for CV owner
                      vehicle_type === 'CV' && ( //detail.vehicle.isT2H && userType === USER_TYPE.INDIVIDUAL &&
                        <Button
                          type='add-new'
                          className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__t2h'
                          label='vehicle.detail.T2H'
                          values='T2H'
                          onClick={() => {
                            history.push(`/T2H/${props.match.params.id}`)
                          }}
                          disabled={props.isDemoUser}
                        />
                      )
                    }
                  </div>

                  <div className='detail-vehicle-flex'>
                    <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__name'>
                      <div className='label'>
                        <FormattedMessage id='vehicle.detail.marketingName' defaultMessage='vehicle.detail.marketingName' />
                      </div>
                      <div className='value'>{model.marketing_name}</div>
                    </div>
                    <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__age'>
                      <div className='label'>
                        <FormattedMessage id='vehicle.detail.age' defaultMessage='vehicle.detail.age' />
                      </div>
                      <div>
                        <div className='value'>{format.showAge(delivery_date)}</div>
                      </div>
                    </div>
                  </div>

                  <div className='detail-vehicle-flex'>
                    <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__vin'>
                      <div className='label'>
                        <FormattedMessage id='common.labels.vin' defaultMessage='common.labels.vin' />
                      </div>
                      <div className='value'>{vin}</div>
                    </div>
                    <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__warranty'>
                      <div className='value'>
                        {isISP(vehicle) && !ISPExpired(vehicle) ? (
                          warranrtFormat(isp_information.isp_end_date, isp_information.isp_master.isp_mileage)
                        ) : warranty && isInWarranty(vehicle) ? (
                          warranrtFormat(warranty.warranty_end, warranty.warranty_mileage)
                        ) : (
                          <div>
                            <div className='label'>
                              <FormattedMessage id='privilege.warranty' defaultMessage='privilege.warranty' />
                            </div>
                            <FormattedHTMLMessage id='privilege.outOfWarranty' defaultMessage='privilege.outOfWarranty' />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {warranty_badge && !isEmpty(warranty_badge) && (
                    <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__maintenance'>
                      <div className='label'>
                        <FormattedMessage id='vehicle.detail.warranty' defaultMessage='vehicle.detail.warranty' />
                      </div>
                      <div className='value'>
                        <FormattedMessage id='vehicle.detail.warrantyCondition' defaultMessage='vehicle.detail.warrantyCondition' />
                      </div>
                    </div>
                  )}

                  {userType !== USER_TYPE.INDIVIDUAL && (
                    <React.Fragment>
                      <div className='detail-vehicle-wrapper__details__body__details__field'>
                        <div className='label'>
                          <FormattedMessage id='vehicle.detail.driverFullName' defaultMessage='vehicle.detail.driverFullName' />
                        </div>
                        <div className='value'>{format.fullName(driver.given_name, driver.surname)}</div>
                      </div>
                      <div className='detail-vehicle-wrapper__details__body__details__field'>
                        <div className='label'>
                          <FormattedMessage id='vehicle.detail.driverPhoneNumber' defaultMessage='vehicle.detail.driverPhoneNumber' />
                        </div>
                        <div className='value'>{(driver && driver.mobile) || '-'}</div>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                {userType === USER_TYPE.CORPORATE && (
                  <React.Fragment>
                    <VehicleDetailsController
                      controllers={controllers}
                      controllersList={controllersList}
                      onAddController={assign}
                      onRemoveController={clear}
                    />
                    <div className='detail-vehicle-wrapper__details__body__line'></div>
                  </React.Fragment>
                )}
                {!isEmpty(detail.vehicle.insuranceInfo) && userType === USER_TYPE.INDIVIDUAL && (
                  <div>
                    <VehicleDetailsInsurance vehicleDetail={detail.vehicle} isDemoUser={props.isDemoUser} />
                  </div>
                )}
                <NextMaintainace vehicle={vehicle} intervalMaster={interval_check_master} />
              </div>
            </div>

            {serviceCoupons.length > 0 && (
              <Slider
                title='vehicle.detail.serviceCoupons'
                component={CouponItem}
                data={detail.vehicle.serviceCoupons}
                userType={props.userType}
                fromPage={props.fromPage}
              />
            )}
            {cashCoupons.length > 0 && (
              <Slider
                title='vehicle.detail.cashCoupons'
                component={CouponItem}
                data={detail.vehicle.cashCoupons}
                userType={props.userType}
                fromPage={props.fromPage}
              />
            )}
            {userType !== USER_TYPE.CONTROLLER && (
              <div className='detail-vehicle__action'>
                <Button
                  className='delete'
                  type='delete'
                  icon='delete'
                  label='vehicle.detail.deleteVehicle'
                  onClick={deleteButtonClickHandler}
                  disabled={props.isDemoUser}
                />
              </div>
            )}

            {/* Delete Vehicle Modal */}
            {/* <img
            className="vehicle-detail-fab"
            src={ checkLanguage() === 'th' ? chat_th : chat_en}
            alt=""
            onClick={e => {
              e.stopPropagation()
              window.previousLocation = '/vehicle/details'
              history.push('/dealer-search')
              // history.vehicle = license_plate
            }}
          /> */}

            {/* Delete Vehicle Modal */}
            <DeleteVehicleReasonModal isOpen={shouldShowDeleteModal} onConfirm={deleteF} onClose={deleteVehicleModalCloseHandler} />
          </div>
        </div>
      )}
    </>
  )
}

const warranrtFormat = (date, mileage) => {
  return (
    <div>
      <div className='label'>
        <FormattedMessage id='privilege.endOfWarranty' defaultMessage='privilege.endOfWarranty' />
      </div>
      <FormattedHTMLMessage
        id='privilege.warrantyFormatVehicle'
        defaultMessage='privilege.warrantyFormatVehicle'
        values={{
          date: showIspDate(date, true),
          mileage: formatNumber(mileage)
        }}
      />
      <div className='warranty-reamark'>
        <FormattedMessage id='privilege.remark' defaultMessage='privilege.remark' />
      </div>
    </div>
  )
}

const getOwnerManualLink = (vehicleModel, modelYear) => {
  let link = 'https://www.isuzu-tis.com/service/owners-manual'
  if (vehicleModel === 'PPV') {
    return link = `${link}?model=mux&year=${Math.floor(modelYear)}`
  } else if (vehicleModel === 'PICKUP') {
    return link = `${link}?model=dmax&year=${Math.floor(modelYear)}`
  } else {
    return link
  }
}

DetailVehicle.propTypes = {
  controllers: PropTypes.array,
  userType: PropTypes.number
}

const mapDispatchToProps = /* istanbul ignore next */ (dispatch) =>
  bindActionCreators(
    {
      deleteVehicles,
      assignControllers,
      unassignControllers,
      addController,
      clearController
    },
    dispatch
  )

export default withRouter(connect(null, mapDispatchToProps)(DetailVehicle))
