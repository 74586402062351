import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core'

import { authSignout } from 'store/actions/auth'

import MenuList from 'components/elements/Menu/MenuList'

import './_index.scss'

const styles = {}

const Menu = (props) => {
  const dispatch = useDispatch()

  /*
mode tab in other page
0       : desktop
1       : mobile
*/

  /*
isActive tab to router
myProfile       : /profile
manageCompany   : /company/list
changePassword  : /profile/change-password
changeLanguage  : /profile/settings
agreement       : /terms-conditions
privacyPolicy   : /privacy-policies
help            : /help-page
*/

  const signoutHandler = useCallback(() => {
    dispatch(authSignout())
  }, [dispatch, authSignout])
  const tabActive = (menuListActive) => {
    return props.tabActive === menuListActive
  }

  return (
    <>
      {props.mode === 0 && (
        <>
          <MenuList label='menus.setting' isTitle borderTop />
          <MenuList
            label='myProfile.labels.changePassword'
            isActive={tabActive('changePassword')}
            path='/profile/change-password'
            indend={2}
            showIcon
          />
          <MenuList label='menus.changeLanguage' isActive={tabActive('changeLanguage')} path='/profile/settings' indend={2} showIcon />
          <MenuList label='createAccount.labels.agreement' isActive={tabActive('agreement')} path='/terms-conditions' showIcon />
          <MenuList label='common.labels.privacypolicy' isActive={tabActive('privacyPolicy')} path='/privacy-policies' showIcon />
          <MenuList label='myProfile.labels.help' isActive={tabActive('help')} path='/help-page' showIcon />
        </>
      )}
      {props.mode === 1 && (
        <>
          <MenuList label='menus.myProfile' isActive={tabActive('myProfile')} path='/profile' showIcon borderTop />
          <MenuList label='menus.addVehicle' isActive={tabActive('addVehicle')} path='/vehicles/search' showIcon />
          {props.profile.type === 2 && (
            <MenuList label='menus.controller' isActive={tabActive('manageCompany')} path='/controllers' showIcon />
          )}
          {props.profile.type === 2 && (
            <MenuList label='menus.manageCompany' isActive={tabActive('manageCompany')} path='/company/list' showIcon />
          )}
          <MenuList label='menus.setting' isTitle />
          <MenuList
            label='myProfile.labels.changePassword'
            isActive={tabActive('changePassword')}
            path='/profile/change-password'
            indend={1}
            showIcon
          />
          <MenuList label='menus.changeLanguage' isActive={tabActive('changeLanguage')} path='/profile/settings' indend={1} showIcon />
          <MenuList label='createAccount.labels.agreement' isActive={tabActive('agreement')} path='/terms-conditions' showIcon />
          <MenuList label='common.labels.privacypolicy' isActive={tabActive('privacyPolicy')} path='/privacy-policies' showIcon />
          <MenuList label='myProfile.labels.help' isActive={tabActive('help')} path='/help-page' showIcon />
          <MenuList label='myProfile.labels.logout' isActive={tabActive('logout')} onClick={signoutHandler} marginBottom />
        </>
      )}
    </>
  )
}
export const mapStateToProps = (state) => ({
  profile: state.profile
})

export default connect(mapStateToProps)(withStyles(styles)(Menu))
