import React, { useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

// Redux
import { connect } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Method
import * as helper from 'utils/helper'

// Components
import Swiper from 'react-id-swiper'
// Styles
import './_index.scss'
import './_privswiper.scss'

const PrivilegesConditions = ({ detail, isNseries, master }) => {
  const ref = useRef(null)
  const classes = classnames('privileges-conditions')
  const privileges = ['no_status', 'bronze', 'silver', 'gold', 'platinum']
  const nSeriesPrivilege = ['no_status_nseries', 'white', 'red', 'black']

  let privilegesItems = []
  if (isNseries) {
    privilegesItems = detail && nSeriesPrivilege.slice(helper.getNSeriesPrivCond(detail.current_status))
  } else {
    privilegesItems = detail && privileges.slice(helper.getPrivCond(detail.current_status))
  }

  const onNextClick = useCallback(() => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }, [ref])

  const onPrevClick = useCallback(() => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }, [ref])

  const swiperItems = privilegesItems.map((item, index) => {
    const showPrevButton = index > 0
    const showNextButton = index !== privilegesItems.length - 1
    return (
      <div key={index}>
        <div className='privSwiper__header'>
          <div className='privSwiper__header__prev'>
            {showPrevButton && <div className='swiper-button-prev swiper-button-black' onClick={onPrevClick}></div>}
          </div>
          <img
            className='privSwiper__header__img'
            src={helper.getAssets(1, 3, master && item, 'privileges_and_conditions_header' || /*istanbul ignore next*/ '')}
            alt=''
          />
          <div className='privSwiper__header__next'>
            {showNextButton && <div className='swiper-button-next swiper-button-black' onClick={onNextClick}></div>}
          </div>
        </div>
        <div>
          <img
            className='privSwiper__body'
            src={helper.getAssets(1, 3, master && item, 'privileges_and_conditions_body' || /*istanbul ignore next*/ '')}
            alt=''
          />
        </div>
      </div>
    )
  })

  const swiperParams = {
    loop: false,
    spaceBetween: 30,
    autoplay: false,
    rebuildOnUpdate: true,
    autoHeight: true
  }

  return (
    <div className={classes}>
      <div className='privileges-conditions__title'>
        <FormattedMessage id='privilege.excellencyConditions' defaultMessage='privilege.excellencyConditions' />
      </div>

      <Swiper {...swiperParams} ref={ref}>
        {swiperItems}
      </Swiper>
    </div>
  )
}

const mapStateToProps = /* istanbul ignore next*/ (state) => ({
  master: state.master.contents
})

PrivilegesConditions.defaultProps = {
  detail: {
    current_status: null
  }
}
export default connect(mapStateToProps, null)(PrivilegesConditions)
