export const css = {}

export const styles = (theme) => ({
  col: {
    textAlign: 'center',
    paddingTop: '2rem',
    '& > span': {
      padding: '2rem 1rem'
    }
  }
})
