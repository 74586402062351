import React from 'react'

import PendingItemList from './pendingItemList'
import ItemList from './itemList'

const checkItemExist = (repairDetail) => {
  return repairDetail.item_list
}

const checkPendingItem = (repairDetail) => {
  return (repairDetail.pending_items && repairDetail.pending_items.length > 0) || repairDetail.has_claim_item
}

const RepairDetailBodyItem = (props) => {
  return (
    <React.Fragment>
      {checkItemExist(props.RepairDetail) && <ItemList RepairDetail={props.RepairDetail} />}
      {checkPendingItem(props.RepairDetail) && <PendingItemList RepairDetail={props.RepairDetail} />}
    </React.Fragment>
  )
}

export default RepairDetailBodyItem
