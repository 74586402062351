import api from 'api/'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'
import { COMPANY } from '../definitions'

const getCompanyList = () => {
  return (dispatch) => {
    dispatch(showLoader())
    return api.getCompanyList().then((res) => {
      dispatch({
        type: COMPANY.GET,
        data: res.data
      })
      dispatch(hideLoader())
    })
  }
}

/**
 *
 * @param {*} data
 */

const checkAvalibleAddCompany = async (data, dispatch) => {
  const body = {}
  try {
    await api.checkAvalibleAddCompany(data).then((res) => {
      console.log(JSON.stringify(res))
      body.data = res.data
      body.status = res.status
    })
    return body
  } catch (err) {
    // dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'delete-vehicles')))
    body.status = err.response.status
    body.descripttion = err.response.data.error_message
    return body
  }
}

/**
 *
 * @param {*} data
 */

const AddCompany = async (data, dispatch) => {
  const body = {}
  try {
    await dispatch(showLoader())

    await api.addCompany(data).then((res) => {
      body.status = res.status
      getCompanyList()
      return res
    })
    await dispatch(hideLoader())
    dispatch(showAlert(alert(200, 'company-add')))
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
    body.status = err.response.status
    body.descripttion = err.response.data.error_message
    return body
  }
}
export { getCompanyList, checkAvalibleAddCompany, AddCompany }
