import React, { Fragment } from 'react'

// Images
import bannerDownload from 'assets/images/signup/banner_download.png'
import applicationDetail from 'assets/images/signup/myisuzu_application_detail.png'
import applicationDetailMobile from 'assets/images/signup/myisuzu_application_detail_mobile.png'
import backgroundPainting from 'assets/images/signup/background_painting.png'

// Router
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Methods
import { openAppStoreOrPlayStore } from 'utils/helper'

// Styles
import './_index.scss'

const Signup = () => {
  const isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classnames('signup', isMobile() && 'signup--mobile')}>
      <div className='signup--content-top'>
        <div className='signup--title'>my-ISUZU ย้ายไปเป็น{isMobile() && <br />}โมบายแอปพลิเคชันแล้ว</div>
        <div className='signup--separate'>
          <div className='signup--short-line-red'></div>
        </div>
        <div className='signup--description'>
          ดาวน์โหลดได้ที่ App Store หรือ Google Play <br /> และลงทะเบียนเพื่อเช็กสิทธิประโยชน์ผ่านแอปฯ
        </div>
        {isMobile() ? (
          <span
            className='signup--click-to-download'
            onClick={() => {
              openAppStoreOrPlayStore()
            }}
          >
            คลิกเลย
          </span>
        ) : (
          <img className='signup--img-banner' src={bannerDownload} alt='Banner Download' />
        )}
      </div>
      <div className='signup--content-bottom'>
        {isMobile() ? (
          <img
            className='signup--img-detail-mobile'
            src={applicationDetailMobile}
            alt='Application Detail'
            onClick={() => {
              openAppStoreOrPlayStore()
            }}
          />
        ) : (
          <Fragment>
            <div className='signup--background-full-width'>
              <div className='signup--background-painting'>
                <img src={backgroundPainting} alt='background' />
                <img src={backgroundPainting} className='signup--img-rotate-180-deg' alt='background' />
              </div>
            </div>
            <img className='signup--img-detail' src={applicationDetail} alt='Application Detail' />
          </Fragment>
        )}
      </div>
    </div>
  )
}

Signup.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
}

export default withRouter(Signup)
