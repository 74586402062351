import React from 'react'

const RepairDetailHeader = (props) => {
  return (
    <div className='repair-detail__header'>
      <span>{props.RepairDetail.date_in}</span>
    </div>
  )
}

export default RepairDetailHeader
