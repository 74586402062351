import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import queryString from 'query-string'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import Select from 'components/elements/Select'
import QRCode from 'qrcode.react'
import DealerAppoint from 'domain/DealerSearch/components/DealerAppointment'
import ReactModal from 'react-modal'
import Checkbox from 'components/elements/Checkbox'

// Method
import _filter from 'lodash/filter'
import { checkLanguage } from 'utils/languages'
import _sortBy from 'lodash/sortBy'
import { formatTime } from 'utils/format'
import Cookies from 'js-cookie'
import { getAssets, checkError } from 'utils/helper'

// Actions
import { getDealers, getDealerDetail } from 'store/actions/dealers'
import { getProfileServiceCenter } from 'store/actions/profile'
import { getVehicleServiceCenter } from 'store/actions/vehicles'
import { logUserChatInteraction } from 'store/actions/chat'

// Styles
import './_index.scss'

const SearchDealer = (props) => {
  const modalRef = useRef()
  const [province, _setProvince] = useState('')
  const [district, _setDistrict] = useState('')
  const [center, setCenter] = useState('')
  const [centerDetail, setCenterDetail] = useState('')
  const [prevLocation, setPrevLocation] = useState('home')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBP, setIsBP] = useState(false)
  const [lineDealerName, setLineDealerName] = useState('')
  const [lineName, setLineName] = useState('')
  const [lineId, setLineId] = useState('')
  const [centerCode, setCenterCode] = useState('')
  const [startGetDealerDetail, setStartGetDealerDetail] = useState(true)

  const dealers = useSelector((state) => state.dealers)
  const query = queryString.parse(window.location.search)

  useEffect(() => {
    if (startGetDealerDetail) {
      props
        .getDealerDetail(query.c)
        .then(() => setStartGetDealerDetail(false))
        .catch((err) => {
          checkError(err)
        })
    }
    if (window.previousLocation === `/vehicle/details`) {
      getServiceCenter(true, false)
      setPrevLocation('vehicleDetail')
    } else if (window.previousLocation === `/isp`) {
      getServiceCenter(true, true)
      setPrevLocation('isp')
    } else {
      getServiceCenter(false, false)
      setPrevLocation('home')
    }
  }, [])

  useEffect(() => {
    if (query.c) {
      if (dealers) {
        setIsModalOpen(true)
        setLineDealerName((dealers.data && dealers.data.name) || '')
        setLineName((dealers.data && dealers.data.name) || '')
        setLineId((dealers.data && dealers.data.line_id) || 'isuzuthai')
        setCenterCode((dealers.data && dealers.data.code) || '-')
      }
    }
  }, [dealers])

  const getServiceCenter = (vehiclePage, isp) => {
    if (vehiclePage) {
      const ispId = Cookies.get('ispId') ? Cookies.get('ispId') : props.vehicle.id
      const vehicleId = isp ? ispId : props.vehicle.id
      props
        .getVehicleServiceCenter(vehicleId)
        .then((res) => {
          setCenter(res.data)
        })
        .catch((err) => {
          checkError(err)
        })
    } else {
      if (query.c) {
        setCenter(dealers)
      } else {
        props
          .getProfileServiceCenter()
          .then((res) => {
            setCenter(res.data)
          })
          .catch((err) => {
            checkError(err)
          })
      }
    }
  }

  const lineClick = (message) => {
    props.logUserChatInteraction(centerDetail.dealer && centerDetail.dealer.name)
    window.open(message)
  }

  // const closeModal = () => modalRef.current.closeModal()

  const openCenter = (detail) => {
    setCenterDetail(detail)
    modalRef.current.openModal()
  }

  const openCenterAppoint = (detail) => {
    setCenterDetail(detail)
    setIsModalOpen(true)
  }

  const openAppointment = () => {
    setIsModalOpen(true)
  }

  const closeAppointModal = () => {
    setIsModalOpen(false)
  }

  const toggleCheckbox = () => {
    setIsBP(!isBP)
  }

  const lang = checkLanguage()
  const provinceOptions =
    !!props.province && props.province.length > 0
      ? props.province.map((province) => ({
          label: lang === 'th' ? province.name_th : province.name_en,
          value: lang === 'th' ? province.name_th : province.name_en
        }))
      : []

  const districtOptions =
    !!props.district && props.district.length > 0
      ? _filter(props.district, (d) => {
          if (lang === 'th') {
            return d.province_name_th === province.value
          } else {
            return d.province_name_en === province.value
          }
        }).map((district) => ({
          label: lang === 'th' ? district.name_th : district.name_en,
          value: lang === 'th' ? district.name_th : district.name_en
        }))
      : []

  const provinceBPOptions =
    !!props.provinceBP && props.provinceBP.length > 0
      ? props.provinceBP.map((province) => ({
          label: checkLanguage() === 'th' ? province.province_name_th : province.province_name_en,
          value: checkLanguage() === 'th' ? province.province_name_th : province.province_name_en
        }))
      : []

  const districtBPOptions =
    !!props.districtBP && props.districtBP.length > 0
      ? _filter(props.districtBP, (d) => {
          if (lang === 'th') {
            return d.province_name_th === province.value
          } else {
            return d.province_name_en === province.value
          }
        }).map((district) => ({
          label: lang === 'th' ? district.district_name_th : district.district_name_en,
          value: lang === 'th' ? district.district_name_th : district.district_name_en
        }))
      : []

  const setProvince = (province) => {
    _setProvince(province)
    _setDistrict('')
  }

  const setDistrict = (district) => {
    _setDistrict(district)
  }

  const searchDealer = () => {
    props
      .getDealers(province.value, district.value, isBP)
      .then((res) => {
        const sorted = _sortBy(res, /*istanbul ignore next*/ (o) => o.dealer.code)
        setCenter(sorted)
      })
      .catch((err) => {
        checkError(err)
      })
  }

  //line contents
  const modalTitle = (centerDetail && centerDetail.dealer.name) + ' - ' + centerDetail.name
  const given_name = props.profile && props.profile.given_name
  const license = props.vehicleDetail && props.vehicleDetail.license_plate
  const branchName = centerDetail && centerDetail.name
  const delaerName = centerDetail && centerDetail.dealer.name

  //preset messages
  const ispMessage = 'คุณ ' + given_name + ' ' + license + ' สนใจรายละเอียด ISP จากศูนย์บริการ (' + branchName + ') ของท่าน'
  const vehicleMessage = 'คุณ ' + given_name + ' ' + license + ' สนใจนัดหมายที่ศูนย์บริการ (' + branchName + ') ของท่าน'
  const homeMessage = 'คุณ ' + given_name + ' ต้องการสอบถามข้อมูลจาก ' + delaerName + ' (' + branchName + ')'

  const lineOAMessage =
    centerDetail.line_oa_link +
    '/?' +
    encodeURI(prevLocation === 'isp' ? ispMessage : prevLocation === 'vehicleDetail' ? vehicleMessage : homeMessage)

  const lineAddFriend = centerDetail.line_id ? `https://line.me/R/ti/p/@${centerDetail.line_id}` : `https://line.me/R/ti/p/@isuzuthai`

  const modalAppointment = (
    <div>
      <div className='appointment-header'>
        <div className='modal__header__text appointment'>
          <p>
            <FormattedMessage id='dealerAppointment.request' defaultMessage='dealerAppointment.request' />
          </p>
          {centerDetail && centerDetail.dealer.name}
          <br />
          {centerDetail && `(${centerDetail.name})`}
          {query.c && lineDealerName && lineDealerName}
          <br />
          {query.c && lineName && `(${lineName})`}
        </div>
        <Button iconOnly type='button-close' icon='clear' onClick={closeAppointModal} />
      </div>
      <div>
        <DealerAppoint
          branchName={lineName ? lineName : centerDetail.name}
          line={lineId ? lineId : centerDetail.line_id}
          vehicleDetail={props.vehicleDetail}
          serviceCenter={centerCode !== '' ? centerCode : centerDetail.code}
        />
      </div>
    </div>
  )

  const modalContent = (
    <div className='dealer-modal'>
      <div className='dealer-modal__contacts'>
        <div className='dealer-modal__contacts__qr'>
          <FormattedMessage id='dealerSearch.instructionLabel' defaultMessage='dealerSearch.instructionLabel' />

          <div className='dealer-modal__contacts__qr__wrapper'>
            <QRCode level='L' size={128} value={lineOAMessage} />
            <div className='dealer-modal__contacts__qr__separator'>
              <div className='dealer-modal__contacts__qr__separator--button' />
            </div>
            <div className='dealer-modal__contacts__qr__label'>
              <Button type='line' label='dealerSearch.chatButton' onClick={() => lineClick(lineAddFriend)} />
            </div>
          </div>
        </div>
      </div>

      <div className='dealer-modal__desktop-phone'>
        <FormattedMessage id={'dealerSearch.phone'} defaultMessage={'dealerSearch.phone'} />: {centerDetail.appointment_phone_no_from}
      </div>
      <div className='dealer-modal__hours'>
        <div className='dealer-modal__hours__header'>
          <FormattedMessage id={'dealerSearch.hours'} defaultMessage={'dealerSearch.hours'} />
        </div>
        <div className='dealer-modal__hours__days'>
          <FormattedMessage id={'dealerSearch.mtf'} defaultMessage={'dealerSearch.mtf'} />
          {
            /*istanbul ignore next*/ (centerDetail.monday_friday_start_time === null ||
              centerDetail.monday_friday_start_time === '00:00:00') &&
            (centerDetail.monday_friday_end_time === null || centerDetail.monday_friday_end_time === '00:00:00') ? (
              <div className='dealer-modal__hours__days--closed'>
                <FormattedMessage id={'dealerSearch.closed'} defaultMessage={'dealerSearch.closed'} />
              </div>
            ) : (
              <span>
                {formatTime(centerDetail.monday_friday_start_time) || ''} - {formatTime(centerDetail.monday_friday_end_time) || ''}
              </span>
            )
          }
        </div>
        <div className='dealer-modal__hours__days'>
          <FormattedMessage id={'dealerSearch.sat'} defaultMessage={'dealerSearch.sat'} />
          {
            /*istanbul ignore next*/ (centerDetail.saturday_start_time === null || centerDetail.saturday_start_time === '00:00:00') &&
            (centerDetail.saturday_end_time === null || centerDetail.saturday_end_time === '00:00:00') ? (
              <div className='dealer-modal__hours__days--closed'>
                <FormattedMessage id={'dealerSearch.closed'} defaultMessage={'dealerSearch.closed'} />
              </div>
            ) : (
              <span>
                {formatTime(centerDetail.saturday_start_time) || ''} - {formatTime(centerDetail.saturday_end_time) || ''}
              </span>
            )
          }
        </div>
        <div className='dealer-modal__hours__days'>
          <FormattedMessage id={'dealerSearch.sun'} defaultMessage={'dealerSearch.sun'} />
          {
            /*istanbul ignore next*/ (centerDetail.sunday_start_time === null || centerDetail.sunday_start_time === '00:00:00') &&
            (centerDetail.sunday_end_time === null || centerDetail.sunday_end_time === '00:00:00') ? (
              <div className='dealer-modal__hours__days--closed'>
                <FormattedMessage id={'dealerSearch.closed'} defaultMessage={'dealerSearch.closed'} />
              </div>
            ) : (
              <span>
                {formatTime(centerDetail.sunday_start_time) || ''} - {formatTime(centerDetail.sunday_end_time) || ''}
              </span>
            )
          }
        </div>
      </div>
      <div>
        <div className='dealer-modal__contacts for-mobile'>
          {centerDetail.bp_service_center && centerDetail.bp_service_center.is_bp_only ? (
            <div className='bp-only'>
              <a href={'tel:' + centerDetail.appointment_phone_no_from}>
                <img src={getAssets(3, 3, 'btn_bp', 'search_dealer_detail')} alt='' />
              </a>
            </div>
          ) : (
            <div>
              {' '}
              {!centerDetail.is_bp && !centerDetail.bp_service_center ? (
                <div className='exclude-bp'>
                  <img
                    className='contact-img'
                    src={getAssets(3, 3, 'btn_appointment', 'search_dealer_detail')}
                    onClick={() => {
                      openAppointment()
                    }}
                    alt=''
                  />
                  <a href={'tel:' + centerDetail.appointment_phone_no_from}>
                    <img src={getAssets(3, 3, 'btn_call', 'search_dealer_detail')} alt='' />
                  </a>
                  <img
                    src={getAssets(3, 3, 'btn_chat', 'search_dealer_detail')}
                    onClick={() => {
                      lineClick(lineAddFriend)
                    }}
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <div className='contact-top'>
                    <img
                      className='contact-img'
                      src={getAssets(3, 3, 'btn_appointment', 'search_dealer_detail')}
                      onClick={() => {
                        openAppointment()
                      }}
                      alt=''
                    />
                    <a href={'tel:' + centerDetail.appointment_phone_no_from}>
                      <img src={getAssets(3, 3, 'btn_call', 'search_dealer_detail')} alt='' />
                    </a>
                  </div>
                  <div className='contact-bottom'>
                    <img
                      className='contact-bottom__left'
                      src={getAssets(3, 3, 'btn_chat', 'search_dealer_detail')}
                      onClick={() => {
                        lineClick(lineAddFriend)
                      }}
                      alt=''
                    />
                    <a href={`tel:${centerDetail.bp_service_center && centerDetail.bp_service_center.appointment_phone_no_from}`}>
                      <img src={getAssets(3, 3, 'btn_bp', 'search_dealer_detail')} alt='' />
                    </a>
                  </div>
                </div>
              )}{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <div className='search-dealer'>
      {window.previousLocation === '/vehicle-list' ||
      window.previousLocation === '/vehicle-detail' ||
      window.location.search === '?l=true' ||
      query.ap === 'true' ? (
        <ReactModal
          isOpen={isModalOpen}
          overlayClassName='modal-appointment'
          contentClassName='modal-appointment-content'
          style={{
            content: {
              top: '10px',
              left: '10px',
              right: '10px',
              bottom: 'none',
              overflow: 'inherit'
            }
          }}
        >
          {modalAppointment}
        </ReactModal>
      ) : (
        <Modal mode={9} inputTitle={modalTitle} content={modalContent} ref={modalRef}>
          <Button
            type='add-new'
            label='dealerSearch.appointment'
            onClick={() => {
              openAppointment()
            }}
          />
        </Modal>
      )}
      {isModalOpen && (
        <ReactModal
          isOpen={isModalOpen}
          overlayClassName='modal-appointment'
          style={{
            content: {
              top: '10px',
              left: '10px',
              right: '10px',
              bottom: 'none',
              overflow: 'inherit'
            }
          }}
        >
          {modalAppointment}
        </ReactModal>
      )}
      <div className='search-dealer__selection'>
        <FormattedMessage id='dealerSearch.header' defaultMessage='dealerSearch.header' />
        <div className='search-dealer__selection__checkbox'>
          <Checkbox checked={isBP} onClick={toggleCheckbox} />
          <FormattedMessage id='dealerSearch.bodyPaint' defaultMessage='dealerSearch.bodyPaint' />
        </div>
        <div className='search-dealer__selection__dropdown'>
          <Select
            id='select-province'
            options={isBP ? provinceBPOptions : provinceOptions}
            placeholder='dealerSearch.province'
            onChange={setProvince}
            noMapping
          />
          <Select
            id='select-district'
            options={isBP ? districtBPOptions : districtOptions}
            value={district.value}
            placeholder='dealerSearch.district'
            onChange={setDistrict}
            noMapping
          />
        </div>
        <Button
          className='dealer-search-btn'
          label='dealerSearch.search'
          color='primary-shadow'
          icon='search'
          disabled={!district.value}
          onClick={searchDealer}
        />
      </div>

      {/* {center && center.length === 0 &&
              <div className="search-dealer__result">
                No Dealers Found
              </div>
            } */}

      {center &&
        center.length > 0 &&
        center.map((p, index) => {
          return (
            <div
              key={index}
              className='search-dealer__result'
              onClick={() => {
                window.previousLocation === '/vehicle-list' ||
                window.previousLocation === '/vehicle-detail' ||
                query.ap === 'true' ||
                window.location.search === '?l=true'
                  ? openCenterAppoint(p)
                  : openCenter(p)
              }}
            >
              <div>
                <strong>{p.dealer.name}</strong> - {p.name}
              </div>
            </div>
          )
        })}
    </div>
  )
}

SearchDealer.propTypes = {
  getProfileServiceCenter: PropTypes.func,
  getVehicleServiceCenter: PropTypes.func
}

SearchDealer.defaultProps = {}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  dealerList: state.dealers,
  profile: state.profile,
  vehicleDetail: state.vehicleDetail && state.vehicleDetail.vehicle,
  vehicle: state.vehicleDetail
})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      getDealers,
      getDealerDetail,
      getProfileServiceCenter,
      logUserChatInteraction,
      getVehicleServiceCenter
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchDealer))
