import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Methods
import { ispDateFormat, showNumber } from 'utils/format'
import { checkError } from 'utils/helper'
import api from 'api/'

// Components
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'

// Constants
import { USER_TYPE, activityTypes, ISP_STATUS } from 'config/constant'

// Actions
import { cancelRegistration, getISPDetail, autoCancel } from 'store/actions/ispDetail'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Router
import { withRouter } from 'react-router-dom'

// Proptypes
import PropTypes from 'prop-types'

// CSS
import './index.scss'

const ISPStatus = ({ history, profile, ispDetail, cancelRegistration, autoCancel }) => {
  const modalRef = useRef()
  const [user_type, setUserType] = useState()

  useEffect(() => {
    setUserType(profile.type === 1 ? 'บุคคลทั่วไป' : profile.type === 2 ? 'นิติบุคคล' : 'ผู้ดูแลรถ')
  })
  const cancel = async () => {
    const payload = {
      application_no: ispDetail.application_no_myi
    }
    try {
      await cancelRegistration(payload, ispDetail.hash_id)
      history.push(`/isp/${ispDetail.hash_id}`)
    } catch (err) {
      checkError(err)
    }
  }

  const _autoCancel = async () => {
    try {
      const payload = {
        application_no: ispDetail.application_no_myi
      }
      api.logActivities({
        type: activityTypes.ISP,
        description:
          `รับทราบการยกเลิก ISP|${ispDetail.vin ? ispDetail.vin : ''}|` +
          `${ispDetail.license_plate ? ispDetail.license_plate : ''}|` +
          `${ispDetail.register_description ? ispDetail.register_description : ''}|` +
          `${ispDetail.isp_price ? ispDetail.isp_price : ''}|` +
          `${user_type}|${ispDetail.application_no_sap ? ispDetail.application_no_sap : ''}`
      })
      await autoCancel(payload, ispDetail.hash_id)
      history.push(`/isp/${ispDetail.hash_id}`)
    } catch (err) {
      checkError(err)
    }
  }

  let today = new Date()
  let before_expire = new Date(ispDetail.payment_expiry_date)
  before_expire.setDate(before_expire.getDate() - 7)

  const onProcess = (
    <div>
      <Modal mode={2} title='ispPackage.cancel.title' content='ispPackage.cancel.content' ref={modalRef} onConfirmed={cancel} />
      {(today === before_expire || today > before_expire) && (
        <div className='isp-status__header'>
          <FormattedMessage id='ispPackage.registration.expiry' defaultMessage='ispPackage.registration.expiry' />
        </div>
      )}
      <div className='isp-status__header'>
        <FormattedHTMLMessage id='ispPackage.registration.title' defaultMessage='ispPackage.registration.title' />
      </div>

      <img src={ispDetail.on_process_image_url && ispDetail.on_process_image_url} alt='' />
      <FormattedHTMLMessage
        id='ispPackage.registration.packageDetail'
        values={{
          years: ispDetail.registered_isp_year,
          mileages: showNumber(ispDetail.registered_isp_mileage),
          price: showNumber(parseInt(ispDetail.isp_price))
        }}
        defaultMessage='ispPackage.registration.packageDetail'
      />
      {ispDetail.status === ISP_STATUS.IT10_DRAFT && ispDetail.application_no_sap && (
        <FormattedHTMLMessage
          id='ispPackage.registration.documentNo'
          values={{
            num: ispDetail.application_no_sap
          }}
          defaultMessage='ispPackage.registration.documentNo'
        />
      )}

      <FormattedHTMLMessage
        id='ispPackage.registration.vinNo'
        values={{
          vin: ispDetail.vin
        }}
        defaultMessage='ispPackage.registration.vinNo'
      />
      <FormattedHTMLMessage
        id='ispPackage.registration.detail'
        values={{
          license: ispDetail.license_plate,
          date: ispDateFormat(ispDetail.payment_expiry_date)
        }}
        defaultMessage='ispPackage.registration.paymentNew'
      />
      <a href='tel:+029662199'>02-966-2199</a>
      {profile.type !== USER_TYPE.CONTROLLER && profile.id === ispDetail.register_user_hash_id && (
        <div className='isp-status__button'>
          <Button
            type='submit'
            color='primary'
            label='ispPackage.button.cancel'
            onClick={() => {
              modalRef.current.openModal()
              api.logActivities({
                type: activityTypes.ISP,
                description:
                  `ยกเลิกการสมัคร ISP|${ispDetail.vin ? ispDetail.vin : ''}|` +
                  `${ispDetail.license_plate ? ispDetail.license_plate : ''}|` +
                  `${ispDetail.register_description ? ispDetail.register_description : ''}|` +
                  `${ispDetail.isp_price ? ispDetail.isp_price : ''}|` +
                  `${user_type}|${ispDetail.application_no_sap ? ispDetail.application_no_sap : ''}`
              })
            }}
          />
        </div>
      )}
    </div>
  )

  const autoCancelComponent = (
    <div className='auto-cancel'>
      <FormattedHTMLMessage id='ispPackage.autoCancel.content' defaultMessage='ispPackage.autoCancel.content' />
      {profile.type === USER_TYPE.CORPORATE && (
        <span className='auto-cancel__btn' onClick={_autoCancel}>
          <FormattedMessage id='ispPackage.button.click' defaultMessage='ispPackage.button.click' />
        </span>
      )}
      {profile.type === USER_TYPE.INDIVIDUAL && !ispDetail.is_corporate_vehicle && (
        <span className='auto-cancel__btn' onClick={_autoCancel}>
          <FormattedMessage id='ispPackage.button.click' defaultMessage='ispPackage.button.click' />
        </span>
      )}
    </div>
  )

  const ispSubmitted = (
    <div>
      <div className='isp-status__header'>
        <FormattedMessage id='ispPackage.registration.submitted' defaultMessage='ispPackage.registration.submitted' />
      </div>
      <img src={ispDetail.on_process_image_url && ispDetail.on_process_image_url} alt='' />
      <FormattedMessage id='ispPackage.registration.issueRemark' defaultMessage='ispPackage.registration.issueRemark' />
      <a href='tel:+029662020'>02-966-2020</a>
    </div>
  )

  return (
    <div className='isp-status'>
      <div id='on-process'>
        {(ispDetail.status === ISP_STATUS.MYI_DRAFT ||
          ispDetail.status === ISP_STATUS.SENT_DRAFT_TO_IT10 ||
          ispDetail.status === ISP_STATUS.SENT_DRAFT_ERROR ||
          ispDetail.status === ISP_STATUS.CREATE_DRAFT_ERROR ||
          ispDetail.status === ISP_STATUS.IT10_DRAFT) && <div>{onProcess}</div>}
        {ispDetail.status === ISP_STATUS.APPLICATION_AUTO_CANCEL && <div>{autoCancelComponent}</div>}
        {ispDetail.status === ISP_STATUS.APPLICATION_SUBMITTED && <div>{ispSubmitted}</div>}
      </div>
    </div>
  )
}

ISPStatus.propTypes = {
  cancelRegistration: PropTypes.func,
  getISPDetail: PropTypes.func,
  autoCancel: PropTypes.func
}

export const mapStateToProps = (state) => ({
  ispDetail: state.ispDetail,
  ispVehicle: state.ispVehicle,
  initialValues: {
    vin: state.ispDetail.vin,
    license_plate: state.ispDetail.license_plate
  },
  lang: state.config.lang
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        cancelRegistration,
        getISPDetail,
        autoCancel
      },
      dispatch
    )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ISPStatus))
