import React, { useEffect } from 'react'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import InputSearch from 'components/elements/InputSearch'
import Button from 'components/elements/Button'
import Select from 'components/elements/Select'

// Constant
import {
  controllerOptions,
  controllerStatusOptions,
  couponOptions,
  vehicleOptions,
  vehicleTypeOptions,
  controllerActivationStatusOptions,
  controllerAssignmentStatusOptions
} from 'config/constant'

// Actions
import {
  setKeyword as setKeywordVehicles,
  setOrderBy as setOrderByVehicles,
  setType as setTypeVehicles,
  setController as setControllerVehicles,
  toggleSortBy as toggleSortByVehicles,
  toggleView
} from 'store/actions/filterVehicles'

import {
  setKeyword as setKeywordController,
  setOrderBy as setOrderByController,
  toggleSortBy as toggleSortByController,
  setStatus as setControllerStatus,
  setAssignment as setControllerAssignment
} from 'store/actions/filterController'

import {
  setKeyword as setKeywordCoupons,
  setOrderBy as setOrderByCoupons,
  toggleSortBy as toggleSortByCoupons
} from 'store/actions/filterCoupons'

import { getControllersList } from 'store/actions/controllers'

// Constants
import { USER_TYPE } from 'config/constant'

// Styles
import './_index.scss'

const Filter = ({
  isList,
  type,
  userType,

  // Vehicles
  vehicleKeyword,
  vehicleOrderBy,
  vehicleSortBy,
  vehicleType,
  vehicleController,
  setTypeVehicles,
  setControllerVehicles,
  setKeywordVehicles,
  setOrderByVehicles,
  toggleSortByVehicles,
  toggleView,

  // Controllers
  controllers,
  controllerKeyword,
  controllerOrderBy,
  controllerSortBy,
  controllerStatus,
  controllerAssignment,

  setKeywordController,
  setOrderByController,
  toggleSortByController,
  setControllerStatus,
  setControllerAssignment,

  // Coupons
  couponKeyword,
  couponOrderBy,
  couponSortBy,
  toggleSortByCoupons,
  setKeywordCoupons,
  setOrderByCoupons,

  getControllersList
}) => {
  const handleChangeKeyword = (payload) => {
    if (type === 'vehicles') setKeywordVehicles(payload)
    if (type === 'controllers') setKeywordController(payload)
    if (type === 'coupons') setKeywordCoupons(payload)
  }

  const handleSortBy = () => {
    if (type === 'vehicles') toggleSortByVehicles()
    if (type === 'controllers') toggleSortByController()
    if (type === 'coupons') toggleSortByCoupons()
  }

  const handleOrderBy = (orderBy) => {
    if (type === 'vehicles') setOrderByVehicles(orderBy.value)
    if (type === 'controllers') setOrderByController(orderBy.value)
    if (type === 'coupons') setOrderByCoupons(orderBy.value)
  }

  useEffect(() => {
    if (type === 'vehicles' && getControllersList && userType === USER_TYPE.CORPORATE) {
      getControllersList()
    }
  }, [getControllersList, type, userType])

  let filterBar

  const classes = classnames('filter', `filter-${type}`)

  const mergedControllerOptions = [
    ...controllerStatusOptions,
    ...controllers.map(({ full_name, id }) => ({
      label: full_name,
      value: id
    }))
  ]

  switch (type) {
    case 'vehicles':
      filterBar = (
        <div className={classes}>
          <InputSearch value={vehicleKeyword} onChange={handleChangeKeyword} />
          {(userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) && (
            <Select
              id='select-types'
              className='select__types'
              prefixIcon='filter_list'
              options={vehicleTypeOptions}
              value={vehicleType}
              onChange={({ value }) => setTypeVehicles(value)}
              noMapping
            />
          )}
          {userType === USER_TYPE.CORPORATE && (
            <Select
              id='select-controllers'
              className='select__controllers'
              prefixIcon='filter_list'
              options={mergedControllerOptions}
              value={vehicleController}
              onChange={({ value }) => setControllerVehicles(value)}
              noMapping
            />
          )}
          <Select
            id='select-sortby'
            placeholder='common.placeholders.selectOrderBy'
            prefixIcon='sort'
            options={vehicleOptions}
            value={vehicleOrderBy || null}
            onChange={handleOrderBy}
            onIconClick={handleSortBy}
            isFlipIcon={!vehicleSortBy}
            noMapping
          />
          <Button
            className='change-view'
            color='transparent'
            type='small'
            icon={isList ? 'view_module' : 'list'}
            label={isList ? 'common.labels.gridView' : 'common.labels.listView'}
            onClick={toggleView}
          />
          <Button className='change-view-sm' type='small' iconOnly icon={isList ? 'view_module' : 'list'} onClick={toggleView} />
        </div>
      )
      break

    case 'controllers':
      filterBar = (
        <div className={classes}>
          <InputSearch value={controllerKeyword} onChange={handleChangeKeyword} />
          <Select
            id='select-activation-status'
            className='select__controllers'
            prefixIcon='filter_list'
            options={controllerActivationStatusOptions}
            value={controllerStatus || null}
            onChange={({ value }) => setControllerStatus(value)}
            noMapping
          />
          <Select
            id='select-assignment-status'
            className='select__controllers'
            prefixIcon='filter_list'
            options={controllerAssignmentStatusOptions}
            value={controllerAssignment || null}
            onChange={({ value }) => setControllerAssignment(value)}
            noMapping
          />
          <Select
            id='select-sortby'
            prefixIcon='sort'
            options={controllerOptions}
            value={controllerOrderBy.toString() || null}
            placeholder='common.placeholders.selectOrderBy'
            onChange={handleOrderBy}
            onIconClick={handleSortBy}
            isFlipIcon={!controllerSortBy}
            noMapping
          />
        </div>
      )
      break

    case 'coupons':
      filterBar = (
        <div className={classes}>
          <InputSearch value={couponKeyword} onChange={handleChangeKeyword} />
          <Select
            id='select-sortby'
            prefixIcon={couponOrderBy !== 31 ? 'sort' : ''}
            options={couponOptions}
            value={couponOrderBy || null}
            placeholder='common.placeholders.selectOrderBy'
            onChange={handleOrderBy}
            onIconClick={handleSortBy}
            isFlipIcon={!couponSortBy}
            noMapping
          />
        </div>
      )
      break
    default:
      filterBar = null
  }

  return filterBar
}

const mapStateToProps /* istanbul ignore next */ = (state) => ({
  vehicleKeyword: state.filterVehicles.keyword,
  vehicleOrderBy: state.filterVehicles.orderBy,
  vehicleSortBy: state.filterVehicles.sortBy,
  vehicleType: state.filterVehicles.type,
  vehicleController: state.filterVehicles.controller,
  isList: state.filterVehicles.isList,

  controllerKeyword: state.filterController.keyword,
  controllerOrderBy: state.filterController.orderBy,
  controllerSortBy: state.filterController.sortBy,
  controllerStatus: state.filterController.status,
  controllerAssignment: state.filterController.assignment,

  couponKeyword: state.filterCoupons.keyword,
  couponOrderBy: state.filterCoupons.orderBy,
  couponSortBy: state.filterCoupons.sortBy,

  controllers: state.controllersList.data,
  userType: state.profile.type
})

const mapDispatchToProps /* istanbul ignore next */ = (dispatch) =>
  bindActionCreators(
    {
      setKeywordVehicles,
      setOrderByVehicles,
      setTypeVehicles,
      setControllerVehicles,
      toggleSortByVehicles,
      toggleView,

      toggleSortByController,
      setKeywordController,
      setOrderByController,
      setControllerStatus,
      setControllerAssignment,

      toggleSortByCoupons,
      setKeywordCoupons,
      setOrderByCoupons,

      getControllersList
    },
    dispatch
  )

Filter.propTypes = {
  type: PropTypes.string,
  setTypeVehicles: PropTypes.func,
  setControllerVehicles: PropTypes.func,
  setKeywordVehicles: PropTypes.func,
  setKeywordController: PropTypes.func,
  setKeywordCoupons: PropTypes.func,
  setOrderByVehicles: PropTypes.func,
  setOrderByCoupons: PropTypes.func,
  setOrderByController: PropTypes.func,
  toggleSortByVehicles: PropTypes.func,
  toggleSortByCoupons: PropTypes.func,
  toggleSortByController: PropTypes.func,
  vehicleKeyword: PropTypes.string,
  vehicleSortBy: PropTypes.number,
  vehicleOrderBy: PropTypes.number,
  couponKeyword: PropTypes.string,
  couponSortBy: PropTypes.number,
  couponOrderBy: PropTypes.number,
  controllerKeyword: PropTypes.string,
  controllerSortBy: PropTypes.number,
  controllerOrderBy: PropTypes.number,
  setControllerStatus: PropTypes.func,
  setControllerAssignment: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
