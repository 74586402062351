import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Button from 'components/elements/Button'

import moment from 'moment'
import 'moment/locale/th'

import './_index.scss'

import * as helper from 'utils/helper'

const lineContact = (message) => {
  window.open(`https://line.me/R/oaMessage/@truck2hand/?${message}`)
}

const linkToT2H = () => {
  window.open('https://www.truck2hand.com/')
}

const T2HDetail = () => {
  const profile = useSelector((state) => state.profile) || {}
  const vehicle = useSelector((state) => state.vehicleDetail) || {}
  const [encode, setEncode] = useState('')

  let presetMsg = ''
  let license_plate
  let vin
  let model
  let year

  useEffect(() => {
    license_plate = vehicle.vehicle.license_plate
    vin = vehicle.vehicle.vin
    model = vehicle.vehicle.model.marketing_code
    year = moment(vehicle.vehicle.retail_sales_date).format('YYYY')

    presetMsg = `คุณ ${profile.full_name ? profile.full_name : ''} เจ้าของรถทะเบียน ${
      license_plate ? license_plate : ''
    } ต้องการสอบถามข้อมูลจาก Truck2Hand เพื่อลงขายรถ หรือติดต่อได้ที่หมายเลข ${profile.mobile_phone ? profile.mobile_phone : ''}
รายละเอียดเพิ่มเติม
เลขที่บัตร ปชช : ${profile.national_id ? profile.national_id : profile.passport_no}
เลข VIN : ${vin ? vin : ''}
รุ่นรถ : ${model ? model : ''}
ปีที่ซื้อ : ${year ? year : ''}`
    setEncode(encodeURI(presetMsg))
  }, [vehicle.vehicle.vehicle_type])

  return (
    <div className='t2h-wrapper'>
      <img src={helper.getAssets(7, 3, 't2h_01', 't2h_information')} alt='' />
      <div className='box'>
        <Button
          type='add-new'
          className='line-t2h contact-with-t2h-line'
          id='contact-with-t2h-line'
          label='truck2Hand.button.contactLineT2H'
          onClick={(e) => {
            e.preventDefault()
            lineContact(encode)
          }}
        />
      </div>
      <img src={helper.getAssets(7, 3, 't2h_02', 't2h_information')} alt='' />
      <div className='box'>
        <Button
          type='add-new'
          className='contact-line'
          label='truck2Hand.button.contactLine'
          onClick={(e) => {
            e.preventDefault()
            lineContact(encode)
          }}
        />
      </div>
      <div>
        <img src={helper.getAssets(7, 3, 't2h_03', 't2h_information')} alt='' />
      </div>
      <div>
        <img src={helper.getAssets(7, 3, 't2h_04', 't2h_information')} alt='' />
      </div>
      <div>
        <Button
          type='add-new'
          className='line-bottom'
          label='truck2Hand.button.contactLine'
          onClick={(e) => {
            e.preventDefault()
            lineContact(encode)
          }}
        />
      </div>
      <div>
        <Button type='add-new' className='website' label='truck2Hand.button.website' onClick={linkToT2H} />
      </div>
    </div>
  )
}

export default T2HDetail
