import React, { useRef } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import PopupISPVehicle from 'domain/Isp/components/PopupISPVehicle'
import ISPAds from 'domain/Isp/components/ISPPackageAds'
import ISPStatus from 'domain/Isp/components/ISPStatus'
import ISPVehicleDetail from '../ISPVehicleDetail'

// Method
import * as helper from 'utils/helper'
import { ispPackageFormat, formatNumber, showIspDate } from 'utils/format'
import api from 'api/'

// Constants
import { USER_TYPE, activityTypes, ISP_STATUS } from 'config/constant'
import { vehicleModalIcon } from 'config/icon'

// Styles
import './_index.scss'

// lodash
import { get } from 'lodash'

const DetailIsp = ({ ispList, ispDetail, profile, location, history, detail }) => {
  const modalRef = useRef()

  const closeModal = () => modalRef.current.closeModal()

  const user_type = profile.type === 1 ? 'บุคคลทั่วไป' : profile.type === 2 ? 'นิติบุคคล' : 'ผู้ดูแลรถ'

  const allVehicles = Object.values(ispList.data)

  const now = new Date()
  const expire = new Date(ispDetail.isp_end_date)
  let next45Days = new Date(ispDetail.isp_approve_date)
  next45Days.setDate(next45Days.getDate() + 45)

  const isExpire = now > expire
  const coApprove = now > next45Days

  return (
    <div className='detail-isp'>
      <Modal
        mode={8}
        title={'privilege.vehicleModal'}
        content={
          <PopupISPVehicle vehicles={ispList} ispList={allVehicles} close={closeModal} isp={true} clickClose={true} ispDetailPage={true} />
        }
        ref={modalRef}
      />

      {ispList.total_count > 1 && (
        <Button
          className='isp-btn'
          icon={vehicleModalIcon}
          label='privilege.vehicleModal'
          type='vehicle'
          onClick={() => {
            modalRef.current.openModal()
          }}
        />
      )}
      <div>
        {ispList.total_count > 0 && (
          <div className='detail-isp-wrapper'>
            {(ispDetail.status === ISP_STATUS.MYI_CANCEL_DRAFT ||
              ispDetail.status === ISP_STATUS.SENT_CANCEL_TO_IT10 ||
              ispDetail.status === ISP_STATUS.APPLICATION_CANCELLED ||
              ispDetail.status === ISP_STATUS.SENT_CANCEL_ERROR ||
              ispDetail.status === ISP_STATUS.CANCEL_ERROR ||
              ispDetail.status === ISP_STATUS.APPLICATION_APPROVED ||
              ispDetail.status === null ||
              (ispDetail.status === ISP_STATUS.APPLICATION_AUTO_CANCEL && location.state === 'auto-cancel')) && (
              <div className='detail-isp__isp'>
                {ispDetail.isp_package_code !== null ? (
                  <div id='current-isp'>
                    <div className='detail-isp__isp__header'>
                      <img src={helper.getAssets(2, 2, 'isp_logo', 'isp_product_information')} alt='' />
                    </div>

                    <div className='detail-isp__isp__vehicle'>
                      <img src={ispDetail.vehicle_image} alt='' />
                      <div className='detail-isp__isp__vehicle__name'>
                        <FormattedMessage id='privilege.ispName' defaultMessage='privilege.ispName' />: {ispDetail.vehicle_name || '-'}
                      </div>
                      <div className='detail-isp__isp__vehicle__license'>
                        <FormattedMessage id='privilege.ispLicense' defaultMessage='privilege.ispLicense' />: {ispDetail.license_plate}
                      </div>
                      {profile.type !== USER_TYPE.INDIVIDUAL && !coApprove && (
                        <div className='detail-isp__isp__vehicle__remark'>
                          <FormattedHTMLMessage
                            id='ispPackage.vehicle.detail.coRemark'
                            defaultMessage='ispPackage.vehicle.detail.coRemark'
                          />
                        </div>
                      )}
                    </div>

                    <div className='detail-isp__isp__package'>
                      <div className='detail-isp__isp__package__detail'>
                        <div className='detail-isp__isp__package__detail__box'>
                          <div className='detail-isp__isp__package__detail__box pkg-header'>
                            <FormattedMessage id='privilege.warranty' defaultMessage='privilege.warranty' />
                          </div>
                          <div className='detail-isp__isp__package__detail__box pkg-text'>
                            {ispDetail && !isExpire ? (
                              ispPackageFormat(ispDetail.isp_year, ispDetail.isp_mileage)
                            ) : (
                              <FormattedHTMLMessage id='privilege.outOfWarranty' defaultMessage='privilege.outOfWarranty' />
                            )}
                          </div>
                        </div>
                      </div>
                      {!isExpire && (
                        <div className='detail-isp__isp__package__detail'>
                          <div className='detail-isp__isp__package__detail__box'>
                            <div className='detail-isp__isp__package__detail__box pkg-header'>
                              <FormattedHTMLMessage id='privilege.endOfWarranty' defaultMessage='privilege.endOfWarranty' />
                            </div>
                            <div className='pkg-text'>
                              <div>
                                <FormattedHTMLMessage
                                  id='privilege.warrantyFormat'
                                  defaultMessage='privilege.warrantyFormat'
                                  values={{
                                    date: showIspDate(ispDetail.isp_end_date, true),
                                    mileage: formatNumber(ispDetail.isp_mileage)
                                  }}
                                />
                              </div>
                              <div className='warranty-reamark'>
                                <FormattedMessage id='privilege.remark' defaultMessage='privilege.remark' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {ispDetail && ispDetail.is_in_isp ? (
                      <div className='detail-isp__isp__privilege'>
                        <img
                          src={helper.getAssets(
                            2,
                            3,
                            helper.getISPStatus(
                              ispDetail && ispDetail.isp_package_code ? ispDetail.isp_package_code : /*istanbul ignore next*/ undefined
                            ),
                            'isp_product_information'
                          )}
                          alt=''
                        />
                      </div>
                    ) : (
                      <div className='detail-isp__isp__expiry'>
                        <Button type='red' label='ispPackage.button.notValidCond' disabled />
                      </div>
                    )}

                    {(profile.type === USER_TYPE.CORPORATE || (profile.type === USER_TYPE.INDIVIDUAL && !ispDetail.is_corporate_vehicle)) &&
                      ispDetail.ISPAvailablePackage !== null && (
                        <div
                          className='detail-isp__isp__topup'
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push({
                              pathname: `/isp-selection/${ispDetail.hash_id}`,
                              state: { ispDetail: ispDetail }
                            })
                            api.logActivities({
                              type: activityTypes.ISP,
                              description: `เลือกรถเพื่อสมัคร ISP Top-up|${ispDetail.vin ? ispDetail.vin : ''}|${
                                ispDetail.license_plate ? ispDetail.license_plate : ''
                              }|||${user_type}|`
                            })
                          }}
                        >
                          <img src={get(ispDetail, 'ISPAvailablePackage.0.topup_image_url', '')} alt='' />
                        </div>
                      )}
                  </div>
                ) : (
                  <div>{detail.vehicle.id && <ISPVehicleDetail ispDetail={ispDetail} ispList={ispList} vehicle={detail.vehicle} />}</div>
                )}
              </div>
            )}
          </div>
        )}
        {ispList.total_count === 0 && <ISPAds ispDetail={ispDetail} ispList={ispList} />}
      </div>
      {ispDetail.status !== null &&
        ispDetail.status !== ISP_STATUS.MYI_CANCEL_DRAFT &&
        ispDetail.status !== ISP_STATUS.SENT_CANCEL_TO_IT10 &&
        ispDetail.status !== ISP_STATUS.APPLICATION_CANCELLED &&
        location.state !== 'auto-cancel' && (
          <div className='isp-package__isp'>
            <ISPStatus ispDetail={ispDetail} profile={profile} />
          </div>
        )}
    </div>
  )
}

const mapDispatchToProps =
  /*istanbul ignore next */
  (dispatch) => bindActionCreators({}, dispatch)

export default connect(null, mapDispatchToProps)(withRouter(DetailIsp))
