import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postPageView } from 'store/actions/profile'
import { isMobileApp } from 'utils/validation'

// Router
import { withRouter } from 'react-router-dom'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import { Form, Field, reduxForm } from 'redux-form'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import vinEng1 from 'assets/images/vin-eng-1.JPG'
import vinEng2 from 'assets/images/vin-eng-2.JPG'
import vinThai1 from 'assets/images/vin-thai-1.JPG'
import vinThai2 from 'assets/images/vin-thai-2.JPG'

// Actions
import { searchVehicleVin } from 'store/actions/vehicles'
import { findVehicle_vin, findVehicle_max17, findVehicle_alphanumeric } from 'utils/validation'
import { checkError } from 'utils/helper'

// Styles
import './_index.scss'
import { USER_TYPE } from 'config/constant'
import { EndOfSupportBanner } from 'components/elements/EndOfSupportBanner/EndOfSupportBanner'

const VehicleSearch = ({ history, lang, postPageView, vehicleSearch, handleSubmit, searchVehicleVin, profile }) => {
  const [isNotFound, setIsNotFound] = useState(false)
  const [errorMessageTitle, setErrorMessageTitle] = useState('vehicle.add.notFound.title')
  const [errorMessageDesc, setErrorMessageDesc] = useState('vehicle.add.notFound.desc')

  useEffect(() => {
    postPageView()
  })

  const search = () => {
    searchVehicleVin(vehicleSearch.values.vin)
      .then(() => {
        console.log('found')
        history.push('/vehicle/add')
      })
      .catch((data) => {
        setIsNotFound(true)
        const response = data.response.data
        switch (response.error_code) {
          case 20020:
            setErrorMessageTitle('vehicle.add.already.title')
            setErrorMessageDesc('vehicle.add.already.desc')
            break
          default:
            setErrorMessageTitle('vehicle.add.notFound.title')
            setErrorMessageDesc('vehicle.add.notFound.desc')
        }
        checkError(data)
      })
  }

  const vinContent = (
    <div className='how-to'>
      <p>
        <FormattedMessage id='createAccount.info.vin' defaultMessage='createAccount.info.vin' />
      </p>
      {lang === 'en' && (
        <div className='how-to__image'>
          <img src={vinEng1} alt='' />
          <img src={vinEng2} alt='' />
        </div>
      )}
      {lang === 'th' && (
        <div className='how-to__image'>
          <img src={vinThai1} alt='' />
          <img src={vinThai2} alt='' />
        </div>
      )}
    </div>
  )

  return (
    <div className='vehicle-search'>
      <div className='vehicle-search__header'>{!isMobileApp() && <DetailHeader showBack defaultPath='controllers' type='basic' />}</div>
      <div className='vehicle-search__body'>
        <div className='vehicle-search__body-wrapper'>
          <div className='vehicle-search__body__vin'>
            <label>
              <FormattedMessage id='createAccount.info.vinTitle' defaultMessage='createAccount.info.vinTitle' />
            </label>
            <p>
              <FormattedMessage id='createAccount.info.vin' defaultMessage='createAccount.info.vin' />
            </p>

            {lang === 'en' && (
              <div className='vehicle-search__body__vin__image'>
                <img src={vinEng1} alt='' />
                <img src={vinEng2} alt='' />
              </div>
            )}

            {lang === 'th' && (
              <div className='vehicle-search__body__vin__image'>
                <img src={vinThai1} alt='' />
                <img src={vinThai2} alt='' />
              </div>
            )}
          </div>

          {!isNotFound && (
            <Form onSubmit={handleSubmit(search)} className='vehicle-search__form'>
              <div className='vehicle-search__body'>
                <p>
                  <FormattedMessage id='vehicle.add.title' defaultMessage='vehicle.add.title' />
                </p>
                <Field
                  name='vin'
                  component={Input}
                  type='text'
                  label={'common.labels.vin'}
                  isRequired
                  isClearable
                  placeholder={'common.placeholders.vin'}
                  id='vin'
                  validate={[findVehicle_vin, findVehicle_max17, findVehicle_alphanumeric]}
                  vinTooltip
                  vinContent={vinContent}
                />

                <Button type='submit' color='primary' label='common.button.next' />
              </div>
            </Form>
          )}
          {isNotFound && (
            <div className='vehicle-search__form'>
              <div className='vehicle-search__body feedback'>
                <p>
                  <FormattedMessage id={errorMessageTitle} defaultMessage={errorMessageTitle} />
                </p>
                <div className='remark'>
                  <FormattedMessage id={errorMessageDesc} defaultMessage={errorMessageDesc} />
                </div>
                <Button color='transparent' label='vehicle.add.tryAgain' onClick={() => setIsNotFound(false)} />
              </div>
            </div>
          )}
          {profile.type === USER_TYPE.INDIVIDUAL && <EndOfSupportBanner />}
        </div>
      </div>
    </div>
  )
}

VehicleSearch.propTypes = {
  searchVehicleVin: PropTypes.func,
  vehicleSearch: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  lang: PropTypes.string,
  profile: PropTypes.object
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
  vehicleSearch: state.form.vehicleSearch,
  lang: state.config.lang,
  profile: state.profile
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        searchVehicleVin,
        postPageView
      },
      dispatch
    )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'vehicleSearch'
    })(VehicleSearch)
  )
)
