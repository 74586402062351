import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import RepairDetailHeader from './RepairDetailHeader'
import RepairDetailBody from './RepairDetailBody'
import RepairDetailBodyTop from './RepairDetailBody/RepairDetailBodyTop'
import RepairDetailBottom from './RepairDetailBottom'

// Actions
import { getRepairDetail } from 'store/actions/repairHistory'

// Styles
import './index.scss'

// Utils
import { checkError } from 'utils/helper'

import RepairDetailBodyItem from './RepairDetailBody/RepairDetailBodyItem'

const RepairDetail = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const repairDetail = useSelector((state) => state.repairDetail)
  const repairId = parseInt(params.al)

  useEffect(() => {
    dispatch(getRepairDetail(params.id, repairId)).catch((err) => {
      checkError(err)
    })
  }, [dispatch, params, getRepairDetail])

  return (
    <Wrapper Params={params}>
      <RepairDetailHeader RepairDetail={repairDetail} />
      <RepairDetailBody>
        <RepairDetailBodyTop RepairDetail={repairDetail} />
        <RepairDetailBodyItem RepairDetail={repairDetail} />
      </RepairDetailBody>
      <RepairDetailBottom RepairDetail={repairDetail} />
    </Wrapper>
  )
}

const Wrapper = (props) => {
  return (
    <div className='repair'>
      <DetailHeader showBack defaultPath={`/repair-history/${props.Params.id}`} type='repair-history' />
      <div className='repair-detail'>{props.children}</div>
    </div>
  )
}

export default RepairDetail
