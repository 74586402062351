import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

// Redux
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import NavItem from 'components/elements/NavItem'

// Method
import * as dim from 'utils/breakpoint'
import { checkError, openAppStoreOrPlayStore } from 'utils/helper'

// Actions
import { getProfileStat } from 'store/actions/profile'
import { getVehicleLength } from 'store/actions/vehicles'

// Styles
import './_index.scss'

// Validation Regex
import { isMobileApp } from 'utils/validation'

import { isMobile } from 'react-device-detect'
import { mobileAppInstallerIcon } from 'config/icon'

// Constant
import { USER_TYPE } from 'config/constant'

const Navigation = ({ profile, getProfileStat, location, history, vehicleLength, vehicleId, unreadCoupon }) => {
  const [dimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [isOpen, setIsOpen] = useState(true)
  let [timer] = useState()

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(profile, 'type')) {
      clearInterval(timer)
      timer = setInterval(
        /* istanbul ignore next */
        () => {
          getProfileStat().catch(checkError)
        },
        60000
      )
    }

    if (!Object.prototype.hasOwnProperty.call(profile, 'type')) clearInterval(timer)
  }, [])

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', updateDimensions)
      clearInterval(timer)
    }
  }, [])

  const updateDimensions = () => {
    setDimension({ width: window.innerWidth, height: window.innerHeight })
  }

  /**
   * Close sub menu everytime sub menu item is clicked
   */
  const closeMenu = () => {}

  const closeCard = () => {
    setIsOpen(false)
    Cookies.remove('showAppInstaller')
  }

  const mobileAppInstaller = () => {
    return (
      <div className='mobileAppInstaller'>
        <button id='mobileX' onClick={closeCard}>
          X
        </button>
        <div className='mobileAppInstaller__icon'>{mobileAppInstallerIcon}</div>
        <div className='mobileAppInstaller__text1'>ใช้งาน my-ISUZU ได้ดีกว่า ผ่านทางแอปพลิเคชัน</div>
        <div className='mobileAppInstaller__text2'>
          <span className='mobileAppInstaller__link' onClick={openAppStoreOrPlayStore}>
            เปิดผ่านแอป
          </span>
        </div>
      </div>
    )
  }

  const menu =
    Object.prototype.hasOwnProperty.call(profile, 'type') && location.pathname !== '/invite-controllers' ? (
      <div className={isMobile ? 'navigation' : 'top__menu__nav__navigation'}>
        {isOpen && Cookies.get('showAppInstaller') && isMobile && profile.type === USER_TYPE.INDIVIDUAL && mobileAppInstaller()}
        {!isMobileApp() && (
          <div className='navigation__main'>
            <NavItem
              currentLocation={history.location.pathname}
              exact
              target='/'
              isNewIcon
              icon='home'
              label='menus.home'
              onClick={closeMenu}
              id='home-nav-bar'
            />

            <NavItem
              currentLocation={history.location.pathname}
              target='/vehicles'
              isNewIcon
              icon='vehicle'
              label='menus.vehicles'
              onClick={closeMenu}
              id='vehicle-nav-bar'
            />

            <NavItem
              currentLocation={history.location.pathname}
              target='/coupons'
              isNewIcon
              icon='coupon'
              label='menus.coupons'
              onClick={closeMenu}
              isCoupon
              counter={unreadCoupon > 0 ? (unreadCoupon > 99 ? '99+' : unreadCoupon) : null}
              id='coupon-nav-bar'
            />

            <NavItem
              currentLocation={history.location.pathname}
              target='/privilege'
              isNewIcon
              icon='privilege'
              label='menus.privilege'
              onClick={closeMenu}
              id='privilege-nav-bar'
            />

            {!(dim.desktop(dimension) || dim.tablet(dimension)) && (
              <NavItem
                currentLocation={history.location.pathname}
                target='/setting'
                isNewIcon
                icon='profile'
                label='menus.myProfile'
                onClick={closeMenu}
                id='account-menu'
              />
            )}
          </div>
        )}
      </div>
    ) : null

  return menu
}

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getProfileStat,
        getVehicleLength
      },
      dispatch
    )

export const mapStateToProps =
  /*istanbul ignore next*/
  (state) => ({
    profile: state.profile,
    unreadCoupon: state.profileStat.unread_coupon,
    vehicleLength: state.vehicleLength.length,
    vehicleId: state.vehicleLength.id
  })

Navigation.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
  unreadCoupon: PropTypes.number,
  getProfileStat: PropTypes.func,
  getVehicleLength: PropTypes.func,
  location: PropTypes.any
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation))
