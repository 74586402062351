import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, intlShape } from 'react-intl'

import Chip from 'components/elements/Chip'
import Button from 'components/elements/Button'

import './_index.scss'
import Select from 'components/elements/Select'

const VehicleDetailsController = ({ controllersList = [], controllers = [], onAddController, onRemoveController }, context) => {
  const [selectedController, setSelectedController] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [controllerIds, setControllerIds] = useState([])

  useEffect(() => {
    const controllerIds = controllers.map(({ id }) => id)
    setControllerIds(controllerIds)
  }, [controllers])

  const addButtonClickHandler = () => {
    setIsAdding(true)
  }

  const cancelClickHandler = () => {
    setIsAdding(false)
    setSelectedController([])
  }

  const controllerOptions = useMemo(
    () =>
      controllersList
        .filter(({ id }) => !controllerIds.includes(id))
        .map(({ id: value, full_name: label }) => ({
          label,
          value
        })),
    [controllersList, controllerIds]
  )

  const controllerChangeHandler = (controller) => {
    setSelectedController(controller)
  }

  const confirmButtonClick = () => {
    if (onAddController && selectedController) {
      onAddController(selectedController.value)
    }

    setIsAdding(false)
    setSelectedController([])
  }

  const removeButtonClick = (id) => {
    if (onRemoveController) {
      onRemoveController(id)
    }
  }

  return (
    <div className='vehicle-details-controllers'>
      <div className='vehicle-details-controllers__header'>
        <FormattedMessage id='common.labels.controller' defaultMessage='common.labels.controller' />
      </div>

      {controllers.length === 0 && (
        <div className='vehicle-details-controllers__unassigned'>
          <span className='vehicle-details-controllers__unassigned__value'>
            <FormattedMessage id='vehicle.detail.unassigned' defaultMessage='vehicle.detail.unassigned' />
          </span>
        </div>
      )}

      <div className='vehicle-details-controllers__chips'>
        {controllers.map(({ id, full_name }) => (
          <Chip key={id} label={full_name} onButtonClick={() => removeButtonClick(id)} />
        ))}
      </div>
      <div className='vehicle-details-controllers__action'>
        {!isAdding && controllerOptions.length > 0 && (
          <Button
            className='controller-item__delete--btn'
            icon='add'
            color='primary'
            type='button'
            label='vehicle.detail.addController'
            onClick={addButtonClickHandler}
          />
        )}

        {isAdding && (
          <React.Fragment>
            <Select
              options={controllerOptions}
              onChange={controllerChangeHandler}
              placeholder={context.intl.formatMessage({ id: 'vehicle.list.selectController', default: 'vehicle.list.selectController' })}
              noMapping
            />
            <div className='vehicle-details-controllers__action__buttons'>
              <Button
                className='controller-item__delete--btn'
                type='small'
                color='primary'
                label='vehicle.detail.add'
                disabled={selectedController.length === 0}
                onClick={confirmButtonClick}
              />
              <Button
                className='controller-item__delete--btn'
                type='small'
                color='dark'
                label='vehicle.detail.cancel'
                onClick={cancelClickHandler}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

VehicleDetailsController.contextTypes = {
  intl: intlShape
}

VehicleDetailsController.propTypes = {
  controllers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string
    })
  )
}

export default VehicleDetailsController
