import { datadogLogs } from '@datadog/browser-logs'

export const init = () => {
  /**
   * @type {import("@datadog/browser-logs").LogsInitConfiguration}
   */
  datadogLogs.init({
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: +process.env.REACT_APP_SAMPLE_RATE
  })
}
