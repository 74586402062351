import React from 'react'

import { FormattedMessage } from 'react-intl'

const RepairDetailBodyTop = (props) => {
  return (
    <React.Fragment>
      <div className='repair-detail__body__top'>
        <div className='repair-detail__body__top__detail'>
          <FormattedMessage id='RepairHistory.serviceCenter' defaultMessage='RepairHistory.serviceCenter' />
          <span className='service-center'>{props.RepairDetail.service_center_name}</span>
        </div>
        <div className='repair-detail__body__top__detail'>
          <FormattedMessage id='RepairHistory.mileage' defaultMessage='RepairHistory.mileage' />
          <span className='mileage'>
            {props.RepairDetail.mileage} <FormattedMessage id='RepairHistory.kilometer' defaultMessage='RepairHistory.kilometer' />
          </span>
        </div>
        <div className='repair-detail__body__top__detail'>
          <FormattedMessage id='RepairHistory.paymentType' defaultMessage='RepairHistory.paymentType' />
          <span className='payment_type'>{props.RepairDetail.payment_type || '-'}</span>
        </div>
      </div>
      <div className='repair-detail__body__line'></div>
    </React.Fragment>
  )
}

export default RepairDetailBodyTop
