import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// SWR Concepts
// import axios from 'axios'
// import useSWR from 'swr'
// import queryString from 'query-string'
// import { VEHICLE } from '../../../store/definitions'
// import env from 'config/environment'

import ListPager from 'components/elements/ListPager'
import { getVehicles } from 'store/actions/vehicles'

import classnames from 'classnames'
import orderBy from 'lodash/orderBy'
import Cookies from 'js-cookie'

import './_index.scss'
import { PROFILE } from 'store/definitions'

import { checkError } from 'utils/helper'

const PAGE_LIMIT = 50

// SWR Concepts
// const baseUrl = env.api
// const fetcher = url => axios.get(url).then(res => res)

const PopupVehicle = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(0)
  const { data: vehicles, ...vehiclesData } = useSelector((state) => state.vehicles) || {}

  const sortedData = orderBy(vehicles, (data) => data.vehicle.delivery_date, 'desc')

  // const { data: dataVehicle } = useSWR(
  //   baseUrl + '/vehicles?' + queryString.stringify(true, '', 1, offset, PAGE_LIMIT, 0, 0, undefined, 'all', false, true),
  //   fetcher
  // )

  // let dataVehicleList, totalVehicleCount, hasOnlyCV
  // if (dataVehicle) {
  //   totalVehicleCount = parseInt(dataVehicle.headers['x-total-count']) || dataVehicle.data.length
  //   hasOnlyCV = (dataVehicle.headers['has-only-cv'] === 'true' ? true : false) || null

  //   dataVehicleList = dataVehicle.data.map(d => {
  //     d.is_checked = true
  //     return d
  //   })
  // }

  // useEffect(() => {
  //   if (dataVehicle) {
  //     dispatch({
  //       type: VEHICLE.GET,
  //       total_count: totalVehicleCount,
  //       has_only_cv: hasOnlyCV,
  //       data: dataVehicleList,
  //       isReload: false
  //     })
  //   }
  // }, [dataVehicle])

  useEffect(() => {
    dispatch(getVehicles(true, '', 1, offset, PAGE_LIMIT, 0, 0, undefined, 'all', false, true)).catch((err) => {
      checkError(err)
    })
  }, [dispatch, offset, PAGE_LIMIT])

  const handlePageChange = (_offset) => {
    setOffset(_offset)

    // force this function return "true" to make reduxActionGet of ListPager working correctly
    return true
  }

  const handleClickItem = (dataId) => {
    if (props.exc) {
      Cookies.set('excId', dataId)
      history.push(`/excellency/${dataId}`)
    }

    if (props.isp) {
      Cookies.set('ispId', dataId)
      history.push(`/isp/${dataId}`)
    }

    if (props.clickClose) {
      props.close()
    }
    dispatch({
      type: PROFILE.PAGEVIEW
    })
  }

  const vehicleList = sortedData.map((data) => (
    <div key={data.id} className='modal__body__content__privilege__item' onClick={() => handleClickItem(data.id)}>
      <div className='modal__body__content__privilege__item__img'>
        <img src={data.image_url || data.vehicle.model.marketing_image_url} alt={data.name || data.vehicle.model.marketing_name} />
      </div>

      <div className='modal__body__content__privilege__item__info'>
        <span className='modal__body__content__privilege__item__info__name'>{data.name || data.vehicle.model.marketing_name}</span>
        <span className='modal__body__content__privilege__item__info__license'>{data.vehicle.license_plate}</span>
      </div>
    </div>
  ))

  const classes = classnames('popup-vehicles')
  return (
    <div className={classes}>
      <ListPager limit={PAGE_LIMIT} total={vehiclesData.total_count} reduxActionGet={handlePageChange}></ListPager>
      <div className='popup-vehicles__list-wrapper'>{vehicleList}</div>
    </div>
  )
}

export default PopupVehicle
