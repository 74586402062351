import { VEHICLE, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  id: null,
  name: null,
  image_url: null,
  is_favorite: false,
  status: null,
  vehicle: {
    id: null,
    vin: null,
    license_plate: null,
    chassis_no: null,
    engine_no: null,
    last_maintenance_service_date: null,
    last_maintenance_service_mileage: null,
    model: {
      marketing_name: null,
      marketing_image_url: null
    },
    serviceCoupons: [],
    cashCoupons: []
  },
  driver: {
    givenName: null,
    surname: null,
    mobile: null
  },
  controllers: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE.DETAIL:
      return Object.assign({}, action.vehicleDetail)

    case VEHICLE.SEARCH:
      return Object.assign({}, action.vehicleDetail)

    case VEHICLE.FAV:
      if (state.id === action.id) {
        return Object.assign({}, state, {
          ...state,
          is_favorite: action.value
        })
      } else {
        return state
      }

    case VEHICLE.UPDATE_CONTROLLERS: {
      return { ...state, controllers: action.data }
    }

    case VEHICLE.GET_INSURANCE:
      return Object.assign({}, state, { ...action.data })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
