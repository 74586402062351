import { CONTROLLER, CLEAR_THIS_STATE } from 'store/definitions'
import _Filter from 'lodash/filter'

const initialState = {
  total_count: 0,
  data: [],
  stats: {
    total: 0,
    active: 0,
    in_active: 0,
    assigned: 0
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTROLLER.GET:
      return Object.assign({}, state, {
        ...state,
        total_count: action.total_count,
        data: action.isReload ? action.data : [...state.data, ...action.data]
      })

    case CONTROLLER.DELETE:
      const newData4 = _Filter(state.data, (d) => {
        return d.id !== action.id
      })

      return Object.assign({}, state, {
        ...state,
        total_count: state.total_count - 1,
        data: newData4
      })

    case CONTROLLER.STAT: {
      const newState = {
        ...state,
        stats: action.stats
      }

      return newState
    }

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
