/* eslint-disable */
// Cookies
import Cookies from 'js-cookie'
import { InvalidTokenError, jwtDecode } from 'jwt-decode'

// Env Config
import env from 'config/environment'
import { USER_TYPE } from '../config/constant'

const baseDom = env.envdom
const NODE_ENV = process.env.NODE_ENV
const AMPLIFY_ENV = process.env.REACT_APP_AMPLIFY

// Setting cookie with `domain=.amplifyapp.com` on Google Chrome no longer work
// as of 2024-08-21. Seems like Google Chrome has blacklisted this domain.
// Subdomains like `pr-123.d3ukx83jgkzkzo.amplifyapp.com` are still working,
// however.
// This issue can be replicated by running
// `document.cookie = "_TEST=1; path=/; domain=.amplifyapp.com"` directly in
// browser console. The browser will ignore this cookie. But if we remove the
// `domain=.amplifyapp.com` part, cookie will be set as expected.
// See also: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#write_a_new_cookie
const getCookieDomain = () => {
  if (NODE_ENV === 'development' || AMPLIFY_ENV === 'PREVIEW') {
    return undefined
  }

  return baseDom
}

export const isJwtValid = (
  token,
  /** For dependency injection during unit testing only. */
  currentUnixTimestamp = Date.now()
) => {
  if (typeof token !== 'string') {
    return false
  }

  try {
    const payload = jwtDecode(token)
    const tokenExpirationTimestamp = payload.exp

    if (!tokenExpirationTimestamp) {
      return false
    }

    // JavaScript timestamp is in milliseconds. While jwt.exp timestamp is in
    // seconds.
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
    // See: https://stackoverflow.com/questions/39926104/what-format-is-the-exp-expiration-time-claim-in-a-jwt
    return Math.ceil(currentUnixTimestamp / 1000) <= tokenExpirationTimestamp
  } catch (error) {
    if (error instanceof InvalidTokenError) {
      return false
    }

    throw error
  }
}

/**
 * return true if found cookie MYI-APP_TOKEN
 */
export const checkAuth = () => {
  const currentUserType = getCurrentUserType()
  const loginUserType = getLoginUserType()

  if (loginUserType !== undefined && currentUserType !== undefined && currentUserType !== loginUserType) {
    //if current user type not match login user type it will remove token
    removeCurrentUserType()
    forceLogOut()

    return false
  }

  return !!getToken()
}

/**
 * return token cookie MYI-APP_TOKEN
 */
export const getToken = () => {
  const token = Cookies.get('MYI-APP_TOKEN')

  return isJwtValid(token) ? token : undefined
}

export const getTime = () => {
  return Cookies.get('Generate_Time')
}

/**
 * return token cookie MYI-APP_REFRESH_TOKEN
 */
export const getRefreshToken = () => {
  return localStorage.getItem('MYI-APP_REFRESH_TOKEN')
}

/**
 * set token to cookie
 */

export const setToken = (token, expiryDate) => {
  return new Promise((resolve) => {
    if (expiryDate) {
      Cookies.set('MYI-APP_TOKEN', token, {
        expires: new Date(expiryDate),
        domain: getCookieDomain()
      })
      Cookies.set('Generate_Time', new Date(expiryDate))
    } else {
      Cookies.set('MYI-APP_TOKEN', token, {
        domain: getCookieDomain()
      })
    }
    resolve()
  })
}

/**
 * set refresh Line login retry
 */

export const setRetryLineLogin = () => {
  const now = new Date()
  now.setMinutes(now.getMinutes() + 1)
  Cookies.set('TRY-LINE-LOGIN', 'TRY-LINE-LOGIN', { expires: now })
}

export const getRetryLineLogin = () => {
  return Cookies.get('TRY-LINE-LOGIN')
}
//to set current user type
export const setCurrentUserType = (type) => {
  Cookies.set('CURRENT_USER_TYPE', type)
}

export const getCurrentUserType = () => {
  return Cookies.get('CURRENT_USER_TYPE')
}

export const removeCurrentUserType = () => {
  Cookies.remove('CURRENT_USER_TYPE')
}

//to set login user type
export const setLoginUserType = (type) => {
  if (type === USER_TYPE.INDIVIDUAL || type === USER_TYPE.CORPORATE || type === USER_TYPE.CONTROLLER) {
    Cookies.set('LOGIN_USER_TYPE', type)
  }
}

export const getLoginUserType = () => {
  return Cookies.get('LOGIN_USER_TYPE')
}

export const removeLoginUserType = () => {
  Cookies.remove('LOGIN_USER_TYPE')
}

/**
 * set refresh token to cookie
 */
export const setRefreshToken = (token) => {
  return new Promise((resolve) => {
    localStorage.setItem('MYI-APP_REFRESH_TOKEN', token)

    resolve()
  })
}

export const removeRefreshToken = () => {
  return new Promise((resolve) => {
    localStorage.removeItem('MYI-APP_REFRESH_TOKEN')

    resolve()
  })
}

/**
 * delete token from cookie
 */
export const removeToken = () => {
  return new Promise((resolve) => {
    Cookies.remove('MYI-APP_TOKEN', {
      domain: getCookieDomain()
    })
    Cookies.remove('excId')
    Cookies.remove('ispId')
    resolve()
  })
}

/**
 * set line state in cookie
 */
export const uriRedirect = env.base
export const lineClientID = env.line
export const setLineState = (token, expiryDate) => {
  if (expiryDate) {
    Cookies.set('LINE_STATE', token, {
      expires: new Date(expiryDate),
      domain: getCookieDomain()
    })
  } else {
    Cookies.set('LINE_STATE', token, {
      domain: getCookieDomain()
    })
  }
}

/**
 *
 */
export const removeLineState = () => {
  Cookies.remove('LINE_STATE')
}

/**
 *
 */
export const getLineState = () => {
  return Cookies.get('LINE_STATE')
}

/**
 * Force logout, remove token and reload window
 */
export const forceLogOut = () => {
  removeToken()
  window.location.reload()
}
