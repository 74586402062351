import { CONFIG } from 'store/definitions'
import { checkLanguage } from 'utils/languages'

const initialState = {
  lang: checkLanguage(),
  scale: 'medium'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIG.LANG:
      return Object.assign(
        {},
        {
          ...state,
          lang: action.lang
        }
      )

    case CONFIG.SCALE:
      return Object.assign(
        {},
        {
          ...state,
          scale: action.scale
        }
      )

    default:
      return state
  }
}
