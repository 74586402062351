import React, { useState, forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

//css
import './_index.scss'

//component
import Button from 'components/elements/Button'

// Method
import { formatNumber } from 'utils/format'

const ListPager = forwardRef((props, ref) => {
  const [index, setIndex] = useState(0)

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const prevButton = () => {
    const currProps = props
    const indexNext = index - currProps.limit
    if (currProps.reduxActionGet(indexNext)) {
      setIndex(indexNext)
      scrollToTop()
      localStorage.setItem('pageIndex', indexNext)
    }
  }
  const nextButton = () => {
    const currProps = props
    const indexNext = index + currProps.limit
    if (indexNext <= currProps.total) {
      if (currProps.reduxActionGet(indexNext)) {
        setIndex(indexNext)
        scrollToTop()
        localStorage.setItem('pageIndex', indexNext)
      }
    }
  }

  const { className, total, limit } = props
  const classes = classnames('list-pager', className)

  return (
    <div className={classes}>
      {props.checkAll && props.listType === 'inbox-list' && total > 0 && (
        <Button
          onClick={props.checkAll}
          icon='check'
          color='transparent'
          className='button--check-all'
          label='inbox.labels.checkAll'
        ></Button>
      )}

      <div className='list-pager__total'>
        <FormattedMessage id='inbox.labels.of' defaultMessage='inbox.labels.of'>
          {(_of) => (
            <span>
              {(total < 1 ? 0 : index + 1) +
                ' - ' +
                (index + limit > total ? formatNumber(total) : index + limit) +
                ' ' +
                _of +
                ' ' +
                formatNumber(total)}
            </span>
          )}
        </FormattedMessage>
      </div>
      <Button disabled={index < 1} iconOnly type='pager' onClick={prevButton} icon='keyboard_arrow_left' />

      <Button disabled={index + limit >= total} iconOnly type='pager' onClick={nextButton} icon='keyboard_arrow_right' />
    </div>
  )
})

ListPager.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number,
  total: PropTypes.number,
  reduxActionGet: PropTypes.func,
  checkAll: PropTypes.func
}

ListPager.propTypes = {
  className: PropTypes.string,
  type: PropTypes.number
}

export default ListPager
