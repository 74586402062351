import { gql } from '@apollo/client'

export const HERO_BANNERS_QUERY = gql`
  query HeroBanners {
    customerViewer {
      cms {
        heroBanners {
          edges {
            node {
              ... on ImageBanner {
                id
                imageUrl
                webTargetUrl
              }

              ... on UserError {
                code
                message
              }
            }
          }
        }
      }
    }
  }
`
