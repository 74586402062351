import { ISP_VEHICLES, CLEAR_THIS_STATE } from '../definitions'

const initialState = {
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ISP_VEHICLES.GET:
      return Object.assign({}, state, {
        ...state,
        data: action.ispVehicle,
        total_count: action.total_count
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
