import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import './index.scss'

import * as format from 'utils/format'
import * as helper from 'utils/helper'

const VehicleCard = (props) => {
  return (
    <div className='card' onClick={props.onClick}>
      <div className='card__image'>
        <img src={props.vehicleImage} alt='' />
      </div>
      <div className='card__detail'>
        <div className='card__detail__name'>{props.vahicleName}</div>
        <div>
          <FormattedMessage id='privilege.licensePlate' defaultMessage='privilege.licensePlate' /> : {props.licensePlate}
        </div>
        <div>
          {props.excellency && props.excellency.expiry_date && (
            <FormattedHTMLMessage
              id='privilege.expiredDate'
              values={{
                date: format.showIspDate(props.excellency.expiry_date, true)
              }}
              defaultMessage='privilege.expiredDate'
            />
          )}
        </div>
        {props.excellency && props.excellency.current_status && props.excellency.current_status !== '-' && (
          <div>
            <img
              src={helper.getAssets(
                1,
                2,
                helper.getExcellencyStatus(
                  props.excellency.current_status ? props.excellency.current_status : /*istanbul ignore next*/ undefined
                ),
                'excellency_detail_screen'
              )}
              alt=''
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default VehicleCard
