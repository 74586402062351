import React from 'react'

// Router
import { withRouter, useHistory, useLocation } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Button from 'components/elements/Button'

const ButtonBack = ({ defaultPath, isUseDefault, isGoBack }) => {
  const history = useHistory()
  const location = useLocation()

  const backTo = () => {
    if (isGoBack === true) {
      history.goBack()
    } else if (isGoBack === false) {
      history.push('/')
    } else if (history.length > 1 && location.key) {
      history.goBack()
    } else if (history.length === 1 && isUseDefault) {
      history.push(defaultPath)
    } else {
      history.push('/')
    }
  }

  const classes = classnames('button-back')
  return <Button iconOnly type='button-back' icon='arrow_back' onClick={backTo} className={classes} />
}

ButtonBack.propTypes = {
  defaultPath: PropTypes.string,
  history: PropTypes.object
}

ButtonBack.defaultProps = {
  defaultPath: ''
}

export default withRouter(ButtonBack)
