import './MyiPrivacyPolicy.scss'

import React from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'

import { MYI_PRIVACY_POLICY_QUERY } from '../graphql/myi-privacy-policy-query'

export function MyiPrivacyPolicy() {
  const params = useParams()
  const dispatch = useDispatch()

  const privacy = useQuery(MYI_PRIVACY_POLICY_QUERY, {
    onError: (error) => {
      // This will send error to Datadog.
      console.error(error)

      dispatch(showAlert(alert(500)))
    }
  })

  if (privacy.data && privacy.data.cmsNamespace.myiPrivacyPolicy) {
    if (params.locale === 'th') {
      return (
        <div
          className='myi-privacy-policy'
          dangerouslySetInnerHTML={{ __html: privacy.data.cmsNamespace.myiPrivacyPolicy.htmlContentTh }}
        />
      )
    } else {
      return (
        <div
          className='myi-privacy-policy'
          dangerouslySetInnerHTML={{ __html: privacy.data.cmsNamespace.myiPrivacyPolicy.htmlContentEn }}
        />
      )
    }
  }

  return
}
