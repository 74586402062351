import { LOADER } from '../definitions'

/**
 *
 */
const showLoader = () => {
  return (dispatch) => {
    dispatch({
      type: LOADER.SHOW
    })
  }
}

/**
 *
 */
const hideLoader = () => {
  return (dispatch) => {
    dispatch({
      type: LOADER.HIDE
    })
  }
}

export { showLoader, hideLoader }
