import React from 'react'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Button from 'components/elements/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

// Classnames
import classnames from 'classnames'

// Actions
import { hideAlert } from 'store/actions/alert'

// Method
import { FormattedMessage } from 'react-intl'

// Styles
import './_index.scss'

const Alert = ({ hideAlert, alert }) => {
  const closeAlert = () => {
    hideAlert()
  }

  const classes = classnames('snackbar', `snackbar--${alert.type}`)

  // TODO Add onClick
  const goToBookmark = <Button className='goto-bookmark' type='add-new' label='timeline.bookmark.button.saveBookmark' />

  return (
    <div>
      <Snackbar
        className={classes}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={Object.prototype.hasOwnProperty.call(alert, 'type')}
        onClose={closeAlert}
        autoHideDuration={6000}
        message={
          <div className='snackbar__message'>
            {alert && alert.message && !alert.value && (
              /* istanbul ignore next [03/22] */
              <FormattedMessage id={alert.message} defaultMessage={alert.message} />
            )}
            {alert && alert.value && (
              /* istanbul ignore next [03/22] */
              <FormattedMessage id={alert.message} values={{ value: alert.value }} defaultMessage={alert.message} />
            )}
          </div>
        }
        action={
          alert.type === 'bookmark-success'
            ? [goToBookmark]
            : [<Button className='snackbar__close' key='close' iconOnly icon='close' onClick={closeAlert} />]
        }
        TransitionComponent={(props) => <Slide {...props} direction='up' />}
      />
    </div>
  )
}

export const mapStateToProps = (state) => ({
  alert: state.alert
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        hideAlert
      },
      dispatch
    )

Alert.propTypes = {
  hideAlert: PropTypes.func,
  alert: PropTypes.object
}
export default connect(mapStateToProps, mapDispatchToProps)(Alert)
