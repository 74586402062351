export const css = {
  textField: {
    root: {
      '& label.Mui-focused': {
        color: 'white'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'yellow'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white'
        },
        '&:hover fieldset': {
          borderColor: 'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'yellow'
        }
      }
    }
  },

  input: {
    fontSize: '12px',
    fontFamily: 'TIS'
  },

  inputLabel: {
    fontSize: '16px',
    fontFamily: 'TIS'
  }
}

export const styles = (theme) => ({
  title: {
    textAlign: 'center',
    margin: 'auto'
  },
  row: {
    margin: '4rem'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {},
  fieldset: {
    width: '350px',
    height: '55px'
  },
  cssLabel: {
    marginTop: '0.5rem',
    '&$cssFocused $notchedOutline': {
      color: '#ED1C24',
      marginTop: 'unset'
    }
  },
  shrink: {
    '&$cssLabel': {
      transform: 'translate(14px, -10px) scale(0.75)'
    }
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#ED1C24 !important`
    }
  },
  cssFocused: {
    '&$cssLabel': {
      color: '#ED1C24'
    }
  },
  cssShrink: {
    transform: 'translate(14px, -10px) scale(0.75)'
  },
  notchedOutline: {},
  error: {
    '& >input': {
      background: '#FFF2F4',
      borderColor: '#ED1C24'
    }
  },
  root: {
    width: '100%'
  }
})
