import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import DetailHeader from 'components/layouts/DetailHeader'
import ChannelSelection from './ChannelSelection'
import RoleSelection from './RoleSelection'

import { Form, Field, reduxForm } from 'redux-form'

// Actions
import { inviteController, getNextControllerStats } from 'store/actions/controllers'

import { setPlaceholder, setValidation } from './Label/format'

import { FormattedHTMLMessage } from 'react-intl'

// Styles
import './_index.scss'

// Utils
import { checkError } from 'utils/helper'

import { postPageView } from 'store/actions/profile'

const getValues = (form) => {
  return form && form.values
}

const ControllerAdd = ({ controllerForm, change, initialValues }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [role, setRole] = useState('controller')
  const [channel, setChannel] = useState('email')
  const [initial, setInitialValue] = useState(true)
  // const [role] = useState('controller')
  // const [channel] = useState('email')
  const { handleSubmit } = useForm()

  const values = getValues(controllerForm)
  const isError = controllerForm && controllerForm.syncErrors

  const insertController = async () => {
    const payload = {
      invite_channel: channel,
      sub_type: role === 'driver' ? 'd' : null,
      username: values && values.username,
      email: channel === 'email' && values ? values.contact.toLowerCase() : '-',
      mobile_phone: channel === 'sms' && values ? values.contact : '-'
    }
    dispatch(inviteController(payload))
      .then(() => {
        history.push(`/controllers`)
      })
      .catch((err) => {
        checkError(err)
      })
  }

  useEffect(() => {
    dispatch(getNextControllerStats(), postPageView()).catch((err) => {
      checkError(err)
    })
  }, [])

  useEffect(() => {
    if (controllerForm && !controllerForm.values && initial) {
      if (initialValues.username) {
        change('username', initialValues.username)
        setInitialValue(false)
      }
    }
  }, [controllerForm, initialValues])

  const roleActive = (roleActive) => {
    return role === roleActive ? 'radio_active' : ''
  }

  const usernameLabel = <FormattedHTMLMessage id='controller.invite.inviteUsername' defaultMessage='controller.invite.inviteUsername' />

  const contactLabel = <ChannelSelection setChannel={setChannel} channel={channel} />

  return (
    <div className='controller-form'>
      <div className='controller-form__header'>
        <DetailHeader showBack defaultPath='controllers' type='controller-add' />
      </div>
      <Form onSubmit={handleSubmit(insertController)} className='controller-form__form'>
        <React.Fragment>
          <RoleSelection role={role} setRole={setRole} roleActive={roleActive} />
          {setField('username', 'username', usernameLabel)}
          {setField('contact', channel, contactLabel)}
          <div className='controller-form__form__action'>
            <Button type='submit' color='primary' label='controller.invite.invite' disabled={!!isError} />
          </div>
        </React.Fragment>
      </Form>
    </div>
  )
}

const setField = (value, type, label) => {
  return (
    <Field
      name={value}
      component={Input}
      label={label}
      // label='common.labels.email' // reproduce error when it was assigned by label parameter
      isClearable
      required
      placeholder={setPlaceholder(type)}
      validate={setValidation(type)}
      id={`controller_${value}`}
    />
  )
}

ControllerAdd.propTypes = {
  controllerForm: PropTypes.object,
  controllerDetail: PropTypes.object,
  inviteController: PropTypes.func,
  getNextControllerStats: PropTypes.func,
  handleSubmit: PropTypes.func
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  controllerForm: state.form.controller,
  controllerDetail: state.controllerDetail,
  initialValues: {
    username: state.controllerStats.next_username
  }
})

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: 'controller',
    enableReinitialize: true
  })(withRouter(ControllerAdd))
)
