const alert = (errorCode, type = undefined, identifier = undefined) => {
  switch (errorCode) {
    case 200:
      switch (type) {
        case 'inbox-delete':
          return {
            type: 'success',
            message: 'inbox.deleteSuccess'
          }
        case 'profile-password_change':
          return {
            type: 'success',
            message: 'myProfile.changePassword.success'
          }
        case 'profile-settings':
          return {
            type: 'success',
            message: 'myProfile.settings.success'
          }
        case 'profile-line_connect':
          return {
            type: 'success',
            message: 'myProfile.settings.success'
          }
        case 'profile-save-success':
          return {
            type: 'success',
            message: 'myProfile.settings.saveSuccess'
          }
        case 'vehicle-assign_controller':
          return {
            type: 'success',
            message: 'vehicle.alert.assign'
          }
        case 'vehicle-unassign_controller':
          return {
            type: 'success',
            message: 'vehicle.alert.unassign'
          }
        case 'vehicle-upload-image':
          return {
            type: 'success',
            message: 'common.success.upload'
          }
        case 'vehicle-setCover-image':
          return {
            type: 'success',
            message: 'common.success.coverImage'
          }
        case 'vehicle-delete-image':
          return {
            type: 'success',
            message: 'common.success.deleteImage'
          }
        case 'delete-vehicles':
          return {
            type: 'success',
            message: 'vehicle.alert.delete'
          }
        case 'forgot-password':
          return {
            type: 'success',
            message: 'signIssue.alert.otpSent'
          }
        case 'company-add':
          return {
            type: 'success',
            message: 'company.toast.success'
          }
        case 'til-refinance':
          return {
            type: 'success',
            message: 'common.success.requestSuccess'
          }
        case 'bookmark':
          return {
            type: 'bookmark-success',
            message: 'timeline.bookmark.alert.success'
          }
        default:
          return {
            type: 'success',
            message: 'common.success.success'
          }
      }
    case 304:
      switch (type) {
        case 'inbox-MarkAsRead':
          return {
            // expected no alert
          }
        case 'inbox-MarkAsUnread':
          return {
            // expected no alert
          }
        default:
          return {
            type: 'warning',
            message: 'alert.400.20001'
          }
      }
    case 400:
      switch (type) {
        case 20007:
          switch (identifier) {
            case 'sms':
            case 'email':
              return {
                type: 'warning',
                message: `alert.${errorCode}.${type}.${identifier}`
              }
            default:
              return {
                type: 'warning',
                message: 'alert.400.20007.default'
              }
          }
        case 20019:
          return {
            type: 'warning',
            message: 'alert.400.20019'
          }
        default:
          if (!isNaN(type)) {
            return {
              type: 'warning',
              message: `alert.${errorCode}.${type}`
            }
          } else {
            return {
              type: 'warning',
              message: 'alert.400.20001'
            }
          }
      }
    case 401:
      switch (type) {
        case 10002:
          return {
            type: 'warning',
            message: 'login.errors.loginFailed'
          }
        case 10004:
          return {
            type: 'warning',
            message: `alert.${errorCode}.${type}`,
            value: identifier
          }
        case 'lineConnect':
          return {
            type: 'warning',
            message: 'login.errors.lineloginFailed'
          }
        default:
          if (!isNaN(type)) {
            return {
              type: 'warning',
              message: `alert.${errorCode}.${type}`
            }
          } else {
            return {
              type: 'warning',
              message: 'alert.401.10001'
            }
          }
      }
    case 403:
      return {
        type: 'error',
        message: 'alert.403.30001'
      }
    case 409:
      switch (type) {
        case 50002:
          return {
            type: 'warning',
            message: 'createAccount.alert.accountExist'
          }
        default:
          if (!isNaN(type)) {
            return {
              type: 'warning',
              message: `alert.${errorCode}.${type}`
            }
          } else {
            return {
              type: 'warning',
              message: 'alert.409.50001'
            }
          }
      }
    case 429:
      switch (type) {
        default:
          if (!isNaN(type)) {
            return {
              type: 'warning',
              message: `alert.${errorCode}.${type}`
            }
          } else {
            return {
              type: 'warning',
              message: 'alert.429.49999'
            }
          }
      }
    case 500:
      switch (type) {
        case 99001:
          return {
            type: 'error',
            message: 'common.errors.internalServerError'
          }
        case 99003:
          return {
            type: 'error',
            message: 'alert.500.99003'
          }
        default:
          if (!isNaN(type)) {
            return {
              type: 'error',
              message: `alert.${errorCode}.${type}`
            }
          } else {
            return {
              type: 'error',
              message: 'common.errors.internalServerError'
            }
          }
      }
      case 0:
        return {
          type: 'warning',
          message: 'alert.0'
        }
    default:
      switch (type) {
        case 'profile-password_change':
          return {
            type: 'error',
            message: 'myProfile.changePassword.failed'
          }
        default:
          return {
            type: 'error',
            message: 'common.errors.internalServerError'
          }
      }
  }
}

export default alert
