import Cookies from 'js-cookie'

export const setPreviousLocation = (value) => {
  Cookies.set('previousLocation', value)
}

export const getPreviousLocation = () => {
  const location = Cookies.get('previousLocation')
  return location
}
