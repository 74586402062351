import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import DetailHeader from 'components/layouts/DetailHeader'

import Menu from 'components/elements/Menu'

import './_index.scss'

import { useHistory } from 'react-router'

const Setting = () => {
  const history = useHistory()
  useEffect(() => {
    if (!isMobile) {
      history.push(`/profile/change-password`)
    }
  }, [])

  return (
    <div className='setting'>
      <DetailHeader showBack type='setting' />
      <Menu mode={1} />
    </div>
  )
}

export default Setting
