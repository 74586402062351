import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import filterVehicles from './filterVehicles'
import filterCoupons from './filterCoupons'
import filterController from './filterController'
import coupons from './coupons'
import couponsServiceSlider from './couponsServiceSlider'
import couponsCashSlider from './couponsCashSlider'
import couponsCashGroup from './couponsCashGroup'
import vehicles from './vehicles'
import vehicleDetail from './vehicleDetail'
import profile from './profile'
import profileStat from './profileStat'
import controllers from './controllers'
import controllerDetail from './controllerDetail'
import controllersList from './controllersList'
import controllersSlider from './controllersSlider'
import controllerStats from './controllerStats'
import promotions from './promotions'
import vehiclesSlider from './vehiclesSlider'
import bannersSlider from './bannersSlider'
import promotionDetail from './promotionDetail'
import promotionsSlider from './promotionsSlider'
import alert from './alert'
import loader from './loader'
import config from './config'
import profileServiceCenters from './profileServiceCenters'
import master from './master'
import dealers from './dealers'
import mux from './mux'
import loginBanner from './loginBanner'
import ispVehicle from './ispVehicles'
import ispDetail from './ispDetail'
import repairHistory from './repiarHistory'
import repairDetail from './repairDetail'
import tilRefinance from './tilRefinance'
import company from './company'
import vehicleLength from './vehicleLength'
import individualCustomerEndOfSupportBanner from './individualCustomerEndOfSupportBanner'

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    config,
    auth,
    filterVehicles,
    filterCoupons,
    filterController,
    coupons,
    couponsServiceSlider,
    couponsCashSlider,
    couponsCashGroup,
    vehicles,
    vehicleDetail,
    profile,
    profileStat,
    profileServiceCenters,
    controllers,
    controllerDetail,
    controllersSlider,
    vehiclesSlider,
    bannersSlider,
    promotions,
    promotionDetail,
    promotionsSlider,
    controllersList,
    controllerStats,
    alert,
    loader,
    master,
    dealers,
    mux,
    loginBanner,
    ispVehicle,
    ispDetail,
    company,
    vehicleLength,
    repairHistory,
    repairDetail,
    tilRefinance,
    individualCustomerEndOfSupportBanner,
    form: formReducer
  })

export default reducers
