import axios from 'axios'
import queryString from 'query-string'
import { getToken, getRefreshToken, forceLogOut, setToken, removeRefreshToken } from 'utils/auth'
import { checkLanguage } from 'utils/languages'
import { isMobileApp } from 'utils/validation'
import env from 'config/environment'
import moment from 'moment'
import 'moment/locale/th'

require('es6-promise').polyfill()
require('isomorphic-fetch')

const baseUrl = env.api

const url = {
  getVehicles: (query) => baseUrl + '/vehicles?' + queryString.stringify(query),
  getCoupons: (query) => baseUrl + '/coupons?' + queryString.stringify(query),
  getMuxOwner: baseUrl + '/myIsuzu/isMuxOwner',
  getISPVehicles: baseUrl + '/isp/vehicle-list/?'
}

const axiosInstance = axios.create()

const api = {
  signIn: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/login', payload)
  },
  signOut: function () {
    return axiosInstance.post(baseUrl + '/users/logout')
  },
  lineSignIn: function (id_token, redirect, type, remember_me) {
    type = parseInt(type)

    return axiosInstance.get(
      queryString.stringifyUrl({
        url: new URL('/auth/user/social-media/access-token', baseUrl).href,
        query: {
          provider: 'line',
          authorization_code: id_token,
          redirect_uri: redirect,
          type,
          remember_me
        }
      })
    )
  },
  lineConnect: function (payload) {
    return axiosInstance.post(baseUrl + '/users/me/social-media/connect', payload)
  },
  signUpIndividual: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/verify', payload)
  },
  signUpCorporate: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/corporate/verify', payload)
  },
  signUpController: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/controller/verify', payload)
  },
  registrationIndividual: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/registration', payload)
  },
  registrationIndividualResend: function (id) {
    return axiosInstance.post(baseUrl + '/auth/user/registration/' + id + '/resend')
  },
  registrationIndividualVerify: function (id, code) {
    return axiosInstance.post(baseUrl + '/auth/user/registration/' + id + '/verify', { code })
  },
  registrationIndividualSignup: function (registration_token) {
    return axiosInstance.post(baseUrl + '/auth/user/signup', { registration_token })
  },
  registrationController: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/controller/registration', payload)
  },
  registrationControllerResend: function (id) {
    return axiosInstance.post(baseUrl + '/auth/controller/registration/' + id + '/resend')
  },
  registrationControllerVerify: function (id, code) {
    return axiosInstance.post(baseUrl + '/auth/controller/registration/' + id + '/verify', { code })
  },
  registrationControllerSignup: function (registration_token) {
    return axiosInstance.post(baseUrl + '/auth/controller/signup', { registration_token })
  },
  registrationCorporate: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/corporate/registration', payload)
  },
  registrationCorporateSignup: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/corporate/signup', payload)
  },
  requestPasswordReset: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/request-password-reset', payload)
  },
  requestPasswordResetVerify: function (userID, payload) {
    return axiosInstance.post(baseUrl + '/auth/user/request-password-reset/' + userID + '/verify', payload)
  },
  requestPasswordResend: function (userID) {
    return axiosInstance.post(baseUrl + '/auth/user/request-password-reset/' + userID + '/resend')
  },
  resetPassword: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/reset-password', payload)
  },
  forgotAccount: function (payload) {
    return axiosInstance.post(baseUrl + '/auth/user/forgot-account', payload)
  },
  getProfile: function () {
    return axiosInstance.get(baseUrl + '/users/me')
  },
  getProfileServiceCenters: function () {
    return axiosInstance.get(baseUrl + '/users/me/service-centers')
  },
  getVehicleServiceCenters: function (id) {
    return axiosInstance.get(baseUrl + '/vehicles/service-centers/' + id)
  },
  editProfile: function (payload) {
    return axiosInstance.patch(baseUrl + '/users/me', payload)
  },
  getProfileStat: function () {
    return axiosInstance.get(baseUrl + '/users/me/stats')
  },
  getControllers: function (query) {
    return axiosInstance.get(baseUrl + '/controllers?' + queryString.stringify(query))
  },
  getControllerDetail: function (id) {
    return axiosInstance.get(baseUrl + '/controllers/' + id)
  },
  getControllerStats: function () {
    return axiosInstance.get(baseUrl + '/controllers/statistics')
  },
  getNextControllerStats: function () {
    return axiosInstance.get(baseUrl + '/controllers/stats')
  },
  getControllerVehicleStats: function (id) {
    return axiosInstance.get(baseUrl + '/controllers/' + id + '/vehicle-stats')
  },
  inviteController: function (payload) {
    return axiosInstance.post(baseUrl + '/controllers/invite', payload)
  },
  deleteController: function (id) {
    return axiosInstance.delete(baseUrl + '/controllers/' + id)
  },
  editController: function (id, payload) {
    return axiosInstance.patch(baseUrl + '/controllers/' + id, payload)
  },
  resendControllerInvitation: function (id) {
    return axiosInstance.post(baseUrl + `/controllers/${id}/re-invite`)
  },
  getCoupons: function (query) {
    return axiosInstance.get(url.getCoupons(query))
  },
  readCoupons: function (query) {
    return axiosInstance.put(baseUrl + '/coupons/read?' + queryString.stringify(query))
  },
  getServiceCoupons: function () {
    return axiosInstance.get(baseUrl + '/coupons/service?ob=7')
  },
  readServiceCoupons: function (query) {
    return axiosInstance.put(baseUrl + '/coupons/service/read?' + queryString.stringify(query))
  },
  getCashCoupons: function () {
    return axiosInstance.get(baseUrl + '/coupons/cash')
  },
  readCashCoupons: function (payload) {
    return axiosInstance.put(baseUrl + '/coupons/cash/read', { ids: payload })
  },
  readGroupCashCoupons: function (query) {
    return axiosInstance.put(baseUrl + '/coupons/cash/group/read?' + queryString.stringify(query))
  },
  getMasterContents: function () {
    return axiosInstance.get(baseUrl + '/masters/contents')
  },
  getMasterCouponTypes: function () {
    return axiosInstance.get(baseUrl + '/masters/coupon-types')
  },
  getDistricts: function () {
    return axiosInstance.get(baseUrl + '/dealers/districts?bp=false')
  },
  getBPDistricts: function () {
    return axiosInstance.get(baseUrl + '/dealers/districts?bp=true')
  },
  getProvinces: function () {
    return axiosInstance.get(baseUrl + '/dealers/provinces?bp=false')
  },
  getBPProvinces: function () {
    return axiosInstance.get(baseUrl + '/dealers/provinces?bp=true')
  },
  getDealers: function (query) {
    return axiosInstance.get(baseUrl + '/dealers?' + queryString.stringify(query))
  },
  getDealerDetail: function (query) {
    return axiosInstance.get(baseUrl + '/dealers/line?' + queryString.stringify(query))
  },
  updateDealerAppointment: function (payload) {
    return axiosInstance.post(baseUrl + '/dealers/saveAppointment/', payload)
  },
  getPromotions: function (query) {
    return axiosInstance.get(baseUrl + '/promotions?' + queryString.stringify(query))
  },
  getPromotionDetail: function (id) {
    return axiosInstance.get(baseUrl + '/promotions/' + id)
  },
  getTargetVehicles: function (id) {
    return axiosInstance.get(baseUrl + '/promotions/' + id + '/vehicles')
  },
  getVehicles: function (query) {
    return axiosInstance.get(url.getVehicles(query))
  },
  getISPVehicles: function () {
    return axiosInstance.get(baseUrl + '/isp/vehicle-list/?')
  },
  getISPDetail: function (id) {
    return axiosInstance.get(baseUrl + '/isp/vehicle-list/?hash_id=' + id)
  },
  activateVehicle: function (id) {
    return axiosInstance.post(baseUrl + '/vehicles/' + id)
  },
  editVehicleDetail: function (id, payload) {
    return axiosInstance.patch(baseUrl + '/vehicles/' + id, payload)
  },
  downloadVehicles: function () {
    return axiosInstance.get(baseUrl + '/vehicles/download')
  },
  getVehicleDetail: function (id) {
    return axiosInstance.get(baseUrl + '/vehicles/' + id)
  },
  toggleVehicleFavorite: function (id, is_favorite) {
    return axiosInstance.patch(baseUrl + '/vehicles/' + id, { is_favorite })
  },
  assignVehicleController: function (id, controller_user_id) {
    return axiosInstance.patch(baseUrl + '/vehicles/' + id, { controller_user_id })
  },
  assignVehiclesControllers: function (payload) {
    return axiosInstance.post(baseUrl + '/vehicles/assign-controllers', payload)
  },
  unassignVehiclesControllers: function (payload) {
    return axiosInstance.post(baseUrl + '/vehicles/unassign-controllers', payload)
  },
  clearController: function (id) {
    return axiosInstance.delete(baseUrl + '/vehicles/' + id + '/controllers')
  },
  deleteVehicle: function (id, payload) {
    return axiosInstance.post(baseUrl + '/vehicles/' + id + '/delete', payload)
  },
  deleteVehicles: function (payload) {
    return axiosInstance.post(baseUrl + '/vehicles/delete', payload)
  },
  searchVehicleVin: function (vin) {
    return axiosInstance.get(baseUrl + '/vehicles/search/' + vin)
  },
  logActivities: function (payload) {
    return axiosInstance.post(baseUrl + '/users/me/activities', payload)
  },
  amazonUpload: function (url, payload) {
    return axios.put(url, payload, { isAmazon: true })
  },
  amazonDownload: function (url) {
    return axios.get(url, {
      isDownload: true,
      responseType: 'blob'
    })
  },
  refreshToken: function (payload) {
    return fetch(`${baseUrl}/auth/refresh-token`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(payload)
    })
  },
  addToHomeScreen: function (payload) {
    return axiosInstance.post(baseUrl + '/users/a2hs', payload)
  },
  allowNotification: function (payload) {
    return axiosInstance.post(baseUrl + '/users/notification', payload)
  },
  getUserAdditionalStatus: function (payload) {
    return axiosInstance.get(baseUrl + '/users/a2hsstatus', payload)
  },
  registrationDevice: function (payload) {
    return axiosInstance.post(baseUrl + '/users/me/addUserDevice', payload)
  },
  getMuxOwner: function () {
    return axiosInstance.get(baseUrl + '/myIsuzu/isMuxOwner')
  },
  ispRegistration: function (payload) {
    return axiosInstance.post(baseUrl + '/isp/register-purchase', payload)
  },
  cancelRegistration: function (payload) {
    return axiosInstance.patch(baseUrl + '/isp/cancel-registration', payload)
  },
  ispAutoCancel: function (payload) {
    return axiosInstance.patch(baseUrl + '/isp/auto-cancel', payload)
  },
  deleteVehicleImage: function (id, payload) {
    return axiosInstance.delete(baseUrl + `/vehicles/${id}/image`, payload)
  },
  setCoverImage: function (id, payload) {
    return axiosInstance.patch(baseUrl + `/vehicles/${id}/cover`, payload)
  },
  getRepairHistory: function (id, query) {
    return axiosInstance.get(baseUrl + `/services/${id}/?` + queryString.stringify(query))
  },
  getCompanyList: function () {
    return axiosInstance.get(baseUrl + `/corporates/child-list`)
  },
  checkAvalibleAddCompany: function (payload) {
    return axiosInstance.post(baseUrl + `/corporates/can-add`, payload)
  },
  addCompany: function (payload) {
    return axiosInstance.post(baseUrl + `/corporates/add-child`, payload)
  },
  getVehicleInsurance: function (id) {
    return axiosInstance.get(baseUrl + `/vehicles/insurance/${id}`)
  },
  getVehicleLength: async function () {
    return axiosInstance.get(baseUrl + `/vehicles/length`)
  },
  getRepairDetail: function (id, query) {
    return axiosInstance.get(baseUrl + `/services/${id}/item?ro=${query}`)
  },
  getRefinanceDetail: function (payload) {
    return axiosInstance.post(baseUrl + '/myIsuzu/til/get-refinance-detail', payload)
  },
  registerTransactionContractRf: function (payload) {
    return axiosInstance.post(baseUrl + '/myIsuzu/til/register-transaction-contract-rf', payload)
  },
  getDefaultVehicle: function () {
    return axiosInstance.get(baseUrl + `/vehicles/retrieve-vehicle-til-refinance`)
  },
  generateDynamicLink: function (payload) {
    return axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'No Auth'
      }
    })
  }
}

axiosInstance.interceptors.request.use(
  /*istanbul ignore next*/
  (config) => {
    const auth = getToken()
    const accLang = checkLanguage() === 'th' ? 'th-TH' : 'en-US'

    if (auth && !config.isAmazon && !config.isDownload) {
      config.headers.authorization = 'Bearer ' + auth
      config.headers['Accept-Language'] = accLang
    }

    if (config.isAmazon) {
      config.headers['Content-Type'] = config.data.type
      config.headers['Cache-Control'] = 'private, max-age=31536000'
    }
    if (config.isDownload) {
      config.headers['Cache-Control'] = 'no-cache'
    }

    return config
  }
)

// Add a 401 response interceptor
/*istanbul ignore next*/
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== '/signin' &&
      window.location.pathname !== '/_error'
    ) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const refreshToken = getRefreshToken()
      let now = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      if (refreshToken !== undefined && refreshToken !== null && !isSafari) {
        return api
          .refreshToken({ refreshToken: refreshToken })
          .then((res) => res.json())
          .then(async (res) => {
            if (res.token !== undefined && res.token !== null && res.token !== '') {
              if (new Date(res.valid_until) > moment(now)) {
                setToken(res.token, new Date(res.valid_until))
              }
            } else {
              logoutAndRemoveRefreshToken()
            }

            const originalReq = error.config
            originalReq._retry = true

            return axiosInstance(originalReq)
          })
          .catch((err) => Promise.reject(err))
      } else {
        logoutAndRemoveRefreshToken()
      }
    }

    if (error.response && error.response.status === 403 && window.location.pathname !== '/') {
      window.location.pathname = '/'
      return
    }

    return Promise.reject(error)
  }
)

const logoutAndRemoveRefreshToken = () => {
  if (!isMobileApp()) {
    removeRefreshToken()
    forceLogOut()
  }
}

export { api as default, baseUrl }

export { url }
