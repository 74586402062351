import 'core-js/stable'
import 'core-js/proposals/string-replace-all'

import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { SWRConfig } from 'swr'
import axios from 'axios'

// Redux
import { Provider } from 'react-redux'

// Router
import { ConnectedRouter } from 'connected-react-router'

// Store
import { store, history } from './store'

// Components
import Wrapper from 'domain/Wrapper'
import ErrorBoundary from 'domain/ErrorBoundary'

// Datadog
import * as datadogRum from 'utils/datadog'
import * as datadogLog from 'utils/datadogLog'
import { checkError } from 'utils/helper'

import { apolloClient } from 'domains/common/graphql/client'

const fetcher = (url) =>
  axios
    .get(url)
    .then((res) => res)
    .catch((err) => checkError(err))

datadogRum.init()
datadogLog.init()

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SWRConfig
          value={{
            fetcher: fetcher
          }}
        >
          <ApolloProvider client={apolloClient}>
            <Wrapper />
          </ApolloProvider>
        </SWRConfig>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
)

window.log = (...msgs) => {
  if (process.env.REACT_APP_ENV.trim() === 'DEV') console.log(...msgs)
}
