import React, { useEffect, useState } from 'react'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Router
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

//validation
import {
  forgotpw_pwmin8,
  forgotpw_pw,
  passwordsMustMatch,
  forgotpw_pw2,
  forgotpw_indiv,
  forgotpw_indiv_alphanumeric,
  forgotpw_otp,
  forgotpw_otp_numeric,
  forgotpw_otpmax6,
  forgotpw_corp,
  forgotpw_cont
} from 'utils/validation'

// Components
import ContentHeader from 'components/layouts/ContentHeader'
import { Form, Field, reduxForm } from 'redux-form'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TabBar from 'components/elements/TabBar'
import Select from 'components/elements/Select'
import Instruction from 'components/elements/InstructionMobileUpdate'

// Actions
import { requestPasswordReset, requestPasswordResetVerify, resetPassword, resendPassword } from 'store/actions/auth'
import { showAlert } from 'store/actions/alert'

// Styles
import './_index.scss'

// Methods
import { FormattedMessage, injectIntl } from 'react-intl'
import { checkLanguage, onLanguageChanged } from 'utils/languages'
import { isMobileApp } from 'utils/validation'
import { checkError } from 'utils/helper'

// Constants
import { languageOptions, USER_TYPE } from 'config/constant'
import { postPageView } from 'store/actions/profile'

var secondsRemaining
const SigninIssue = ({
  intl,
  history,
  emailCencored,
  requestPasswordReset,
  requestPasswordResetVerify,
  resetPassword,
  resendPassword,
  location,
  postPageView,
  handleSubmit
}) => {
  const [selectedType, setSelectedType] = useState(
    location.state && !isNaN(location.state) ? parseInt(location.state) : USER_TYPE.INDIVIDUAL
  )
  const [pageType, setPageType] = useState('password')
  const [pageStep, setPageStep] = useState(0)
  const [selectedChannel, setSelectedChannel] = useState('sms')
  const [seconds, setSeconds] = useState('00')
  const [minutes, setMinutes] = useState('05')
  const [retries, setRetries] = useState(-1)
  const [intervalHandle, setIntervalHandle] = useState()

  useEffect(() => {
    clearInterval(intervalHandle)
    postPageView()
    secondsRemaining = 300
  }, [])

  const tick /* istanbul ignore next */ = () => {
    var min = Math.floor(secondsRemaining / 60)
    var sec = secondsRemaining - min * 60

    if (sec < 10) {
      sec = '0' + sec
    }
    if (min < 10) {
      min = '0' + min
    }
    if ((min === 0) & (sec === 0)) {
      clearInterval(intervalHandle)
    } else {
      secondsRemaining = secondsRemaining - 1
    }
    setSeconds(sec)
    setMinutes(min)
  }

  const startCountDown = () => {
    const id = setInterval(tick, 1000)
    setIntervalHandle(id)
  }

  const resetCountDown = () => {
    setSeconds('00')
    setMinutes('05')

    clearInterval(intervalHandle)
    setRetries(retries + 1)

    startCountDown()
  }

  const changeChannelRadio = (event) => setSelectedChannel(event.target.value)

  const changeType = /*istanbul ignore next*/ (activeIndex) => setSelectedType(activeIndex + 1)

  let submit = /*istanbul ignore next*/ () => {}

  const classes = classnames('signin-issue')

  let pageDOM = null

  const radioChannelDOM = (
    <div className='signin-issue__form__radio'>
      <RadioGroup aria-label='selected_channel' value={selectedChannel} onChange={changeChannelRadio}>
        <FormControlLabel
          value='sms'
          control={<Radio />}
          label={intl.formatMessage({ id: 'common.labels.sms', defaultMessage: 'common.labels.sms' })}
        />
        <FormControlLabel
          value='email'
          control={<Radio />}
          label={intl.formatMessage({ id: 'common.labels.email', defaultMessage: 'common.labels.email' })}
        />
      </RadioGroup>
    </div>
  )
  /*istanbul ignore next*/
  const InstructionDOM = function (props) {
    return (
      <div className='signin-issue__form__instruction'>
        <h5 className='signin-issue__form__instruction__title'>
          {' '}
          <FormattedMessage id={props.title} defaultMessage={props.title} />
        </h5>
        <span>
          <FormattedMessage id={props.subTitle} defaultMessage={props.subTitle} />
        </span>
      </div>
    )
  }

  switch (pageType) {
    case 'password':
      switch (pageStep) {
        case 1:
          pageDOM = (
            <div>
              <InstructionDOM
                subTitle={
                  selectedChannel === 'email'
                    ? 'signIssue.password.otpDescription'
                    : /* istanbul ignore next */
                      'signIssue.password.otpSmsDescription'
                }
                title='signIssue.password.title'
              />

              <div className='signin-issue'>
                <div className='signin-issue__form__result'>
                  <div className='signin-issue__form__result__field'>
                    <span>{emailCencored}</span>
                  </div>
                  <div className='signin-issue__form__result__field'>
                    {retries < 2 && (
                      <Button
                        color='transparent'
                        label='signIssue.password.resend'
                        onClick={async (e) => {
                          e.preventDefault()
                          try {
                            await resendPassword()
                            resetCountDown()
                          } catch (err) {
                            checkError(err)
                          }
                        }}
                        type='button'
                      />
                    )}
                  </div>
                </div>
                <div className='signin-issue__form__otp-message'>
                  {retries === 2 &&
                  minutes === '00' &&
                  /* istanbul ignore next */
                  seconds === '00' ? (
                    /* istanbul ignore next */
                    <span>
                      {' '}
                      <FormattedMessage id='signIssue.password.otpMax' defaultMessage='signIssue.password.otpMax' />
                    </span>
                  ) : retries > 0 && retries <= 2 ? (
                    <span>
                      <FormattedMessage
                        id='signIssue.password.otpInfo'
                        defaultMessage='signIssue.password.otpInfo'
                        values={{ value: retries }}
                      />
                    </span>
                  ) : (
                    <span>
                      <FormattedMessage id='signIssue.password.otpResendInfo' defaultMessage='signIssue.password.otpResendInfo' />
                    </span>
                  )}

                  <div className='signin-issue__form__otp-message--timer'>
                    <span>
                      {minutes}:{seconds}
                    </span>
                  </div>

                  {pageStep === 1 && (
                    <Field
                      name='otp'
                      type='otp'
                      label={'common.labels.otp'}
                      validate={[forgotpw_otp, forgotpw_otp_numeric, forgotpw_otpmax6]}
                      component={Input}
                      id='otp'
                    />
                  )}
                </div>
              </div>

              <div className='signin-issue__form__action'>
                <div className='signin-issue__form__action__section signin__form__action__section--half'>
                  <Button
                    color='transparent'
                    label='common.button.back'
                    onClick={() => {
                      setPageStep(0)
                      setRetries(-1)
                    }}
                    type='button'
                  />
                </div>
                <div className='signin-issue__form__action__section signin__form__action__section--half'>
                  <Button color='primary' label='common.button.next' type='submit' />
                </div>
              </div>
              <Instruction />
            </div>
          )
          submit = () => {
            const eventError = () => {
              setPageStep(0)
              setRetries(-1)
            }
            requestPasswordResetVerify(eventError)
              .then(() => {
                setPageStep(2)
              })
              .catch((err) => {
                checkError(err)
              })
          }
          break
        case 2:
          pageDOM = (
            <div>
              <InstructionDOM title='signIssue.password.title' subTitle='signIssue.password.inputNewPassword'></InstructionDOM>

              <Field
                name='password'
                component={Input}
                type='password'
                label='common.labels.password'
                isRequired
                placeholder='common.placeholders.inputPassword'
                id='password'
                togglePassword
                isClearable
                validate={[forgotpw_pw, forgotpw_pwmin8]}
              />

              <Field
                name='password_repeated'
                component={Input}
                type='password'
                label='common.labels.confirmPassword'
                isRequired
                togglePassword
                isClearable
                placeholder='common.placeholders.repeatPassword'
                id='password_repeat'
                validate={[forgotpw_pw2, passwordsMustMatch]}
              />

              <div className='signin-issue__form__action'>
                <div className='signin-issue__form__action__section'>
                  <Button color='primary' label='common.button.next' type='submit' />
                </div>
              </div>
            </div>
          )
          submit = () => {
            resetPassword()
              .then(() => setPageStep(3))
              .catch((err) => {
                checkError(err)
              })
          }
          break
        case 3:
          pageDOM = (
            <div>
              <InstructionDOM title='signIssue.password.title' subTitle='signIssue.password.changeSuccess'></InstructionDOM>

              <div className='signin-issue__form__action'>
                <div className='signin-issue__form__action__section'>
                  <Button color='primary' type='submit' label='login.button.signin' onClick={() => history.push('/signin')} />
                </div>
              </div>
            </div>
          )
          break
        default:
          let identfierString
          let identifierTitle
          let validationArr

          if (selectedType === USER_TYPE.INDIVIDUAL) {
            identfierString = 'signIssue.password.identifierIndividual'
            identifierTitle = 'signIssue.password.subTitleIndividual'
            validationArr = [forgotpw_indiv, forgotpw_indiv_alphanumeric]
          } else if (selectedType === USER_TYPE.CORPORATE) {
            identfierString = 'signIssue.password.identifierCorporate'
            identifierTitle = 'signIssue.password.subTitleCorporate'
            validationArr = [forgotpw_corp]
          } else {
            identfierString = 'signIssue.password.identifierController'
            identifierTitle = 'signIssue.password.subTitleController'
            validationArr = [forgotpw_cont]
          }

          pageDOM = (
            <div>
              <InstructionDOM title='signIssue.password.title' subTitle={identifierTitle}></InstructionDOM>

              <Field
                name='identifier'
                component={Input}
                type='text'
                label={identfierString}
                isRequired
                isClearable
                placeholder={identfierString}
                id='identifier'
                validate={validationArr}
              />

              {radioChannelDOM}

              <div className='signin-issue__form__action'>
                <div className='signin-issue__form__action__section signin__form__action__section--half'>
                  {/* <Button color="transparent" label="common.button.back" onClick={() => this.setState({ pageType: undefined, selectedType: 1, retries: -1 })} type="button" /> */}
                  <Button
                    color='transparent'
                    label='common.button.back'
                    onClick={() =>
                      history.push({
                        pathname: '/signin',
                        state: selectedType
                      })
                    }
                    type='button'
                  />
                </div>
                <div className='signin-issue__form__action__section signin__form__action__section--half'>
                  <Button color='primary' label='common.button.next' type='submit' />
                </div>
              </div>
            </div>
          )
          submit = () => {
            requestPasswordReset(selectedType, selectedChannel)
              .then(() => {
                resetCountDown()
                setPageStep(1)
              })
              .catch((err) => {
                checkError(err)
              })
          }
      }

      break
    // case 'account':
    //   switch (this.state.pageStep) {
    //     case 1:
    //       pageDOM = <div>
    //         <InstructionDOM title="signIssue.account.title"
    //           subTitle={this.state.selectedChannel === 'email' ? 'signIssue.account.success' : 'signIssue.account.smsSuccess'}>
    //         </InstructionDOM>

    //         <div className="signin-issue__form__action">
    //           <div className="signin-issue__form__action__section">
    //             <Button color="primary" type="submit " label="login.button.signin" onClick={() => this.props.history.push('/signin')} />
    //           </div>
    //         </div>
    //       </div>
    //       break;
    //     default:
    //       pageDOM = <div>
    //         <InstructionDOM title="signIssue.account.title"
    //           subTitle="signIssue.account.subTitle">
    //         </InstructionDOM>

    //         {this.state.selectedType !== 2 &&
    //           <Field
    //             name="identifier"
    //             component={Input}
    //             type="text"
    //             label={'signIssue.account.identifier'}
    //             isRequired
    //             isClearable
    //             placeholder={'common.placeholders.inputIdentifier'}
    //             id="identifier"
    //             validate={[forgotacc_indiv, forgotacc_indiv_alphanumer]}
    //           />
    //         }

    //         {this.state.selectedType === 2 &&
    //           <Field
    //             name="company_reg_id"
    //             component={Input}
    //             type="text"
    //             label={'createAccount.labels.companyReg'}
    //             isRequired
    //             isClearable
    //             placeholder={'createAccount.placeholders.companyReg'}
    //             validate={[forgotacc_regId, forgotacc_regId_alphanumeric]}
    //             id="company_id"
    //           />
    //         }

    //         {this.state.selectedType === 2 &&
    //           <Field
    //             name="company_branch_tax_id"
    //             component={Input}
    //             type="text"
    //             label={'createAccount.labels.branchTax'}
    //             isRequired
    //             isClearable
    //             placeholder={'createAccount.placeholders.branchTax'}
    //             validate={[forgotacc_branch, forgotacc_branch_alphanumeric]}
    //             id="branch_id"
    //           />
    //         }

    //         {radioChannelDOM}

    //         <div className="signin-issue__form__action">
    //           <div className="signin-issue__form__action__section signin__form__action__section--half">
    //             <Button color="transparent" label="common.button.back" type="button"
    //               onClick={() => this.setState({ pageType: undefined, selectedType: 1, retries: -1 })} />
    //           </div>
    //           <div className="signin-issue__form__action__section signin__form__action__section--half">
    //             <Button color="primary" label="common.button.next" type="submit" />
    //           </div>
    //         </div>
    //       </div>
    //       this.submit = () => {
    //         forgotAccount(this.state.selectedType, this.state.selectedChannel)
    //           .then(() => this.setState({ pageStep: 1 }))
    //       }
    //   }
    //   break;
    default:
      pageDOM = (
        <div className='signin-issue__form__page_select'>
          <p>
            <FormattedMessage id='signIssue.labels.instructions' defaultMessage='signIssue.labels.instructions' />
          </p>
          <div className='signin-issue__form__page_select__option'>
            <Button
              color='transparent'
              type='button'
              label='signIssue.labels.forgotPassword'
              onClick={() => {
                setPageType('password')
                setPageStep(0)
              }}
            />
            <Button
              color='transparent'
              type='button'
              label='signIssue.labels.forgotAccount'
              onClick={() => {
                setPageType('account')
                setPageStep(0)
              }}
            />
            <Button color='transparent-primary' type='button' label='login.button.signin' onClick={() => history.push('/signin')} />
          </div>
        </div>
      )
      submit = /*istanbul ignore next*/ () => {}
  }

  return (
    <div className={classes}>
      <ContentHeader title='signIssue.labels.title' subTitle='signIssue.labels.subTitle' />
      {(pageType === 'password' || pageType === 'account') &&
        pageStep === 0 &&
        (isMobileApp() ? (
          <div className='webview-sub-header'>
            <FormattedMessage id='common.labels.individual' defaultMessage='common.labels.individual' />
          </div>
        ) : (
          <TabBar
            tabs={['common.labels.individual', 'common.labels.corporate', 'common.labels.controllerLoginIssue']}
            defaultValue={selectedType - 1}
            onActiveIndexChange={changeType}
          />
        ))}
      <Form onSubmit={handleSubmit(submit)} className='signin-issue__form'>
        {pageDOM}
      </Form>

      {!isMobileApp() && (
        <div className='signin-issue__footer'>
          <div className='signin-issue__footer__language'>
            <Select options={languageOptions} value={checkLanguage()} onChange={onLanguageChanged} className='open-top' noMapping />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  emailCencored: state.auth.identifier
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        requestPasswordReset,
        requestPasswordResetVerify,
        resetPassword,
        resendPassword,
        showAlert,
        postPageView
      },
      dispatch
    )

SigninIssue.propTypes = {
  emailCencored: PropTypes.string,
  requestPasswordReset: PropTypes.func,
  requestPasswordResetVerify: PropTypes.func,
  resetPassword: PropTypes.func,
  resendPassword: PropTypes.func,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  showAlert: PropTypes.func
}

SigninIssue.defaultProps = {
  className: ''
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'signinIssue'
    })(injectIntl(SigninIssue))
  )
)
