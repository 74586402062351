import { MUX } from 'store/definitions'

const initialState = {
  is_owner: false,
  status: 'idle' // idle, loading, succeeded, failed
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MUX.IS_OWNER_LOADING:
      return Object.assign({}, state, {
        ...state,
        status: 'loading'
      })
    case MUX.IS_OWNER_SUCCEEDED:
      return Object.assign({}, state, {
        ...state,
        is_owner: action.is_owner,
        status: 'succeeded'
      })
    case MUX.IS_OWNER_FAILED:
      return Object.assign({}, state, {
        ...state,
        is_owner: action.is_owner,
        status: 'failed'
      })
    default:
      return state
  }
}
