import React, { useEffect, useRef } from 'react'

// Router
import { useHistory } from 'react-router-dom'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Components
import Filter from 'components/elements/Filter'
import ControllerItem from 'domain/Controllers/components/ControllerItem'
import ListPager from 'components/elements/ListPager'
import ContentHeader from 'components/layouts/ContentHeader'

import Fab from '@material/react-fab'

// Actions
import { getControllers } from 'store/actions/controllers'

// Styles
import './_index.scss'
import { PROFILE } from 'store/definitions'

// Utils
import { checkError } from 'utils/helper'

const PAGE_LIMIT = 50

const Controllers = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const listPagingRef = useRef()
  dispatch({
    type: PROFILE.PAGEVIEW
  })
  const controllers = useSelector((state) => state.controllers)
  const { keyword, orderBy, sortBy, status, assignment } = useSelector((state) => state.filterController)

  useEffect(() => {
    dispatch(getControllers(true, keyword, orderBy + sortBy, 0, PAGE_LIMIT, true, status, assignment))
      .then(() => {
        if (listPagingRef.current) {
          listPagingRef.current.resetState()
        }
      })
      .catch((err) => checkError(err))
  }, [keyword, orderBy, sortBy, status, assignment, getControllers])

  const listPagingChangePage = async (offset) => {
    dispatch(getControllers(true, keyword, orderBy + sortBy, offset, PAGE_LIMIT, true, status, assignment)).catch((err) => checkError(err))
  }

  return (
    <div className='controllers'>
      <div className='controllers__header'>
        <ContentHeader title={'controller.list.title'} subTitle={'controller.list.subTitle'} type='controllers' />
        <Filter type='controllers' />
        <div className='controllers__header__pager'>
          <ListPager
            limit={PAGE_LIMIT}
            total={controllers.total_count}
            ref={listPagingRef}
            reduxActionGet={listPagingChangePage}
          ></ListPager>
        </div>
      </div>
      <div className='controllers__body'>
        <div className='list'>
          {controllers.data.map((controller) => (
            <ControllerItem key={controller.id} detail={controller} />
          ))}
        </div>
      </div>

      <Fab
        className='fab'
        icon={<i className='material-icons'>add</i>}
        onClick={(e) => {
          e.stopPropagation()
          history.push('/controller/add')
        }}
      />
    </div>
  )
}

export default Controllers
