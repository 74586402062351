import './MyiTermsAndConditions.scss'

import React from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'

import { MYI_TERMS_AND_CONDITIONS_QUERY } from '../graphql/queries/myi-terms-and-conditions-query'

export function MyiTermsAndConditions() {
  const params = useParams()
  const dispatch = useDispatch()

  const terms = useQuery(MYI_TERMS_AND_CONDITIONS_QUERY, {
    onError: (error) => {
      // This will send error to Datadog.
      console.error(error)

      dispatch(showAlert(alert(500)))
    }
  })

  if (terms.data && terms.data.cmsNamespace.myiTermsAndConditions) {
    if (params.locale === 'th') {
      return (
        <div
          className='myi-terms-and-conditions'
          dangerouslySetInnerHTML={{ __html: terms.data.cmsNamespace.myiTermsAndConditions.htmlContentTh }}
        />
      )
    } else {
      return (
        <div
          className='myi-terms-and-conditions'
          dangerouslySetInnerHTML={{ __html: terms.data.cmsNamespace.myiTermsAndConditions.htmlContentEn }}
        />
      )
    }
  }

  return
}
