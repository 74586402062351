import React, { useEffect, useState } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Loader from 'components/sections/Loader'
import DetailHeader from 'components/layouts/DetailHeader'
import DetailVehicleForm from 'domain/Vehicle/components/DetailVehicleForm'

// Actions
import { getVehicleDetail } from 'store/actions/vehicles'

// Styles
import './_index.scss'

// Utils
import { checkError } from 'utils/helper'

import { postPageView } from 'store/actions/profile'

const VehicleEdit = ({ getVehicleDetail, postPageView, match }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    Promise.all([getVehicleDetail(match.params.id), postPageView()]).catch((err) => {
      checkError(err)
    })
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <Loader forceLoad />
  } else {
    return (
      <div className='vehicle-edit'>
        <div className='vehicle-edit__header'>
          {window.previousLocation === '/vehicle/details' ? (
            <DetailHeader showBack defaultPath={`/vehicle/${match.params.id}`} type='basic' />
          ) : (
            <DetailHeader type='basic' />
          )}
        </div>
        <DetailVehicleForm />
      </div>
    )
  }
}

VehicleEdit.propTypes = {
  getVehicleDetail: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

export const mapStateToProps = (state) => ({
  vehicleDetail: state.vehicleDetail
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getVehicleDetail,
        postPageView
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehicleEdit))
