/**
 *
 */
export const desktop = (dimension) => {
  return dimension.width >= 1200 ? true : false
}

/**
 *
 */
export const tablet = (dimension) => {
  return dimension.width >= 768 && dimension.width <= 1199 ? true : false
}

/**
 *
 * @param {*} dimension
 */
export const phoneMd = (dimension) => {
  return dimension.width >= 481 && dimension.width <= 767 ? true : false
}
