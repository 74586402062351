import { CONFIG } from 'store/definitions'
import { setLanguage } from 'utils/languages'
import { setScale } from 'utils/scale'

/**
 * Dispatch language and set it to cookie
 * @param {*} lang
 * string 'th' or 'en'
 */
const changeLang = (lang = 'th') => {
  return (dispatch) => {
    dispatch({
      type: CONFIG.LANG,
      lang: lang
    })
    setLanguage(lang)
  }
}

/**
 * Dispatch scale and set it to cookie
 * @param {*} scale
 * string 'small', 'medium' or 'large'
 */
const changeScale = (scale) => {
  return (dispatch) => {
    dispatch({
      type: CONFIG.SCALE,
      scale
    })
    setScale(scale)
  }
}

export { changeLang, changeScale }
