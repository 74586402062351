import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Parser from 'html-react-parser'

// Router
import { withRouter, useHistory } from 'react-router-dom'

// Components
import TabBar from 'components/elements/TabBar'
import PackageTemplate from './PackageTemplate'
import PackageHeader from './PackageHeader'
import PackageProtection from './PackageProtection'
import FAQAndTerm from './FAQAndTerm'
import PackageRemark from './PackageRemark'
import DetailHeader from 'components/layouts/DetailHeader'
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import PopupISPVehicle from 'domain/Isp/components/PopupISPVehicle'

// CSS
import './index.scss'

// Constants
import { ISP_MASTER_NON_EXY, ISP_MASTER_BRONZE, ISP_MASTER_SILVER } from 'config/constant'
import { vehicleModalIcon } from 'config/icon'

// Actions
import { getISPVehicles } from 'store/actions/ispVehicles'
import { getISPDetail } from 'store/actions/ispDetail'

// Utils
import { checkError } from 'utils/helper'

// lodash
import isEmpty from 'lodash/isEmpty'

const ISPPackageAds = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const handleActiveIndexUpdate = (activeIndex) => setActiveIndex(activeIndex)
  const vehicleLength = useSelector((state) => state.vehicleLength.length)
  const ispVehicle = useSelector((state) => state.ispVehicle)
  const ispDetail = useSelector((state) => state.ispDetail)

  const modalRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()

  const closeModal = () => modalRef.current.closeModal()

  const pathname = history.location.pathname

  const allVehicles = Object.values(ispVehicle.data)

  // https://tripetch.atlassian.net/browse/LOGOPER-3038?focusedCommentId=87060
  // Both useEffect is support when direct open url /isp-about
  // from push notification
  useEffect(() => {
    const fetchData = () => {
      if (vehicleLength > 0 && ispVehicle.data.length === 0) {
        dispatch(getISPVehicles()).catch((err) => {
          checkError(err)
        })
      }
    }
    fetchData()
  }, [vehicleLength])

  useEffect(() => {
    if (ispVehicle && ispVehicle.total_count > 0 && isEmpty(ispDetail)) {
      const defaultIspVehicle = Object.values(ispVehicle.data).filter((vehicle) => {
        return vehicle.is_default
      })
      if (defaultIspVehicle.length > 0 && defaultIspVehicle[0]) {
        dispatch(getISPDetail(defaultIspVehicle[0].hash_id)).catch((err) => {
          checkError(err)
        })
      }
    }
  }, [ispVehicle.total_count])

  return (
    <div className='isp-package'>
      <PackageHeader />
      {pathname !== '/isp/no-vehicle' && pathname !== '/isp-about' && <DetailHeader showBack type='basic' />}
      <Modal
        mode={8}
        title={'privilege.vehicleModal'}
        content={
          <PopupISPVehicle
            vehicles={ispVehicle}
            ispList={allVehicles}
            close={closeModal}
            isp={true}
            clickClose={true}
            ispDetailPage={true}
          />
        }
        ref={modalRef}
      />

      {ispVehicle.total_count > 1 && (
        <Button
          className='isp-btn'
          icon={vehicleModalIcon}
          label='privilege.vehicleModal'
          type='vehicle'
          onClick={() => {
            modalRef.current.openModal()
          }}
        />
      )}
      <div className='isp-package__isp'>
        <PackageProtection />
        <div className='isp-package__isp__ads'>
          <div>
            <TabBar
              tabs={[Parser(ISP_MASTER_NON_EXY.tabTitle), Parser(ISP_MASTER_BRONZE.tabTitle), Parser(ISP_MASTER_SILVER.tabTitle)]}
              onActiveIndexChange={handleActiveIndexUpdate}
              isPackage
            />

            <div className='isp-package__ads'>
              {activeIndex === 0 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_NON_EXY} type='normal' />
                </div>
              )}
              {activeIndex === 1 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_BRONZE} type='bronze' />
                </div>
              )}
              {activeIndex === 2 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_SILVER} type='silver' />
                </div>
              )}
            </div>
          </div>
        </div>
        <FAQAndTerm />
        <PackageRemark />
      </div>
    </div>
  )
}

export default withRouter(ISPPackageAds)
