import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { openMyISUZUApp } from '../../utils/helper'

const CreatePassword = () => {
  const location = useLocation()

  useEffect(() => {
    const query = parse(location.search)
    openMyISUZUApp('/create-password', { token: query.token || '' })
  }, [])
  return <div></div>
}

export default CreatePassword
