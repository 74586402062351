import React, { useRef } from 'react'

// Proptypes
import PropTypes from 'prop-types'

// Component
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import ReactModal from 'react-modal'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Config
import { deleteIcon } from 'config/icon'

// Actions
import { deleteVehicleImage, setCoverImage } from 'store/actions/vehicles'

// CSS
import './index.scss'

import { checkError } from 'utils/helper'

const FullImage = ({ image, match, isCover, onClose, isDelete, isOfficial, deleteVehicleImage, setCoverImage }) => {
  const modalRef = useRef()

  const deleteImage = async (id, image_url, is_official) => {
    try {
      await deleteVehicleImage(id, {
        image_url: is_official ? 'official' : image_url
      })
    } catch (err) {
      checkError(err)
    }
  }

  const _setCoverImage = async (id, image_url) => {
    try {
      await setCoverImage(id, { image_url: image_url })
    } catch (err) {
      checkError(err)
    }
  }

  const imageModal = (
    <div className='full-image' id='full-image'>
      <Modal
        mode={2}
        title='vehicle.fullImage.modalTitle'
        content='vehicle.fullImage.modalContent'
        ref={modalRef}
        onConfirmed={() => {
          deleteImage(match.params.id, image, isOfficial)
          onClose()
        }}
      />
      <div className='full-image__header'>
        <Button iconOnly type='button-back' icon='arrow_back' onClick={onClose} />
        {!isCover && (
          <Button
            color='transparent-primary'
            label='common.button.changeCover'
            onClick={() => {
              _setCoverImage(match.params.id, image)
              onClose()
            }}
          />
        )}
      </div>
      <div className='full-image__body'>
        <img src={image} alt='' />
      </div>
      <div className='full-image__footer'>
        <div className='full-image__footer__delete'>
          {isDelete && <Button iconOnly type='delete' icon={deleteIcon} onClick={() => modalRef.current.openModal()} />}
        </div>
      </div>
    </div>
  )
  return (
    <ReactModal isOpen={true} className='full-image__modal'>
      {imageModal}
    </ReactModal>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      deleteVehicleImage,
      setCoverImage
    },
    dispatch
  )

FullImage.propTypes = {
  image: PropTypes.string,
  isCover: PropTypes.bool,
  deleteVehicleImage: PropTypes.func
}

FullImage.defaultProps = {
  image: '',
  isCover: false
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullImage))
