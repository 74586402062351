import moment from 'moment'
import 'moment/locale/th'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { checkLanguage } from 'utils/languages'
import find from 'lodash/find'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import { store } from '../store'
import { MAX_REMAINING, EXCELLENCY_STATUS } from '../config/constant'
import api from 'api/'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'
import { stringifyUrl } from 'query-string'
import { isAndroid, isIOS } from 'react-device-detect'
import { appStoreUrl, playStoreUrl } from 'config/environment'

export const getCurrentStatus = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  switch (normalized) {
    case 'bronze':
      return 'bronze'
    case 'silver':
    case 'silver+':
      return 'silver'
    case 'gold':
    case 'gold+':
      return 'gold'
    case 'platinum':
    case 'platinum+':
      return 'platinum'
    case 'white':
      return 'white'
    case 'red':
      return 'red'
    case 'black':
      return 'black'
    default:
      return 'old'
  }
}

export const getNextStatus = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  switch (normalized) {
    case 'silver+':
      return 'gold'
    case 'gold+':
    case 'platinum+':
      return 'platinum'
    default:
      return normalized
  }
}

export const getExcellencyStatus = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  switch (normalized) {
    case 'bronze':
      return 'bronze'
    case 'silver':
    case 'silver+':
      return 'silver'
    case 'gold':
    case 'gold+':
      return 'gold'
    case 'platinum':
    case 'platinum+':
      return 'platinum'
    case 'white':
      return 'white'
    case 'red':
      return 'red'
    case 'black':
      return 'black'
    default:
      return undefined
  }
}

export const exNextStatus = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  if (normalized === 'bronze') return <FormattedMessage id='privilege.status.bronze' defaultMessage='privilege.status.bronze' />
  else if (normalized === 'silver') return <FormattedMessage id='privilege.status.silver' defaultMessage='privilege.status.silver' />
  else if (normalized === 'silver+')
    return <FormattedMessage id='privilege.status.silver_plus' defaultMessage='privilege.status.silver_plus' />
  else if (normalized === 'gold') return <FormattedMessage id='privilege.status.gold' defaultMessage='privilege.status.gold' />
  else if (normalized === 'gold+') return <FormattedMessage id='privilege.status.gold_plus' defaultMessage='privilege.status.gold_plus' />
  else if (normalized === 'platinum') return <FormattedMessage id='privilege.status.platinum' defaultMessage='privilege.status.platinum' />
  else if (normalized === 'platinum+')
    return <FormattedMessage id='privilege.status.platinum_plus' defaultMessage='privilege.status.platinum_plus' />
  else if (normalized === 'red') return <FormattedMessage id='privilege.status.red' defaultMessage='privilege.status.red' />
  else if (normalized === 'white') return <FormattedMessage id='privilege.status.white' defaultMessage='privilege.status.white' />
  else if (normalized === '-') return '-'
}

export const getISPStatus = (status) => {
  if (status !== undefined) return status
  else return 'non_isp_in_warranty'
}

export const getPrivCond = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  if (normalized === 'bronze') return 1
  else if (normalized === 'silver') return 2
  else if (normalized === 'silver+') return 2
  else if (normalized === 'gold') return 3
  else if (normalized === 'gold+') return 3
  else if (normalized === 'platinum') return 4
  else if (normalized === 'platinum+') return 4
  else return 0
}

export const getNSeriesPrivCond = (status) => {
  const normalized = toLower(status).replace(/\s+/g, '')
  if (normalized === 'white') return 1
  else if (normalized === 'red') return 2
  else if (normalized === 'black') return 3
  else return 0
}

export const getAssets = (modules, content, name, page) => {
  const lang = checkLanguage() === 'th' ? 1 : 2
  const { master } = store.getState()
  const assetItem = find(master.contents, {
    module_id: modules,
    content_type: content,
    language: lang,
    content_name: name,
    content_page: page
  })
  return assetItem ? assetItem.content : null
}

export const getISILogo = /*istanbul ignore next */ (type) => {
  const { master } = store.getState()
  const assetItem = find(master.coupon_types, { campaign_code: '211' })

  if (type === 1) return assetItem ? assetItem.coupon_logo : null
  else if (type === 2) return assetItem ? assetItem.vehicle_badge : null
}

export const getProvinces = /*istanbul ignore next */ () => {
  const { master } = store.getState()
  const province = master.getProvinces

  return province
}

export const getProvinceName = () => {
  const lang = checkLanguage()

  return 'province_name_' + lang
}

export const isInWarranty = (vehicleObj) => {
  if (isEmpty(vehicleObj.warranty)) return false

  let mileage = false,
    expired = false

  // check expiry by mileage
  if (vehicleObj.last_service_mileage) {
    if (vehicleObj.last_service_mileage >= vehicleObj.warranty.warranty_mileage) mileage = true
  }

  // check expiry by date
  const now = moment()
  if (moment(vehicleObj.warranty.warranty_end) < now) expired = true

  if (mileage || expired) {
    return false
  } else {
    return true
  }
}

export const isISP = (vehicleObj) => {
  if (isEmpty(vehicleObj.isp_information)) return false
  else return true
}

export const ISPExpired = (vehicleObj) => {
  const now = moment()
  if (!isEmpty(vehicleObj.isp_information)) {
    if (now > moment(vehicleObj.isp_information.isp_end_date)) return true
    else return false
  } else return false
}

export const getDisplayCompany = (corporate) => {
  if (!corporate || isEmpty(corporate)) {
    return ''
  }

  const { company_prefix, company_name } = corporate

  return company_prefix === 'อื่นๆ' ? `${company_name}` : `${company_prefix} ${company_name}`
}

export const isHTMLTag = (htmlString) => {
  return /<\/?[a-z][\s\S]*>/i.test(htmlString)
}

/**
 * get owner id by key
 *
 * @param {object} - user
 * @returns {string} - ownerId
 */
export const getOwnerId = (user) => {
  if (isEmpty(user)) {
    return ''
  }

  // Passport
  if (user.passport_no) {
    return user.passport_no
  }

  // Company Registration Id
  if (user.company_reg_id) {
    return user.company_reg_id
  }

  // National Id
  if (user.national_id) {
    return user.national_id
  }
}

export const nodeRender = (current_status, next_status, remaining) => {
  let max_remaining
  let status = []
  let position

  const all_status = Object.values(EXCELLENCY_STATUS)

  if (all_status.includes(current_status)) {
    max_remaining = MAX_REMAINING[current_status.toUpperCase()]

    const car_position = (max_remaining - remaining) / max_remaining
    position = max_remaining - remaining > 0 ? `${max_remaining - remaining}${max_remaining}` : '0'

    for (let i = 0; i < max_remaining + 1; i++) {
      const current_position = i / max_remaining
      if (current_position === car_position) {
        if (current_position === 0) {
          status.push(`${current_status}_current`)
        } else {
          status.push('normal_current')
        }
      } else if (current_position < car_position) {
        if (current_position === 0) {
          status.push(`${current_status}_previous`)
        } else {
          status.push('normal_previous')
        }
      } else if (current_position === 0) {
        status.push(`${current_status}_start`)
      } else if (current_position === 1) {
        status.push(`${next_status}_next`)
      } else {
        status.push('normal')
      }
    }
  } else {
    if (next_status === EXCELLENCY_STATUS.BRONZE) {
      max_remaining = 2
    } else {
      // For N-Seires next status WHITE
      max_remaining = 2
    }

    const car_position = (max_remaining - remaining) / max_remaining
    position = max_remaining - remaining > 0 ? `${max_remaining - remaining}${max_remaining}` : '0'

    for (let i = 0; i < max_remaining + 1; i++) {
      const current_position = i / max_remaining
      if (current_position === car_position) {
        if (current_position === 0) {
          status.push('normal_current_start')
        } else {
          status.push('normal_current')
        }
      } else if (current_position < car_position) {
        if (current_position === 0) {
          status.push('normal_start')
        } else {
          status.push('normal_previous')
        }
      } else if (current_position === 1) {
        status.push(`${next_status}_next`)
      } else {
        status.push('normal')
      }
    }
  }

  return { status, position }
}

/**
 * @param {string|null} [path=''] Path to navigate to
 * @param {object} [query={}] Query object
 */
export const openMyISUZUApp = async (path = '', query = {}) => {
  const link = process.env.REACT_APP_LINK

  const payload = {
    dynamicLinkInfo: {
      domainUriPrefix: process.env.REACT_APP_DOMAIN_URI_PREFIX,
      link: stringifyUrl({ url: `${link}${path || ''}`, query: query }),
      androidInfo: {
        androidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME
      },
      iosInfo: {
        iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID,
        iosAppStoreId: process.env.REACT_APP_IOS_APP_STORE_ID
      },
      navigationInfo: {
        enableForcedRedirect: true
      }
    }
  }

  try {
    const result = await api.generateDynamicLink(payload)
    const link = result.data.shortLink
    window.location.href = link
  } catch (err) {
    store.dispatch(showAlert(alert(0, '')))
  }
}

export const openAppStoreOrPlayStore = () => {
  if (isIOS) {
    window.location.href = appStoreUrl
    return
  }
  if (isAndroid) {
    window.location.href = playStoreUrl
    return
  }
}

export const getAppStoreOrPlayStoreUrl = () => {
  if (isIOS) {
    return appStoreUrl
  }
  if (isAndroid) {
    return playStoreUrl
  }
}

export const checkError = (err) => {
  if (err && err.isAxiosError) {
    if (err && err.response && err.response.status < 500) {
      console.log('axios 4xx', err)
    } else if (!err.response) {
      console.log('no response', err)
    } else {
      // https://tripetch.atlassian.net/browse/MIM-3714
      // We'd like to gain more visibility on the error object when analyzing on Datadog
      console.error('unexpected axios error', JSON.stringify(err, null, 2))
    }
  } else {
    console.log('not an axios error')
    throw err
  }
}
