import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, intlShape } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import ButtonBack from 'components/elements/ButtonBack'
import VehicleItem from 'domain/Vehicle/components/VehicleItem'
import Button from 'components/elements/Button'
import TabBar from 'components/elements/TabBar'
import ListPager from 'components/elements/ListPager'
import Modal from 'components/elements/Modal'

import { deleteController, getControllerDetail, getControllerVehicleStats, resendControllerInvitation } from 'store/actions/controllers'
import { getVehicles } from 'store/actions/vehicles'

import { USER_TYPE, USER_TYPE_RESOLVER } from 'config/constant'

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import './_index.scss'
import { PROFILE } from 'store/definitions'

// Utils
import { checkError } from 'utils/helper'

const PAGE_LIMIT = 50

const ControllerDetail = (_, context) => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [isList, setIsList] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(1)
  const [offset, setOffset] = useState(0)
  const modalRef = useRef()
  const pagingRef = useRef()

  const controllerDetail = useSelector((state) => state.controllerDetail)
  const profile = useSelector((state) => state.profile)
  const vehicles = useSelector((state) => state.vehicles.data)

  dispatch({
    type: PROFILE.PAGEVIEW
  })
  const handleChangePage = (_offset) => {
    setOffset(_offset)

    // force this function return "true" to make reduxActionGet of ListPager working correctly
    return true
  }

  const handleDeleteController = async () => {
    try {
      await dispatch(deleteController([{ id: controllerDetail.id }]))

      if (history) {
        history.push('/controllers')
      }
    } catch (err) {
      checkError(err)
    }
  }

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.openModal()
    }
  }

  const handleResend = () => {
    dispatch(resendControllerInvitation(params.id))
  }

  useEffect(() => {
    dispatch(getControllerDetail(params.id)).catch((err) => {
      checkError(err)
    })
    dispatch(getControllerVehicleStats(params.id)).catch((err) => {
      checkError(err)
    })
  }, [dispatch, params, getControllerDetail, getControllerVehicleStats])

  useEffect(() => {
    dispatch(getVehicles(true, '', 1, offset, PAGE_LIMIT, 0, 0, params.id, 'all', !!activeTabIndex)).catch((err) => {
      checkError(err)
    })
  }, [dispatch, params, offset, activeTabIndex, getVehicles])

  const totalCount = useMemo(() => {
    // all vehicles
    if (activeTabIndex === 0) {
      return (controllerDetail.vehicleStats && controllerDetail.vehicleStats.all) || 0
    }

    // next maintenance
    if (activeTabIndex === 1) {
      return (controllerDetail.vehicleStats && controllerDetail.vehicleStats.next_maintenance) || 0
    }
  }, [activeTabIndex, controllerDetail])

  const controllerInformationItem = (
    <div className='controller-detail__body'>
      <div className='controller-detail__body-wrapper'>
        <div className='info'>
          <div className='controller-detail__body__info controller-detail__body__info__name'>
            <span>
              <FormattedMessage id='common.labels.name' defaultMessage='common.labels.name' />
            </span>
            <div className='controller-detail__body__info__name__value'>{controllerDetail.full_name || '-'}</div>
          </div>
          <div className='controller-detail__body__info__group'>
            <div className='controller-detail__body__info controller-detail__body__info__username'>
              <span>
                <FormattedMessage id='common.labels.username' defaultMessage='common.labels.username' />
              </span>
              <div className='controller-detail__body__info__username controller-detail__body__info__username__value'>
                {controllerDetail.username || '-'}
              </div>
            </div>
            <div className='controller-detail__body__info controller-detail__body__info__email'>
              <span>
                <FormattedMessage id='common.labels.email' defaultMessage='common.labels.email' />
              </span>
              <div className='controller-detail__body__info__email__value'>{controllerDetail.email || '-'}</div>
            </div>
          </div>
          <div className='controller-detail__body__info__group'>
            <div className='controller-detail__body__info controller-detail__body__info__mobile-phone'>
              <span>
                <FormattedMessage id='common.labels.mobilePhone' defaultMessage='common.labels.mobilePhone' />
              </span>
              <div className='controller-detail__body__info__mobile-phone__value'>{controllerDetail.mobile_phone || '-'}</div>
            </div>
            <div className='controller-detail__body__info controller-detail__body__info__office-phone'>
              <span>
                <FormattedMessage id='common.labels.officePhone' defaultMessage='common.labels.officePhone' />
              </span>
              <div className='controller-detail__body__info__office-phone__value'>{controllerDetail.office_phone || '-'}</div>
            </div>
            <div className='controller-detail__body__info controller-detail__body__info__office-phone-ext'>
              <span>
                <FormattedMessage id='common.labels.officePhoneExt' defaultMessage='common.labels.officePhoneExt' />
              </span>
              <div className='controller-detail__body__info__office-phone-ext__value'>{controllerDetail.office_phone_ext || '-'}</div>
            </div>
          </div>

          <div className='controller-detail__body__info__group'>
            <div className='controller-detail__body__info controller-detail__body__info__assignment'>
              <span>
                <FormattedMessage id='controller.detail.assignment' defaultMessage='controller.detail.assignment' />
              </span>
              <div className='controller-detail__body__info__assignment__value'>
                <FormattedMessage
                  id='controller.detail.units'
                  defaultMessage='controller.detail.units'
                  values={{ value: (controllerDetail.vehicleStats && controllerDetail.vehicleStats.all) || 0 }}
                />
              </div>
            </div>

            <div className='controller-detail__body__info status'>
              <div>
                <span>
                  <FormattedMessage id='controller.detail.status' defaultMessage='controller.detail.status' />
                </span>
                <div className='controller-detail__body__info__username'>
                  {controllerDetail.status === 201 ? (
                    <FormattedMessage id='controller.detail.active' defaultMessage='controller.detail.active' />
                  ) : (
                    <FormattedMessage id='controller.detail.inactive' defaultMessage='controller.detail.inactive' />
                  )}
                </div>
              </div>
              {controllerDetail.status !== 201 && (
                <Button
                  type='button'
                  color='primary'
                  label='controller.detail.resend'
                  className='controller-detail__body__info__resend--button'
                  onClick={handleResend}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='controller-detail'>
      <div className='controller-detail__header'>
        <ButtonBack defaultPath='/controllers' className='button-back' />
        <Button
          className='controller-detail__header--delete'
          icon='delete'
          type='delete'
          label='controller.detail.delete'
          onClick={handleOpenModal}
        />
        <Button
          className='change-view-sm'
          color='transparent'
          type='small'
          icon={isList ? 'view_module' : 'list'}
          label={isList ? 'common.labels.gridView' : 'common.labels.listView'}
          onClick={() => setIsList(!isList)}
        />
      </div>

      {controllerInformationItem}

      <div className='controller-detail__vehicles'>
        <div className='controller-detail__vehicles__tabs'>
          <div className='controller-detail__vehicles__tabs__wrapper'>
            <TabBar
              tabs={[
                context.intl.formatMessage(
                  {
                    key: 'allVehicleUnits',
                    id: 'controller.detail.allVehicleUnits',
                    defaultMessage: 'controller.detail.allVehicleUnits'
                  },
                  { value: (controllerDetail.vehicleStats && controllerDetail.vehicleStats.all) || 0 }
                ),

                context.intl.formatMessage(
                  {
                    key: 'nextMaintenanceVehicleUnits',
                    id: 'controller.detail.nextMaintenanceVehicleUnits',
                    defaultMessage: 'controller.detail.nextMaintenanceVehicleUnits'
                  },
                  {
                    value: (controllerDetail.vehicleStats && controllerDetail.vehicleStats.next_maintenance) || 0
                  }
                )
              ]}
              colors={['normal', 'favorite']}
              activeIndex={activeTabIndex}
              onActiveIndexChange={setActiveTabIndex}
              isFolder
            />

            <Button
              className='change-view'
              color='transparent'
              type='small'
              icon={isList ? 'view_module' : 'list'}
              label={isList ? 'common.labels.gridView' : 'common.labels.listView'}
              onClick={() => setIsList(!isList)}
            />
          </div>
        </div>

        <div className='controller-detail__vehicles__tabs__pager'>
          <ListPager limit={PAGE_LIMIT} total={totalCount} type={profile.type} ref={pagingRef} reduxActionGet={handleChangePage} />
        </div>
      </div>
      <div className='controller-detail__vehicles__detail'>
        <div className='controller-detail__vehicles__list'>
          {isEmpty(vehicles) && (
            <div className='controller-detail__vehicles__list__novehicles'>
              <FormattedMessage id='common.labels.noVehicles1' defaultMessage='common.labels.noVehicles1' />
            </div>
          )}
          {!isEmpty(vehicles) && isList && (
            <div className={'vehicles__body__list--header ' + USER_TYPE_RESOLVER[USER_TYPE.CORPORATE].toLowerCase()}>
              <div className='vehicles__body__list--header__item name'>
                <FormattedMessage id='vehicle.list.listName' defaultMessage='vehicle.list.listName' />
              </div>
              <div className='vehicles__body__list--header__item assignment-column'>
                <FormattedMessage id='vehicle.list.assignTo' defaultMessage='vehicle.list.assignTo' />
              </div>
              <div className='vehicles__body__list--header__item last-maintenance-date'>
                <FormattedMessage id='vehicle.list.lastMaintenanceDate' defaultMessage='vehicle.list.lastMaintenanceDate' />
              </div>
              <div className='vehicles__body__list--header__item next-main'>
                <FormattedMessage id='vehicle.list.listMaintenance' defaultMessage='vehicle.list.listMaintenance' />
              </div>
              <div className='vehicles__body__list--header__item age'>
                <FormattedMessage id='vehicle.list.listAge' defaultMessage='vehicle.list.listAge' />
              </div>
              <div className='vehicles__body__list--header__item type'>
                <FormattedMessage id='vehicle.list.vehicleType' defaultMessage='vehicle.list.vehicleType' />
              </div>
              <div className='vehicles__body__list--header__item driver-name'>
                <FormattedMessage id='vehicle.list.driverName' defaultMessage='vehicle.list.driverName' />
              </div>
              <div className='vehicles__body__list--header__item fav'>&nbsp;</div>
            </div>
          )}
          {!isEmpty(vehicles) && (
            <div className='list'>
              {vehicles.map((data) => {
                data = omit(data, 'is_checked')
                return (
                  <VehicleItem
                    key={data.id}
                    detail={data}
                    userType={profile.type}
                    isList={isList}
                    withCheck={false}
                    fromPage='Controller Details'
                  />
                )
              })}
            </div>
          )}
          <div className='controller-detail__vehicles__list__action'>
            <Button className='delete' iconOnly icon='delete' type='delete' label='controller.detail.delete' onClick={handleOpenModal} />
          </div>
        </div>
      </div>

      <Modal
        mode={2}
        title='controller.delete.title'
        content='controller.delete.description'
        onConfirmed={handleDeleteController}
        ref={modalRef}
      />
    </div>
  )
}

ControllerDetail.contextTypes = {
  intl: intlShape
}

export default ControllerDetail
