import React, { useEffect } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// classnames
import classnames from 'classnames'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import ContentHeader from 'components/layouts/ContentHeader'

// Actions
import { getPromotionDetail } from 'store/actions/promotions'

// Method
import { checkError } from 'utils/helper'

// Styles
import './_index.scss'
import DetailPromotion from 'domain/Promotions/components/DetailPromotion'
import { postPageView } from 'store/actions/profile'
import { isMobileApp } from 'utils/validation'

const PromotionDetail = ({ match }) => {
  const promotionDetail = useSelector((state) => state.promotionDetail)
  const profile = useSelector((state) => state.profile)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPromotionDetail(match.params.id)).catch((err) => {
      checkError(err)
    })
    dispatch(postPageView)
  }, [])

  const classes = classnames('promotion-detail')

  return (
    <div className={classes}>
      <ContentHeader title={'promotion.title'} subTitle={'promotion.subTitle'} />
      <DetailHeader
        showBack
        defaultPath={isMobileApp() ? '/' : '/promotions'}
        type='basic'
        detail={promotionDetail}
        userType={profile.type}
      />

      <DetailPromotion detail={promotionDetail} />
    </div>
  )
}

PromotionDetail.propTypes = {
  getPromotionDetail: PropTypes.func,
  promotionDetail: PropTypes.object,
  profile: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

export default PromotionDetail
