import React from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Button from 'components/elements/Button'

// i18n
import { FormattedMessage } from 'react-intl'

// Styles
import './_index.scss'

const VehicleAction = ({ selectedVehicles, onClose, onAssign, onUnassign, onRemove }) => {
  const classes = classnames('vehicle-action')

  return (
    <div className={classes}>
      {selectedVehicles.length > 0 && (
        <div className='vehicle-action__wrapper'>
          <div className='vehicle-action__left'>
            <Button onClick={onClose} icon='close' iconOnly />
          </div>
          <div className='vehicle-action__right'>
            <div className='vehicle-action__right__primary'>
              <Button onClick={onAssign} className='vehicle-action__right__assign' type='small' label='vehicle.list.assign' />
              <Button onClick={onUnassign} className='vehicle-action__right__unassign' type='small' label='vehicle.list.unassign' />
            </div>
            <span className='vehicle-action__right__label'>
              <FormattedMessage id='vehicle.list.selectedVehiclesUnits' values={{ value: selectedVehicles.length }} />
            </span>
            <Button onClick={onRemove} className='vehicle-action__right__clear' type='small' label='vehicle.list.delete' />
            <Button onClick={onRemove} className='vehicle-action__right__clear--icon' type='small' icon='delete' />
          </div>
        </div>
      )}
    </div>
  )
}

VehicleAction.propTypes = {
  selectedVehicles: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default VehicleAction
