import { LOADER } from 'store/definitions'

const initialState = false

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADER.SHOW:
      return true

    case LOADER.HIDE:
      return initialState

    default:
      return state
  }
}
