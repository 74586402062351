import React from 'react'

// Styles
import './_index.scss'

const TermofService = ({ lang }) => {
  return (
    <div className='terms_section'>
      {lang === 'en' && (
        <div className='terms-cond-setting'>
          {/* <h2 className="terms-cond__title">my-ISUZU Web Application <br/>Terms &amp; Conditions </h2> */}
          <h3>Definitions</h3>
          <ul className='terms-cond__ul-1'>
            <li>
              “my-ISUZU” means my-ISUZU Application (Web & Mobile App) which is online platform for providing information owned by Tri Petch
              Isuzu Sales Co., Ltd. for the owner and user of Isuzu vehicle.
            </li>
            <li>“Company” means Tri Petch Isuzu Sales Co., Ltd.</li>
            <li>
              “Tri Petch Group” means companies which Tri Petch Isuzu Sales Co., Ltd., and Tri Petch Holding Co., Ltd., holds the share
              whether directly or indirectly.
            </li>
            <li>“Vehicle” means Isuzu Vehicle.</li>
            <li>
              “Dealer” means Isuzu Authorized Dealer as per the name list in the website&nbsp;{' '}
              <a href='http://www.isuzu-tis.com'>www.isuzu-tis.com</a>.
            </li>
            <li>“User” means my-ISUZU user(s).</li>
          </ul>
          <ol className='terms-cond__ol-1'>
            <li>
              <strong>1. Membership Condition</strong>
              <ol>
                <li>
                  1.1 In case User is ordinary person, User must have the following qualification:
                  <ol className='terms-cond__ol-2'>
                    <li>
                      1) User must have a name and surname matched with the name and surname in the Vehicle registration book with the
                      status as owner.
                    </li>
                    <li>
                      2) In case of hire purchasing or leasing, User must have the name and surname matched with the name and surname in
                      Vehicle registration book with the status as possessor, or being the vehicle’s User in the system.
                    </li>
                  </ol>
                </li>
                <li>
                  1.2 In case User is a juristic person, for example, company, partnership, User must have the following qualification:
                  <ol className='terms-cond__ol-2'>
                    <li>
                      1) User must have juristic person name matched with the name and surname in the Vehicle registration book with the
                      status as owner.
                    </li>
                    <li>
                      2) In case of hire purchasing or leasing, User must have a juristic name matched with the name and surname in Vehicle
                      registration book with the status as possessor, or being the vehicle’s User in the system.
                    </li>
                  </ol>
                </li>
                <li>
                  1.3 In case a second handed Vehicle purchased from the previous owner, the new owner or possessor must show the evidence
                  of ownership to the Company’s official and inform them to change the owner name in the system, and then he/she can apply
                  and register for the membership.
                </li>
                <li>
                  1.4 Vehicle which is not sold by Tri Petch Isuzu Sales Co., Ltd., or Isuzu Dealer in Thailand shall not be registered to
                  use my-ISUZU service.
                </li>
              </ol>
            </li>
            <li>
              <strong>2. my-ISUZU Terms of Use</strong>
              <ol>
                <li>
                  2.1 Create User Account
                  <ol className='terms-cond__ol-2'>
                    <li>
                      1) User who will use my-ISUZU must be registered to create a user account by using ID card number or passport number
                      as the main information to create a user account. User must fill in their mobile phone number, and may fill in
                      username,&nbsp;home telephone number, email for company reference.
                    </li>
                    <li>
                      2) User who registers to use my-ISUZU must choose his/her own unique password (“Password”) to use with Username or ID
                      card number.
                    </li>
                    <li>3) User must be responsible for arrangement, change, keep safe &amp; secure his/her own username and Password.</li>
                  </ol>
                </li>
                <li>
                  2.2 Sign in
                  <br />
                  Using my-ISUZU is limited for User who is registered as member of my-ISUZU only, details of which as follows:
                  <ol className='terms-cond__ol-2'>
                    <li>
                      1) User can sign in my-ISUZU by two options:
                      <ul>
                        <li>
                          Sign in by using username or ID card number with password (in case of ordinary person). Sign in by using username,
                          email, or Tax ID with password (In case of a juristic person as Admin) and sign in by using username, or email
                          with password (In case of a juristic person as Controller).
                        </li>
                        <li>
                          Sign in by using a social media account for example Line, which must be according to the procedure and conditions
                          of the said social media.
                        </li>
                      </ul>
                    </li>
                    <li>
                      2) User must be responsible and must be under terms and condition in using Line Application and must be responsible
                      for content, conversation, communication, chat message with the couther parties, Isuzu Dealer, official or staff of
                      Isuzu Dealer.
                    </li>
                    <li>
                      3) User must be responsible for the setting user account including the adjustment or customization Vehicle list and
                      all pictures uploaded to the user account.
                    </li>
                  </ol>
                </li>

                <li>
                  2.3 Posting pictures in my-ISUZU
                  <ol className='terms-cond__ol-2'>
                    <li>For posting pictures in the user account, User can do the privacy setting in each posting in 2 types: </li>
                    <li>
                      “Only Me”. Pictures and descriptions posted shall appear only to User who posts it, other my-ISUZU User cannot see
                      them.
                    </li>
                    <li>
                      “Public”. Pictures and descriptions posted shall appear to all my-ISUZU Users to see. Other my-ISUZU User can have
                      following interaction with the posted pictures:
                    </li>
                  </ol>
                  <ul className='terms-cond__ul-1 dist'>
                    <li>Use button(s) (Like/Love/Funny/Wow) to share your reaction for the post.</li>
                    <li>Save pictures in the Bookmark collection.</li>
                    <li>Share picture(s) to other social media as per such social media’s terms and conditions.</li>
                  </ul>
                  <p>
                    When using the Service, User may not conduct any act that falls under, or is determined by the Company to fall under,
                    any of the following:
                  </p>
                  <ol className='terms-cond__ol-2'>
                    <li>1) Acts that violate any laws or regulations or that are associated with criminal activity;</li>
                    <li>2) Acts that defraud or threaten the Company, other Registered Users or other third parties :</li>
                    <li>3) Acts against public order and good morals;</li>
                    <li>
                      4) Acts that infringe any IP Rights, portrait rights, privacy rights, reputation or other rights or interests of the
                      Company, other Registered Users or other third parties;
                    </li>
                    <li>
                      5) Acts to transmit through the Service to other Registered Users any information or data th that:
                      <ol className='terms-cond__ol-1'>
                        <li>- contains excessively violent or cruel content;</li>
                        <li>- contains computer viruses or other hazardous computer programs;</li>
                        <li>
                          - contains content that damages the reputation or the credit of the Company, other users of the Service or other
                          third parties;
                        </li>
                        <li>- contains excessively indecent content;</li>
                        <li>- contains content that encourages discrimination;</li>
                        <li>- contains content that encourages suicide or self-mutilation;</li>
                        <li>- contains content that encourages drug abuse;</li>
                        <li>- contains antisocial content; </li>
                        <li>- is intended to be distributed to third parties , including but not limited to chain mails; or </li>
                        <li>- contains content that causes uncomfortable feelings to third parties.</li>
                      </ol>
                    </li>
                    <li>6) Acts that place an excessive burden on the network or system o the service;</li>
                    <li>7) Acts to reverse-engineer or otherwise analyze the software or other systems provided by the Company;</li>
                    <li>8) Acts that are likely to interrupt the operation of the Service;</li>
                    <li>9) Acts to access the network or system of the Company improperly;</li>
                    <li>10) Acts to impersonate a third party;</li>
                    <li>11) Acts to use the User ID or password of other users of the Service;</li>
                    <li>12) Acts of exploitation for soliciting marketing through the Service without the Company’s prior consent;</li>
                    <li>13) Acts to collect information of other users of the Service;</li>
                    <li>
                      14) Acts that cause disadvantage, damage or uncomfortable feelings to other users of the Service or other third
                      parties;
                    </li>
                    <li>15) Acts that violate this Terms & Conditions;</li>
                    <li>16) Acts to provide Antisocial Forces with profit;</li>
                    <li>17) Acts that are intended to meet unacquainted persons for dating or relationship;</li>
                    <li>18) Other acts that the Company deems to be inappropriate.</li>
                  </ol>
                  <br />
                  <p>
                    In case User performs in any action in 2.3 or violates any provision of this Terms & Conditions, the Company may (1)
                    delete or cease to show the post; (2) temporarily cease the usage of such User; or (3) cancel the membership or service
                    to such User as per 2.4 without notifying the customer in advance.
                  </p>
                </li>

                <li>
                  2.4 Termination of Membership
                  <ol className='terms-cond__ol-2'>
                    <li>1) User can terminate the membership by following the procedure as specified in my-ISUZU.</li>
                    <li>
                      2) The Company would reserve the right to revise, change, review, cancel, and or revoke the membership or any benefit
                      including terms and conditions at any time without notifying User in advance.
                    </li>
                    <li>
                      3) The Company would reserve the right to terminate my-ISUZU, entirely or partially. Also, the Company reserves the
                      right to adjust, improve, move or change the service site of my-ISUZU without notifying User in advance.
                    </li>
                    <li>
                      4) Consent Withdrawal for collecting and disclosing Personal Data through channels as provided by the Company shall
                      not be deemed cancellation or revocation of my-ISUZU automatically. If you would like to stop using my-ISUZU, you can
                      terminate the user account in my-ISUZU.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>3. General Terms and Condition</strong>
              <ol>
                <li>
                  3.1 Improvement
                  <br />
                  The Company reserves the right to improve these Terms and Conditions without prior notice to User for the appropriateness
                  and efficiency in providing the service. Therefore, the Company recommends that Users read these Terms &amp; Conditions
                  every time a User visits or obtains any service from my-ISUZU.
                </li>
                <li>
                  3.2 Disclaimer of my-ISUZU
                  <br />
                  The Information whether general information, technical information, specific information provided and appeared in my-ISUZU
                  is provided “as is” and without warranty of any kind expressed or implied, including (but not limited to) any implied
                  warranties of merchantability, fitness for any particular purpose, or non-infringement of third party rights. The Company
                  believes this information is provided as the most up-to-date information as of the date of issuance or each improvement.
                  It may possibly contain some errors and inaccuracies from display which the Company is not responsible for information in
                  the display and my-ISUZU&apos;s content maintained by the third party or failure from any links from my-ISUZU to other
                  website(s).
                  <br />
                  <br />
                  <p>
                    The Company reserves the right to adjust or change information contains herein whether the series, color, model,
                    technical information of product, after sale service information, advertisement, marketing activities information,
                    knowledge sharing information without prior notice
                  </p>
                </li>
                <li>
                  3.3 Limitation of Liability
                  <br />
                  The Company and Tri Petch Group Companies are not responsible and liable to user for any damage whether directly or
                  indirectly in the following events:
                  <ol className='terms-cond__ol-2'>
                    <li>1) Stealing password or using User’s information to log in to user account by third-party.</li>
                    <li>2) Revise, change in Account Setting or customization of user account by User or third-party.</li>
                    <li>3) Reject, cancel or revoke the user account registration according to clause 1.5.</li>
                    <li>4) Technical problem including problem due to internet connection of User and the internet provider.</li>
                    <li>
                      5) In case User cannot obtain service through my-ISUZU or cannot join the activity arranged by or through my-ISUZU due
                      to internet connection of User and the internet provider.&nbsp;
                    </li>
                    <li>
                      6) The Company and Tri Petch Group Companies are not responsible and liable for content of information, service or
                      news which User directly receive from the third party for example other business operator(s), service provider(s),
                      business partner(s). In case of any conflict or dispute between or among &nbsp;User and any third party for example
                      other business operator(s), service provider(s), business partner(s), we suggest User to deal and settle conflict or
                      dispute with the third party on User own account and you shall not claim from the Company or Tri Petch Group
                      Companies.
                    </li>
                  </ol>
                </li>
                <li>
                  3.4 Intellectual Property
                  <p>
                    The Company reserves the right in content, picture, graphic, digital file both photo and motion picture, video file and
                    any design as appeared on this my ISUZU Web Application. There objected may not be copied, duplicated, modified, sold or
                    distributed for commercial purpose or use in any purpose other than personal use of my ISUZU Web Application unless
                    obtaining the permission from the Company or related owner of such Intellectual Property Right in advance.
                  </p>
                </li>
                <li>
                  3.5 Applicable Law
                  <p>This Privacy Policy shall be governed under Thai Law.</p>
                </li>

                <li>
                  3.6 Severability
                  <p>
                    In case any content of these Terms & Conditions shall be invalid, illegal or unenforceable, the validity, legality and
                    enforceability of the remaining provisions shall not in any way be affected or impaired thereby.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      )}

      {lang === 'th' && (
        <div className='terms-cond-setting'>
          <h3>นิยาม</h3>
          <ul className='terms-cond__ul-1'>
            <li>
              “my-ISUZU” หมายถึง แอปพลิเคชัน my-ISUZU ซึ่งเป็นแพลตฟอร์มเพื่อให้บริการข้อมูลออนไลน์ของ บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด
              แก่เจ้าของและผู้ใช้งานรถยนต์อีซูซุ
            </li>
            <li>“บริษัทฯ” หมายถึง บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด</li>
            <li>
              “บริษัทในกลุ่มตรีเพชร” หมายถึง บริษัทที่บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด หรือบริษัท ตรีเพชรโฮลดิ้ง จำกัด
              ถือหุ้นไม่ว่าโดยตรงหรือโดยอ้อม
            </li>
            <li>“รถยนต์” หมายถึง รถยนต์ ยี่ห้ออีซูซุ</li>
            <li>
              “ผู้จำหน่ายอีซูซุ” หมายถึง ผู้จำหน่ายรถยนต์อีซูซุที่ได้รับการแต่งตั้ง (Isuzu Authorized Dealer) รายชื่อ ตามที่ปรากฎในเว็ปไซต์
              &nbsp;
              <a href='http://www.isuzu-tis.com'>www.isuzu-tis.com</a>
            </li>
            <li>“ผู้ใช้งาน” หมายถึง ผู้ใช้งาน my-ISUZU</li>
          </ul>
          <ol className='terms-cond__ol'>
            <li>
              <strong>1. เงื่อนไขการสมัครสมาชิก</strong>
              <ol>
                <li>
                  1.1&nbsp;กรณีผู้ใช้งานเป็นบุคคลธรรมดา ผู้ใช้งานต้องมีคุณสมบัติดังต่อไปนี้
                  <ol className='terms-cond__ol'>
                    <li>1) ผู้ใช้งานต้องมีชื่อและนามสกุลตรงกับชื่อในสมุดคู่มือจดทะเบียนรถยนต์ และมีสถานะเป็นเจ้าของรถ</li>
                    <li>
                      2) กรณีเช่าซื้อรถยนต์หรือลิซซิ่ง ผู้ใช้งานต้องมีชื่อและนามสกุลตรงกับชื่อผู้ครอบครองในสมุดคู่มือจดทะเบียนรถยนต์
                      หรือเป็นผู้ใช้รถยนต์ในระบบ
                    </li>
                  </ol>
                </li>
                <li>
                  1.2&nbsp;กรณีผู้ใช้งานเป็นนิติบุคคล เช่น รถยนต์ในนามบริษัท ห้างหุ้นส่วนจำกัด ฯลฯ ผู้ใช้งานต้องมีคุณสมบัติดังต่อไปนี้
                  <ol className='terms-cond__ol'>
                    <li>1) ผู้ใช้งานต้องมีชื่อและนามสกุลตรงกับชื่อในสมุดคู่มือจดทะเบียนรถยนต์ และมีสถานะเป็นเจ้าของรถ &nbsp;</li>
                    <li>
                      2) กรณีเช่าซื้อรถยนต์หรือลิซซิ่ง ผู้ใช้งานต้องมีชื่อและนามสกุลตรงกับชื่อผู้ครอบครองในสมุดคู่มือจดทะเบียนรถยนต์
                      หรือเป็นผู้ใช้รถยนต์ในระบบ
                    </li>
                  </ol>
                </li>
                <li>
                  1.3&nbsp;กรณีรถยนต์มือสองที่ซื้อจากเจ้าของรถยนต์เดิม
                  &nbsp;เจ้าของรถยนต์หรือผู้ครอบครองใหม่จะต้องแสดงหลักฐานความเป็นเจ้าของรถต่อเจ้าหน้าที่ของบริษัทฯ
                  และแจ้งเปลี่ยนชื่อผู้ใช้รถยนต์ในระบบ &nbsp; จึงจะสามารถสมัครและลงทะเบียนเป็นสมาชิกได้ &nbsp;
                </li>
                <li>
                  1.4&nbsp;รถยนต์ที่ไม่ได้จัดจำหน่ายโดยบริษัท ตรีเพชรอีซูซุเซลส์ จำกัด หรือ ผู้จำหน่ายอีซูซุที่ได้รับแต่งตั้งในประเทศ
                  ไม่สามารถสมัครเป็นสมาชิกเพื่อใช้งาน &nbsp;my-ISUZU ได้&nbsp;
                </li>
              </ol>
            </li>
            <li>
              <strong>2. เงื่อนไขการใช้ my-ISUZU</strong>
              <ol>
                <li>
                  2.1&nbsp;การสร้างบัญชีผู้ใช้งาน (Create User Account)
                  <ol className='terms-cond__ol'>
                    <li>
                      1) ผู้ใช้งานที่จะใช้งาน my-ISUZU จะต้องลงทะเบียนเพื่อสร้างบัญชีผู้ใช้งาน (Create User Account) โดยใช้ข้อมูล
                      เลขบัตรประชาชน หรือ เลขหนังสือเดินทาง เป็นข้อมูลหลักเพื่อการสร้างบัญชีผู้ใช้งาน โดยผู้ใช้งานจะต้องระบุข้อมูล
                      เบอร์โทรศัพท์มือถือ และอาจระบุ &nbsp;ชื่อผู้ใช้งาน (Username) เบอร์โทรศัพท์บ้าน อีเมลล์
                      เพิ่มเติมเป็นข้อมูลให้กับบริษัทก็ได้&nbsp;
                    </li>
                    <li>
                      2) ผู้ใช้งานที่ลงทะเบียนเพื่อใช้งาน my-ISUZU จะต้องกำหนด รหัสผ่านที่มีเอกลักษณ์เฉพาะของตนเอง (“รหัสผ่าน”)
                      เพื่อใช้ประกอบกับชื่อผู้ใช้งาน (Username) เลขบัตรประชาชน
                    </li>
                    <li>3) ผู้ใช้งานต้องรับผิดชอบในการจัดการ เปลี่ยนแปลง เก็บรักษา ชื่อผู้ใช้งานและ รหัสผ่านของตนเอง</li>
                  </ol>
                </li>
                <li>
                  2.2&nbsp;การเข้าใช้งาน (Sign in)
                  <br />
                  การใช้งาน my-ISUZU นั้นจะจำกัดเฉพาะผู้ใช้งานที่ลงทะเบียนเป็นสมาชิกของ my-ISUZU เท่านั้น &nbsp;โดยมีรายละเอียดดังต่อไปนี้
                  <ol className='terms-cond__ol'>
                    <li>
                      1) ผู้ใช้งานสามารถเข้าใช้งาน my-ISUZU ได้สองวิธี กล่าวคือ
                      <ul>
                        <li>
                          การเข้าใช้งานโดย ใช้ชื่อผู้ใช้งาน (Username) หรือเลขบัตรประจำตัวประชาชน ประกอบกับ รหัสผ่านใน
                          (กรณีของผู้ใช้งานบุคคลธรรมดา) การเข้าใช้งานโดยใช้ชื่อผู้ใช้งาน อีเมลล์ หรือ เลขประจำตัวผู้เสียภาษีอากร ประกอบกับ
                          รหัสผ่านใน (กรณีของผู้ใช้งานนิติบุคคล ประเภทตัวแทนนิติบุคคล) และการเข้าใช้งานโดยใช้ชื่อผู้ใช้งาน หรือ อีเมลล์
                          &nbsp;ประกอบกับ รหัสผ่านใน (กรณีของผู้ใช้งานนิติบุคคล ประเภทผู้ดูแลรถ)
                        </li>
                        <li>
                          การเข้าใช้งานโดยบัญชีสื่อสังคมออนไลน์ (Social Media) เช่น Line ซึ่งต้องเป็นไปตามวิธีการ
                          เงื่อนไขของสื่อสังคมออนไลน์ (Social Media) นั้นๆ&nbsp;
                        </li>
                      </ul>
                    </li>
                    <li>
                      2) ผู้ใช้งานต้องรับผิดชอบและอยู่ภายใต้เงื่อนไขและข้อกำหนดในการใช้ LINE Application และรับผิดชอบเนื้อหา บทสนทนา
                      การสื่อสาร ข้อความกับคู่สนทนา ผู้จำหน่ายอีซูซุ เจ้าหน้าที่ของผู้จำหน่ายอีซูซุ
                    </li>
                    <li>
                      3) ผู้ใช้งานต้องรับผิดชอบเกี่ยวกับการตั้งค่าบัญชีผู้ใช้งาน รวมไปถึง การปรับเปลี่ยนรถยนต์ในรายการ และ
                      ภาพที่โพสต์ลงในบัญชีผู้ใช้งานของตนเอง
                    </li>
                  </ol>
                </li>
                <li>
                  2.3&nbsp;การโพสต์ภาพบน my-ISUZU
                  <ol className='terms-cond__ol'>
                    <li>ในการโพสต์ภาพในบัญชีผู้ใช้งาน ผู้ใช้งานสามารถตั้งค่าความเป็นส่วนตัวให้กับภาพที่โพสต์ เป็นสองลักษณะ ดังนี้</li>

                    <li>
                      “เฉพาะฉัน (Only Me)” ภาพและรายละเอียดที่โพสต์จะปรากฎให้ผู้ใช้งานเห็นเพียงผู้เดียวเท่านั้น ผู้ใช้งาน my-ISUZU
                      ท่านอื่นไม่สามารถมองเห็นได้
                    </li>
                    <li>
                      “สาธารณะ (Public)” ภาพที่โพสต์และรายละเอียดที่โพสต์จะปรากฎให้ ผู้ใช้งาน my-ISUZU ท่านอื่นสามารถมองเห็นได้ โดยผู้ใช้งาน
                      my-ISUZU ท่านอื่นสามารถมีกิจกรรมดังนี้กับภาพที่ท่านโพสต์
                    </li>
                    <ol className='terms-cond__ol'>
                      <ul>
                        <li>กดเครื่องหมาย (Like/Love/Funny/Wow) เพื่อแสดงความรู้สึกต่อภาพของท่าน</li>
                        <li>กดเซฟเพื่อจัดเก็บภาพใน Bookmark</li>
                        <li>แชร์ภาพออกไปยัง social media อื่นตามเงื่อนไขของ social media นั้นๆ</li>
                      </ul>
                    </ol>
                    <li> เมื่อใช้บริการ ผู้ใช้งานต้องไม่กระทำการที่เข้าลักษณะ</li>
                    <li> และไม่กระทำการที่บริษัทจะพิจารณาว่าจะเข้าลักษณะ ดังต่อไปนี้</li>

                    <li>1) การกระทำที่ละเมิดกฎหมายหรือระเบียบที่เกี่ยวข้องกับอาชญากรรม</li>
                    <li>2) การกระทำที่เป็นการโกงหรือข่มขู่บริษัท หรือ ผู้ใช้งานรายอื่น หรือ บุคคลที่สาม</li>
                    <li>3) การกระทำที่ฝ่าฝืนความสงบเรียบร้อยและศีลธรรมอันดี</li>
                    <li>
                      4) การกระทำที่ละเมิดสิทธิในทรัพย์สินทางปัญญา สิทธิในรูปภาพ สิทธิส่วนบุคคล ชื่อเสียง หรือสิทธิอื่นๆ
                      หรือประโยชน์ของบริษัท ผู้ใช้งาน หรือบุคคลที่สาม
                    </li>
                    <li>5) การกระทำ การโพสต์ หรือ แชร์เนื้อหาหรือภาพ ที่มีลักษณะดังต่อไปนี้</li>
                    <ol className='terms-cond__ol'>
                      <ul>
                        <li>มีเนื้อหาที่มีความรุนแรง</li>
                        <li>มีไวรัสคอมพิวเตอร์ หรือโปรแกรมที่จะเป็นอันตราย</li>
                        <li>
                          มีเนื้อหาที่ทำให้ชื่อเสียงหรือความน่าเชื่อถือของบริษัท หรือผู้ใช้บริการรายอื่น หรือบุคคลที่สาม ได้รับความเสียหาย
                        </li>
                        <li>มีเนื้อหาหยาบคายหรืออนาจาร</li>
                        <li>มีเนื้อหาสนับสนุนให้เกิดการเลือกปฏิบัติ</li>
                        <li>มีเนื้อหาสนับสนุนการฆ่าตัวตายหรือทำร้ายตนเอง</li>
                        <li>มีเนื้อหาสนับสนุนการใช้สารเสพติด</li>
                        <li>มีเนื้อหาต่อต้านสังคม</li>
                        <li>มีเจตนาเพื่อกระจายข้อความไปยังบุคคลที่สาม รวมถึงแต่ไม่จำกัดเพียง จดหมายลูกโซ่</li>
                        <li>มีเนื้อหาที่จะทำให้บุคคลที่สามรู้สึกไม่สบายใจ</li>
                      </ul>
                    </ol>
                    <li>6) การกระทำที่ทำให้เกิดภาระหนักขึ้นในเครือข่ายหรือระบบของบริการ</li>
                    <li>7) การกระทำเพื่อการทำวิศวกรย้อนกลับ (reverse engineering) หรือวิเคราะห์ซอฟแวร์หรือระบบอื่นใดที่บริษัทจัดให้</li>
                    <li>8) การกระทำที่เป็นการรบกวนขัดขวางการให้บริการ</li>
                    <li>9) การกระทำที่เป็นการเข้าสู่เครือข่ายหรือระบบของบริษัทโดยมิชอบ</li>
                    <li>10) การกระทำเพื่อลอกเลียนแบบบุคคลที่สาม</li>
                    <li>11) การกระทำเพื่อใช้ ชื่อสมาชิก หรือรหัสผู้ใช้ของผู้ใช้บริการคนอื่น</li>
                    <li>12) การกระทำที่เป็นการหาประโยชน์เพื่อการชักชวนทำธุรกิจ ผ่านทางบริการ โดยไม่ได้รับความยินยอมจากบริษัท</li>
                    <li>13) การกระทำเพื่อเก็บข้อมูลผู้ใช้บริการคนอื่น</li>
                    <li>14) การกระทำเพื่อทำให้ผู้ใช้บริการคนอื่น หรือบุคคลที่สามเสียผลประโยชน์ ได้รับความเสียหาย หรือรู้สึกไม่สบายใจ</li>
                    <li>15) การกระทำที่ละเมิด กฎ เงื่อนไขและข้อตกลง</li>
                    <li>16) การกระทำเพื่อสนับสนุนกำไรให้กับผู้มีอิทธิพลซึ่งไม่ชอบด้วยกฎหมาย</li>
                    <li>17) การกระทำโดยจงใจเพื่อพบบุคคลแปลกหน้าในการหาคู่หรือหาความสัมพันธ์</li>
                    <li>18) การกระทำอื่นๆ ที่บริษัทเห็นว่าไม่เหมาะสม</li>

                    <li>
                      ในกรณีผู้ใช้งานกระทำการ ดังกล่าวในข้อ 2.3 หรือกระทำฝ่าฝืนข้อตกลงและเงื่อนไขฉบับนี้ บริษัทอาจ (1) ลบ หรือ
                      ระงับการแสดงข้อความของข้อมูลที่โพสต์ (2) ระงับการใช้งานของผู้ใช้งานรายนั้นชั่วคราว หรือ (3) ยกเลิกการลงทะเบียน หรือ
                      การให้บริการของผู้ใช้งานรายนั้นตามข้อ 2.5 โดยไม่จำต้องบอกกล่าวล่วงหน้า
                    </li>
                  </ol>
                  <li>
                    2.4 การอนุญาตให้ใช้สิทธิในเนื้อหา
                    <ol className='terms-cond__ol'>
                      ผู้ใช้งานอนุญาตให้ ผู้ใช้งานอื่น รวมถึง บริษัทฯ บริษัทในกลุ่มตรีเพชร และผู้จำหน่ายอีซูซุ เข้าถึง ใช้ทำซ้ำ ดัดแปลง
                      เผยแพร่ ใช้ประโยชน์ เนื้อหา ข้อมูล และภาพ ที่ผู้ใช้งานโพสต์ เพื่อประโยชน์ในการทำธุรกิจ บริษัทฯ บริษัทในกลุ่มตรีเพชร
                      โดยไม่คิดค่าตอบแทน
                    </ol>
                  </li>
                  <li>
                    2.5&nbsp;การสิ้นสุดการเป็นสมาชิก
                    <ol className='terms-cond__ol'>
                      <li>1) ผู้ใช้งานสามารถยกเลิกการเป็นสมาชิกได้โดยการปฏิบัติตามขั้นตอนที่ได้กำหนดไว้ใน my-ISUZU</li>
                      <li>
                        2) บริษัทฯขอสงวนสิทธิ์ในการแก้ไข เปลี่ยนแปลง ทบทวน ยกเลิก และหรือ เพิกถอน การเป็นสมาชิก หรือสิทธิประโยชน์ใดๆ
                        รวมถึงข้อกำหนดและเงื่อนไข ในเวลาใดๆ โดยไม่ต้องแจ้งให้ผู้ใช้งานทราบล่วงหน้า
                      </li>
                      <li>
                        3) บริษัทฯสงวนสิทธิ์ในการยกเลิกการให้บริการ my-ISUZU ทั้งหมดหรือบางส่วน รวมไปถึง ปรับเปลี่ยน ปรับปรุง การย้าย Site
                        การให้บริการใน my-ISUZU โดยไม่จำต้องบอกกล่าวผู้ใช้งานล่วงหน้า
                      </li>
                      <li>
                        4) การถอนความยินยอมการจัดเก็บ รวบรวม เปิดเผยข้อมูลส่วนบุคคลของท่านตามช่องทางที่บริษัทฯ &nbsp;จัดเตรียมไว้ให้
                        ยังไม่ถือเป็นการยกเลิกหรือเพิกถอนการใช้งาน my-ISUZU โดยอัตโนมัติ &nbsp;หากท่านไม่ประสงค์จะใช้งาน my-ISUZU
                        ท่านสามารถดำเนินการเพื่อยกเลิกบัญชีผู้ใช้ใน my-ISUZU ได้
                      </li>
                    </ol>
                  </li>
                </li>
              </ol>
            </li>
            <li>
              <strong>3. เงื่อนไขทั่วไป</strong>
              <ol>
                <li>
                  3.1 การปรับปรุงข้อกำหนดและเงื่อนไข
                  <br />
                  บริษัทฯ ขอสงวนสิทธิ์ในการแก้ไขปรับปรุงข้อกำหนดและเงื่อนไข โดยไม่แจ้งให้ผู้ใช้งานทราบล่วงหน้า
                  &nbsp;เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ &nbsp;ดังนั้น บริษัทฯ แนะนำให้ผู้ใช้งานทุกท่านอ่านข้อกำหนดและเงื่อนไข
                  ทุกครั้งที่แวะชมหรือใช้บริการจาก &nbsp;my-ISUZU ของบริษัทฯ &nbsp;
                </li>
                <li>
                  3.2 ข้อปฎิเสธความรับผิดในข้อมูลบน my-ISUZU
                  <br />
                  ข้อมูลต่างๆ ไม่ว่าจะเป็น ข้อมูลทั่วไป ข้อมูลทางเทคนิก หรือ ข้อมูลเฉพาะที่จัดทำขึ้นและปรากฎใน my-ISUZU นี้ จัดทำขึ้น
                  “อย่างที่เป็น” และไม่มีการรับประกันใดๆ เกี่ยวกับข้อมูล &nbsp;ความพร้อมในการขาย ความเหมาะสมแก่การใช้ประโยชน์เฉพาะอย่าง หรือ
                  การไม่ละเมิดสิทธิ์ของบุคคลที่สาม &nbsp; &nbsp;บริษัทฯ เชื่อว่าข้อมูลต่างๆที่จัดเตรียมเป็นข้อมูลถูกต้องล่าสุดเท่าที่มี ณ
                  วันที่จัดทำและปรับปรุง my-ISUZU แต่ละครั้ง &nbsp;แต่อาจมีข้อผิดพลาดจากการแสดงผลปรากฎอยู่ด้วย
                  &nbsp;บริษัทฯจะไม่รับผิดชอบต่อข้อผิดพลาดจากการแสดงผลใดๆ ทั้งสิ้น &nbsp;บริษัทฯ
                  จะไม่รับผิดชอบต่อข้อมูลและเนื้อหาเว็บไซต์ที่ได้รับการดูแลโดยบุคคลที่สาม หรือความผิดพลาดที่เกิดจากการเชื่อมต่อของ
                  &nbsp;my-ISUZU นี้กับ เว็บไซต์อื่นๆ
                  <br />
                  <p>
                    บริษัทฯ สงวนสิทธิ์ที่จะเปลี่ยนแปลงข้อมูลต่างๆ ไม่ว่าจะเป็นข้อมูลรุ่น สี แบบ ข้อมูลทางเทคนิกของผลิตภัณฑ์
                    &nbsp;ข้อมูลการให้บริการหลังการขาย การโฆษณาประชาสัมพันธ์ &nbsp;ข้อมูลการจัดกิจกรรม ข้อมูลที่เป็นการให้ความรู้ต่างๆ
                    โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </p>
                </li>
                <li>
                  3.3 การจำกัดความรับผิด
                  <br />
                  บริษัทฯ และบริษัทในกลุ่มตรีเพชร ไม่มีหน้าที่และความรับผิดในการชดใช้ค่าเสียหายทั้งโดยตรงและโดยอ้อมต่อผู้ใช้งาน ใน กรณี
                  ดังต่อไปนี้
                  <ol className='terms-cond__ol'>
                    <li>1) การขโมย รหัสผ่าน หรือการใช้ข้อมูลผู้ใช้งานเพื่อเข้าใช้งานบัญชีของผู้ใช้งานโดยบุคคลที่สาม</li>
                    <li>
                      2) การแก้ไข เปลี่ยนแปลงข้อมูลในบัญชี (Account Setting) หรือการปรับเปลี่ยนบัญชี (Customization)โดยผู้ใช้งาน
                      หรือบุคคลที่สาม
                    </li>
                    <li>3) การปฏิเสธ ยกเลิก หรือ เพิกถอนการลงทะเบียน บัญชีผู้ใช้งาน</li>
                    <li>4) ปัญหาข้อขัดข้องทางเทคนิค รวมถึง ปัญหาการเชื่อมต่อเครือข่ายสื่อสารของผู้ใช้งานกับผู้ให้บริการเครือข่าย</li>
                    <li>
                      5) กรณีผู้ใช้งานไม่สามารถรับบริการตาม my-ISUZU หรือไม่สามารถร่วมกิจกรรมที่จัดผ่านหรือโดย my-ISUZU
                      ได้จากปัญหาการเชื่อมต่อเครือข่ายสื่อสารของผู้ใช้งานกับผู้ให้บริการเครือข่าย
                    </li>
                    <li>
                      6) บริษัทฯ และบริษัทในกลุ่มตรีเพชร ไม่มีหน้าที่และความรับผิดต่อข้อมูล การให้บริการ
                      หรือข่าวสารที่ผู้ใช้งานได้รับจากบุคคลที่สามโดยตรง อาทิเช่น ผู้ประกอบการอื่น ผู้ให้บริการร่วม คู่ค้าทางธุรกิจ &nbsp;
                      กรณีมีข้อขัดแย้ง หรือข้อพิพาทระหว่างผู้ใช้งานกับบุคคลที่สาม อาทิเช่น ผู้ประกอบการอื่น ผู้ให้บริการร่วม คู่ค้าทางธุรกิจ
                      ขอให้ผู้ใช้งานดำเนินการแก้ไขและยุติข้อพิพาทระหว่างกันเอง โดยผู้ใช้งานไม่สามารถเรียกร้องเอาจากบริษัทฯ
                      หรือบริษัทในกลุ่มตรีเพชรได้
                    </li>
                  </ol>
                </li>
                <li>
                  3.4 ทรัพย์สินทางปัญญา
                  <p>
                    บริษัทฯ สงวนลิขสิทธิ์ในpข้อความ รูปภาพ กราฟฟิก ไฟล์ภาพทั้งภาพนิ่งและภาพเคลื่อนไหว ไฟล์วีดิโอ และดีไซน์ต่างที่ปรากฎบน
                    my-ISUZU นี้ &nbsp;โดยไม่อนุญาตให้ผู้ใดทำซ้ำ ทำสำเนา ดัดแปลง ขาย หรือเผยแพร่ เพื่อการใช้ประโยชน์ในเชิงพาณิชย์
                    หรือนำไปใช้ในจุดประสงค์ที่นอกเหนือจากการใช้ประโยชน์ส่วนตัวในการใช้ my-ISUZU เว้นแต่จะได้รับอนุญาตจากบริษัทฯ
                    หรือเจ้าของสิทธิทรัพย์สินทางปัญญาที่เกี่ยวข้องก่อน
                  </p>
                </li>
                <li>
                  3.5 กฎหมายที่ใช้บังคับ
                  <p>ข้อกำหนดและเงื่อนไข my-ISUZU ฉบับนี้ให้เป็นไปตามกฎหมายไทย</p>
                </li>
                <li>
                  3.6 โมฆะบางส่วน
                  <p>
                    ในกรณีที่ปรากฏในภายหลังว่าเนื้อหาส่วนใดส่วนหนึ่งของข้อกำหนดและเงื่อนไขนี้เป็นโมฆะ
                    ให้ถือว่าเนื้อหาส่วนที่เป็นโมฆะไม่มีผลบังคับในข้อกำหนดและเงื่อนไขนี้
                    และเนื้อหาดังกล่าวไม่กระทบกระเทือนถึงความสมบูรณ์ของเนื้อหาในส่วนอื่นๆ
                  </p>
                  <br />
                  <p className='terms-cond-setting__footer'>*********</p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      )}
    </div>
  )
}

export default TermofService
