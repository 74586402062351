import React, { useEffect } from 'react'

// Styles
import './_index.scss'
import { pageview } from 'utils/treasureData'

const Page404 = () => {
  useEffect(() => {
    pageview()
  }, [])

  return (
    <div className='page404'>
      <h1>404 Error</h1>
      <p>{"You've reached an unreachable page."}</p>
      <p>Please use the navigation menu.</p>
    </div>
  )
}

export default Page404
