import { FILTER } from '../definitions'

/**
 *
 * @param {*} keyword
 */
const setKeyword = (keyword) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.CONTROLLER.KEYWORD,
      keyword
    })
  }
}

/**
 *
 * @param {*} assignment
 */
const setAssignment = (assignment) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.CONTROLLER.ASSIGNMENT,
      assignment
    })
  }
}

/**
 *
 * @param {*} activationStatus
 */
const setStatus = (activationStatus) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.CONTROLLER.STATUS,
      activationStatus
    })
  }
}

/**
 *
 * @param {*} orderBy
 */
const setOrderBy = (orderBy) => {
  return (dispatch) => {
    dispatch({
      type: FILTER.CONTROLLER.ORDER,
      orderBy
    })
  }
}

/**
 *
 */
const toggleSortBy = () => {
  return (dispatch) => {
    dispatch({
      type: FILTER.CONTROLLER.SORT
    })
  }
}

export { setKeyword, setOrderBy, toggleSortBy, setAssignment, setStatus }
