import { COUPON } from '../definitions'
import api from 'api/'
import alert from 'utils/alert'
import { showLoader, hideLoader } from 'store/actions/loader'
import { showAlert } from 'store/actions/alert'
import { activityTypes } from 'config/constant'

import { getProfileStat } from 'store/actions/profile'

/**
 *
 * @param {*} logType
 * @param {*} couponName
 * @param {*} vin
 * @param {*} licensePlate
 */
const logUserCouponInteraction = (couponCategory, description) => {
  return () => {
    api.logActivities({
      type: couponCategory === 'CC' ? activityTypes.CASH_COUPON : activityTypes.SERVICE_COUPON,
      description: description
    })
  }
}

/**
 *
 * @param {*} isReload
 * @param {*} q
 * @param {*} ob
 * @param {*} off
 * @param {*} lt
 */
const getCoupons = (isReload = true, q = '', ob = 7, off = 0, lt = 30, cc = '', exp = '', ty = '', val = '', vid = '') => {
  return (dispatch, getState) => {
    const { config } = getState()
    if (isReload) dispatch(showLoader())

    return api
      .getCoupons({ q, ob, off, lt, cc, exp, ty, val, vid })
      .then((res) => {
        const total_count = parseInt(res.headers['x-total-count']) || res.data.length
        const data = res.data.map((cp) => {
          return Object.assign({}, cp, {
            coupon_info: {
              name: cp.type['name_' + config.lang],
              type: cp.type.type,
              cash_coupon_number: cp.cash_coupon_number,
              coupon_logo: cp.type.coupon_logo,
              marketing_logo: cp.type.coupon_marketing_logo,
              campaign_code: cp.type.campaign_code,
              title: cp.detail['title_' + config.lang],
              benefits: cp.detail['benefit_' + config.lang],
              terms: cp.detail['terms_' + config.lang],
              remarks: cp.detail['remark_' + config.lang],
              coupon_category: cp.type.coupon_category,
              value: parseInt(cp.value) || 0,
              license_plate: cp.vehicle && cp.vehicle.license_plate,
              owner_id: (cp.user && cp.user.national_id) || (cp.user && cp.user.passport_no) || (cp.user && cp.user.company_reg_id),
              asterisk: cp.show_expiry_asterisk,
              is_read: cp.is_read,
              is_unlimited: cp.is_unlimited
            }
          })
        })

        dispatch({
          type: COUPON.GET,
          total_count,
          data,
          isReload
        })
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 */
const readAllCoupons = (q = '') => {
  return (dispatch) => {
    return api
      .readCoupons({ q })
      .then((res) => {
        dispatch({
          type: COUPON.READ
        })

        return Promise.all([
          dispatch(getProfileStat()),
          /*istanbul ignore next 2019-11-22*/
          dispatch(getServiceCouponsSlider()),
          /*istanbul ignore next 2019-11-22*/
          dispatch(getCashCouponsSlider())
        ])
      })
      .catch((err) => {})
  }
}

/**
 *
 */
const getServiceCouponsSlider = () => {
  return (dispatch, getState) => {
    const { config } = getState()

    return api.getServiceCoupons().then((res) => {
      let data = []
      let total_count = 0
      if (Array.isArray(res.data) && res.data.length > 0) {
        const nonExpiredCoupons = res.data.filter((c) => {
          return c.status === 101 || c.status === 301
        })
        data = nonExpiredCoupons
        total_count = nonExpiredCoupons.length
      }

      dispatch({
        type: COUPON.SLIDER_SERVICE,
        total_count,
        data: data.map((cp) => {
          return Object.assign({}, cp, {
            coupon_info: {
              name: cp.type['name_' + config.lang],
              type: cp.type.type,
              coupon_logo: cp.type.coupon_logo,
              campaign_code: cp.type.campaign_code,
              coupon_category: cp.type.coupon_category || 'SC',
              title: cp.detail['title_' + config.lang],
              benefits: cp.detail['benefit_' + config.lang],
              terms: cp.detail['terms_' + config.lang],
              remarks: cp.detail['remark_' + config.lang],
              license_plate: cp.vehicle && cp.vehicle.license_plate,
              asterisk: cp.show_expiry_asterisk,
              from_home: true, //Used to differentiate flow on couponclick under coupon item component
              is_read: cp.is_read,
              is_unlimited: cp.is_unlimited
            }
          })
        })
      })
    })
  }
}

/**
 *
 */
const setCashCouponGroup = (ty, val, cc, vid, exp, ids) => {
  return (dispatch) => {
    dispatch({
      type: COUPON.SLIDER_CASH_SET,
      set: true,
      ids: ids,
      data: {
        cc: cc,
        val: val,
        ty: ty,
        vid: vid,
        exp: exp
      }
    })
  }
}

/**
 *
 */
const clearCashCouponGroup = () => {
  return (dispatch) => {
    dispatch({
      type: COUPON.CLEAR_CASH_GROUP
    })
  }
}

/**
 *
 */
const unmountCashCouponGroup = () => {
  return (dispatch) => {
    dispatch({
      type: COUPON.UNMOUNT_CASH_GROUP
    })
  }
}
/**
 *
 */
const readCashCouponsSlider = (ids) => {
  return (dispatch) => {
    api
      .readCashCoupons(ids)
      .then((res) => {
        return Promise.all([
          dispatch(getProfileStat()),
          /*istanbul ignore next 2019-11-22*/
          dispatch(getServiceCouponsSlider()),
          /*istanbul ignore next 2019-11-22*/
          dispatch(getCashCouponsSlider())
        ])
      })
      .catch((err) => {})
  }
}
/**
 *
 */
const readGroupCashCoupons = (cc, exp, val, vid) => {
  return (dispatch) => {
    return api
      .readGroupCashCoupons({ cc, exp, val, vid })
      .then(
        /*istanbul ignore next 2019-11-22*/ (res) => {
          dispatch(getProfileStat())
          return res
        }
      )
      .catch((err) => {})
  }
}

/**
 *
 */
const getCashCouponsSlider = () => {
  return (dispatch, getState) => {
    const { config } = getState()

    return api.getCashCoupons().then((res) => {
      let data = []
      let total_count = 0

      /*istanbul ignore next 2019-11-22*/
      if (Array.isArray(res.data.cash_coupons) && res.data.cash_coupons.length > 0) {
        const nonExpiredCoupons = res.data.cash_coupons.filter((c) => {
          return c.cash_coupon.status === 101
        })
        data = nonExpiredCoupons
        total_count = res.data.total_count
      }
      dispatch({
        type: COUPON.SLIDER_CASH,
        total_count,
        data: data.map(
          /*istanbul ignore next 2019-11-22*/ (cp) => {
            let cash_info = cp.cash_coupon
            return Object.assign({}, cash_info, {
              coupon_info: {
                ids: cp.ids,
                total_count: total_count,
                name: cash_info.type['name_' + config.lang],
                amt: cp.count,
                type: cash_info.type.type,
                coupon_logo: cash_info.type.coupon_logo,
                coupon_category: cash_info.type.coupon_category || 'CC',
                campaign_code: cash_info.type.campaign_code,
                title: cash_info.detail['title_' + config.lang],
                benefits: cash_info.detail['benefit_' + config.lang],
                terms: cash_info.detail['terms_' + config.lang],
                remarks: cash_info.detail['remark_' + config.lang],
                marketing_logo: cash_info.type.coupon_marketing_logo,
                license_plate: cash_info.vehicle && cash_info.vehicle.license_plate,
                owner_id:
                  (cash_info.user && cash_info.user.national_id) ||
                  (cash_info.user && cash_info.user.passport_no) ||
                  (cash_info.corporate && cash_info.corporate.company_reg_id),
                value: parseInt(cash_info.value) || 0,
                from_home: true, //Used to differentiate flow on couponclick under coupon item component
                is_read: cp.is_read,
                is_unlimited: cp.is_unlimited
              }
            })
          }
        )
      })
    })
  }
}

/**
 *
 */
const readServiceCouponsSlider = (cg, vin) => {
  return (dispatch, getState) => {
    return api
      .readServiceCoupons({ cg, vin })
      .then(
        /*istanbul ignore next 2019-11-22*/ (res) => {
          dispatch(getProfileStat())
          return res
        }
      )
      .catch((err) => {})
  }
}

/**
 *
 */
const downloadLogo =
  /* istanbul ignore next */
  (url) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        api
          .amazonDownload(url)
          .then((res) => {
            let result
            const reader = new FileReader()
            reader.onloadend = () => {
              result = reader.result
              resolve(result)
            }
            reader.readAsDataURL(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }

export {
  logUserCouponInteraction,
  getCoupons,
  getServiceCouponsSlider,
  getCashCouponsSlider,
  setCashCouponGroup,
  clearCashCouponGroup,
  readCashCouponsSlider,
  readGroupCashCoupons,
  readServiceCouponsSlider,
  readAllCoupons,
  unmountCashCouponGroup,
  downloadLogo
}
