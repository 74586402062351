import React from 'react'
import { InstructionSection, Label, Content, IconContainer, Icon } from './style'

import { redLINE, redPhone } from 'config/icon'

import { FormattedMessage } from 'react-intl'

const Instruction = () => {
  const onClickPresetMessage = () => {
    const message = 'ต้องการเปลี่ยนเบอร์มือถือเพื่อรับ OTP ลืมพาสเวิร์ดเข้าแอปพลิเคชัน my-ISUZU'
    window.open(`https://line.me/R/oaMessage/@isuzuthai/?${encodeURI(message)}`)
  }

  return (
    <InstructionSection>
      <Content>
        <FormattedMessage id='login.labels.instructionLine' defaultMessage='login.labels.instructionLine' />
        <IconContainer>
          <Icon>{redLINE}</Icon>
        </IconContainer>
        <Label onClick={onClickPresetMessage} id='connect-line-button'>LINE ISUZU Thailand</Label>
      </Content>
      <Content>
        <FormattedMessage id='login.labels.instructionMobile' defaultMessage='login.labels.instructionMobile' />
        <IconContainer>
          <i>{redPhone}</i>
        </IconContainer>
        <Label href={'tel: 021180777'} id='call-isuzu-button'>02 1180777</Label>
      </Content>
      <FormattedMessage id='login.labels.instructionDate' defaultMessage='login.labels.instructionDate' />
    </InstructionSection>
  )
}

export default Instruction
