import React from 'react'
import {
  DURATION_EN,
  OBJECTIVE_INFO_EN,
  DATA_SUBJECT_EN,
  TYPE_OF_PERSONAL_EN,
  TRANSFER_PERSONAL_DATA_EN,
  COOKIE_EN,
  IMPROVEMENT_OF_PRIVACY_POLICY_EN,
  CONTACT_EN,
  OBJECTIVE_DETAIL_EN,
  PERSONAL_DATA_EN,
  HEADER_EN,
  DEFINITION,
  REMARK_EN
} from './privacyPolicyInfo'

const PrivacyPolicyEN = () => (
  <div>
    <p>{HEADER_EN}</p>
    <ol className='privacy__ol-1'>
      <li>
        <strong>1. Personal Data Definition</strong>
        <br />
        <p>{DEFINITION}</p>
      </li>
      <li>
        <strong>2. Collected Personal Data</strong>
        <br />
        <ol>
          <li>2.1 Collected Personal Data</li>
          <p>The Company collects your Personal Data and other data as follows:</p>
          <table className='privacy_table' cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr>
                <td valign='top' width='40%' align='center'>
                  <strong>{PERSONAL_DATA_EN.columnHeader1}</strong>
                </td>
                <td valign='top' width='60%' align='center'>
                  <strong>{PERSONAL_DATA_EN.columnHeader2}</strong>
                </td>
              </tr>
              {PERSONAL_DATA_EN.records.map((v, k) => (
                <tr key={k}>
                  <td valign='top'>{v.title}</td>
                  <td valign='top'>{v.describtion}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <li>2.2 Method to collect Personal Data </li>
          <p>
            The Company will collect Personal Data from various channels. The Company willreceive your information from the authorized
            dealer (“Dealer”) as the main channel.Besides, the Company may collect Personal Data from other activities that you will
            provideinformation directly or through website{' '}
            <a href='https://isuzu-tis.com' target='_blank' rel='noopener noreferrer'>
              www.isuzu-tis.com
            </a>{' '}
            &nbsp;or other Company’s website(s)(“Website”), customer service center, the Company’s affiliates including collecting
            fromwebsite visiting behavior from the web browser or the Company may collect Personal Datafrom other source which are not
            directly from you, for example from other customers, tradeassociations or organization(s).
          </p>

          <li>2.3 Duration to keep Personal Data</li>
          <p>{DURATION_EN}</p>
        </ol>
      </li>
      <li>
        <strong>3. Objectives to collect and keep Personal Data</strong>
        <p>{OBJECTIVE_DETAIL_EN}</p>
        <table className='privacy_table' cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr>
              <td valign='top' width='70%' align='center'>
                <strong>{OBJECTIVE_INFO_EN.columnHeader1}</strong>
              </td>
              <td valign='top' width='30%' align='center'>
                <strong>{OBJECTIVE_INFO_EN.columnHeader2}</strong>
              </td>
            </tr>
            {OBJECTIVE_INFO_EN.records.map((v, k) => (
              <tr key={k}>
                <td valign='top'>
                  <div className='objective-column'>
                    <div>{k + 1}. </div>
                    {v.objective}
                  </div>
                </td>
                <td valign='top'>{v.ground}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <p>If you decline to provide Personal Data to the Company, we may not be able to render service to you in some case. </p>
      </li>

      <li>
        <strong>4. Disclosure of Personal Data</strong>
        <ol>
          <li>4.1 Type of Personal Data recipient</li>
          <p>{TYPE_OF_PERSONAL_EN}</p>
          <li>4.2 Send or transfer Personal Data oversea</li>
          <p>{TRANSFER_PERSONAL_DATA_EN}</p>
        </ol>
      </li>

      <li>
        <strong>5. Data Subject’s Rights</strong>
        <p>
          Under the principle, terms and conditions prescribed in the applicable Personal Data Protection Law in Thailand, you are entitled
          to the following rights:
        </p>
        <ol>
          {DATA_SUBJECT_EN.map((text, k) => (
            <li key={k}>
              {' '}
              {text.no} &nbsp;{text.info} &nbsp;
            </li>
          ))}
        </ol>
      </li>

      <li>
        <strong>6. Cookies</strong>
        <p>{COOKIE_EN}</p>
      </li>
      <li>
        <strong>7. Improvement of Privacy Policy</strong>
        <p>{IMPROVEMENT_OF_PRIVACY_POLICY_EN}</p>
      </li>
      <li>
        <strong>8. Contact Us</strong>
        <p>{CONTACT_EN}</p>
        <table className='privacy_table' cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr>
              <td valign='top' width='50%' align='center'>
                <strong>Personal Data Controller</strong>
              </td>
              <td valign='top' width='50%' align='center'>
                <strong>Venue and contact</strong>
              </td>
            </tr>
            <tr>
              <td valign='top'>Tri Petch Isuzu Sales Co., Ltd.</td>
              <td valign='top'>
                <strong>E-mail:&nbsp;</strong>
                <a href='mailto:info@isuzu-tis.com'>info@isuzu-tis.com</a>&nbsp;
                <br />
                <br />
                <strong>Telephone Number:&nbsp;</strong>021180777
                <br />
                <br />
                <strong>Address:&nbsp;</strong>Customer Relations Office, 1088, Vibhavadi Rangsit Road, Chatuchak sub-district, Chatuchak
                District, Bangkok, 10900
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          The Company is welcome to response to your enquiry, suggestion and comment to develop and improve the Website and the Company’s
          services.
        </p>
        <p>{REMARK_EN}</p>
      </li>
    </ol>
  </div>
)

export default PrivacyPolicyEN
