import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import Parser from 'html-react-parser'

import { currencyFormat } from 'utils/format'
import { ispCheck } from 'config/icon'

// Styles
import './index.scss'

const packageTemplate = ({ type, data }) => {
  const classname = 'package-container'

  return (
    <React.Fragment>
      <div className={classname}>
        <div className={`${classname}__title`}>
          <div className={`${classname}__title__${type}`}>
            <span>{data.headerTitle}</span>
          </div>
        </div>
        <div className={`${classname}__title-text`}>{data.priceTitle}</div>
        {data.dmaxPackage && setPackageTemplate(classname, data.dmaxPackage.name, data.dmaxPackage.dmaxPackageList)}
        {data.muxPackage && setPackageTemplate(classname, data.muxPackage.name, data.muxPackage.muxPackageList)}
        {setPrivilegeTemplate(classname, data.privilegeList, data.suggestionTitle)}
      </div>
    </React.Fragment>
  )
}

export default packageTemplate

const setPackageTemplate = (classname, name, data) => {
  return (
    <div className={`${classname}__package`}>
      <div className={`${classname}__package__detail`}>
        {name}
        <div className={`${classname}__package__detail__price`}>
          {data.map((v) => (
            <div className={`${classname}__package__detail__price__container`}>
              {v.fullPrice !== 0 && (
                <div className={`${classname}__package__detail__price__current`}>
                  <FormattedHTMLMessage id='isp.currency' defaultMessage='isp.currency' values={{ value: currencyFormat(v.fullPrice) }} />
                </div>
              )}
              <div className={`${classname}__package__detail__price__discount`}>
                <FormattedHTMLMessage id='isp.currency' defaultMessage='isp.currency' values={{ value: currencyFormat(v.discountPrice) }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const setPrivilegeTemplate = (classname, data, suggestionTitle) => {
  return (
    <div className={`${classname}__privilege`}>
      <div className={`${classname}__privilege__title`}>
        <span>{Parser(suggestionTitle)}</span>
      </div>
      {data.detail.map((value) => (
        <div className={`${classname}__privilege__container`}>
          <div className={`${classname}__title-text`}>{value.detailTitle}</div>
          {value.item.map((value) => (
            <div className={`${classname}__privilege__detail`}>
              <div>
                <i>{ispCheck}</i>
              </div>
              <div>
                <span>{value}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
