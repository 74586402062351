import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

// Router
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import {
  profileIcon,
  promotionIcon,
  chatIcon,
  serviceIcon,
  controllerIcon,
  vehicleIcon,
  couponIcon,
  privilegeIcon,
  newProfileIcon,
  homeIcon,
  bellIcon,
  contactIcon
} from 'config/icon'

// Styles
import './_index.scss'

const NavItem = (props) => {
  const [currentLocation, setCurrentLocation] = useState(props.history.location.pathname)

  useEffect(() => setCurrentLocation(props.history.location.pathname))

  useEffect(() => {
    setCurrentLocation(props.history.location.pathname)
  }, [currentLocation])

  const {
    className,
    target,
    isMore,
    isOpen,
    isSubMenu,
    isNewIcon,
    isLink,
    url,
    label,
    icon,
    icon_close,
    counter,
    staticContext,
    isCoupon,
    currentLocation: currentLocationProps,
    ...otherProps
  } = props

  const classes = classnames('nav-item', { 'nav-item--active': props.target === currentLocation }, className)

  let subIcon
  if (isSubMenu) {
    switch (icon) {
      case 'profile':
        subIcon = profileIcon
        break
      case 'promotion':
        subIcon = promotionIcon
        break
      case 'chat':
        subIcon = chatIcon
        break
      case 'service':
        subIcon = serviceIcon
        break
      case 'controller':
        subIcon = controllerIcon
        break
      default:
        return null
    }
  }

  let newIcon
  if (isNewIcon) {
    switch (icon) {
      case 'home':
        newIcon = homeIcon
        break
      case 'vehicle':
        newIcon = vehicleIcon
        break
      case 'profile':
        newIcon = newProfileIcon
        break
      case 'privilege':
        newIcon = privilegeIcon
        break
      case 'inbox':
        newIcon = bellIcon
        break
      case 'contact':
        newIcon = contactIcon
        break
      case 'coupon':
        newIcon = couponIcon
        break
      default:
    }
  }

  const item = isMore ? (
    <a className={classes} {...otherProps} id={props.id}>
      {isOpen && <i className='material-icons'>{icon_close}</i>}
      {!isOpen && <i className='material-icons'>{icon}</i>}
      {label ? (
        <span>
          <FormattedMessage id={label} defaultMessage={label} />
        </span>
      ) : (
        <span className='nav-item__empty-span'></span>
      )}
    </a>
  ) : (
    <NavLink to={target} className={classes} {...otherProps}>
      {!isSubMenu && !isNewIcon && <i className='material-icons'>{icon}</i>}
      {!isSubMenu && isNewIcon && icon && (
        <div className={icon === 'inbox' ? 'nav-item__icon-wrapper-inbox' : 'nav-item__icon-wrapper'}>{newIcon}</div>
      )}
      {isSubMenu && subIcon}
      {counter && counter <= 99 && <span className={isCoupon ? 'coupon-counter' : 'counter'}>{counter}</span>}
      {counter && counter > 99 && <span className={isCoupon ? 'coupon-counter' : 'counter'}>99+</span>}
      {label && (
        <span>
          <FormattedMessage id={label} defaultMessage={label} />
        </span>
      )}
    </NavLink>
  )

  return <React.Fragment>{item}</React.Fragment>
}

NavItem.propTypes = {
  currentLocation: PropTypes.string
}

NavItem.defaultProps = {
  currentLocation: '',
  counter: null
}

export default withRouter(NavItem)
