import { REPAIR_HISTORY } from '../definitions'
import api from 'api/'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'

/**
 *
 * @param {*} id
 */
const getRepairHistory = (id, limit, offset) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .getRepairHistory(id, { lm: limit, of: offset })
        .then((res) => {
          dispatch({
            type: REPAIR_HISTORY.GET,
            repairHistory: res.data,
            limit: limit,
            offset: offset
          })
          resolve(res.data)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          reject(err)
        })
    })
  }
}

const getRepairDetail = (id, ro) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getRepairDetail(id, ro)
        .then((res) => {
          dispatch({
            type: REPAIR_HISTORY.DETAIL,
            repairDetail: res.data
          })
          dispatch(hideLoader())
          resolve(res.data)
        })
        .catch((err) => {
          dispatch(hideLoader())
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          reject(err)
        })
    })
  }
}

export { getRepairHistory, getRepairDetail }
