import React, { useEffect, useState, useRef, useCallback } from 'react'
import Cookies from 'js-cookie'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Router
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Components
import { Form, Field, reduxForm } from 'redux-form'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import Checkbox from 'components/elements/Checkbox'
import TabBar from 'components/elements/TabBar'
import Select from 'components/elements/Select'
import Modal from 'components/elements/Modal'
import SocialConnect from 'components/elements/Social'
import { lineIcon } from 'config/icon'
import BannerItem from 'components/sections/BannerItem'
import Slider from 'components/elements/Slider'

// Actions
import { authSignin, authSigninLine, revokeRefreshToken } from 'store/actions/auth'
import { signIn_individual, signIn_indiv_an, signIn_corp, signIn_password } from 'utils/validation'

import { getLoginBanner } from 'store/actions/banners'

// Utils
import { checkLanguage, onLanguageChanged } from 'utils/languages'

// Styles
import './_index.scss'

// Methods
import queryString from 'query-string'
import { FormattedMessage } from 'react-intl'

//constants
import { checkAuth, getLoginUserType, setLoginUserType, getRetryLineLogin } from 'utils/auth'
import { languageOptions, USER_TYPE } from 'config/constant'
import { postPageView } from 'store/actions/profile'
import { generateLineRedirectUri, issueLineSignin } from 'utils/line'

const additionalUsernameFieldProps = {
  [USER_TYPE.INDIVIDUAL]: {
    label: 'login.labels.identifierIndividual',
    validate: [signIn_individual, signIn_indiv_an]
  },
  [USER_TYPE.CORPORATE]: {
    label: 'login.labels.identifierCorporate',
    validate: [signIn_corp]
  },
  [USER_TYPE.CONTROLLER]: {
    label: 'login.labels.identifierController',
    // validate: [signIn_controller]
    validate: [signIn_individual, signIn_indiv_an]
  }
}
const Signin = ({ history, loginBanner, getLoginBanner, authSignin, authSigninLine, postPageView, handleSubmit }) => {
  const [selectedType, setSelectedType] = useState(() => {
    const currentType = getLoginUserType()
    return currentType ? currentType : USER_TYPE.INDIVIDUAL
  })

  const [rememberMe, setRememberMe] = useState(true)
  const [isLineSignin, setIsLineSignin] = useState(false)

  const lineSignUpModalRef = useRef()

  const changeType = (activeIndex) => {
    setSelectedType(activeIndex + 1)
    setLoginUserType(activeIndex + 1)
  }

  const lineSignin = useCallback(() => {
    const type = getLoginUserType() || selectedType

    localStorage.setItem('rememberMe', rememberMe)
    Cookies.set('showAppInstaller', true)

    issueLineSignin({ redirectUri: generateLineRedirectUri(type) })

    setIsLineSignin(true)
  }, [rememberMe, selectedType])

  useEffect(() => {
    getLoginBanner()

    const urlParams = queryString.parse(window.location.search, { decode: false })

    const lineLoginRetry = getRetryLineLogin()
    const type = urlParams.type

    // Prevent redirect loop after logged in with LINE by removing nested LINE
    // query params from `redirect`.
    //
    // For example, when redirected back from LINE login page, MYI web URL
    // normally look like this:
    // `/signin?type=1&redirect=%2F&code=auth_code&state=random_string`
    //
    // But the problematic MYI web URL may look like this:
    // `/signin?type=1&redirect=%2F%3Ftype%3D1%26redirect%3D%252F%26code%3Dold_code5%26state%3Dold_state&code=new_code&state=new_state`
    //
    // Notice that, inside `redirect`, we also have `code` and `state`, which
    // indicated redirect loop during LINE login.
    //
    // See also: https://developers.line.biz/en/docs/line-login/integrate-line-login/#receiving-the-authorization-code
    // Note that LINE API document does not seems to match with some of real
    // world behaviors. So try not to rely on it too much. Accept what we can
    // see in the real world.
    if (typeof urlParams.redirect === 'string' && urlParams.redirect && typeof urlParams.code === 'string' && urlParams.code) {
      const sanitizedRedirectUrl = queryString.exclude(
        decodeURIComponent(urlParams.redirect),
        ['state', 'redirect', 'liffClientId', 'liffRedirectUri'],
        // Avoid sorting the query of redirect link (request_uri mismatched)
        { sort: false }
      )
      const sanitizedQueryString = queryString.stringify(
        {
          ...urlParams,
          redirect: encodeURIComponent(sanitizedRedirectUrl)
        },
        // Leave other parameters untouched. And also avoid double encoding URI
        // component.
        { encode: false }
      )
      const originalQueryString = queryString.stringify(urlParams, { encode: false, sort: false })
      const isQueryStringChanged = originalQueryString !== sanitizedQueryString

      if (isQueryStringChanged) {
        // We need this info to be visible in both browser console and Datadog.
        // Normally, this case should rarely occur, so we will allow this log to
        // trigger Datadog Monitors.
        console.error('Sign-in page query-string:', {
          originalValue: originalQueryString,
          sanitizedValue: sanitizedQueryString,
          isQueryStringChanged
        })

        window.location.search = sanitizedQueryString

        // Allow the page to reload first, then will continue with the rest of
        // the logic once the query string is ready.
        return
      }
    }

    if (type) {
      setLoginUserType(type)
    }
    if (urlParams.code) {
      /* istanbul ignore else */
      if (!checkAuth()) {
        let redirectUri = '/signin'
        const search = queryString.parse(window.location.search)

        if (!type) {
          window.location.pathname = '/signin'
        }

        if (search.redirect) {
          redirectUri = queryString.stringifyUrl({ url: redirectUri, query: { redirect: search.redirect } })
        }

        urlParams.rememberMe = rememberMe

        authSigninLine(urlParams, redirectUri, parseInt(search.type)).then((res) => {
          if (res && res.is_connected_line === false && lineSignUpModalRef.current) {
            lineSignUpModalRef.current.openModal()
          }
        })
      }
    } else if (!lineLoginRetry) {
      const isLineBrowser = navigator.userAgent.indexOf('Line/') > -1
      let loginType = getLoginUserType()
      if (!loginType) {
        setLoginUserType(USER_TYPE.INDIVIDUAL)
      }
      if (!checkAuth() && isLineBrowser) {
        lineSignin()
      }
    }
    postPageView()
  }, [authSigninLine, getLoginBanner, lineSignin, postPageView, rememberMe])

  const signin = () => {
    // send payload of authentication sign in including type of user (selected tab + 1) and remember me checkbox
    authSignin({
      type: selectedType,
      rememberMe: rememberMe
    })
    Cookies.set('showAppInstaller', true)
  }

  /**
   *  Toggle state of remember of checkbox clicked
   */
  const toggleCheckbox = () => {
    setRememberMe(!rememberMe)
  }

  const classes = classnames('signin')
  const usernameFieldProps = !isLineSignin ? additionalUsernameFieldProps[selectedType] : ''
  return (
    <div className={classes}>
      <div className='signin__desktop'>
        <div className='signin__desktop__banner'>
          <Slider title='' component={BannerItem} data={loginBanner} userType={1} forceNumber={1} isBanner={true} fromPage='signin' />
        </div>
        <div className='signin__desktop__content'>
          <Modal mode={7} title='login.lineUnregistered.title' content='login.lineUnregistered.description' ref={lineSignUpModalRef} />
          <div className='signin__desktop__detail'>
            <TabBar
              defaultValue={selectedType - 1}
              tabs={['common.labels.individual', 'common.labels.corporate', 'common.labels.controllerLogin']}
              onActiveIndexChange={changeType}
              page='signin'
              isFolder
            />

            <div className='signin__body'>
              <Form onSubmit={handleSubmit(signin)} className='signin__form'>
                <div className='signin__form__field'>
                  <Field
                    name='identifier'
                    component={Input}
                    type='username'
                    isRequired
                    isClearable
                    id='signin_identifier'
                    placeholder='common.placeholders.inputIdentifier'
                    autoComplete='username'
                    {...usernameFieldProps}
                  />

                  <Field
                    name='password'
                    component={Input}
                    type='password'
                    label={'common.labels.password'}
                    isRequired
                    togglePassword
                    isClearable
                    validate={!isLineSignin && [signIn_password]}
                    placeholder={'common.placeholders.inputPassword'}
                    id='signin_password'
                  />

                  <div className='signin__form__action__section signin__form__action__section--half'>
                    <Checkbox nativeControlId='signin__form__section__remember-me' checked={rememberMe} onClick={toggleCheckbox} />
                    <label className='signin__form__section__remember-me' htmlFor='signin__form__section__remember-me'>
                      <FormattedMessage id='login.labels.remember' defaultMessage='login.labels.remember' />
                    </label>
                  </div>
                </div>
                <div className='signin__form__action'>
                  <Button type='submit' color='primary' label='login.button.signin' />
                </div>
              </Form>
              <div className='signin__social_media'>
                <Button
                  type='line'
                  label='login.button.lineConnect'
                  customIcon={lineIcon}
                  onClick={() => {
                    lineSignin()
                  }}
                />
              </div>
              <div className='signin__issue'>
                <Button
                  color='transparent'
                  label='login.labels.forgetPassword'
                  onClick={() => {
                    history.push({
                      pathname: '/signin/issue',
                      state: selectedType
                    })
                  }}
                />
              </div>
            </div>
          </div>

          <div className='signin__footer'>
            <div className='signin__footer__language'>
              <Select options={languageOptions} value={checkLanguage()} onChange={onLanguageChanged} className='open-top' noMapping />
            </div>
          </div>

          <div className='social'>
            <SocialConnect />
          </div>
        </div>
      </div>
    </div>
  )
}

export const mapStateToProps = (state) => ({
  loginBanner: state.loginBanner.data
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        authSignin,
        authSigninLine,
        revokeRefreshToken,
        getLoginBanner,
        postPageView
      },
      dispatch
    )

Signin.propTypes = {
  history: PropTypes.object,
  search: PropTypes.string,
  authSignin: PropTypes.func,
  handleSubmit: PropTypes.func,
  authSigninLine: PropTypes.func,
  revokeRefreshToken: PropTypes.func,
  dispatch: PropTypes.func,
  getLoginBanner: PropTypes.func,
  loginBanner: PropTypes.array
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'signin'
    })(Signin)
  )
)
