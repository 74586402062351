import React, { useState } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Tab from '@material/react-tab'
import ReactTabBar from '@material/react-tab-bar'

// Methods
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Styles
import './_index.scss'

const TabBar = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.defaultValue)

  const handleActiveIndexUpdate = (activeIndex) => {
    setActiveIndex(activeIndex)
    props.onActiveIndexChange(activeIndex)
  }

  let classes = classnames(
    'tab-bar',
    { 'tab-bar--folder': props.isFolder },
    { 'tab-bar--package': props.isPackage },
    { 'tab-bar--package--1': activeIndex === 0 && props.isPackage },
    { 'tab-bar--package--2': activeIndex === 1 && props.isPackage },
    { 'tab-bar--package--3': activeIndex === 2 && props.isPackage }
  )

  const tabs = props.tabs.map((value, index) => {
    if (props.page === 'signin') {
      return (
        <Tab
          //setFocusOnActive={false}
          className='tab-bar__signin-tab'
          key={index}
        >
          <FormattedHTMLMessage id={value} defaultMessage={value} />
        </Tab>
      )
    } else {
      return (
        <Tab
          //setFocusOnActive={false}
          className={`tab-bar__tab  ${props.isPackage && `tab-bar__tab--${index}`}
          ${props.colors ? props.colors[index] : ''}`}
          key={index}
        >
          {!(value === 'inbox.labels.favorites' || value === 'vehicle.list.favorites') && (
            <FormattedMessage id={value} defaultMessage={value} />
          )}
          {(value === 'inbox.labels.favorites' || value === 'vehicle.list.favorites') && <i className='material-icons'>favorites</i>}
        </Tab>
      )
    }
  })

  return (
    <ReactTabBar className={classes} activeIndex={activeIndex} handleActiveIndexUpdate={handleActiveIndexUpdate}>
      {tabs}
    </ReactTabBar>
  )
}

TabBar.propTypes = {
  activeIndex: PropTypes.number,
  isFolder: PropTypes.bool,
  isPackage: PropTypes.bool,
  onActiveIndexChange: PropTypes.func,
  tabs: PropTypes.array,
  colors: PropTypes.array
}

export default withRouter(TabBar)
