import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import { Field, Form } from 'redux-form'

import {
  editVehicle_driverGivenName,
  editVehicle_driverGivenNameMax32,
  editVehicle_driverSurname,
  editVehicle_driverSurnameMax32,
  editVehicle_name,
  editVehicle_phone
} from 'utils/validation'

import Button from 'components/elements/Button'
import Input from 'components/elements/Input'
import ButtonMaterial from '@material-ui/core/Button'

import { USER_TYPE } from 'config/constant'
import { moreIcon } from 'config/icon'

const DetailVehicleFormContent = (props) => {
  return (
    <div className='detail-vehicle-form'>
      <div className='detail-vehicle-form-wrapper'>
        <Form onSubmit={props.handleSubmit(props.submit)} className='detail-vehicle-form__form'>
          {props.imageDom}
          <div className='detail-vehicle-form__body'>
            <p>
              <FormattedMessage id='vehicle.edit.title' defaultMessage='vehicle.edit.title' />
            </p>
            <div className='detail-vehicle-form__body__detail'>
              <Field
                name='name'
                className='detail-vehicle-form__body__detail__info'
                component={Input}
                type='text'
                label='vehicle.edit.vehicleName'
                isRequired
                isClearable
                validate={[editVehicle_name]}
                placeholder='vehicle.placeholder.inputVehicleName'
              />
              {props.userType !== USER_TYPE.INDIVIDUAL && (
                <React.Fragment>
                  <Field
                    name='given_name'
                    className='detail-vehicle-form__body__detail__info'
                    component={Input}
                    type='text'
                    label='vehicle.edit.driverGivenName'
                    isClearable
                    validate={[editVehicle_driverGivenName, editVehicle_driverGivenNameMax32]}
                    placeholder='vehicle.placeholder.driverGivenName'
                  />

                  <Field
                    name='surname'
                    className='detail-vehicle-form__body__detail__info'
                    component={Input}
                    type='text'
                    label='vehicle.edit.driverSurname'
                    isClearable
                    validate={[editVehicle_driverSurname, editVehicle_driverSurnameMax32]}
                    placeholder='vehicle.placeholder.driverSurname'
                  />

                  <Field
                    name='mobile'
                    className='detail-vehicle-form__body__detail__info'
                    component={Input}
                    type='text'
                    label='vehicle.edit.driverMobilePhone'
                    isClearable
                    validate={[editVehicle_phone]}
                    placeholder='vehicle.placeholder.driverMobilePhone'
                  />
                </React.Fragment>
              )}
              <Button type='submit' color='primary' label='vehicle.edit.saveVehicle' disabled={props.invalid || props.inCorrectFile} />

              {/* We're deprecating the uploading of vehicle images. The code below is to support
                  existing users who may still have multiple images. */}
              {props.image_list && props.image_list.length > 1 && (
                <>
                  <div className='detail-vehicle-form__body__detail__images_wrapper'>
                    {props.image_list &&
                      props.image_list.map((v, index) => (
                        <div className='detail-vehicle-form__body__detail__images_wrapper__img' key={index}>
                          <img
                            src={v.image_url}
                            onClick={() => props.imageOnFocus(v.image_url, v.is_cover, v.is_official)}
                            className={v.is_cover && 'isCover'}
                            alt='upload__img'
                          />
                          {(props.image_count > 1 || (props.image_count === 1 && !v.is_official)) && (
                            <ButtonMaterial
                              className={'editVehicle'}
                              onClick={() => {
                                props.setState.setUserVehicleId(props.detail.id)
                                props.setState.setImageUrl(v.is_official ? 'official' : v.image_url)
                                props.setState.setCoverImage(v.is_cover)
                                props.openModal()
                              }}
                            >
                              <i>{moreIcon}</i>
                            </ButtonMaterial>
                          )}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default DetailVehicleFormContent
