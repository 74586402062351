import React from 'react'
import * as helper from 'utils/helper'

import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router'

const Badge = ({ detail, vehicle }) => {
  const history = useHistory()

  return (
    <React.Fragment>
      <div className='detail-vehicle__badges'>
        {vehicle.vehicle_type === 'LCV' && (
          <React.Fragment>
            {vehicle.has_isi_package && (
              <div className='detail-vehicle__badges__wrapper'>
                <img
                  className='vehicle-item__wrapper__data__desc__badges__isi'
                  src={vehicle.has_isi_package && helper.getISILogo(2)}
                  alt=''
                />
              </div>
            )}
            {vehicle.excellency &&
              Object.prototype.hasOwnProperty.call(vehicle.excellency, 'current_status') &&
              vehicle.excellency.current_status !== 'Old' &&
              Object.prototype.hasOwnProperty.call(vehicle.excellency, 'current_status') &&
              vehicle.excellency.current_status && (
                <div className='detail-vehicle__badges__wrapper' onClick={() => history.push(`/excellency/${detail.id}`)}>
                  <img
                    className='detail-vehicle__badges__excellency'
                    src={helper.getAssets(
                      1,
                      1,
                      helper.getExcellencyStatus(
                        vehicle.excellency && vehicle.excellency.current_status ? vehicle.excellency.current_status : undefined
                      ),
                      'vehicle_detail'
                    )}
                    alt=''
                  />
                </div>
              )}

            {!helper.isISP(detail.vehicle) && helper.isInWarranty(detail.vehicle) && (
              <div className='detail-vehicle__badges__wrapper' onClick={() => history.push(`/isp/${detail.id}`)}>
                <img
                  className='detail-vehicle__badges__isp'
                  src={helper.getAssets(2, 1, helper.getISPStatus(undefined), 'vehicle_detail_screen')}
                  alt=''
                />
              </div>
            )}

            {
              /*istanbul ignore next*/ helper.isISP(detail.vehicle) && !helper.ISPExpired(detail.vehicle) && (
                <div className='detail-vehicle__badges__wrapper' onClick={() => history.push(`/isp/${detail.id}`)}>
                  <img
                    className='detail-vehicle__badges__isp'
                    src={helper.getAssets(
                      2,
                      3,
                      helper.getISPStatus(
                        vehicle.isp_information && vehicle.isp_information.isp_package
                          ? vehicle.isp_information.isp_package_code
                          : undefined
                      ),
                      'vehicle_detail_screen'
                    )}
                    alt=''
                  />
                </div>
              )
            }
          </React.Fragment>
        )}
        {vehicle.vehicle_type === 'CV' && (
          <React.Fragment>
            {!isEmpty(vehicle.warranty_badge) && (
              <div className='detail-vehicle__badges__wrapper'>
                <img className='detail-vehicle__badges__isp' src={vehicle.warranty_badge.warranty_badge_url} alt='' />
              </div>
            )}
            {vehicle.excellency &&
              Object.prototype.hasOwnProperty.call(vehicle.excellency, 'current_status') &&
              vehicle.excellency.current_status !== 'Old' &&
              Object.prototype.hasOwnProperty.call(vehicle.excellency, 'current_status') &&
              vehicle.excellency.current_status && (
                <div className='detail-vehicle__badges__wrapper' onClick={() => history.push(`/excellency/${detail.id}`)}>
                  <img
                    className='detail-vehicle__badges__excellency'
                    src={helper.getAssets(
                      1,
                      1,
                      helper.getExcellencyStatus(
                        vehicle.excellency && vehicle.excellency.current_status ? vehicle.excellency.current_status : undefined
                      ),
                      'vehicle_detail'
                    )}
                    alt=''
                  />
                </div>
              )}
          </React.Fragment>
        )}

        {!isEmpty(detail.vehicle.icare) && detail.vehicle.icare.in_icare === true && (
          <div className='detail-vehicle__badges__wrapper'>
            <img className='detail-vehicle__badges__isuzu_care' src={helper.getAssets(11, 1, 'icare_badge', 'vehicle_detail')} alt='' />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default Badge
