import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

// Router
import { useHistory } from 'react-router-dom'

// Redux
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Filter from 'components/elements/Filter'
import TabBar from 'components/elements/TabBar'
import VehicleAction from 'domain/Vehicle/components/VehicleAction'
import ContentHeader from 'components/layouts/ContentHeader'
import Button from 'components/elements/Button'
import VehicleShowItems from './VehicleShowItems'

// Actions
import { getVehicles, checkAll, uncheckAll, getVehicleLength } from 'store/actions/vehicles'

// Methods
import _filter from 'lodash/filter'
import _ from 'lodash'

// Constants
import { USER_TYPE_RESOLVER, USER_TYPE } from 'config/constant'

// Styles
import './_index.scss'
import { PROFILE } from 'store/definitions'

// Icon
import { calendar } from 'config/icon'

// Utils
import { checkError } from 'utils/helper'
import { EndOfSupportBanner } from 'components/elements/EndOfSupportBanner/EndOfSupportBanner'

const ITEMS_LIMIT = 12

const Vehicles = ({
  vehicles,
  userType,
  checkAll,
  uncheckAll,
  getVehicles,
  getVehicleLength,
  filter: { keyword, orderBy, sortBy, isList, controller, type }
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const length = useSelector((state) => state.vehicleLength.length)

  dispatch({
    type: PROFILE.PAGEVIEW
  })
  let listData = vehicles.data
  let hasMoreRecords = vehicles.hasMoreRecords

  useEffect(() => {
    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   return;
    // }
    getVehicles(true, keyword, orderBy + sortBy, 0, ITEMS_LIMIT, activeTabIndex, 0, controller, type).catch((err) => {
      checkError(err)
    })
  }, [getVehicles, keyword, orderBy, sortBy, activeTabIndex, type, controller])

  const fetchMoreData = _.debounce(() => {
    getVehicles(
      false,
      keyword,
      orderBy + sortBy,
      (vehicles.offset ? vehicles.offset : 0) + ITEMS_LIMIT,
      ITEMS_LIMIT,
      activeTabIndex,
      0,
      controller,
      type
    ).catch((err) => {
      checkError(err)
    })
    getVehicleLength().catch((err) => {
      checkError(err)
    })
  }, 1000)

  useEffect(() => {
    if (activeTabIndex === 1) {
      listData = _filter(vehicles.data, ['is_favorite', true])
      setTotalCount(listData.length)
    } else {
      setTotalCount(length)
    }
  }, [activeTabIndex])

  useEffect(() => {
    const detailId = vehicles.data[0] && vehicles.data[0].id
    if (length === 0) {
      history.push('/vehicles/search')
    } else if (length === 1 && detailId) {
      history.push({
        pathname: `/vehicle/${detailId}`,
        state: { fromPage: 'Vehicle List' }
      })
    }
    setTotalCount(vehicles.total_count)
  }, [length, vehicles.data])

  const handleAssign = () => {
    history.push('/vehicles/assign-controller')
  }

  const handleUnassign = () => {
    history.push('/vehicles/unassign-controller')
  }

  const handleRemove = () => {
    history.push('/vehicles/delete')
  }

  const classesBody = classnames('vehicles__body', { 'has--checked': vehicles.selectedVehicles.length > 0 })

  return (
    <div className='vehicles'>
      <div className='vehicles__header'>
        <ContentHeader title={'vehicle.list.title'} type='vehicles' userType={userType} />
        <Filter type='vehicles' />
        <TabBar
          tabs={['vehicle.list.all', 'vehicle.list.favorites']}
          colors={['normal', 'favorite']}
          onActiveIndexChange={setActiveTabIndex}
          isFolder
        />
        <div className='vehicles__header__pager'>
          <VehicleAction
            selectedVehicles={vehicles.selectedVehicles}
            onAssign={handleAssign}
            onUnassign={handleUnassign}
            onRemove={handleRemove}
            onClose={uncheckAll}
          />
        </div>
        <div className='dealer-appointment'>
          <Button
            type='add-new'
            label='dealerAppointment.appointment'
            icon={calendar}
            onClick={(e) => {
              e.stopPropagation()
              window.previousLocation = '/vehicle-list'
              history.push({
                pathname: '/dealer-search'
              })
            }}
          />
        </div>
      </div>
      <div className={classesBody}>
        {userType === USER_TYPE.INDIVIDUAL && <EndOfSupportBanner />}
        {isList && (
          <div className={'vehicles__body__list--header ' + (USER_TYPE_RESOLVER[userType] || '').toLowerCase()}>
            {getFormatMessage('vehicle.list.listName', 'name')}
            {userType === USER_TYPE.CORPORATE && getFormatMessage('vehicle.list.listName', 'assignment-column')}
            {getFormatMessage('vehicle.list.lastMaintenanceDate', 'last-maintenance-date')}
            {getFormatMessage('vehicle.list.listMaintenance', 'next-main')}
            {getFormatMessage('vehicle.list.listAge', 'age')}
            {getFormatMessage('vehicle.list.vehicleType', 'type')}
            {getFormatMessage('vehicle.list.driverName', 'driver-name')}
            <div className='vehicles__body__list--header__item fav'>&nbsp;</div>
          </div>
        )}
        {vehicles.total_count === 0 && (
          <div className='vehicles__body__list--header'>
            {userType !== USER_TYPE.CONTROLLER ? (
              <FormattedMessage id='common.labels.noVehicles2' defaultMessage='common.labels.noVehicles2' />
            ) : (
              <FormattedMessage id='common.labels.noVehicles1' defaultMessage='common.labels.noVehicles1' />
            )}
          </div>
        )}
        <div className='list'>
          <div className='list--header'>
            {checkAll && userType === USER_TYPE.CORPORATE && totalCount > 0 && (
              <Button onClick={checkAll} icon='check' color='transparent' className='button--check-all' label='vehicle.list.checkAll' />
            )}
            <div className='vehicles__body__count'>
              <FormattedMessage id='vehicle.list.total' values={{ value: totalCount }} defaultMessage='vehicle.list.total' />
            </div>
          </div>
          {VehicleShowItems(listData, userType, isList, hasMoreRecords, fetchMoreData)}
        </div>
      </div>
    </div>
  )
}

const getFormatMessage = (text, classname) => {
  return (
    <div className={`vehicles__body__list--header__item ${classname}`}>
      <FormattedMessage id={text} defaultMessage={text} />
    </div>
  )
}

Vehicles.propTypes = {
  checkAll: PropTypes.func,
  getVehicles: PropTypes.func,
  getControllersList: PropTypes.func,
  vehicles: PropTypes.object,
  userType: PropTypes.string,
  controllers: PropTypes.array,
  filter: PropTypes.shape({
    keyword: PropTypes.string,
    orderBy: PropTypes.number,
    sortBy: PropTypes.number,
    isList: PropTypes.bool,
    type: PropTypes.string,
    controller: PropTypes.string
  }),
  history: PropTypes.object
}

export const mapStateToProps = (state) => ({
  vehicles: state.vehicles,
  userType: state.profile.type,
  filter: state.filterVehicles,
  controllers: state.controllersList.data,
  length: state.vehicleLength.length
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        getVehicles,
        checkAll,
        uncheckAll,
        getVehicleLength
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles)
