import React from 'react'

import { FormattedMessage } from 'react-intl'

import DetailLayout from 'domain/RepairHistory/components/DetailLayout/DetailLayout'

const ItemList = (props) => {
  return (
    <div className='repair-detail__body__item'>
      {(props.RepairDetail.item_list.part_list.length > 0 ||
        props.RepairDetail.item_list.labour_list.length > 0 ||
        props.RepairDetail.item_list.sup_con_list.length > 0 ||
        props.RepairDetail.item_list.consume_list.length > 0) &&
        getHeaderLayout(props.RepairDetail)}
      {props.RepairDetail.item_list.part_list.length > 0 && getDetailLayout(props.RepairDetail, 'parts')}
      {props.RepairDetail.item_list.labour_list.length > 0 && getDetailLayout(props.RepairDetail, 'labour')}
      {props.RepairDetail.item_list.sup_con_list.length > 0 && getDetailLayout(props.RepairDetail, 'supcon')}
      {props.RepairDetail.item_list.consume_list.length > 0 && getDetailLayout(props.RepairDetail, 'consume')}
    </div>
  )
}

const getHeaderLayout = (repairDetail) => {
  let header = repairDetail.is_icare ? (
    <FormattedMessage id='RepairHistory.RepairDetail.list.icare' defaultMessage='RepairHistory.RepairDetail.list.icare' />
  ) : (
    <FormattedMessage id='RepairHistory.RepairDetail.list.paid' defaultMessage='RepairHistory.RepairDetail.list.paid' />
  )

  return <div className='repair-detail__body__item__header'>{header}</div>
}

const getDetailLayout = (repairDetail, subListName) => {
  let values
  let price
  let itemList = repairDetail.item_list

  switch (subListName) {
    case 'parts':
      values = itemList.part_list
      price = itemList.part_price
      break
    case 'labour':
      values = itemList.labour_list
      price = itemList.labour_price
      break
    case 'supcon':
      values = itemList.sup_con_list
      price = itemList.sup_con_price
      break
    case 'consume':
      values = itemList.consume_list
      price = itemList.consume_price
      break
    default:
      values = null
  }

  return <DetailLayout subList={subListName} values={values} price={price} icare={repairDetail.is_icare} />
}

export default ItemList
