import React from 'react'

import { FormattedMessage } from 'react-intl'

import { isMobile } from 'react-device-detect'
import isEmpty from 'lodash/isEmpty'

import DetailLayout from '../../../DetailLayout/DetailLayout'

const PendingItemList = (props) => {
  return (
    <React.Fragment>
      {!isEmpty(props.RepairDetail.pending_items) && (
        <div className='repair-detail__body__item'>
          <div className='repair-detail__body__item__pending'>
            <div className='repair-detail__body__item__header'>
              <FormattedMessage id='RepairHistory.RepairDetail.list.pending' defaultMessage='RepairHistory.RepairDetail.list.pending' />
            </div>
            {props.RepairDetail.pending_items.map((value) => (
              <ul key={value.name}>
                <li>{value.name}</li>
              </ul>
            ))}
          </div>
          {!isMobile && <div className='repair-detail__body__line'></div>}
        </div>
      )}
      {props.RepairDetail.has_claim_item && (
        <div className='repair-detail__body__item'>
          <div className='repair-detail__body__item__header'>
            <FormattedMessage id='RepairHistory.RepairDetail.list.other' defaultMessage='RepairHistory.RepairDetail.list.other' />
          </div>
          <DetailLayout subList='claim' icare={props.RepairDetail.has_claim_item} />
        </div>
      )}
    </React.Fragment>
  )
}

export default PendingItemList
