import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

//Components
import Button from 'components/elements/Button'
import QRCode from 'qrcode.react'
import { facebookIcon, lineIcon, instagramIcon, youtubeIcon } from 'config/icon'
import fb1 from 'assets/images/fb1.jpg'
import fb2 from 'assets/images/fb2.jpg'
import fb3 from 'assets/images/fb3.jpg'
import ContentHeader from 'components/layouts/ContentHeader'

// Actions
import { logUserChatInteraction } from 'store/actions/chat'
import { getProfileServiceCenter } from 'store/actions/profile'

// Utils
import { checkError } from 'utils/helper'

// classnames
import classnames from 'classnames'

// Styles
import './_index.scss'
import { postPageView } from 'store/actions/profile'

const ChatPage = (props) => {
  useEffect(() => {
    getProfileServiceCenter().catch((err) => {
      checkError(err)
    })
    postPageView()
  }, [])

  const lineClick = (link, dealer) => {
    logUserChatInteraction(dealer).catch((err) => {
      checkError(err)
    })
    window.open(link)
  }

  const isMobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = classnames('chat-page-wrapper')

  return (
    <div className={classes}>
      <ContentHeader title={'chat.pageTitle'} subTitle={'chat.pageSubtitle'} />

      <div className='chat-page-wrapper__body'>
        <div className='chat-page-wrapper__body__header'>
          {props.profile ? (
            <p>
              <FormattedMessage id='chat.title' defaultMessage='chat.title' />
            </p>
          ) : (
            <FormattedMessage id='chat.firstTime' defaultMessage='chat.firstTime' />
          )}
        </div>

        {props.profile &&
          props.profile.map((p) => {
            return (
              <div key={p.service_center_id}>
                {isMobile() === true ? (
                  <Button
                    type='line-dealer'
                    className='chat-page-wrapper__body__line-btn'
                    label={p.dealer_name + ' - ' + p.service_center_name}
                    customIcon={lineIcon}
                    onClick={() => lineClick(p.line_oa_link, p.dealer_name)}
                  />
                ) : (
                  <div className='chat-page-wrapper__body__qrcode'>
                    <div className='chat-page-wrapper__body__qrcode__text'>
                      {p.dealer_name} - {p.service_center_name}
                    </div>
                    <QRCode level='L' size={256} value={p.line_oa_link} />
                  </div>
                )}
              </div>
            )
          })}

        <div className='chat-page-wrapper__body__service-center'>
          {props.profile && (
            <p>
              <FormattedMessage id='chat.subTitle' defaultMessage='chat.subTitle' />
            </p>
          )}
          <div className='chat-page-wrapper__body__service-center--btn'>
            <Button
              icon='list'
              label='chat.provinceSC'
              color='dark'
              onClick={() => window.open('http://isuzu-tis.com/dealer/', '_blank')}
            />
          </div>
          <div className='social-media'>
            <div className='social-media__item social-media__item--facebook'>
              <div className='social-media__item__facebook'>{facebookIcon}</div>
              <div className='social-media__item__sub'>
                <a
                  href='https://www.facebook.com/ALLNEWISUZUDMAX/'
                  className='social-media__item__sub__item'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className='social-media__item__sub__item__image'>
                    <img src={fb1} alt='' />
                  </div>
                  <div className='social-media__item__sub__item__desc'>
                    <FormattedMessage id='chat.dmax' defaultMessage='chat.dmax' />
                  </div>
                </a>
                <a
                  href='https://www.facebook.com/isuzumuxthailand/'
                  className='social-media__item__sub__item'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className='social-media__item__sub__item__image'>
                    <img src={fb2} alt='' />
                  </div>
                  <div className='social-media__item__sub__item__desc'>
                    <FormattedMessage id='chat.mux' defaultMessage='chat.mux' />
                  </div>
                </a>
                <a
                  href='https://www.facebook.com/IsuzuTrucksThailandOfficial/'
                  className='social-media__item__sub__item'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className='social-media__item__sub__item__image'>
                    <img src={fb3} alt='' />
                  </div>
                  <div className='social-media__item__sub__item__desc'>
                    <FormattedMessage id='chat.facebookofficial' defaultMessage='chat.facebookofficial' />
                  </div>
                </a>
              </div>
            </div>
            <div className='social-media__item'>
              <a href='https://line.me/R/ti/p/%40isuzuthai' className='social-media__item__line' target='_blank' rel='noopener noreferrer'>
                {lineIcon}
              </a>
            </div>
            <div className='social-media__item'>
              <a
                href='https://www.youtube.com/channel/UCmTP28q4oZsMCyx16PiPJwA'
                className='social-media__item__youtube'
                target='_blank'
                rel='noopener noreferrer'
              >
                {youtubeIcon}
              </a>
            </div>
            <div className='social-media__item'>
              <a
                href='https://www.instagram.com/isuzuthailandofficial/'
                className='social-media__item__instagram'
                target='_blank'
                rel='noopener noreferrer'
              >
                {instagramIcon}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  profile: state.profileServiceCenters.data
})

const mapDispatchToProps = /*istanbul ignore next*/ (dispatch) =>
  bindActionCreators(
    {
      logUserChatInteraction,
      getProfileServiceCenter,
      postPageView
    },
    dispatch
  )

ChatPage.propTypes = {
  profile: PropTypes.array,
  logUserChatInteraction: PropTypes.func,
  getProfileServiceCenter: PropTypes.func
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatPage)
