import { FILTER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  keyword: '',
  orderBy: 31,
  sortBy: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER.COUPON.KEYWORD:
      return Object.assign({}, state, {
        ...state,
        keyword: action.keyword
      })

    case FILTER.COUPON.ORDER:
      return Object.assign({}, state, {
        ...state,
        orderBy: action.orderBy
      })

    case FILTER.COUPON.SORT:
      return Object.assign({}, state, {
        ...state,
        sortBy: state.sortBy ? 0 : 1
      })

    case FILTER.COUPON.CLEAR:
      return {
        ...initialState
      }

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
