import React from 'react'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import RepairHistoryList from 'domain/RepairHistory/components/RepairHistoryList'

const RepairHistory = (props) => {
  return (
    <div>
      <DetailHeader showBack defaultPath={`/vehicle/${props.match.params.id}`} type='repair-history' />
      <RepairHistoryList />
    </div>
  )
}

export default RepairHistory
