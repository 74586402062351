import React from 'react'

// Classnames
import classnames from 'classnames'

// Styles
import './_index.scss'

const GuideItem = (props) => {
  const classes = classnames('guide-item')
  return (
    <div className={classes}>
      <img src={props.detail.url} alt='' />
    </div>
  )
}

export default GuideItem
