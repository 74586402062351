import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import T2HDetail from 'domain/T2H/components/T2HDetail'
import DetailHeader from 'components/layouts/DetailHeader'

// Proptypes
import PropTypes from 'prop-types'

// Actions
import { getVehicleDetail } from 'store/actions/vehicles'
import { postPageView } from 'store/actions/profile'

// Utils
import { checkError } from 'utils/helper'

const T2H = ({ master, match, getVehicleDetail }) => {
  useEffect(() => {
    getVehicleDetail(match.params.id).catch((err) => checkError(err))
  }, [])

  return (
    <div>
      <DetailHeader showBack type='basic' />
      {master.contents && <T2HDetail />}
    </div>
  )
}

T2H.propTypes = {
  getVehicleDetail: PropTypes.func,
  vehicleDetail: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

const mapStateToProps =
  /* istanbul ignore next*/
  (state) => ({
    master: state.master,
    vehicleDetail: state.vehicleDetail
  })

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        postPageView,
        getVehicleDetail
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(T2H)
