import { AUTH } from 'store/definitions'

const initialState = {
  line_uid: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH.SIGNUP:
      return Object.assign({}, state, {
        ...state,
        ...action.data
      })

    case AUTH.CLEAR:
      return initialState

    default:
      return state
  }
}
