import { DEALER, CLEAR_THIS_STATE } from '../definitions'

const initialState = {
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DEALER.GET:
      return Object.assign({}, state, {
        ...state,
        data: action.data
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
