import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'

// Router
import { withRouter } from 'react-router-dom'

import { FormattedHTMLMessage } from 'react-intl'

// Redux
import { useDispatch } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Components
import Button from 'components/elements/Button'
import ConfirmModal from 'components/elements/ConfirmModal'
import DetailHeader from 'components/layouts/DetailHeader'
import { Form, Field, reduxForm } from 'redux-form'

import { Grid, withStyles } from '@material-ui/core'
import TextField from 'components/elements/TextField'

// Actions
import { changePassword } from 'store/actions/profile'
import { isMobileApp } from 'utils/validation'

// Methods
import { newPasswordsMustMatch, change_newpw, change_newpwmin8, changepw, newPasswordsCantMatch, change_pw2 } from 'utils/validation'

// Styles
import './_index.scss'
import { postPageView } from 'store/actions/profile'
import Menu from 'components/elements/Menu'

const styles = {}

const ProfileChangePassword = ({ handleSubmit, pristine, invalid }) => {
  const modalRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(postPageView)
  }, [])

  const setPassword = () => {
    dispatch(changePassword())
  }

  const classes = classnames('profile-change-pass')

  const content = (
    <div className='profile-change-pass-wrapper'>
      <div className='profile-change-pass-wrapper__header'>
        <FormattedHTMLMessage id='myProfile.labels.changePassword' defaultMessage='myProfile.labels.changePassword' />
      </div>

      <Form
        onSubmit={handleSubmit(
          /* istanbul ignore next */
          () => modalRef.current.openModal()
        )}
        className='profile-change-pass-wrapper__form'
      >
        <div className='profile-change-pass-wrapper__form__field'>
          <Field
            name='old_password'
            component={TextField}
            type='password'
            label={<FormattedHTMLMessage id='myProfile.changePassword.oldPassword' defaultMessage='myProfile.changePassword.oldPassword' />}
            isRequired
            isClearable
            validate={[changepw]}
            togglePassword
            id='profile_old_password'
          />
        </div>

        <div className='profile-change-pass-wrapper__form__field'>
          <Field
            name='new_password'
            component={TextField}
            type='password'
            label={<FormattedHTMLMessage id='myProfile.changePassword.newPassword' defaultMessage='myProfile.changePassword.newPassword' />}
            isRequired
            togglePassword
            isClearable
            id='profile_password'
            validate={[newPasswordsCantMatch, change_newpw, change_newpwmin8]}
          />
        </div>

        <div className='profile-change-pass-wrapper__form__field'>
          <Field
            name='password_repeated'
            component={TextField}
            type='password'
            label={
              <FormattedHTMLMessage
                id='myProfile.changePassword.confirmPassword'
                defaultMessage='myProfile.changePassword.confirmPassword'
              />
            }
            isRequired
            isClearable
            togglePassword
            id='profile_password_repeated'
            validate={[newPasswordsMustMatch, change_pw2]}
          />
        </div>

        <div className='profile-change-pass-wrapper__form__action'>
          <Button disabled={pristine || invalid} type='submit' color='primary' label='myProfile.changePassword.changePassword' />
        </div>
      </Form>
    </div>
  )

  return (
    <div className={classes}>
      <ConfirmModal
        mode={2}
        title='myProfile.changePassword.popupTitle'
        content='myProfile.changePassword.description'
        ref={modalRef}
        onConfirmed={setPassword}
      />

      {!isMobileApp() && <DetailHeader showBack={isMobile} defaultPath='profile' type='profile-setting' />}
      {isMobile ? (
        content
      ) : (
        <div className={'content-setting'}>
          <Grid container className={'container-setting'}>
            {!isMobileApp() && (
              <Grid conatiner sm={3}>
                <Menu mode={0} tabActive={'changePassword'} />
              </Grid>
            )}
            <Grid justify={'center'} xs={12} sm={9}>
              {content}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

ProfileChangePassword.propTypes = {
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool
}

export default withRouter(
  reduxForm({
    form: 'changePassword'
  })(withStyles(styles)(ProfileChangePassword))
)
