import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'
import queryString from 'query-string'

// Router
import { useHistory } from 'react-router-dom'

// Components
import Modal from 'components/elements/Modal'
import PopupVehicle from 'components/elements/PopupVehicle'

// Actions
import { getVehicles } from 'store/actions/vehicles'
import { getMuxOwner } from 'store/actions/mux'
import { postPageView } from 'store/actions/profile'
import { getISPVehicles } from 'store/actions/ispVehicles'
import { showLoader } from 'store/actions/loader'

// Method
import { checkError, getAssets } from 'utils/helper'
import { isMobileApp } from 'utils/validation'
import api from 'api/'

// Styles
import './_index.scss'

// Constant
import { activityTypes, USER_TYPE } from 'config/constant'

// Env Config
import env from 'config/environment'

import { isEmpty } from 'lodash'
import { hideLoader } from '../../store/actions/loader'

const Privilege = () => {
  const excellencyRef = useRef()
  const ispRef = useRef()
  const history = useHistory()
  const master = useSelector((state) => state.master)
  const isMux = useSelector((state) => state.mux.is_owner)
  const profile = useSelector((state) => state.profile)
  const vehicles = useSelector((state) => state.vehicles)
  const ispVehicle = useSelector((state) => state.ispVehicle)
  const userType = useSelector((state) => state.profile.type)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader())
      try {
        await Promise.all([
          dispatch(getVehicles(false, '', 1, 0, 2000, 0, 0, undefined, 'all', false, true)),
          dispatch(getMuxOwner()),
          dispatch(postPageView()),
          dispatch(getISPVehicles(false))
        ])
      } catch (err) {
        checkError(err)
      } finally {
        dispatch(hideLoader())
      }
    }

    fetchData()
  }, [])

  const excellencyWidgetClickHandler = () => {
    if (userType === USER_TYPE.INDIVIDUAL) {
      if (vehicles.total_count > 1 && Cookies.get('excId') === undefined) {
        excellencyRef.current.openModal()
      } else if (vehicles.total_count > 1 && Cookies.get('excId') !== undefined) {
        history.push(`/excellency/${Cookies.get('excId')}`)
      } else if (vehicles.total_count === 1 && vehicles.data[0]) {
        history.push(`/excellency/${vehicles.data[0].id}`)
      } else {
        history.push(`/excellency/no-vehicle`)
      }
    } else if (userType === USER_TYPE.CORPORATE || userType === USER_TYPE.CONTROLLER) {
      if (vehicles.total_count > 0) {
        excellencyRef.current.openModal()
      } else {
        history.push(`/excellency/no-vehicle`)
      }
    }

    api
      .logActivities({
        type: activityTypes.EXCELLENCY,
        description: 'Click to see ISUZU Ying Kao Ying Koom details on privilege'
      })
      .catch((err) => checkError(err))
  }

  const ispWidgetClickHandler = () => {
    api
      .logActivities({
        type: activityTypes.ISP,
        description: 'Click to see ISP details on privilege'
      })
      .catch((err) => checkError(err))

    if (ispVehicle.total_count === 0 || !ispVehicle.data[0]) return history.push(`/isp/no-vehicle`)

    // Find  default isp vehicle
    const defaultIspVehicle = Object.values(ispVehicle.data).filter(function (vehicle) {
      return vehicle.is_default
    })

    if (defaultIspVehicle.length > 0 && defaultIspVehicle[0]) {
      return history.push(`/isp/${defaultIspVehicle[0].hash_id}`)
    }

    // In case we cannot find default isp vehicle
    // In case we have only one isp vehicle
    if (ispVehicle.total_count === 1 && ispVehicle.data[0]) {
      return history.push(`/isp/${ispVehicle.data[0].hash_id}`)
      // For Individual user, let user select isp vehicle or get from cookies
    } else if (userType === USER_TYPE.INDIVIDUAL) {
      if (Cookies.get('ispId')) {
        return history.push(`/isp/${Cookies.get('ispId')}`)
      } else {
        ispRef.current.openModal()
        return
      }
    } else {
      history.push(`/isp/${ispVehicle.data[0].hash_id}`)
    }
  }

  const muxPrivilege = () => {
    !isMobileApp() && dispatch(showLoader())

    const url = queryString.stringifyUrl(
      {
        url: `${env.mux}/onboard`,
        query: {
          myi_app: isMobileApp() ? true : null,
          utm_source: 'myisuzu'
        }
      },
      { skipNull: true }
    )
    window.location.href = url
  }

  return (
    <div className='privileges'>
      <div>
        <Modal mode={8} title={'privilege.vehicleModal'} content={<PopupVehicle exc />} ref={excellencyRef} />
        <Modal mode={8} title={'privilege.vehicleModal'} content={<PopupVehicle isp />} ref={ispRef} />
        <div className='privileges__header'>
          <FormattedMessage id='privilege.header' defaultMessage='privilege.header' />
        </div>

        {!isEmpty(profile) && (
          <div className='privileges__body'>
            {vehicles.total_count > 0 && (
              <div className='privileges__body__btn excellency' onClick={excellencyWidgetClickHandler}>
                <div className='privileges__body__content'>
                  <img src={master ? getAssets(1, 2, 'excellency_logo', 'privilege_widget') : ''} alt=''></img>
                </div>
              </div>
            )}
            {!vehicles.has_only_cv && (
              <div className='privileges__body__btn isp' onClick={ispWidgetClickHandler}>
                <div className='privileges__body__content'>
                  <img src={master ? getAssets(2, 2, 'isp_logo', 'privilege_widget') : ''} alt=''></img>
                </div>
              </div>
            )}
            <div className='privileges__body__btn promotions' onClick={() => history.push('/promotions')}>
              <div className='privileges__body__content'>
                <img src={master ? getAssets(8, 2, 'promotion_logo', 'privilege_widget') : ''} alt=''></img>
              </div>
            </div>
            {(isMux || profile.isDemoUser) && (
              <div className='privileges__body__btn ' onClick={muxPrivilege} disabled={profile.isDemoUser}>
                <div className='privileges__body__content'>
                  <img className='muxlogo' src={master ? getAssets(10, 2, 'mux_logo', 'privilege_widget') : ''} alt=''></img>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Privilege
