import React from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Assests
import bg from 'assets/images/isp-background.png'

// Constants
import { USER_TYPE } from 'config/constant'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import { RegisButton } from '../../ISPRegisButton'

// Methods
import { FormattedHTMLMessage } from 'react-intl'

// Styles
import './index.scss'

// lodash
import isEmpty from 'lodash/isEmpty'

const PackageHeader = () => {
  const classnames = 'isp--header'
  const history = useHistory()
  const profile = useSelector((state) => state.profile)
  const ispDetail = useSelector((state) => state.ispDetail)
  const ispList = useSelector((state) => state.ispList)

  const user_type = profile.type === 1 ? 'บุคคลทั่วไป' : profile.type === 2 ? 'นิติบุคคล' : 'ผู้ดูแลรถ'
  return (
    <React.Fragment>
      <div
        className={classnames}
        style={{
          backgroundImage: `url(${bg})`
        }}
      >
        <DetailHeader showBack type='basic' defaultPath='/privilege' isUseDefault={isEmpty(ispDetail)} />
        <div className={`${classnames}--body`}>
          <div className={`${classnames}--body--logo`}>
            <img src='https://cdndev.my.isuzu-tis.com/assets/media/images/isp/isp-logo.svg' alt='' />
          </div>
          <div className={`${classnames}--body--text`}>
            <FormattedHTMLMessage id='isp.header' defaultMessage='isp.header' />
            <FormattedHTMLMessage id='isp.headerDetail' defaultMessage='isp.headerDetail' />
          </div>
          {!isEmpty(ispDetail) && (
            <div>
              {(profile.type === USER_TYPE.CORPORATE || (profile.type === USER_TYPE.INDIVIDUAL && !ispDetail.is_corporate_vehicle)) &&
                ispDetail.ISPAvailablePackage !== null && (
                  <div className={`${classnames}--btn`}>{RegisButton(ispDetail, ispList, user_type, history)}</div>
                )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PackageHeader
