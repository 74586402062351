import React from 'react'
import { FormattedMessage } from 'react-intl'

// Utils
import * as format from 'utils/format'

const NextMaintainace = ({ vehicle = {}, intervalMaster }) => {
  return (
    <div className='detail-vehicle-wrapper__details__body__next-ma'>
      <div className='detail-vehicle-wrapper__details__body__details__section-name'>
        <FormattedMessage id='common.labels.nextVMaintenanceInfo' defaultMessage='common.labels.nextVMaintenanceInfo' />
      </div>
      <div className='detail-vehicle-wrapper__details__body__details__group-field'>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage id='vehicle.detail.nextDate' defaultMessage='vehicle.detail.nextDate' />
            <div className='value'>
              <p>
                {vehicle.next_maintenance_date ? format.ispDateFormat(vehicle.next_maintenance_date) : '-'}
                {vehicle.is_overdue && vehicle.is_overdue.date && (
                  <span className='overdue'>
                    <br />
                    <FormattedMessage id='vehicle.detail.overdue' defaultMessage='vehicle.detail.overdue' />
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage id='vehicle.detail.mileage' defaultMessage='vehicle.detail.mileage' />
            <div className='value'>
              <p>
                {vehicle.next_maintenance_mileage ? format.showNumber(vehicle.next_maintenance_mileage) : '-'}
                {vehicle.is_overdue && vehicle.is_overdue.mileage && (
                  <span className='overdue'>
                    <br />
                    <FormattedMessage id='vehicle.detail.overdue' defaultMessage='vehicle.detail.overdue' />
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='detail-vehicle-wrapper__details__body__details__group-field'>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage id='vehicle.detail.lastDate' defaultMessage='vehicle.detail.lastDate' />
            <div className='value'>
              <p>{vehicle.last_maintenance_service_date ? format.ispDateFormat(vehicle.last_maintenance_service_date) : '-'}</p>
            </div>
          </div>
        </div>
        <div className='detail-vehicle-wrapper__details__body__details__field'>
          <div className='label'>
            <FormattedMessage id='vehicle.detail.mileage' defaultMessage='vehicle.detail.mileage' />
            <div className='value'>
              <p>{vehicle.last_maintenance_service_mileage ? format.formatNumber(vehicle.last_maintenance_service_mileage) : '-'}</p>
            </div>
          </div>
        </div>
      </div>

      {intervalMaster && intervalMaster.interval_check_month && intervalMaster.interval_check_km && (
        <div className='detail-vehicle-wrapper__details__body__details__field detail-vehicle__body__maintenance'>
          <div className='label'>
            <FormattedMessage id='vehicle.detail.nextMaintenanceCondition' defaultMessage='vehicle.detail.nextMaintenanceCondition' />
          </div>
          <div className='value maintenance'>
            {intervalMaster && intervalMaster.interval_check_month && intervalMaster.interval_check_km && (
              <FormattedMessage
                id='vehicle.detail.conditionDetail'
                values={{
                  interval_check_month: format.showNumber(intervalMaster.interval_check_month),
                  interval_check_km: format.showNumber(intervalMaster.interval_check_km)
                }}
                defaultMessage='vehicle.detail.conditionDetail'
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default NextMaintainace
