import api from 'api/'
import { activityTypes } from 'config/constant'

/**
 * Activity log in chat page if user click line@ account button
 * @param {*} serviceCenterName
 */
const logUserChatInteraction = (serviceCenterName) => {
  return () => {
    api.logActivities({
      type: activityTypes.COMMUNICATION,
      description: 'Click Line@ ' + serviceCenterName + ' from chat screen'
    })
  }
}

export { logUserChatInteraction }
