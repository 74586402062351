import React from 'react'

// Constants
import { PROTECTION } from '../info'

// CSS
import './index.scss'

const PackageProtection = () => {
  return (
    <React.Fragment>
      <div className='protection'>
        <div className='protection__title'>{PROTECTION.title}</div>
        <div className='protection__detail' dangerouslySetInnerHTML={{ __html: PROTECTION.detail }} />
        <div className='protection__body'>
          {PROTECTION.icons.map((v) => (
            <div className='protection__body__icon'>
              <img src={v.image} alt='' />
              <p dangerouslySetInnerHTML={{ __html: v.label }} />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PackageProtection
