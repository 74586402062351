import React, { useEffect } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import SearchDealer from 'domain/DealerSearch/components/SearchDealer'

// actions
import { getProfileServiceCenter } from 'store/actions/profile'

// Styles
import './_index.scss'
import { postPageView } from 'store/actions/profile'
import { EndOfSupportBanner } from 'components/elements/EndOfSupportBanner/EndOfSupportBanner'
import { USER_TYPE } from 'config/constant'

const DealerSearch = (props) => {
  useEffect(() => props.postPageView())
  const profile = useSelector((state) => state.profile)

  return (
    <div className='dealer-search'>
      <div className='dealer-search__header'>
        <DetailHeader showBack type='dealer' />
      </div>
      {profile.type === USER_TYPE.INDIVIDUAL && <EndOfSupportBanner />}
      <SearchDealer
        province={props.master && props.master.provinces}
        provinceBP={props.master && props.master.provincesBP}
        district={props.master && props.master.districts}
        districtBP={props.master && props.master.districtsBP}
      />
    </div>
  )
}

DealerSearch.propTypes = {
  master: PropTypes.object,
  getProfileServiceCenter: PropTypes.func
}

const mapStateToProps = /*istanbul ignore next*/ (state) => ({
  master: state.master,
  center: state.profileServiceCenters.data
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getProfileServiceCenter,
        postPageView
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DealerSearch))
