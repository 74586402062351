import React, { useRef, useEffect, forwardRef } from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'
import { List as ReactList, WindowScroller } from 'react-virtualized'

// Styles
import 'react-virtualized/styles.css'
import './_index.scss'

/*istanbul ignore next*/
const List = forwardRef((props, ref) => {
  const listRef = useRef()

  useEffect(() => listRef.current.forceUpdateGrid())

  const rowRenderer = ({ index, key }) => {
    const row = props.data[index]
    const isCreate = props.isCreate
    const RenderComponent = props.component

    if (RenderComponent === void 0) {
      return false
    }

    let itemRender
    if (row && !isCreate) {
      itemRender = (
        <RenderComponent
          key={key}
          detail={row}
          userType={props.userType}
          isChecked={row.is_checked}
          isList={props.isList}
          fromPage={props.fromPage}
        />
      )
    } else if (row && isCreate) {
      itemRender = (
        <RenderComponent
          key={key}
          detail={row}
          userType={props.userType}
          isChecked={row.is_checked}
          isList={props.isList}
          isCreate={isCreate}
          fromPage={props.fromPage}
        />
      )
    } else {
      itemRender = <RenderComponent key={key} isPlaceholder userType={props.userType} isChecked={false} fromPage={props.fromPage} />
    }

    return itemRender
  }
  const classes = classnames('list', {
    adaptable: props.adaptable
  })
  const renderList = (
    <WindowScroller scrollElement={window}>
      {({ height, width }) => {
        return (
          <ReactList
            height={height}
            width={width >= 1200 ? 1200 : width >= 768 ? width - 60 : width}
            rowCount={props.data.length || props.totalCount}
            rowHeight={props.height}
            rowRenderer={rowRenderer}
            className='list__item'
            ref={listRef}
            {...(typeof props.overscanRowCount === 'number' ? { overscanRowCount: this.props.overscanRowCount } : {})}
          />
        )
      }}
    </WindowScroller>
  )
  return <div className={classes}>{renderList}</div>
})

List.propTypes = {
  data: PropTypes.array,
  userType: PropTypes.number,
  totalCount: PropTypes.number,
  height: PropTypes.number,
  isList: PropTypes.bool,
  isCreate: PropTypes.bool,
  adaptable: PropTypes.bool
}

List.defaultProps = {
  userType: 1,
  totalCount: 10,
  rowHeight: 170,
  data: []
}

export default List
