import React, { useEffect, useState } from 'react'

// Router
import { useHistory } from 'react-router'

// Redux
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Slider from 'components/elements/Slider'
import BannerItem from 'components/sections/BannerItem'
import CouponItem from 'domain/Coupons/components/CouponItem'
import VehicleItem from 'domain/Vehicle/components/VehicleItem'
import ControllerItem from 'domain/Controllers/components/ControllerItem'
import PromotionItem from 'domain/Promotions/components/PromotionItem'
import ContentHeader from 'components/layouts/ContentHeader'

// Actions
import { getBannersSlider, getIndividualCustomerEndOfSupportBanner } from 'store/actions/banners'
import { getServiceCouponsSlider, getCashCouponsSlider } from 'store/actions/coupons'
import { getVehiclesSlider } from 'store/actions/vehicles'
import { getPromotionsSlider } from 'store/actions/promotions'
import { getControllersSlider } from 'store/actions/controllers'
import { clearAuth } from 'store/actions/auth'
import { lineConnect, postPageView } from 'store/actions/profile'
import { showLoader, hideLoader } from 'store/actions/loader'
import { issueLineSignin } from 'utils/line'

// Styles
import './_index.scss'

// Constants
import { uriRedirect } from 'utils/auth'
import chat_th from 'assets/images/chat_th.svg'

// Methods
import _isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { checkError } from 'utils/helper'

import 'moment/locale/th'
import { useEnvironment } from '../../hooks/useEnvironment'

const Home = ({
  getBannersSlider,
  getServiceCouponsSlider,
  getCashCouponsSlider,
  getControllersSlider,
  getVehiclesSlider,
  getPromotionsSlider,
  getIndividualCustomerEndOfSupportBanner,
  profile,
  isLoading,
  search,
  lineConnect,
  vehicles,
  banners,
  serviceCoupons,
  sliderTotal,
  cashCoupons,
  promotions,
  controllers,
  endOfSupportBanner
}) => {
  const { environment } = useEnvironment()
  const [urlData] = useState(queryString.parse(search))
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await dispatch(showLoader())
      try {
        await Promise.all([
          profile.type === 1 ? getIndividualCustomerEndOfSupportBanner() : getBannersSlider(),
          getServiceCouponsSlider(),
          getCashCouponsSlider(),
          profile.type === 2 ? getControllersSlider() : Promise.resolve(),
          getVehiclesSlider(),
          getPromotionsSlider()
        ])
      } catch (err) {
        checkError(err)
      }

      if (typeof profile.line_uid !== 'string' && !isLoading && !_isEmpty(urlData) && urlData.state && urlData.code) {
        lineConnect({
          ...urlData
        }).catch((err) => checkError(err))
      }

      postPageView()
      await dispatch(hideLoader())
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setLineConnect = () => {
    issueLineSignin({ redirectUri: new URL('/', uriRedirect), additionalQueries: { bot_prompt: 'aggressive' } })
  }

  const HeaderContent = !profile.line_uid ? (
    <ContentHeader
      type='home'
      title='login.labels.title'
      mobileTitle='login.labels.mobileTitle'
      given_name={profile.given_name}
      env={environment}
      lineConnect={setLineConnect}
    />
  ) : (
    <ContentHeader
      type='home'
      title='login.labels.title'
      mobileTitle='login.labels.mobileTitle'
      given_name={profile.given_name}
      env={environment}
    />
  )

  const bodyContent = (
    <>
      {profile.type === 1 ? (
        <Slider
          title='home.labels.featured'
          component={BannerItem}
          data={endOfSupportBanner}
          userType={profile.type}
          forceNumber={1}
          isBanner={false}
          autoPlay={false}
          isEndOfSupportBanner={true}
        />
      ) : (
        <Slider
          title='home.labels.featured'
          component={BannerItem}
          data={banners}
          userType={profile.type}
          forceNumber={1}
          isBanner={true}
          autoPlay={true}
        />
      )}

      <Slider
        title='home.labels.nextVMaintenance'
        component={VehicleItem}
        data={vehicles}
        userType={profile.type}
        fromPage='Home Screen'
        noPaging
        autoPlay={false}
      />
      <Slider
        title='home.labels.serviceCoupons'
        component={CouponItem}
        data={serviceCoupons}
        userType={profile.type}
        fromPage='Home Screen'
        autoPlay={false}
      />
      <Slider
        title='home.labels.cashCoupons'
        component={CouponItem}
        total={sliderTotal}
        data={cashCoupons}
        userType={profile.type}
        autoPlay={false}
        fromPage='Home Screen'
      />
      <Slider title='home.labels.latestPromotion' component={PromotionItem} data={promotions} userType={profile.type} />
      {profile.type === 2 && (
        <Slider title='home.labels.controllerMaintenance' component={ControllerItem} data={controllers} userType={profile.type} noMargin />
      )}
      <button id='dealer-chat' className='dealer-chat'>
        <img
          className='home-fab dealer-chat'
          src={chat_th}
          alt=''
          onClick={(e) => {
            e.stopPropagation()
            window.previousLocation = '/'
            history.push('/dealer-search')
          }}
        />
      </button>
    </>
  )

  return (
    <div className='home'>
      {HeaderContent}
      {bodyContent}
    </div>
  )
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
  banners: state.bannersSlider.data,
  serviceCoupons: state.couponsServiceSlider.data,
  sliderTotal: state.couponsCashSlider.total_count,
  cashCoupons: state.couponsCashSlider.data,
  vehicles: state.vehiclesSlider.data,
  controllers: state.controllersSlider.data,
  promotions: state.promotionsSlider.data,
  profile: state.profile,
  isLoading: state.loader,
  search: state.router.location.search,
  endOfSupportBanner: state.individualCustomerEndOfSupportBanner.data
})

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getBannersSlider,
        getServiceCouponsSlider,
        getCashCouponsSlider,
        getVehiclesSlider,
        getPromotionsSlider,
        getControllersSlider,
        clearAuth,
        lineConnect,
        postPageView,
        getIndividualCustomerEndOfSupportBanner
      },
      dispatch
    )

Home.propTypes = {
  clearAuth: PropTypes.func,
  history: PropTypes.object,
  banners: PropTypes.array,
  coupons: PropTypes.array,
  vehicles: PropTypes.array,
  controllers: PropTypes.array,
  profile: PropTypes.object,
  promotions: PropTypes.array,
  getBannersSlider: PropTypes.func,
  getServiceCouponsSlider: PropTypes.func,
  getCashCouponsSlider: PropTypes.func,
  getControllersSlider: PropTypes.func,
  getVehiclesSlider: PropTypes.func,
  getPromotionsSlider: PropTypes.func,
  lineConnect: PropTypes.func,
  isLoading: PropTypes.bool,
  search: PropTypes.string,
  endOfSupportBanner: PropTypes.array,
  getIndividualCustomerEndOfSupportBanner: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
