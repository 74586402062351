import styled from 'styled-components'

export const NoInternet = styled.div`
  height: calc(100vh - 21rem);
  align-items: center;
  display: flex;
`

export const Content = styled.div`
  text-align: center;
  width: 100%;
  display: grid;
  
  button {
    margin: auto;
    width: 145px;
  }
`

export const Image = styled.img`
  margin: auto;
`

export const Label = styled.div`
  color: #5D5D5D;
  font-weight: 700;
  margin-bottom: 5rem;
`
