import { gql } from '@apollo/client'

export const MYI_TERMS_AND_CONDITIONS_QUERY = gql`
  query MyiTermsAndConditions {
    cmsNamespace {
      myiTermsAndConditions {
        htmlContentEn
        htmlContentTh
      }
    }
  }
`
