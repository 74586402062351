import React from 'react'
import Collapsible from 'react-collapsible'

// Constant
import { FAQ, TERM_CONDITION } from '../info'

import { expandMore } from 'config/icon'

// CSS
import './index.scss'

const FAQAndTerm = () => (
  <div className='FAQ-term'>
    {collap(FAQ)}
    {collap(TERM_CONDITION)}
  </div>
)

export default FAQAndTerm

const collap = (data) => (
  <div className='collap'>
    <div className='collap--header'>{data.header}</div>
    {data.items.map((v) => (
      <div className='collap--body'>
        <Collapsible trigger={[v.title, expandMore]}>
          {v.detail &&
            v.detail.map((d) => (
              <div className='collap--container'>
                <div className='collap--sub-title'>{d.subTitle && d.subTitle}</div>
                <div className='collap--detail'>
                  {d.lists && d.lists.map((v) => <li>{v}</li>)}
                  {d.listNumber && d.listNumber.map((v) => <p>{v}</p>)}
                </div>
              </div>
            ))}
          {v.table && tableData(v.table)}
        </Collapsible>
      </div>
    ))}
  </div>
)

const tableData = (data) => (
  <table className='collap--table'>
    <tr className='collap--table-header'>
      {data.columnName.map((column) => (
        <th>{column}</th>
      ))}
    </tr>
    {data.records.map((record) => (
      <tr className='collap--table-column'>
        {record.row.map((value, key) => (
          <td className={key === 0 || key === 1 ? 'collap--table-column-check' : 'collap--table-column-label'}>{value}</td>
        ))}
      </tr>
    ))}
  </table>
)
