import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

import networkError from 'assets/images/networkError.svg'

import Button from 'components/elements/Button'

import { NoInternet, Content, Image, Label } from './style'

const NoInternetConnection = () => {
  return (
    <NoInternet>
      <Content>
        <Image src={networkError} alt='' />
        <Label>
          <FormattedHTMLMessage id='common.labels.connectionLost' defaultMessage='common.labels.connectionLost' />
        </Label>
        <Button type='red' label='common.button.tryAgain' onClick={() => window.location.reload()}/>
      </Content>
    </NoInternet>
  )
}

export default NoInternetConnection
