import styled from 'styled-components'

export const InstructionSection = styled.div`
  padding-top: 3rem;
  @media (min-width: 768px) {
    display: none;
  }
`

export const Label = styled.a`
  color: #ed1c24;
  text-decoration: none;
`

export const Content = styled.div`
  display: flex;
`

export const IconContainer = styled.div`
  svg {
    margin-left: 10px;
    margin-right: 4px;
    height: 100%;
  }
`

export const Icon = styled.i``
