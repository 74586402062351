import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import DetailHeader from 'components/layouts/DetailHeader'

import { USER_TYPE } from 'config/constant'

import * as format from 'utils/format'
import { Grid, withStyles } from '@material-ui/core'

import './_index.scss'
import { PROFILE } from 'store/definitions'

const styles = {}

const Profile = () => {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile) || {}

  dispatch({
    type: PROFILE.PAGEVIEW
  })

  return (
    <div className='profile'>
      <DetailHeader type='profile' detail={profile} showBack />
      <div className='profile-bg-wrapper'>
        <Grid container alignContent={'center'} justify={'center'}>
          <Grid alignItems={'center'} xs={12} sm={5}>
            <div className='profile-wrapper'>
              <div className='profile__body'>
                {profile.type !== USER_TYPE.CORPORATE && (
                  <div className='profile__body__full-name'>
                    <div className='label'>
                      <FormattedMessage id='myProfile.labels.fullname' defaultMessage='myProfile.labels.fullname' />
                    </div>
                    <div className='value'>{format.fullName(profile.given_name, profile.surname)}</div>
                  </div>
                )}

                {profile.type === USER_TYPE.CORPORATE && (
                  <div className='profile__body__registration-id'>
                    <div className='label'>
                      <FormattedMessage id='createAccount.labels.companyReg' defaultMessage='createAccount.labels.companyReg' />
                    </div>
                    <div className='value'>{profile.corporate && profile.corporate.company_reg_id}</div>
                  </div>
                )}

                <div className='profile__body__account-type'>
                  <div className='label'>
                    <FormattedMessage id='myProfile.labels.accountType' defaultMessage='myProfile.labels.accountType' />
                  </div>
                  <div className='value'>
                    {format.userType(profile.type)}{' '}
                    <FormattedMessage id='myProfile.labels.account' defaultMessage='myProfile.labels.account' />
                  </div>
                </div>

                {profile.type === USER_TYPE.CORPORATE && (
                  <div className='profile__body__full-name'>
                    <div className='label'>
                      <FormattedMessage id='myProfile.labels.fullname' defaultMessage='myProfile.labels.fullname' />
                    </div>
                    <div className='value'>{format.fullName(profile.given_name, profile.surname)}</div>
                  </div>
                )}

                <div className='profile__body__mobile-num'>
                  <div className='label'>
                    <FormattedMessage id='common.labels.mobilePhoneNumber' defaultMessage='common.labels.mobilePhoneNumber' />
                  </div>
                  <div className='value'>{profile.mobile_phone || '-'}</div>
                </div>

                {(profile.type === USER_TYPE.CORPORATE || profile.type === USER_TYPE.CONTROLLER) && (
                  <div className='profile__body__office-num profile__body__office-num-ext'>
                    <div className='label'>
                      <FormattedMessage id='common.labels.officePhoneNumber' defaultMessage='common.labels.officePhoneNumber' />
                      <div className='value'>{profile.office_phone || '-'}</div>
                    </div>
                    <div className='label'>
                      <FormattedMessage id='common.labels.officePhoneExt' defaultMessage='common.labels.officePhoneExt' />
                      <div className='value'>{profile.office_phone_ext || '-'}</div>
                    </div>
                  </div>
                )}

                {profile.type === USER_TYPE.INDIVIDUAL && (
                  <div className='profile__body__home-phone'>
                    <div className='label'>
                      <FormattedMessage id='common.labels.homePhoneNumber' defaultMessage='common.labels.homePhoneNumber' />
                    </div>
                    <div className='value'>{profile.home_phone || '-'}</div>
                  </div>
                )}

                {profile.birthday && profile.type === USER_TYPE.INDIVIDUAL && (
                  <div className='profile__body__birthday'>
                    <div className='label'>
                      <FormattedMessage id='myProfile.labels.birthday' defaultMessage='myProfile.labels.birthday' />
                    </div>
                    <div className='value'>{profile.birthday ? format.showDate(profile.birthday, true) : '-'}</div>
                  </div>
                )}

                <div className='profile__body__email'>
                  <div className='label'>
                    <FormattedMessage id='common.labels.emailAddress' defaultMessage='common.labels.emailAddress' />
                  </div>
                  <div className='value'>{profile.email || '-'}</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(Profile)
