import { gql } from '@apollo/client'

export const ANONYMOUS_HERO_BANNERS_QUERY = gql`
  query AnonymousHeroBanners {
    cmsNamespace {
      anonymousHeroBanners {
        edges {
          node {
            ... on ImageBanner {
              id
              imageUrl
              webTargetUrl
            }

            ... on UserError {
              code
              message
            }
          }
        }
      }
    }
  }
`
