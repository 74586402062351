import env from 'config/environment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from 'store/actions/loader'
import { getMuxOwner } from 'store/actions/mux'
import { checkError } from 'utils/helper'
import { isMobileApp } from 'utils/validation'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

export const MuxTheExclusive = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const mux = useSelector((state) => state.mux)
  const profile = useSelector((state) => state.profile)

  useEffect(() => {
    if (profile.loadProfileComplete) {
      ;(async () => {
        dispatch(showLoader())

        try {
          await dispatch(getMuxOwner())
        } catch (error) {
          checkError(error)
        } finally {
          dispatch(hideLoader())
        }
      })()
    }
  }, [dispatch, profile])

  useEffect(() => {
    if (['idle', 'loading'].includes(mux.status)) {
      return
    }

    if (mux.status === 'failed' || !mux.is_owner) {
      history.push('/privilege')
      return
    }

    const query = queryString.parse(window.location.search)
    const url = queryString.stringifyUrl(
      {
        url: `${env.mux}/onboard`,
        query: {
          ...query,
          myi_app: isMobileApp() ? true : null,
          utm_source: query.utm_source || 'myisuzu'
        }
      },
      { skipNull: true }
    )
    window.location.href = url
  }, [dispatch, history, mux])

  return <div></div>
}
