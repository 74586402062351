import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import RepairHistoryCard from '../RepairHistoryCard'

// Icons
import { Spin } from 'antd'

const RepairHistoryShowItems = (repairList, hasMore, fetchMoreData, history, params) => (
  <div
    id='scrollableDiv'
    style={{
      height: '100vh'
    }}
  >
    <InfiniteScroll
      dataLength={repairList.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={
        <div className={'predownload'}>
          <Spin />
        </div>
      }
    >
      {repairList.map((value, index) => RepairHistoryCard(value, index, history, params))}
    </InfiniteScroll>
  </div>
)

export default RepairHistoryShowItems
