import { MASTER } from '../definitions'
import api from 'api/'
import { showLoader, hideLoader } from 'store/actions/loader'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'

const getMasters = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: MASTER.LOADING_SHOW })
      dispatch(showLoader())

      Promise.all([
        api.getMasterContents(),
        api.getMasterCouponTypes(),
        api.getProvinces(),
        api.getDistricts(),
        api.getBPProvinces(),
        api.getBPDistricts()
      ])
        .then((val) => {
          dispatch({ type: MASTER.LOADING_HIDE })
          dispatch(hideLoader())
          dispatch({
            type: MASTER.GET,
            masters: {
              contents: val[0].data,
              coupon_types: val[1].data,
              provinces: val[2].data,
              districts: val[3].data,
              provincesBP: val[4].data,
              districtsBP: val[5].data
            }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch({ type: MASTER.LOADING_HIDE })
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

export { getMasters }
