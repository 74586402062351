import { VEHICLE, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE.GET_LENGTH:
      return Object.assign({}, state, {
        ...state,
        length: action.length,
        id: action.hashId
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
