import React, { useEffect } from 'react'

// Router
import { withRouter, useHistory } from 'react-router-dom'

// Redux
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import DetailVehicle from 'domain/Vehicle/components/DetailVehicle'

// Actions
import { getVehicleDetail } from 'store/actions/vehicles'
import { REPAIR_HISTORY } from 'store/definitions'

// Styles
import './_index.scss'

import { postPageView } from 'store/actions/profile'

import { setPreviousLocation } from 'utils/location'
import { isMobileApp } from 'utils/validation'
import { checkError } from 'utils/helper'

import isEmpty from 'lodash/isEmpty'
import { USER_TYPE } from 'config/constant'
import { EndOfSupportBanner } from 'components/elements/EndOfSupportBanner/EndOfSupportBanner'

const VehicleDetail = ({ match, getVehicleDetail, postPageView, clearVehicleDetail }) => {
  const vehicleDetail = useSelector((state) => state.vehicleDetail)
  const profile = useSelector((state) => state.profile)
  const controllersList = useSelector((state) => state.controllersList.data)
  const length = useSelector((state) => state.vehicleLength.length)

  const history = useHistory()
  const fromPage = history.location.state && history.location.state.fromPage

  useEffect(() => {
    if (history.location.state && fromPage) {
      setPreviousLocation(fromPage)
    }

    getVehicleDetail(match.params.id, history).catch((err) => {
      checkError(err)
    })
    postPageView()
    clearVehicleDetail()
  }, [])

  return (
    <div className='vehicle-detail'>
      <DetailHeader
        showBack={(isMobileApp() && length > 1) || isEmpty(fromPage) || !isMobileApp()}
        type='vehicle'
        detail={vehicleDetail}
        userType={profile.type}
        defaultPath={fromPage === 'Vehicle List' ? '/vehicles' : '/'}
        isUseDefault={!fromPage}
        isDemoUser={profile.isDemoUser}
      />

      {profile.type === USER_TYPE.INDIVIDUAL && length === 1 && <EndOfSupportBanner />}

      <DetailVehicle
        detail={vehicleDetail}
        userType={profile.type}
        controllers={controllersList}
        fromPage='Vehicle Detail'
        isDemoUser={profile.isDemoUser}
      />
    </div>
  )
}

VehicleDetail.propTypes = {
  getVehicleDetail: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.object,
    id: PropTypes.string
  })
}

const mapDispatchToProps /* istanbul ignore next */ = (dispatch) =>
  bindActionCreators(
    {
      getVehicleDetail,
      postPageView,
      clearVehicleDetail: () => dispatch({ type: REPAIR_HISTORY.CLEAR })
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(withRouter(VehicleDetail))
