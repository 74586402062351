import React from 'react'
import { FormattedMessage } from 'react-intl'

export const required = (value) => {
  return isEmpty(value) ? 'Field Required' : undefined
}

export const passwordsMustMatch = (value, allValues) => {
  return allValues.password !== value ? (
    <FormattedMessage id='common.validation.passwordMatch' defaultMessage='common.validation.passwordMatch' />
  ) : undefined
}

export const newPasswordsCantMatch = (value, allValues) => {
  return allValues.old_password === value ? (
    <FormattedMessage id='common.validation.passwordNew' defaultMessage='common.validation.passwordNew' />
  ) : undefined
}

export const newPasswordsMustMatch = (value, allValues) => {
  return allValues.new_password !== value ? (
    <FormattedMessage id='common.validation.passwordMatch' defaultMessage='common.validation.passwordMatch' />
  ) : undefined
}

export const email = (value) =>
  value && !/^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value) ? (
    <FormattedMessage id='common.validation.invalidEmail' defaultMessage='common.validation.invalidEmail' />
  ) : undefined

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number (0-9)' : undefined)

export const alphaNumeric = (field) => (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.invalid' defaultMessage='common.validation.invalid' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Invalid ${field}`
  undefined

export const letters = (value) => (value && /[^a-zA-Z ]/i.test(value) ? 'Letters only (a-z, A-Z)' : undefined)

const isEmpty = (value) => {
  return typeof value === 'undefined' || value === undefined || value === '' ? true : false
}

export const isNumber = (max, field) => (value) => {
  return value && isNaN(Number(value)) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.isNumber'
          defaultMessage='common.validation.isNumber'
          values={{ value: max, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
  // return value && isNaN(Number(value)) ? `Please use ${max} digits for your ${field}` : undefined
}

export const isPhoneNumber9 = (max, field) => (value) => {
  return value && !/^0\d{8}$/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.isPhoneNumber'
          defaultMessage='common.validation.isPhoneNumber'
          values={{ value: max, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
}

export const isPhoneNumber10 = (max, field) => (value) => {
  return value && !/^0\d{9}$/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.isPhoneNumber'
          defaultMessage='common.validation.isPhoneNumber'
          values={{ value: max, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
}

export const isContPhoneNo10 = () => (value) =>
  value && !/^0\d{9}$/i.test(value) ? (
    <FormattedMessage id='common.validation.invalidMobileNo' defaultMessage='common.validation.invalidMobileNo' />
  ) : undefined

export const isAlphanumeric = () => (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? (
    <FormattedMessage id='common.validation.alphaNumeric' defaultMessage='common.validation.alphaNumeric' />
  ) : undefined

export const isAlphanumeric2 = (field) => (value) =>
  value && /[^a-zA-Z0-9]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNumericSpc'
          defaultMessage='common.validation.alphaNumericSpc'
          values={{ field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must contain at least 6 characters among the following: Uppercase or Lowercase English characters (a-z, A-Z) or Digits (0-9) and Special characters are not allowed (eg. ! # & % $)`
  undefined

export const isAlphanumeric3 = (field) => (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNumericExt'
          defaultMessage='common.validation.alphaNumericExt'
          values={{ field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must contain English characters (a-z, A-Z) and Digits (0-9) only`
  undefined

export const isAlphanumeric4 = (field) => (value) => {
  return value && /[^a-zA-Z0-9 ]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.invalid' defaultMessage='common.validation.invalid' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : undefined
}

export const isAlphaNonNumeric = (field) => (value) =>
  value && /[^a-zA-Z\u0041-\u007A\u0E01-\u0E5B ]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNonNumeric'
          defaultMessage='common.validation.alphaNonNumeric'
          values={{ field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must be Uppercase or Lowercase English characters (a-z, A-Z) or Thai character (ก-ฮ)`
  undefined

export const isAlphaNonNumWithSpace = (field) => (value) =>
  value && /[^a-zA-Z\u0041-\u007A\u0E01-\u0E5B ]/i.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNonNumeric'
          defaultMessage='common.validation.alphaNonNumeric'
          values={{ field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must be Uppercase or Lowercase English characters (a-z, A-Z) or Thai character (ก-ฮ) or space character`
  undefined

export const signIn = (type, field) => (value) => {
  return isEmpty(value) && type === 'identifier' ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.required' defaultMessage='common.validation.required' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  isEmpty(value) && type === 'password' ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.required' defaultMessage='common.validation.required' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  undefined
}

export const needed = (type, field) => (value) => {
  return isEmpty(value) && type === 'identifier' ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.required' defaultMessage='common.validation.required' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  isEmpty(value) && type === 'password' ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.required' defaultMessage='common.validation.required' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  undefined
}

export const passwordReq = (field) => (value) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.passwordReq' defaultMessage='common.validation.passwordReq' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  undefined
}
export const newPasswordPolicy = /* istanbul ignore next [03/22] */ (field) => (value) => {
  return !/^[a-zA-Z0-9]{8,16}$/g.test(value) ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.newPassword' defaultMessage='common.validation.newPassword' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : // `Please input ${field}`
  undefined
}

export const maxLength = (max, field) => (value) => {
  // value && value.length > max ? `Please use ${max} digits for your ${field}` : undefined
  return value && value.length > max ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.isNumber'
          defaultMessage='common.validation.isNumber'
          values={{ value: max, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
}

export const maxVin = (max, field) => (value) => {
  // value && value.length > max ? `Please use ${max} digits for your ${field}` : undefined
  return value && value.length > max ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.isVin' defaultMessage='common.validation.isVin' values={{ value: max, field: _field }} />
      )}
    </FormattedMessage>
  ) : undefined
}

export const maxLength2 = (max, field) => (value) => {
  return value && value.length > max ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.maxLength'
          defaultMessage='common.validation.maxLength'
          values={{ value: max, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
}

export const minLength = (min, field) => (value) => {
  return value && value.length < min ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage id='common.validation.invalid' defaultMessage='common.validation.invalid' values={{ field: _field }} />
      )}
    </FormattedMessage>
  ) : undefined
}

export const minLength2 = (min, field) => (value) => {
  return value && value.length < min ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.isNumber'
          defaultMessage='common.validation.isNumber'
          values={{ value: min, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : undefined
  // return value && value.length < min ? `Please use ${min} digits for your ${field}` : undefined
}

// username
export const minLength3 = (min, field) => (value) =>
  value && value.length < min ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNumUsername'
          defaultMessage='common.validation.alphaNumUsername'
          values={{ value: min, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must contain at least ${min} characters among the following: Uppercase or Lowercase English characters (a-z, A-Z) or Digits (0-9) and Special characters are not allowed (eg. ! # & % $)`
  undefined

export const minLength4 = (min, field) => (value) =>
  value && value.length < min ? (
    <FormattedMessage id={field} defaultMessage={field}>
      {(_field) => (
        <FormattedMessage
          id='common.validation.alphaNumPassword'
          defaultMessage='common.validation.alphaNumPassword'
          values={{ value: min, field: _field }}
        />
      )}
    </FormattedMessage>
  ) : // `${field} must contain at least ${min} characters among the following: Uppercase or Lowercase English characters (a-z, A-Z) or Digits (0-9) or Special characters (eg. ! # & % $)`
  undefined

export const isMobileApp = () =>
  /(MYISUZU_APP\/[0-9]*.[0-9]*.[0-9]* my-ISUZU(_.+)?)+(\/com.(tpit|tis).myisuzu(.+)?)/i.test(navigator.userAgent) ? true : false

export const isLINEBrowser = (navigator) => navigator.userAgent.indexOf('Line/') > -1

// EDIT PROFILE
export const profileEdit_name = needed('identifier', 'common.labels.givenName')
export const profileEdit_nameAlpha = isAlphaNonNumeric('common.labels.givenName')

export const profileEdit_surname = needed('identifier', 'common.labels.surname')
export const profileEdit_surnameAlpha = isAlphaNonNumeric('common.labels.surname')

export const profileEdit_home_min9 = minLength2(9, 'common.labels.homePhone')
export const profileEdit_home_max9 = maxLength(9, 'common.labels.homePhone')
export const profileEdit_home_number = isPhoneNumber9(9, 'common.labels.homePhone')

export const profileEdit_office_min9 = minLength2(9, 'common.labels.officePhone')
export const profileEdit_office_max9 = maxLength(9, 'common.labels.officePhone')
export const profileEdit_office_number = isPhoneNumber9(9, 'common.labels.officePhone')

export const profileEdit_office_ext_max5 = maxLength(5, 'common.labels.officePhoneExt')
export const profileEdit_office_ext_number = isNumber(5, 'common.labels.officePhoneExt')

export const profileEdit_mobile = needed('identifier', 'common.labels.mobilePhone')
export const profileEdit_mobile_min10 = minLength2(10, 'common.labels.mobilePhone')
export const profileEdit_mobile_max10 = maxLength(10, 'common.labels.mobilePhone')
export const profileEdit_mobile_number = isPhoneNumber10(10, 'common.labels.mobilePhone')

export const profileEdit_email = needed('identifier', 'common.labels.email')
export const profileEdit_email_max100 = maxLength2(100, 'common.labels.email')

export const profileEdit_username = needed('identifier', 'common.labels.username')
export const profileEdit_username_min6 = minLength3(6, 'common.labels.username')
export const profileEdit_username_alphanumeric = isAlphanumeric2('common.labels.username')

// CHANGE PASSWORD
export const changepw = needed('password', 'common.labels.password')

export const change_newpw = needed('password', 'common.labels.newPassword')
export const change_newpwmin8 = newPasswordPolicy('common.labels.newPassword')
export const change_pw2 = needed('password', 'common.labels.confirmPassword')
export const change_pw2_repeat = newPasswordPolicy('myProfile.changePassword.newPassword')

// FORGOT PASSWORD
export const forgotpw_indiv = needed('identifier', 'signIssue.password.identifierIndividual')
export const forgotpw_indiv_alphanumeric = isAlphanumeric4('signIssue.password.identifierIndividual')

export const forgotpw_otp = needed('identifier', 'signIssue.password.otp')
export const forgotpw_otpmin6 = minLength(6, 'signIssue.password.otp')
export const forgotpw_otpmax6 = maxLength2(6, 'signIssue.password.otp')
export const forgotpw_otp_numeric = isNumber(6, 'signIssue.password.otp')

export const forgotpw_pw = needed('password', 'common.labels.newPassword')
export const forgotpw_pwmin8 = newPasswordPolicy('common.labels.newPassword')

export const forgotpw_pw2 = needed('password', 'common.labels.confirmPassword')

export const forgotpw_corp = needed('identifier', 'signIssue.password.identifierCorporate')
export const forgotpw_cont = needed('identifier', 'signIssue.password.identifierController')

// FORGOT ACCOUNT
export const forgotacc_indiv = needed('identifier', 'signIssue.account.identifier')
export const forgotacc_indiv_alphanumer = isAlphanumeric4('signIssue.account.identifier')

export const forgotacc_regId = needed('identifier', 'createAccount.labels.companyReg')
export const forgotacc_regId_alphanumeric = isAlphanumeric4('createAccount.labels.companyReg')

export const forgotacc_branch = needed('identifier', 'createAccount.labels.branchTax')
export const forgotacc_branch_alphanumeric = isAlphanumeric4('createAccount.labels.branchTax')

// EDIT VEHICLE
export const editVehicle_name = needed('identifier', 'vehicle.edit.vehicleName')
export const editVehicle_driverGivenName = isAlphaNonNumeric('vehicle.edit.driverGivenName')
export const editVehicle_driverGivenNameMax32 = maxLength2(32, 'vehicle.edit.driverGivenName')
export const editVehicle_driverSurname = isAlphaNonNumeric('vehicle.edit.driverSurname')
export const editVehicle_driverSurnameMax32 = maxLength2(32, 'vehicle.edit.driverSurname')
export const editVehicle_phone = isPhoneNumber10(10, 'common.labels.mobilePhone')

// FIND VIN
export const findVehicle_vin = needed('identifier', 'common.labels.vin')
export const findVehicle_min16 = minLength(16, 'common.labels.vin')
export const findVehicle_max17 = maxVin(17, 'common.labels.vin')
export const findVehicle_alphanumeric = isAlphanumeric3('common.labels.vin')

// EDIT CONTROLLER
export const contEdit_nationalId = needed('identifier', 'createAccount.labels.nationalid')
export const contEdit_nationalmin13 = minLength2(13, 'createAccount.labels.nationalid')
export const contEdit_nationalmax13 = maxLength(13, 'createAccount.labels.nationalid')
export const contEdit_nationalnumber = isNumber(13, 'createAccount.labels.nationalid')

export const contEdit_passport = needed('identifier', 'createAccount.labels.passportNo')
export const contEdit_passportmin5 = minLength(5, 'createAccount.labels.passportNo')
export const contEdit_passportAlphaNumeric = isAlphanumeric()

export const contEdit_name = needed('identifier', 'common.labels.givenName')
export const contEdit_nameAlpha = isAlphaNonNumWithSpace('common.labels.givenName')

export const contEdit_surname = needed('identifier', 'common.labels.surname')
export const contEdit_surnameAlpha = isAlphaNonNumWithSpace('common.labels.surname')

export const contEdit_mobile = needed('identifier', 'common.labels.mobilePhone')
export const contEdit_mobile_min10 = minLength2(10, 'common.labels.mobilePhone')
export const contEdit_mobile_max10 = maxLength(10, 'common.labels.mobilePhone')
export const contEdit_mobile_number = isPhoneNumber10(10, 'common.labels.mobilePhone')

export const contEdit_office_min9 = minLength2(9, 'common.labels.officePhone')
export const contEdit_office_max9 = maxLength(9, 'common.labels.officePhone')
export const contEdit_office_number = isPhoneNumber9(9, 'common.labels.officePhone')

export const contEdit_email = needed('identifier', 'common.labels.emailAddress')

export const contEdit_username = needed('identifier', 'common.labels.username')
export const contEdit_username_min6 = minLength3(6, 'common.labels.username')
export const contEdit_username_alphanumeric = isAlphanumeric2('common.labels.username')

// INVITE CONTROLLER
export const contInvite_email = needed('identifier', 'common.labels.emailAddress')

export const contInvite_username = needed('identifier', 'common.labels.username')
export const contInvite_username_min6 = minLength3(6, 'common.labels.username')
export const contInvite_username_alphanumeric = isAlphanumeric2('common.labels.username')
export const contInvite_mobile_phone = isContPhoneNo10(10, 'common.validation.invalidMobileNo')
export const contInvite_mobile_need = needed('identifier', 'common.labels.mobilePhone')

// CREATE ACCOUNT
export const createAcc_contact_person = needed('identifier', 'createAccount.labels.contactPerson')
export const createAcc_contact_person_alphanumeric = isAlphaNonNumWithSpace('createAccount.labels.contactPerson')

export const createAcc_mobile = needed('identifier', 'common.labels.mobilePhone')
export const createAcc_mobile_min10 = minLength2(10, 'common.labels.mobilePhone')
export const createAcc_mobile_max10 = maxLength(10, 'common.labels.mobilePhone')
export const createAcc_mobile_number = isPhoneNumber10(10, 'common.labels.mobilePhone')

export const createAcc_home_min9 = minLength2(9, 'common.labels.homePhone')
export const createAcc_home_max9 = maxLength(9, 'common.labels.homePhone')
export const createAcc_home_number = isPhoneNumber9(9, 'common.labels.homePhone')

export const createAcc_office_min9 = minLength2(9, 'common.labels.officePhone')
export const createAcc_office_max9 = maxLength(9, 'common.labels.officePhone')
export const createAcc_office_number = isPhoneNumber9(9, 'common.labels.officePhone')

export const createAcc_office_ext_max5 = maxLength(5, 'common.labels.officePhoneExt')
export const createAcc_office_ext_number = isNumber(5, 'common.labels.officePhoneExt')

export const createAcc_username = needed('identifier', 'common.labels.username')
export const createAcc_username_min6 = minLength3(6, 'common.labels.username')
export const createAcc_username_alphanumeric = isAlphanumeric2('common.labels.username')

export const createAcc_password = needed('password', 'common.labels.password')
export const createAcc_pwmin8 = newPasswordPolicy('common.labels.password')

export const createAcc_natId = needed('identifier', 'createAccount.labels.nationalid')
export const createAcc_natId_numbers = isNumber(13, 'createAccount.labels.nationalid')
export const createAcc_natId_min13 = minLength2(13, 'createAccount.labels.nationalid')
export const createAcc_natId_max13 = maxLength(13, 'createAccount.labels.nationalid')

export const createAcc_passport = signIn('identifier', 'createAccount.labels.passportNo')
export const createAcc_passport_min5 = minLength(5, 'createAccount.labels.passportNo')
export const createAcc_passport_alphanumeric = isAlphanumeric('Passport')

export const createAcc_name = needed('identifier', 'common.labels.givenName')
export const createAcc_nameAlpha = isAlphaNonNumWithSpace('common.labels.givenName')

export const createAcc_surname = needed('identifier', 'common.labels.surname')
export const createAcc_surnameAlpha = isAlphaNonNumWithSpace('common.labels.surname')

export const createAcc_email_max100 = maxLength2(100, 'common.labels.email')

// SIGN UP
export const signUp_natId = signIn('identifier', 'createAccount.labels.nationalid')
export const signUp_min13 = minLength2(13, 'createAccount.labels.nationalid')
export const signUp_max13 = maxLength(13, 'createAccount.labels.nationalid')
export const signUp_numerical = isNumber(13, 'createAccount.labels.nationalid')

export const signUp_passport = signIn('identifier', 'createAccount.labels.passportNo')
export const signUp_min5 = minLength(5, 'createAccount.labels.passportNo')
export const signUp_alphanumeric = isAlphanumeric()

export const signUp_companyId = signIn('identifier', 'createAccount.labels.companyReg')
export const signUp_corp_min13 = minLength2(13, 'createAccount.labels.companyReg')
export const signUp_corp_max13 = maxLength(13, 'createAccount.labels.companyReg')
export const signUp_corp_numerical = isNumber(13, 'createAccount.labels.companyReg')
export const signUp_branchtax = signIn('identifier', 'createAccount.labels.branchTax')
export const signUp_corp_max5 = maxLength(5, 'createAccount.labels.branchTax')
export const signUp_corp_branch_numerical = isNumber(5, 'createAccount.labels.branchTax')

// SIGN IN SCREEN
export const signIn_individual = signIn('identifier', 'login.labels.identifierIndividual')
export const signIn_indiv_an = alphaNumeric('login.labels.identifierIndividual')
export const signIn_corp = signIn('identifier', 'login.labels.identifierCorporate')
export const signIn_controller = signIn('identifier', 'login.labels.identifierController')
export const signIn_password = needed('password', 'common.labels.password')
export const signIn_pwmin8 = newPasswordPolicy('common.labels.password')
