import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { setRoleRadioLabel } from '../Label/format'

const RoleSelection = ({ role, roleActive, setRole }) => {
  return (
    <div className='controller-form__form__selection'>
      <RadioGroup aria-label='selected_id' value={role}>
        {getRadio(setRole, roleActive, 'controller')}
        {getRadio(setRole, roleActive, 'driver')}
      </RadioGroup>
    </div>
  )
}

const getRadio = (setRadio, setClassName, value) => {
  let label = setRoleRadioLabel(value)

  return (
    <FormControlLabel
      value={value}
      className={setClassName(value)}
      control={<Radio />}
      onClick={() => setRadio(value)}
      label={<FormattedMessage id={label} defaultMessage={label} />}
    />
  )
}

export default RoleSelection
