import React, { useRef } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect, useDispatch } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Proptypes
import Parser from 'html-react-parser'

// Components
import Slider from 'components/elements/Slider'
import FeaturedItem from 'components/elements/FeaturedItem'
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'

// Methods
import * as format from 'utils/format'
import { FormattedMessage } from 'react-intl'
import { getPromotionTarget } from 'store/actions/promotions'
import { checkError } from 'utils/helper'

// Styles
import './_index.scss'

const DetailPromotion = ({ match, history, detail }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()

  const getTargetVehicles = () => {
    dispatch(getPromotionTarget(match.params.id))
      .then(() => {
        modalRef.current.openModal()
      })
      .catch((err) => {
        checkError(err)
      })
  }

  const classes = classnames('detail-promotion')

  const detailMessage = detail.content ? detail.content.replace(/(\r\n|\n|\r|↵)/gm, '\n') : ''

  const targetList = (
    <div className='detail-promotion__vehicles'>
      {detail.target_vehicles &&
        detail.target_vehicles.map((d, index) => (
          <div key={`detail-promotion__vehicles__item-${index}`} className='detail-promotion__vehicles__item'>
            <div className='detail-promotion__vehicles__item__img'>
              <img src={d.user_vehicle.image_url || d.vehicle.model.marketing_image_url} alt='' />
            </div>
            <div className='detail-promotion__vehicles__item__info'>
              <span className='detail-promotion__vehicles__item__info__name'>{d.user_vehicle.name || d.vehicle.model.marketing_name}</span>
              <span className='detail-promotion__vehicles__item__info__license'>{d.vehicle.license_plate}</span>
            </div>
          </div>
        ))}
    </div>
  )

  return (
    <div className={classes}>
      <Modal mode={11} title={'promotion.target'} content={targetList} ref={modalRef} />

      <div className='detail-promotion-wrapper'>
        <div className='detail-promotion__meta'>
          <span className='detail-promotion__meta__date'>{format.showDate(detail.start_date, true)}</span>
        </div>

        <div className='detail-promotion__title'>
          <h1>{detail.name}</h1>
        </div>

        <div className='detail-promotion__image'>
          <Slider component={FeaturedItem} data={detail.media} title='promotion.media' noMargin forceNumber={1} />
        </div>

        <div className='detail-promotion__content'>{detail.content ? Parser(`${detailMessage}`) : ''}</div>

        {detail.target_vehicles_count > 0 ? (
          <div className='detail-promotion__target'>
            {detail.target_vehicles_count <= 3 && (
              <div className='detail-promotion__target__vehicles'>
                <div className='detail-promotion__target__vehicles__title'>
                  <FormattedMessage id={'promotion.target'} defaultMessage={'promotion.target'} />
                </div>

                {detail.target_vehicles &&
                  detail.target_vehicles.map((d, index) => (
                    <div key={index} className='detail-promotion__target__vehicles__item'>
                      <div className='detail-promotion__target__vehicles__item__img'>
                        <img src={d.user_vehicle.image_url || d.vehicle.model.marketing_image_url} alt='' />
                      </div>
                      <div className='detail-promotion__target__vehicles__item__info'>
                        <span className='detail-promotion__target__vehicles__item__info__name'>
                          {d.user_vehicle.name || d.vehicle.model.marketing_name}
                        </span>
                        <span className='detail-promotion__target__vehicles__item__info__license'>{d.vehicle.license_plate}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {detail.target_vehicles_count > 3 && (
              <Button
                className='detail-promotion__target__button'
                color='dark'
                label='promotion.target'
                onClick={() => getTargetVehicles()}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps =
  /* istanbul ignore next */
  (state) => ({
    targetVehicles: state.promotionDetail.target_vehicles
  })

DetailPromotion.defaultProps = {
  detail: {
    content: []
  }
}

export default connect(mapStateToProps, null)(withRouter(DetailPromotion))
