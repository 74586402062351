import React from 'react'
import { FormattedMessage } from 'react-intl'
// Styles
import './_index.scss'

import PrivacyPolicyTH from '../../../domain/PrivacyPolicy/components/PrivacyPolicy/th'

const PDPA = () => {
  return (
    <div className='PDPA'>
      <div className='PDPA__wrapper'>
        <div className='PDPA__header'>
          <FormattedMessage id='common.labels.privacypolicy' defaultMessage='common.labels.privacypolicy' />
        </div>
        <br />
        <PrivacyPolicyTH />
        <br />
        <div className='PDPA__header'>
          <FormattedMessage id='common.labels.consent' defaultMessage='common.labels.consent' />
        </div>
        <br />
        {/* <Consent lang='th' /> */}
        <div className='PDPA__body'>
          <p className='tab'>
            ข้าพเจ้าให้ความยินยอมแก่ บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด (ต่อไปนี้เรียกว่า “<strong>บริษัทฯ</strong>”) ในการเก็บรวบรวม ใช้
            และ/หรือเปิดเผย ข้อมูลส่วนบุคคล ตามรายละเอียดดังต่อไปนี้
          </p>
        </div>
        <br />
        <p className='PDPA__sub__header'>1. ข้อมูลส่วนบุคคลที่เก็บรวบรวม</p>
        <br />
        <div className='PDPA__body'>
          <p className='tab'>
            ข้อมูลส่วนบุคคล ได้แก่ ชื่อ นามสกุล วันเดือนปีเกิด ที่อยู่ หมายเลขโทรศัพท์ ไลน์ไอดี เฟซบุ๊คไอดี ระดับการศึกษา อาชีพ
            และข้อมูลส่วนบุคคลอื่นๆ ของข้าพเจ้าเท่าที่จำเป็น รวมถึงข้อมูลส่วนบุคคลตามที่ระบุไว้ข้างต้น ที่บริษัทฯจะเก็บรวบรวมในอนาคต เป็นต้น
            (ต่อไปนี้เรียกว่า &quot;<strong>ข้อมูลส่วนบุคคลที่เก็บรวบรวม</strong>&quot;)
          </p>
        </div>
        <br />
        <p className='PDPA__sub__header'>2. วัตถุประสงค์ของการเก็บรวบรวม</p>
        <br />
        <div className='PDPA__body'>
          <p className='tab' style={{ 'text-indent': 30 }}>
            ข้าพเจ้าให้ความยินยอมแก่บริษัทฯ การใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่เก็บรวบรวม เพื่อประโยชน์ใน การให้การรับประกันรถยนต์
            การบริการหลังการขาย เช่น การติดต่อเพื่อนัด หมายการตรวจสภาพหรือซ่อมบำรุงรถยนต์
            การติดต่อสื่อสารเพื่อสำรวจการใช้งานผลิตภัณฑ์และบริการของบริษัทฯและ/หรือสำรวจความพอใจในการซื้อผลิตภัณฑ์และการให้บริการหลังการขาย
            การแจ้งเตือนกิจกรรมส่งเสริมการขายหรือให้ส่วนลดพิเศษ การศึกษาวิจัยทางการตลาดและการนำเสนอหรือสำรวจ วิจัย
            โดยเกี่ยวกับผลิตภัณฑ์และบริการอื่นที่เกี่ยวข้องกับรถยนต์และไม่เกี่ยวข้องกับรถยนต์
            บริการตลาดกลางออนไลน์เพื่อซื้อขายรถยนต์หรือสินค้ามือสองของบริษัทฯ และบริษัทในกลุ่มตรีเพชร
          </p>
          <p className='tab' style={{ 'text-indent': 30 }}>
            ข้าพเจ้าให้ความยินยอมแก่บริษัทฯ
            ในการนำเสนอผลิตภัณฑ์และบริการอื่นที่เกี่ยวข้องกับรถยนต์และไม่เกี่ยวข้องกับรถยนต์ของบริษัทในกลุ่มตรีเพชร ได้แก่
            บริการให้สินเชื่อเช่าซื้อรถยนต์ โดย บริษัท ตรีเพชรอีซูซุลิสซิ่ง จำกัด บริการด้านประกันภัย โดย บริษัท ตรีเพชรอินชัวรันส์เซอร์วิส
            จำกัด{' '}
          </p>
          <p className='tab' style={{ 'text-indent': 30 }}>
            ข้าพเจ้ารับทราบว่า บริษัทฯ จะดำเนินการเก็บรวบรวม ใช้ และ/หรือเปิดเผย ข้อมูลส่วนบุคคลที่เก็บรวบรวม โดยปฏิบัติ
            ตามนโยบายคุ้มครองความเป็นส่วนตัวซึ่งข้าพเจ้าสามารถเข้าถึงได้ผ่านช่องทางได้ผ่านช่องทางต่างๆ เช่น เว็บไซต์ของบริษัทฯ
          </p>
          <p className='tab' style={{ 'text-indent': 30 }}>
            ข้าพเจ้าได้อ่านรับทราบและเข้าใจข้อความเกี่ยวกับ การเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล
            ที่เก็บรวบรวมซึ่งเกี่ยวกับข้าพเจ้าในหนังสือฉบับนี้โดยตลอดแล้วเห็นว่าถูกต้องตรงตามเจตนาและความประสงค์ของ ข้าพเจ้าทุกประการ
            รวมทั้ง ข้าพเจ้ายินยอมให้ บริษัทฯ ดำเนินการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล ตามรายละเอียดที่ระบุข้างต้น
          </p>
        </div>
      </div>
    </div>
  )
}

export default PDPA
