import moment from 'moment'
import 'moment/locale/th'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { checkLanguage } from 'utils/languages'

/**
 *
 */
export const showIspDate = (date, isLong) => {
  const lang = checkLanguage()
  if (!date) return '-'
  /*istanbul ignore else */
  if (lang === 'en') {
    return moment(date)
      .locale(lang)
      .format(isLong ? 'DD MMM YYYY' : 'DD MMM YY')
  } else if (lang === 'th') {
    return moment(date)
      .locale(lang)
      .add(543, 'years')
      .format(isLong ? 'DD MMM YYYY' : 'DD MMM YY')
  }
}

export const showNumber = (num) => {
  const reg = /^\d+$/
  if (!reg.test(num)) return '-'
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 *
 */
export const showDate = (date, isLong) => {
  const lang = checkLanguage()
  if (!date) return '-'
  /*istanbul ignore else */
  if (lang === 'en') {
    return moment(date)
      .locale(lang)
      .format(isLong ? 'LL' : 'DD MMM YY')
  } else if (lang === 'th') {
    return moment(date)
      .locale(lang)
      .add(543, 'years')
      .format(isLong ? 'LL' : 'DD MMM YY')
  }
}

/**
 *
 * @param {*} date
 * @param {*} isLong
 */
export const showDateTime = (date, isLong) => {
  const lang = checkLanguage()
  moment.locale(lang)

  if (!date) return '-'
  /*istanbul ignore else */
  if (lang === 'en') {
    return moment(date).format(isLong ? 'LLL' : 'DD MMM YY HH:mm')
  } else if (lang === 'th') {
    return moment(date)
      .add(543, 'years')
      .format(isLong ? 'LLL' : 'DD MMM YY HH:mm')
  }
}

/**
 *
 */
export const showAge = (start) => {
  if (!start) return '-'

  const years = moment().diff(start, 'year')
  const months = moment().diff(start, 'months')
  const realMonths = months - Math.floor(years) * 12

  return months > 0 ? `${years ? `${years} ปี` : ``} ${realMonths ? `${realMonths} เดือน` : ``}` : 'น้อยกว่า 1 เดือน';
}

/**
 *
 * @param {*} num
 */
export const formatNumber = (num) => {
  const lang = checkLanguage()
  if (typeof num === 'number') {
    return num.toLocaleString(lang)
  } else {
    return num
  }
}

export const formatNumberWithDecimal = (num, dec) => {
  const lang = checkLanguage()
  if (typeof num === 'number') {
    const options = {
      minimumFractionDigits: dec,
      maximumFractionDigits: dec
    }
    let n = parseFloat(num).toFixed(dec)
    return Number(n).toLocaleString(lang, options)
  } else {
    return num
  }
}

export const formatTime = (timeString) => {
  if (timeString) {
    const chunked = timeString.split(':')
    return `${chunked[0]}:${chunked[1]}`
  }
}

export const userType = (type) => {
  if (type === 1) {
    return <FormattedMessage id='common.labels.individual' defaultMessage='common.labels.individual' />
  } else if (type === 2) {
    return <FormattedMessage id='common.labels.corporate' defaultMessage='common.labels.corporate' />
  } else {
    return <FormattedMessage id='common.labels.controller' defaultMessage='common.labels.controller' />
  }
}

/**
 *
 * @param {*} length
 */
export const randomString = function (length) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

/**
 * Join List - format list with joined string
 *
 * @param {array} [list=[]] - list
 * @param {string} [key=""] - join key
 * @param {string} [joinWith=','] - join separator
 * @returns {string} joined list
 */
export const joinList = (list = [], key = '', joinWith = ',') => list.map((iterator) => iterator[key]).join(`${joinWith} `)

/**
 *
 * @param {string} givenName
 * @param {string} surname
 * @return {string} Full Name
 */
export const fullName = (givenName, surname) => (!givenName && !surname ? '-' : [givenName || '-', surname || '-'].join(' '))

export const getNumberDigits = /*istanbul ignore next 2019-11-22*/ (number) => {
  if (number) {
    return number.toString().length <= 5 ? number.toString().length : '4'
  } else {
    return '3'
  }
}

export const getDisplayNumber = /*istanbul ignore next 2019-11-22*/ (number) => {
  if (number < 99999) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + 'k'
  }
}

/**
 * Mask fill in string
 * eg. str = 1234124350011
 * maskFill(str, 4, 9) = 1234XXXXX0011
 *
 * @param {string} [str=''] - target string
 * @param {number} [fromIndex=0] - mask index start
 * @param {number} [toIndex=INFINITY] - mask index end
 * @param {string} [maskWith='X'] - mask with any key
 * @returns {string} masked string
 */
export const maskFill = (str = '', fromIndex = 0, toIndex = Infinity, maskWith = 'X') =>
  str.substring(0, fromIndex) + str.substring(fromIndex, toIndex).split('').fill(maskWith).join('') + str.substring(toIndex, str.length)

/**
 * format mask ownerId
 *
 * @param {string} - ownerId
 * @returns {string} masked ownerId
 */
export const maskOwnerId = (ownerId = '') => {
  // Passport
  if (ownerId.length === 9) {
    return maskFill(ownerId, 4, 8)
  }

  // National ID and Company ID
  if (ownerId.length === 13) {
    return maskFill(ownerId, 5, 10)
  }

  return ownerId
}

export const ispPackageFormat = (year, mileage) => {
  let result = ''
  result = year ? `${year} ปี หรือ ${formatNumber(mileage)} กม.` : ''
  return result
}

export const ispDateFormat = (date) => {
  return moment(date).locale('th').add(543, 'years').format('DD MMM YYYY')
}

export const paddingZeros = (num, size) => {
  var s = String(num)
  while (s.length < (size || 2)) {
    s = '0' + s
  }
  return s
}

export const currencyFormat = (value) => value.toLocaleString()
