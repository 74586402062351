import React from 'react'
import { FormattedMessage, intlShape } from 'react-intl'
import { useDispatch } from 'react-redux'

import './_index.scss'
import { PROFILE } from 'store/definitions'

const TokenExpire = (props, context) => {
  const dispatch = useDispatch()
  dispatch({
    type: PROFILE.PAGEVIEW
  })
  return (
    <div className='Token_Expire'>
      <h1 className='Token_Expire__title'>
        <FormattedMessage id='createAccount.tokenExpire.title' defaultMessage='createAccount.tokenExpire.title' />
      </h1>
      <pre className='Token_Expire__desc'>
        {context.intl.formatMessage({
          id: 'createAccount.tokenExpire.desc',
          defaultMessage: 'createAccount.tokenExpire.des'
        })}
      </pre>
    </div>
  )
}

TokenExpire.contextTypes = {
  intl: intlShape
}

export default TokenExpire
