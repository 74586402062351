import { DEALER } from '../definitions'

import { showLoader, hideLoader } from 'store/actions/loader'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'
import api from 'api/'

const getDealers = (p = '', d = '', bp = false) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())

      api
        .getDealers({ p, d, bp })
        .then((res) => {
          dispatch({
            type: DEALER.GET,
            data: res.data
          })

          dispatch(hideLoader())
          resolve(res.data)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const updateDealerAppointment = (payload) => {
  return (dispatch) => {
    dispatch(showLoader())
    api
      .updateDealerAppointment(payload)
      .then((res) => {
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(hideLoader())
      })
      .finally(() => {
        console.log('done')
      })
  }
}

const getDealerDetail = (c = '') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())

      api
        .getDealerDetail({ c })
        .then((res) => {
          dispatch({
            type: DEALER.GET,
            data: res.data
          })

          dispatch(hideLoader())
          resolve(res.data)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

export { getDealers, getDealerDetail, updateDealerAppointment }
