import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { thunk } from 'redux-thunk'
import reducers from './reducers'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export const store = createStore(reducers(history), initialState, composedEnhancers)

if (process.env.REACT_APP_ENV.trim() === 'DEV' || process.env.REACT_APP_ENV.trim() === 'UAT') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  // Allow inspecting Redux state easily by typing `MYI.store.getState()`
  // directly into browser console. No browser extensions needed.
  window.MYI = { store }
}
