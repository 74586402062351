import React, { useState, useRef } from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import { change } from 'redux-form'
import Modal from 'components/elements/Modal'
import OtpInput from 'react-otp-input'

// Methods
import { FormattedMessage, injectIntl } from 'react-intl'

// Styles
import './_index.scss'

const Input = (props) => {
  const [type, setType] = useState(props.type)
  const [togglePassword, setTogglePassword] = useState(false)
  const modalVin = useRef()

  const clearInput = () => {
    props.meta.dispatch(change(props.meta.form, props.input.name, ''))
  }

  const togglePasswordChange = () => {
    setType(togglePassword ? 'password' : 'text')
    setTogglePassword(!togglePassword)
  }

  const {
    className,
    placeholder,
    input,
    label,
    id,
    isRequired,
    isClearable,
    textTooltip,
    vinTooltip,
    vinContent,
    meta,
    otpProps,
    intl,
    ...otherProps
  } = props

  const classes = classnames('input', className)

  let inputDOM = null

  switch (type) {
    case 'otp':
      inputDOM = <OtpInput {...otpProps} {...input} renderSeparator={<span>&nbsp;</span>} renderInput={(props) => <input {...props} />} />
      break
    case 'textarea':
      inputDOM = (
        <textarea
          className='input__textarea'
          id={id}
          rows='5'
          type={type}
          placeholder={intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
          {...otherProps}
          defaultValue={props.defaultValue}
        />
      )
      break
    case 'file':
      inputDOM = <input id={id} type='file' className='input__file' />
      break
    case 'usernameOff':
      inputDOM = (
        <input
          className='input__input'
          autoComplete='off'
          id={id}
          placeholder={intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
          {...input}
          {...otherProps}
          type='text'
        />
      )
      break
    case 'passwordOff':
      inputDOM = (
        <input
          className='input__input'
          autoComplete='new-password'
          id={id}
          placeholder={intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
          {...input}
          {...otherProps}
          type='password'
        />
      )
      break
    default:
      inputDOM = (
        <input
          className='input__input'
          id={id}
          placeholder={intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
          {...input}
          {...otherProps}
          type={type}
        />
      )
  }
  return (
    <div className={classes}>
      <Modal mode={5} title='createAccount.info.vinTitle' content={vinContent} ref={modalVin} />

      <label className='input__label' htmlFor={id}>
        {label && <FormattedMessage id={label} defaultMessage={label} />}
        {isRequired && <span className='input__label__required'>*</span>}
      </label>

      {textTooltip && (
        <div className='input__tooltip input__tooltip--text'>
          <i className='material-icons'>info</i>
        </div>
      )}

      {vinTooltip && (
        <div className='input__tooltip input__tooltip--modal' onClick={() => modalVin.current.openModal()}>
          <i className='material-icons'>info</i>
        </div>
      )}

      {textTooltip && (
        <div className='input__tooltip-detail'>
          <FormattedMessage id={textTooltip} defaultMessage={textTooltip} />
        </div>
      )}

      <div className='input__container'>
        {inputDOM}

        <div className='input__actions'>
          {togglePassword && (
            <span className='input__actions__toggle-password' onClick={togglePasswordChange}>
              {!togglePassword && <i className='material-icons'>visibility</i>}
              {togglePassword && <i className='material-icons'>visibility_off</i>}
            </span>
          )}

          {isClearable && props.meta.dirty && (
            <span className='input__actions__clear' onClick={clearInput}>
              <i className='material-icons'>close</i>
            </span>
          )}
        </div>
      </div>

      {meta && meta.touched && meta.error && <span className='input__error'>{meta.error}</span>}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string
}

Input.defaultProps = {
  className: '',
  otpProps: {
    // inputStyle: 'input__otp_cont__input',
    containerStyle: 'input__otp_cont',
    numInputs: 6
  }
}

export default injectIntl(Input)
