import Cookies from 'js-cookie'
import { store } from '../store'
import { changeLang } from '../store/actions/config'

/**
 *
 */
export const checkLanguage = () => {
  const lang = Cookies.get('lang') ? Cookies.get('lang') : 'th'
  return lang
}

/**
 *
 */
export const setLanguage = (value) => {
  Cookies.set('lang', value)
}

/**
 *
 */
export const onLanguageChanged = (lang) => {
  if (lang && lang.value) {
    store.dispatch(changeLang(lang.value))
    Cookies.set('lang', lang.value)
  }
}
