import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import isEmpty from 'lodash/isEmpty'

// Actions
import { getRepairHistory } from 'store/actions/repairHistory'

import RepairHistoryShowItems from './RepairHistoryShowItems'
import RepairHistoryNoItem from './RepairHistoryNoItem'

// Styles
import './index.scss'

// Utils
import { checkError } from 'utils/helper'

const RepairHistoryList = () => {
  const history = useHistory()
  const params = useParams()
  const itemPerPage = 12
  const [hasMore, setHasMore] = useState(true)
  const repairHistory = useSelector((state) => state.repairHistory)
  const dispatch = useDispatch()

  const repairList = repairHistory ? repairHistory.service_order : []

  useEffect(() => {
    if (!repairHistory.service_order || repairHistory.service_order.length === 0) {
      dispatch(getRepairHistory(params.id, itemPerPage, 0)).catch((err) => {
        checkError(err)
      })
    }
  }, [])

  useEffect(() => {
    if (repairHistory.hasMoreRecords === false) {
      setHasMore(false)
    }
  }, [repairHistory])

  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(getRepairHistory(params.id, itemPerPage, (repairHistory.offset ? repairHistory.offset : 0) + itemPerPage))
    }, 1000)
  }

  return (
    <div className='repair-history'>
      <div className='repair-history__body'>
        {!isEmpty(repairList) && RepairHistoryShowItems(repairList, hasMore, fetchMoreData, history, params)}
        {repairList && repairList.length === 0 && RepairHistoryNoItem()}
      </div>
    </div>
  )
}

export default RepairHistoryList
