import React, { useEffect } from 'react'

import DetailHeader from 'components/layouts/DetailHeader'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getProfile } from 'store/actions/profile'

import CompanyAddForm from 'domain/Company/components/CompanyAddForm'

import './_index.scss'
const CompanyAdd = (props) => {
  useEffect(() => {
    if (props.profile.type && props.profile.type !== 2) {
      props.history.push('/')
    }
  }, [])

  return (
    <div className='company-add'>
      <div className='company-add__header'>
        <DetailHeader type={'company-add'} />
      </div>

      <CompanyAddForm />
    </div>
  )
}

export const mapStateToProps = (state) => ({
  profile: state.profile
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        getProfile
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdd)
