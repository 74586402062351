import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

// Proptypes
import PropTypes from 'prop-types'

// Classnames
import classnames from 'classnames'

// Components
import Swiper from 'react-id-swiper'

// Method
import * as dim from 'utils/breakpoint'

// Styles
import './_swiper.scss'
import './_index.scss'

const Slider = (props) => {
  const [dimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = () => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  const swiperParams = {
    slidesPerView: props.forceNumber ? props.forceNumber : dim.desktop(dimension) ? 3 : dim.tablet(dimension) ? 2 : 1,
    navigation: {
      nextEl: '.swiper-button-next.swiper-button-black',
      prevEl: '.swiper-button-prev.swiper-button-black'
    },
    loop: props.isBanner && props.data.length >= 1 ? true : false,
    spaceBetween: 0,
    speed: 1500,
    autoplay: props.autoPlay
      ? {
          delay: 3500,
          disableOnInteraction: false
        }
      : false,
    rebuildOnUpdate: true
  }

  const swiperTimelineParams = {
    slidesPerView: props.forceNumber ? props.forceNumber : dim.desktop(dimension) ? 3 : dim.tablet(dimension) ? 2 : 1,
    loop: true,
    spaceBetween: 0,
    speed: 1500,
    autoplay: props.autoPlay
      ? {
          delay: 3500,
          disableOnInteraction: false
        }
      : false,
    rebuildOnUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  }

  const classes = classnames(
    props.fromPage === 'signin' ? 'slider-signin' : 'slider',
    { 'slider--no-margin': !dim.desktop(dimension) && !dim.tablet(dimension) && props.noMargin },
    'swiper-container--banner'
  )

  const sliderItems = props.data
    ? Array.isArray(props.data) &&
      props.data.map((d, index) => (
        <div key={d.id || index}>
          <props.component
            detail={d}
            userType={props.userType}
            fromPage={props.fromPage}
            isSlider
            isBannerTimeline={props.isBannerTimeline}
            isEndOfSupportBanner={props.isEndOfSupportBanner}
          />
        </div>
      ))
    : []

  const slider =
    sliderItems.length > 0 ? (
      <div className={classes}>
        {props.title && (
          <span style={{ fontSize: '2rem', fontWeight: 'bold' }} className='slider__title'>
            <FormattedMessage id={props.title} defaultMessage={props.title} />
          </span>
        )}
        <Swiper
          // {...swiperParams}
          {...(props.isBannerTimeline ? { ...swiperTimelineParams } : { ...swiperParams })}
          ref={
            /*istanbul ignore next*/ (node) => {
              if (node) return node.swiper
            }
          }
        >
          {sliderItems}
        </Swiper>
      </div>
    ) : null

  return slider
}

Slider.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  userType: PropTypes.number,
  couponType: PropTypes.string,
  isSlider: PropTypes.bool,
  isBanner: PropTypes.bool,
  autoPlay: PropTypes.bool,
  isEndOfSupportBanner: PropTypes.bool
}

Slider.defaultProps = {
  title: '',
  data: [],
  userType: 0,
  couponType: 'service',
  isSlider: false
}

export default Slider
