import { FILTER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  keyword: '',
  orderBy: 1,
  sortBy: 0,
  isList: false,
  type: 'all',
  controller: 'all'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER.VEHICLE.KEYWORD:
      return Object.assign({}, state, {
        ...state,
        keyword: action.keyword
      })

    case FILTER.VEHICLE.ORDER:
      return Object.assign({}, state, {
        ...state,
        orderBy: action.orderBy
      })

    case FILTER.VEHICLE.SORT:
      return Object.assign({}, state, {
        ...state,
        sortBy: state.sortBy ? 0 : 1
      })

    case FILTER.VEHICLE.VIEW:
      return Object.assign({}, state, {
        ...state,
        isList: !state.isList
      })

    case FILTER.VEHICLE.TYPE:
      return Object.assign({}, state, {
        ...state,
        // use action.vehicleType instead type is reserved word
        type: action.vehicleType
      })

    case FILTER.VEHICLE.CONTROLLER:
      return Object.assign({}, state, {
        ...state,
        controller: action.controller
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
