import React from 'react'
import {
  PERSONAL_DATA_TH,
  DURATION_TH,
  OBJECTIVE_DETAIL_TH,
  OBJECTIVE_INFO_TH,
  TYPE_OF_PERSONAL_TH,
  TRANSFER_PERSONAL_DATA_TH,
  DATA_SUBJECT_TH,
  COOKIE_TH,
  IMPROVEMENT_OF_PRIVACY_POLICY_TH,
  CONTACT_TH,
  HEADER_TH,
  REMARK_TH
} from './privacyPolicyInfo'

const PrivacyPolicyTH = () => (
  <div>
    <p>{HEADER_TH}</p>
    <ol className='privacy__ol-1'>
      <strong>1. นิยามข้อมูลส่วนบุคคล</strong>
      <br />
      <p>
        “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัว บุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม ทั้งนี้
        เป็นไปตามกฎหมายที่ใช้บังคับ
      </p>
      <strong>2. การเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
      <br />
      <ol>
        <p>2.1 ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม</p>
        <p>บริษัทฯ ทำการเก็บรวบรวมข้อมูลส่วนบุคคลและข้อมูลต่างๆ ของท่าน โดยมีรายละเอียดดังต่อไปนี้</p>

        <table className='privacy_table' cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr>
              <td valign='top' width='40%' align='center'>
                <strong>{PERSONAL_DATA_TH.columnHeader1}</strong>
              </td>
              <td valign='top' width='60%' align='center'>
                <strong>{PERSONAL_DATA_TH.columnHeader2}</strong>
              </td>
            </tr>
            {PERSONAL_DATA_TH.records.map((v, k) => (
              <tr key={k}>
                <td valign='top'>{v.title}</td>
                <td valign='top'>{v.describtion}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <p>2.2 วิธีการเก็บรวบรวมข้อมูลส่วนบุคคล</p>
        <p>
          บริษัทฯจะทําการเก็บรวบรวมข้อมูลส่วนบุคคลผ่านทางหลายช่องทางบริษัทฯจะได้รับข้อมูลของท่านมาจากผู้จําหน่ายอีซูซุที่ได้รับการแต่งตั้ง(“ผู้จําหน่าย”)เป็นช่องทางหลักนอกจากนั้นบริษัทฯอาจจะเก็บข้อมูลส่วนบุคคลจากกิจกรรมอื่นๆทีท่านจะให้ข้อมูลโดยตรงกับบริษัทฯหรือผ่านทางเว็บไซต์&nbsp;
          <a href='https://isuzu-tis.com' target='_blank' rel='noopener noreferrer'>
            www.isuzu-tis.com
          </a>{' '}
          หรือเว็บไซต์อื่นๆของบริษัท(“เว็บไซต์”)ศูนย์บริการลูกค้าบริษัทในเครือของตรีเพชรซึ่งรวมถึงการเก็บข้อมูลจากการสังเกตุพฤติกรรมการใช้งานเว็บไซต์ของท่านจากการใช้งานเว็บเบราเซอร์หรือบริษัทฯอาจจะเก็บข้อมูลส่วนบุคคลของท่านจากแหล่งอื่นทีไม่ใช่จากท่านโดยตรงเช่นจากลูกค้าท่านอื่นๆของบริษัทฯสมาคมการค้าหรือองค์กรอื่น
          เป็นต้น
        </p>

        <p>2.3 ระยะเวลาในการเก็บข้อมูลส่วนบุคคล</p>
        <p>{DURATION_TH}</p>
      </ol>

      <strong>3. วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
      <p>{OBJECTIVE_DETAIL_TH}</p>
      <table className='privacy_table' cellPadding='0' cellSpacing='0'>
        <tbody>
          <tr>
            <td valign='top' width='70%' align='center'>
              <strong>{OBJECTIVE_INFO_TH.columnHeader1}</strong>
            </td>
            <td valign='top' width='30%' align='center'>
              <strong>{OBJECTIVE_INFO_TH.columnHeader2}</strong>
            </td>
          </tr>
          {OBJECTIVE_INFO_TH.records.map((v, k) => (
            <tr key={k}>
              <td valign='top'>
                <div className='objective-column'>
                  <div>{k + 1}. </div>
                  {v.objective}
                </div>
              </td>
              <td valign='top'>{v.ground}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>ในกรณีที่ท่านไม่ให้ข้อมูลส่วนบุคคลของท่านแก่บริษัทฯ อาจทำให้บริษัทฯ ไม่สามารถให้บริการแก่ท่านได้ในบางกรณี</p>

      <strong>4. การเปิดเผยข้อมูลส่วนบุคคล</strong>
      <br />
      <br />
      <ol>
        <p>4.1 ประเภทของผู้รับข้อมูลส่วนบุคคล</p>
        <p>{TYPE_OF_PERSONAL_TH}</p>
        <p>4.2 การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</p>
        <p>{TRANSFER_PERSONAL_DATA_TH}</p>
      </ol>

      <strong>5. สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong>
      <p>ท่านมีสิทธิดังต่อไปนี้ ทั้งนี้ ภายใต้หลักเกณฑ์ ตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลทั่วไปของประเทศไทยที่ใช้บังคับ </p>
      <ol>
        {DATA_SUBJECT_TH.map((text) => (
          <p key={text}>
            {' '}
            {text.no} &nbsp;{text.info} &nbsp;
          </p>
        ))}
      </ol>
      <br />
      <strong>6. การใช้คุกกี้</strong>
      <p>{COOKIE_TH}</p>

      <strong>7. การปรับปรุงนโยบายความเป็นส่วนตัว</strong>
      <p>{IMPROVEMENT_OF_PRIVACY_POLICY_TH}</p>

      <strong>8. ติดต่อเรา</strong>
      <p>{CONTACT_TH}</p>
      <table className='privacy_table' cellPadding='0' cellSpacing='0'>
        <tbody>
          <tr>
            <td valign='top' width='50%' align='center'>
              <strong>ผู้ควบคุมข้อมูลส่วนบุคคล</strong>
            </td>
            <td valign='top' width='50%' align='center'>
              <strong>สถานที่ติดต่อและวิธีการติดต่อ</strong>
            </td>
          </tr>
          <tr>
            <td valign='top'>บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด</td>
            <td valign='top'>
              <strong>อีเมล์:&nbsp;</strong>
              <a href='mailto:info@isuzu-tis.com'>info@isuzu-tis.com</a>&nbsp;
              <br />
              <br />
              <strong>หมายเลขโทรศัพท์:&nbsp;</strong>021180777
              <br />
              <br />
              <strong>ที่อยู่:&nbsp;</strong>สำนักงานลูกค้าสัมพันธ์ 1088 ถนนวิภาวดีรังสิต แขวงจตุจักร เขตจตุจักร กรุงเทพมหานคร 10900
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        บริษัทฯ ยินดีจะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชม
        อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของเว็บไซต์และการให้บริการของบริษัทฯ ต่อไป{' '}
      </p>
      <p>{REMARK_TH}</p>
    </ol>
  </div>
)

export default PrivacyPolicyTH
