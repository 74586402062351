import React from 'react'
import api from 'api/'
import { activityTypes } from '../../config/constant'
import { checkError } from '../../utils/helper'

class ErrorBoundary extends React.Component {
  constructor() {
    super()
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    api
      .logActivities({
        type: activityTypes.ERROR,
        description: `Error log on web: ${error}|${errorInfo}`
      })
      .catch((err) => checkError(err))
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button onClick={() => window.location.reload()}>Refresh</button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
