import React from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ReactButton from '@material/react-button'

// Methods
import { FormattedMessage, intlShape, injectIntl } from 'react-intl'

// Styles
import './_index.scss'

const Button = ({ className, type, label, icon, color, iconOnly, intl, customIcon, href, ...otherProps }) => {
  const classes = classnames(
    'button',
    { 'button--icon': type === 'icon' },
    { 'button--icon-only': iconOnly },
    { 'button--submit': type === 'submit' },
    { 'button--primary': color === 'primary' },
    { 'button--primary-shadow': color === 'primary-shadow' },
    { 'button--dark': color === 'dark' },
    { 'button--transparent': color === 'transparent' },
    { 'button--transparent-primary': color === 'transparent-primary' },
    { 'button--transparent-primary-shadow': color === 'transparent-primary-shadow' },
    { 'button--small': type === 'small' },
    { 'button--back': type === 'button-back' },
    { 'button--close': type === 'button-close' },
    { 'button--delete': type === 'delete' },
    { 'button--modal-close': type === 'modal-close' },
    { 'button--modal-confirm': type === 'modal-confirm' },
    { 'button--link': type === 'link' },
    { 'button--pager': type === 'pager' },
    { 'button--add-new': type === 'add-new' },
    { 'button--line': type === 'line' },
    { 'button--line-home': type === 'line-home' },
    { 'button--line-dealer': type === 'line-dealer' },
    { 'button--cancel': type === 'cancel' },
    { 'button--light': color === 'light' },
    { 'button--set-cover': type === 'set-cover' },
    { 'button--white': type === 'white' },
    { 'button--red': type === 'red' },
    { 'button--outline-red': type === 'outline-red' },
    { 'button--vehicle': type === 'vehicle' },
    className
  )

  return href ? (
    <a className={classes} {...otherProps}>
      {customIcon && <i className='custom-icon'>{customIcon}</i>}
      {icon && !customIcon && <i className='material-icons'>{icon}</i>}
      {label && (
        <span>
          <FormattedMessage id={label} defaultMessage={label} />
        </span>
      )}
    </a>
  ) : label === 'inbox.document.next' ? (
    <ReactButton className={classes} {...otherProps} type={type}>
      {label && <span>{intl.formatMessage({ id: label, defaultMessage: label })}</span>}
      {customIcon ? <i className='custom-icon'>{customIcon}</i> : icon ? <i className='material-icons icon-next'>{icon}</i> : null}
    </ReactButton>
  ) : (
    <ReactButton
      className={classes}
      {...otherProps}
      type={type}
      icon={customIcon ? <i className='custom-icon'>{customIcon}</i> : icon ? <i className='material-icons'>{icon}</i> : null}
    >
      {label && <span>{intl.formatMessage({ id: label, defaultMessage: label })}</span>}
    </ReactButton>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  customIcon: PropTypes.any,
  iconOnly: PropTypes.bool,
  color: PropTypes.string,
  intl: intlShape.isRequired
}

Button.defaultProps = {
  className: '',
  type: '',
  label: '',
  icon: '',
  customIcon: null,
  iconOnly: false
}

export default injectIntl(Button)
