import React, { forwardRef, useState, useImperativeHandle } from 'react'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Components
import ReactModal from 'react-modal'
import Button from 'components/elements/Button'

// Methods
import { FormattedMessage } from 'react-intl'

// Styles
import './index.scss'

ReactModal.setAppElement('#root')
const ConfirmModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModal(),
      closeModal: () => closeModal()
    }
  })
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (typeof props.onClosed === 'function') {
      props.onClosed()
    }
  }
  const confirm = () => {
    setIsOpen(false)
    props.onConfirmed()
  }
  const classes = classnames('confirm-modal', props.className)

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={classes}
      overlayClassName='modal-overlay'
      shouldFocusAfterRender={false}
      htmlOpenClassName='ReactModal__Html--open'
    >
      <Button iconOnly type='button-close' icon='clear' className='confirm-modal__close' onClick={closeModal} />
      <div className='confirm-modal__header'>
        <div className='confirm-modal__header__text'>
          {props.title && <FormattedMessage id={props.title} defaultMessage={props.title} />}
        </div>
      </div>
      <div className='confirm-modal__body'>
        <div className='confirm-modal__body__content'>
          <FormattedMessage id={props.content} defaultMessage={props.content} />
        </div>
      </div>

      <div className='confirm-modal__action'>
        <Button
          color='transparent'
          type='modal-close'
          label='common.button.cancel'
          className='confirm-modal__cancel-btn'
          onClick={closeModal}
        />
        <Button
          color='transparent'
          type='modal-confirm'
          label='common.button.confirm'
          className='confirm-modal__confirm-btn'
          onClick={confirm}
        />
      </div>
    </ReactModal>
  )
})

ConfirmModal.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
  footer: PropTypes.any,
  detail: PropTypes.any,
  onConfirmed: PropTypes.func,
  onClosed: PropTypes.func
}

export default ConfirmModal
