import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
// import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { TextField, Grid, withStyles } from '@material-ui/core'

import ButtonMaterial from '@material-ui/core/Button'

import Modal from 'components/elements/Modal'

//action
import { checkAvalibleAddCompany, AddCompany, getCompanyList } from 'store/actions/company'
import { showLoader, hideLoader } from 'store/actions/loader'

import { css, styles } from './css.js'
import './_index.scss'

const CompanyAddForm = (props) => {
  const { classes } = props
  // const history = useHistory()
  const dispatch = useDispatch()

  const [isOpen] = useState(false)

  const [error, setError] = useState([])

  const [dataForm, setDataForm] = useState({
    company_reg_id: '',
    vin: ''
  })
  const modalRef = useRef()
  const [headerModal, setHeaderModal] = useState('')
  const [contentModal, setContentModal] = useState('taxIdNotFound')
  const [contentModalShow, setContentModalShow] = useState()
  const [detailModal, setDetailModal] = useState()

  useEffect(() => {
    if (isOpen && modalRef && modalRef.current) {
      modalRef.current.openModal()
    }
  }, [isOpen])

  useEffect(() => {
    switch (contentModal) {
      case 'confirm':
        setContentModalShow(
          <div>
            <Grid container justify='center'>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <FormattedMessage id={'company.modal.add.corporate'} defaultMessage={'company.modal.add.corporate'} />
              </Grid>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <span>{detailModal.company_name}</span>
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <FormattedMessage id={'company.modal.add.companyRegId'} defaultMessage={'company.modal.add.companyRegId'} />
              </Grid>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <span>{dataForm.company_reg_id}</span>
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <FormattedMessage id={'company.modal.add.vinNumber'} defaultMessage={'company.modal.add.vinNumber'} />
              </Grid>
              <Grid xs={5} sm={5} className={'content-modal_col'}>
                <span>{detailModal.vehicle_amount} คัน</span>
              </Grid>
            </Grid>
          </div>
        )
        setHeaderModal('company.modal.add.header')
        break

      case 'unavailable':
      case 'taxIdNotFound':
      case 'licencePlateNotFound':
        setContentModalShow(
          <div>
            {contentModal === 'unavailable' && (
              <Grid container justify='center'>
                <Grid xs={12} sm={12} className={classes.alertModal}>
                  <FormattedMessage
                    id={`company.modal.alert.${contentModal}.text1`}
                    defaultMessage={`company.modal.alert.${contentModal}.text1`}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container justify='center'>
              <Grid xs={12} sm={12} className={classes.alertModal}>
                <FormattedMessage
                  id={`company.modal.alert.${contentModal}.text2`}
                  defaultMessage={`company.modal.alert.${contentModal}.text2`}
                />
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid xs={12} sm={12} className={classes.alertModaltext2}>
                <FormattedMessage
                  id={`company.modal.alert.${contentModal}.text3`}
                  defaultMessage={`company.modal.alert.${contentModal}.text3`}
                />
              </Grid>
            </Grid>
          </div>
        )
        setHeaderModal(`company.modal.alert.${contentModal}.header`)
        break

      default:
    }
  }, [contentModal])

  const handleOpenModal = async () => {
    // check api ก่อน ค่อยให้เปิด
    await validate()

    // if (modalRef.current) {
    //     modalRef.current.openModal()
    // }
  }

  const handleOnChange = (e) => {
    let targetError = []

    let update = {}
    switch (e.target.name) {
      case 'company_reg_id':
        !isNaN(Number(e.target.value)) && (update[e.target.name] = e.target.value)
        targetError = error.filter((target) => target !== e.target.name)
        setError(targetError)
        break
      default:
        update[e.target.name] = e.target.value
        targetError = error.filter((target) => target !== e.target.name)
        setError(targetError)
        break
    }

    setError([])
    setDataForm({ ...dataForm, ...update })
  }

  const validate = async () => {
    // let valid = true;
    const targetError = []

    Object.keys(dataForm).forEach((key) => {
      switch (key) {
        case 'company_reg_id':
          dataForm[key].length !== 13 ? targetError.push(key) : targetError.push()
          break
        case 'vin':
          dataForm[key].length !== 17 ? targetError.push(key) : targetError.push()
          break
        default:
          break
      }
    })
    // eslint-disable-next-line
    if (targetError.length == '0') {
      const dataAddCompany = await checkAvalibleAddCompany(dataForm, dispatch)

      const status = dataAddCompany.status
      setDetailModal(dataAddCompany.data)
      if (status !== 200) {
        switch (dataAddCompany.descripttion) {
          case 'Account Conflict':
            setContentModal('unavailable')
            targetError.push('company_reg_id')
            targetError.push('vin')
            break
          case 'Corporate Not Found':
          case 'Account Not Found':
            setContentModal('taxIdNotFound')
            targetError.push('company_reg_id')
            break
          case 'Vehicle Not Found':
            setContentModal('licencePlateNotFound')
            targetError.push('vin')
            break
          default:
        }
        setError(targetError)
      } else {
        setContentModal('confirm')
      }
      if (modalRef.current) {
        modalRef.current.openModal()
      }
    }
    setError(targetError)
  }

  const handleSubmit = async () => {
    if (contentModal === 'confirm') {
      await AddCompany(dataForm, dispatch)
      await dispatch(showLoader())
      await dispatch(getCompanyList())
      modalRef.current.closeModal()
      await dispatch(hideLoader())

      setDataForm({ company_reg_id: '', vin: '' })
      // if (history) {
      // history.push('/company/list')
      // }
    } else {
      modalRef.current.closeModal()
    }
  }

  return (
    <div>
      <div className='company-add__content'>
        {/* เดียวทำเป็น list */}

        <Grid spacing={3} className={classes.row}>
          <Grid align-items-xs-center xs={12} className={classes.title}>
            <FormattedMessage id='company.add.title' defaultMessage='company.add.title' />
          </Grid>
        </Grid>
        <Grid spacing={3} className={classes.row}>
          <Grid align-items-xs-center xs={10} md={6} lg={5} className={classes.title}>
            <TextField
              id='outlined-basic'
              name='company_reg_id'
              value={dataForm.company_reg_id}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                  outlined: classes.outlined,
                  shrink: classes.shrink
                },
                style: css.input
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  error: classes.error
                },
                style: css.inputLabel
              }}
              label={
                <div>
                  <FormattedMessage id={'company.add.textFieldTax'} defaultMessage={'company.add.textFieldTax'} />
                  {!dataForm.company_reg_id && <span style={{ color: 'red' }}>*</span>}
                </div>
              }
              variant='outlined'
              fullWidth
              onChange={handleOnChange}
              error={error.includes('company_reg_id')}
              helperText={
                error.includes('company_reg_id') && (
                  <FormattedMessage id={'company.add.helperErrorAlert'} defaultMessage={'company.add.helperErrorAlert'} />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid spacing={3} className={classes.row}>
          <Grid align-items-xs-center xs={10} md={6} lg={5} className={classes.title}>
            <TextField
              id='outlined-basic'
              name='vin'
              value={dataForm.vin}
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                  outlined: classes.outlined,
                  shrink: classes.shrink
                },
                style: css.input
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  error: classes.error
                },
                style: css.inputLabel
              }}
              label={
                <div>
                  <FormattedMessage id={'company.add.textFieldVin'} defaultMessage={'company.add.textFieldVin'} />
                  {!dataForm.vin && <span style={{ color: 'red' }}>*</span>}
                </div>
              }
              variant='outlined'
              fullWidth
              helperText={
                error.includes('vin') ? (
                  <FormattedMessage id={'company.add.helperErrorAlert'} defaultMessage={'company.add.helperErrorAlert'} />
                ) : (
                  <FormattedMessage id={'company.add.textFieldVinHelper'} defaultMessage={'company.add.textFieldVinHelper'} />
                )
              }
              onChange={handleOnChange}
              error={error.includes('vin')}
            />
          </Grid>
        </Grid>
        <Grid spacing={3} className={classes.row}>
          <Grid align-items-xs-center xs={10} className={classes.title}>
            {error.length > 0 ? (
              <ButtonMaterial className='submit-add-company-disable' disabled>
                <FormattedMessage id={'common.button.addCompany'} defaultMessage={'common.button.addCompany'} />
              </ButtonMaterial>
            ) : (
              <ButtonMaterial className='submit-add-company' onClick={() => handleOpenModal()}>
                <FormattedMessage id={'common.button.addCompany'} defaultMessage={'common.button.addCompany'} />
              </ButtonMaterial>
            )}
          </Grid>
        </Grid>
      </div>

      <Modal
        mode={13}
        header={headerModal}
        content={contentModalShow}
        ref={modalRef}
        label={contentModal === 'confirm' ? 'common.button.confirm' : 'common.button.understand'}
        onClick={() => handleSubmit()}
      />
    </div>
  )
}

CompanyAddForm.propTypes = {
  isOpen: PropTypes.bool
}

export default withStyles(styles)(CompanyAddForm)
