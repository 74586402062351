import { COUPON, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  total_count: 0,
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COUPON.SLIDER_SERVICE:
      return Object.assign({}, state, {
        ...state,
        total_count: action.total_count,
        data: action.data
      })

    case COUPON.SLIDER_SERVICE_READ:
      const newData = [...state.data]
      const index = newData.findIndex((item) => item.id === action.id)
      newData[index].is_read = !newData[index].is_read
      return Object.assign({}, state, {
        ...state,
        data: newData
      })

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
