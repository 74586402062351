import React from 'react'
import env from 'config/environment'

import './EndOfSupportBanner.scss'
import { getAppStoreOrPlayStoreUrl } from 'utils/helper'

export function EndOfSupportBanner() {
  return (
    <a id='end-of-support-banner' className='end-of-support-banner' href={getAppStoreOrPlayStoreUrl()} target='_blank' rel='noreferrer'>
      <img src={`${env.cdn}/assets/media/images/terminate-banner-1.png`} alt='end-of-support-banner' />
    </a>
  )
}
