import { USER_TYPE } from 'config/constant'
import { randomString } from 'utils/format'
import { setLineState, uriRedirect, lineClientID } from 'utils/auth'
import queryString from 'query-string'

const lineOAuthUrl = 'https://access.line.me/oauth2/v2.1/authorize'

export const issueLineSignin = ({ redirectUri, additionalQueries = {} }) => {
  const stateString = randomString(16)
  setLineState(stateString)

  const url = queryString.stringifyUrl(
    {
      url: lineOAuthUrl,
      query: {
        response_type: 'code',
        client_id: lineClientID,
        state: stateString,
        scope: 'profile',
        redirect_uri: redirectUri,
        ...additionalQueries
      }
    },
    { skipEmptyString: true }
  )

  window.location.href = url
}

export const generateLineRedirectUri = (type = USER_TYPE.INDIVIDUAL) => {
  const search = queryString.parse(window.location.search)

  const signInUrl = new URL('/signin', uriRedirect)

  const redirectUri = queryString.stringifyUrl(
    {
      url: signInUrl.href,
      query: { ...search, type }
    },
    { skipEmptyString: true }
  )

  return redirectUri
}
