import React, { useState, useRef, useEffect, useMemo } from 'react'
import { FormattedMessage, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import Modal from 'components/elements/Modal'
import Select from 'components/elements/Select'
import { deleteReasons } from '../../../../config/constant'

import './_index.scss'

const DeleteVehicleReasonModal = ({ isOpen, onConfirm, onClose }, context) => {
  const [deleteReason, setDeleteReason] = useState('')
  const [remarks, setRemarks] = useState('')
  const modalRef = useRef(null)
  const {
    intl: { formatMessage }
  } = context

  const reasonChangeHandler = (event) => {
    if (!event) {
      return
    }

    if (event.target.value !== '6') {
      setRemarks('')
    }

    setDeleteReason(event.target.value)
  }

  const remarkChangeHandler = (event) => {
    if (!event) {
      return
    }

    setRemarks(event.target.value)
  }

  const modalConfirmHandler = () => {
    if (onConfirm) {
      onConfirm({ reason: deleteReason, remarks })
    }
  }

  useEffect(() => {
    if (isOpen && modalRef && modalRef.current) {
      modalRef.current.openModal()
    }
  }, [isOpen])

  const modalContent = useMemo(
    () => (
      <div className='delete-vehicle-modal__content'>
        <FormattedMessage id='vehicle.delete.description' defaultMessage='vehicle.delete.description' />

        <div className='delete-vehicle-modal__content__selectors'>
          <RadioGroup aria-label='selected_id' value={deleteReason} onChange={reasonChangeHandler}>
            {(deleteReason === '5' || deleteReason === '6' || !deleteReason) && (
              <FormControlLabel
                value='1'
                control={<Radio />}
                label={formatMessage({ id: 'vehicle.delete.sold', defaultMessage: 'vehicle.delete.sold' })}
              />
            )}
            {(deleteReason === '1' || deleteReason === '2' || deleteReason === '3' || deleteReason === '4') && (
              <FormControlLabel
                value={deleteReason}
                control={<Radio />}
                label={formatMessage({ id: 'vehicle.delete.soldTo', defaultMessage: 'vehicle.delete.soldTo' })}
              />
            )}
            <FormControlLabel
              value='5'
              control={<Radio />}
              label={formatMessage({ id: 'vehicle.delete.notUsed', defaultMessage: 'vehicle.delete.notUsed' })}
            />

            <FormControlLabel
              value='6'
              control={<Radio />}
              label={formatMessage({ id: 'vehicle.delete.others', defaultMessage: 'vehicle.delete.others' })}
            />
          </RadioGroup>
          {deleteReason && deleteReason !== '5' && deleteReason !== '6' && (
            <Select
              className='delete-vehicle-modal__content__selectors__select'
              options={deleteReasons}
              value={deleteReason}
              onChange={(selected) => {
                setDeleteReason(selected.value)
              }}
              noMapping
            />
          )}
        </div>

        <textarea
          className='delete-vehicle-modal__content__textarea'
          rows='5'
          id='myInput'
          placeholder={formatMessage({ id: 'vehicle.delete.placeholder', defaultMessage: 'vehicle.delete.placeholder' })}
          value={remarks}
          disabled={deleteReason !== '6'}
          onChange={remarkChangeHandler}
        />
      </div>
    ),
    [deleteReason, remarks]
  )

  return (
    <Modal
      mode={10}
      className='delete-vehicle-modal'
      title='vehicle.delete.title'
      content={modalContent}
      ref={modalRef}
      onConfirmed={modalConfirmHandler}
      onClosed={onClose}
      reason={deleteReason}
      remarks={remarks}
    />
  )
}

DeleteVehicleReasonModal.contextTypes = {
  intl: intlShape
}

DeleteVehicleReasonModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
}

export default DeleteVehicleReasonModal
