import React from 'react'

import EN from './en'
import TH from './th'

// Styles
import './_index.scss'

const PrivacyPolicy = ({ lang }) => {
  return (
    <div className='privacy_section'>
      {lang === 'en' && (
        <div className='privacy'>
          <EN />
        </div>
      )}
      {lang === 'th' && (
        <div className='privacy'>
          <TH />
        </div>
      )}
    </div>
  )
}

export default PrivacyPolicy
