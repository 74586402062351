import { ALERT } from 'store/definitions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT.SHOW:
      return Object.assign({}, action.alert)

    case ALERT.HIDE:
      return initialState

    default:
      return state
  }
}
