export const isErrorType = (event) => event.type === 'error'
export const isSourceConsole = (event) => typeof event.error.source === 'string' && event.error.source === 'console'

export const hasInUrl = (url) => (event) => typeof event.view.url === 'string' && event.view.url.includes(url)
export const hasErrorMessage = (errorMessage) => (event) =>
  typeof event.error.message === 'string' && event.error.message.includes(errorMessage)

export const compose =
  (...fns) =>
  (event) =>
    fns.every((fn) => fn(event))
