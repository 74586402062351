import React, { useRef } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'

// Components
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import PopupVehicle from 'components/elements/PopupVehicle'
import VehicleCard from 'domain/Vehicle/components/VehicleCard'

// Images
import carImg from 'assets/images/car.svg'
import truck from 'assets/images/truck.svg'
import arrow from 'assets/images/arrow_position.svg'
import { privilegeIcon } from 'config/icon'

// Method
import * as helper from 'utils/helper'
import api from 'api/'

// Styles
import './_index.scss'

// Constant
import { activityTypes } from 'config/constant'
import { vehicleModalIcon } from 'config/icon'

// Utils
import { isMobileApp } from 'utils/validation'

const DetailExcellency = ({ detail, list, excellency, history }) => {
  const modalRef = useRef()

  const closeModal = () => modalRef.current.closeModal()

  const current = helper.getCurrentStatus(excellency && excellency.current_status ? excellency.current_status : null)
  const next = helper.getNextStatus(excellency && excellency.next_status ? excellency.next_status : null)
  const isMaxStatus = current === next ? true : false
  const remain = excellency ? excellency.remaining_service : 0
  const isOldStatus = excellency && excellency.current_status && excellency.current_status.toUpperCase() === 'OLD'

  const nodes = helper.nodeRender(current, next, remain)
  const carPosition = nodes ? nodes.position : 'no-excellency'
  const noExcellency = excellency && !excellency.current_status && !excellency.next_status
  const bg =
    detail.vehicle.vehicle_type === 'CV'
      ? helper.getAssets(1, 3, 'cv_bg', 'excellency_detail_screen')
      : helper.getAssets(1, 3, 'lcv_bg', 'excellency_detail_screen')

  const infographicDom = (
    <div className='infographic'>
      <div
        className='infographic__wrapper'
        style={{
          backgroundImage: `url(${bg})`
        }}
      >
        <div className='infographic__wrapper__next__text'>
          <p>
            <FormattedMessage id='privilege.currentStatus' defaultMessage='privilege.currentStatus' />
          </p>
        </div>

        <div className='car'>
          {detail.id && (
            <div className={`car__${carPosition}`} id={`car__${carPosition}`}>
              {remain > 0 && (
                <div className='car__remaining'>
                  <FormattedHTMLMessage
                    id='privilege.remaining'
                    values={{
                      remain: remain
                    }}
                    defaultMessage='privilege.remaining'
                  />
                </div>
              )}
              <img src={detail.vehicle.vehicle_type === 'CV' ? truck : carImg} alt='' />
              {remain > 0 && <img className='car__arrow' src={arrow} alt='' />}
            </div>
          )}
        </div>

        {remain > 0 && (
          <div>
            <div className='nodes'>
              {nodes &&
                nodes.status.map((value, key) => (
                  <div className='nodes__node' key={key}>
                    <img src={helper.getAssets(1, 7, value, 'excellency_detail_screen')} alt='' />
                  </div>
                ))}

              {current !== null && remain !== null && <div className='nodes__connecting-line'></div>}
            </div>

            <div className='status'>
              <div className='status__current'>
                <FormattedMessage id={`privilege.status.${current}`} defaultMessage={`privilege.status.${current}`} />
              </div>
              <div className='status__next'>
                <FormattedMessage id={`privilege.status.${next}`} defaultMessage={`privilege.status.${next}`} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className='detail-excellency'>
      <Modal
        mode={8}
        title={'privilege.vehicleModal'}
        content={<PopupVehicle vehicles={list} close={closeModal} exc={true} clickClose={true} />}
        ref={modalRef}
      />
      <div className='detail-excellency__header'>
        <img
          src={
            detail.vehicle.vehicle_type === 'CV'
              ? helper.getAssets(1, 2, 'excellency_elf_logo', 'excellency_detail_screen')
              : helper.getAssets(1, 2, 'excellency_logo', 'excellency_detail_screen')
          }
          alt=''
        />
      </div>

      {list.total_count > 0 && (
        <div>
          {list.total_count > 1 && (
            <Button
              className='excellency-vehicle-btn'
              label='privilege.vehicleModal'
              icon={vehicleModalIcon}
              type='vehicle'
              onClick={() => {
                /*istanbul ignore else*/
                if (list.total_count > 1) {
                  modalRef.current.openModal()
                }
              }}
            />
          )}

          <div className='detail-excellency__body'>
            <VehicleCard
              vehicleImage={detail.image_url || detail.vehicle.model.marketing_image_url}
              vahicleName={detail.name || detail.vehicle.model.marketing_name}
              licensePlate={detail.vehicle.license_plate}
              excellency={excellency && excellency}
              onClick={
                isOldStatus
                  ? ''
                  : (e) => {
                      e.stopPropagation()
                      history.push({
                        pathname: `/excellency/${detail.id}/privileges-conditions`,
                        state: { isNseries: detail.vehicle.is_nseries }
                      })

                      api.logActivities({
                        type: activityTypes.EXCELLENCY,
                        description: `Click Vehicle Card of ${detail.vehicle.vin} & ${detail.vehicle.license_plate}`
                      })
                    }
              }
            />
          </div>

          {!isMaxStatus || noExcellency ? (
            <div>{infographicDom}</div>
          ) : (
            <div className='detail-excellency__is-platinum'>
              <img src={helper.getAssets(1, 3, `current_status_${current}`, 'excellency_detail_screen')} alt='' />
            </div>
          )}

          <div className='detail-excellency__body'>
            {/* Remove because we use realtime-excellency
            {excellency && !isOldStatus &&
              <div className="detail-excellency__body__info-3__remark">
                <FormattedMessage id="privilege.excellencyRemark" defaultMessage="privilege.excellencyRemark" />
              </div>
            } */}

            <div className='detail-excellency__body__button'>
              {!isMobileApp() && (
                <div>
                  <Button
                    label='vehicle.detail.nextMaintenance'
                    color='light'
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push({
                        pathname: `/vehicle/${detail.id}`,
                        state: {
                          fromPage: 'Excellency'
                        }
                      })

                      api.logActivities({
                        type: activityTypes.EXCELLENCY,
                        description: `Click Next Maintenance of ${detail.vehicle.vin} & ${detail.vehicle.license_plate}`
                      })
                    }}
                  />
                </div>
              )}
              <div>
                <Button
                  label='privilege.condition'
                  color='light'
                  icon={privilegeIcon}
                  disabled={isOldStatus}
                  onClick={(e) => {
                    e.stopPropagation()
                    history.push({
                      pathname: `/excellency/${detail.id}/privileges-conditions`,
                      state: { isNseries: detail.vehicle.is_nseries }
                    })

                    api.logActivities({
                      type: activityTypes.EXCELLENCY,
                      description: `Click Privilege and Condition of ${detail.vehicle.vin} & ${detail.vehicle.license_plate}`
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

DetailExcellency.propTypes = {}

DetailExcellency.defaultProps = {}

export default connect(null, null)(withRouter(DetailExcellency))
