import React, { useEffect, useRef, useState } from 'react'

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Classnames
import classnames from 'classnames'

// Components
import ButtonBack from 'components/elements/ButtonBack'
import ButtonFav from 'components/elements/ButtonFav'
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import DeleteVehicleReasonModal from 'domain/Vehicle/components/DeleteVehicleReasonModal'

import { FormattedMessage } from 'react-intl'

// Actions
import { deleteController } from 'store/actions/controllers'
import { deleteVehicles } from 'store/actions/vehicles'

// Styles
import './_index.scss'

const DetailHeader = ({
  match,
  title,
  userType,
  goTo,
  defaultPath,
  showBack,
  history,
  type,
  detail,
  deleteController,
  deleteVehicles,
  isUseDefault,
  isDemoUser
}) => {
  const deleteControllerRef = useRef()
  const [shouldShowDeleteVehicleModal, setShouldShowDeleteVehicleModal] = useState()
  const [isGoBack, setIsGoBack] = useState(null)

  useEffect(() => {
    switch (match.path) {
      case '/vehicle/:id':
        if (history.location.state) {
          setIsGoBack(true)
        } else {
          setIsGoBack(false)
        }
        break
      default:
        setIsGoBack(null)
    }
  }, [])

  const deleteVehicleButtonClickHandler = () => {
    setShouldShowDeleteVehicleModal(true)
  }

  const deleteVehicleModalCloseHandler = () => {
    setShouldShowDeleteVehicleModal(false)
  }

  const _deleteController = async () => {
    await deleteController([{ id: detail.id }])
    history.push('/controllers')
  }

  const deleteVehicle = async (deleteReason) => {
    await deleteVehicles([detail.id], deleteReason)

    setShouldShowDeleteVehicleModal(false)

    if (deleteReason) {
      history.replace('/vehicles')
    }
  }

  const classes_background = classnames('detail-header_bg')
  const classes = classnames('detail-header', { 'detail-header__grey-fixed': type === 'grey-fixed' })
  const buttonClass = classnames('button-back')

  return (
    <div className={classes_background}>
      <div className={classes}>
        <Modal
          mode={2}
          title='controller.delete.title'
          content={'controller.delete.description'}
          onConfirmed={_deleteController}
          ref={deleteControllerRef}
        />

        <DeleteVehicleReasonModal
          isOpen={shouldShowDeleteVehicleModal}
          onConfirm={deleteVehicle}
          onClose={deleteVehicleModalCloseHandler}
        />

        {showBack && <ButtonBack defaultPath={defaultPath} className='button-back' isUseDefault={isUseDefault} isGoBack={isGoBack} />}
        {goTo && <Button iconOnly type='button-back' icon='arrow_back' onClick={() => history.push(`/${goTo}`)} className={buttonClass} />}

        {type === 'vehicle' && (
          <Button
            className='edit'
            icon='create'
            label='vehicle.detail.editVehicle'
            onClick={(e) => {
              e.stopPropagation()
              window.previousLocation = '/vehicle/details'
              history.push(`/vehicle/edit/${detail.id}`)
            }}
            disabled={isDemoUser}
          />
        )}
        {type === 'company-list' && (
          <Button color='primary' icon='add' label='company.list.add' onClick={() => history.push('/company/add')} />
        )}

        {type === 'company-add' && <Button color='primary' label='common.button.cancel' onClick={() => history.push('/company/list')} />}

        {userType !== 3 && type === 'vehicle' && (
          <Button
            className='delete delete-vehicle'
            type='delete'
            icon='delete'
            label='vehicle.detail.deleteVehicle'
            onClick={deleteVehicleButtonClickHandler}
          />
        )}

        {type === 'profile' && (
          <Button
            className='edit'
            icon='create'
            label='myProfile.button.editProfile'
            type='profile'
            onClick={() => history.push(`/profile/edit`)}
          />
        )}

        {type === 'controllers' && detail.status !== 101 && (
          <Button
            className='edit'
            icon='create'
            label='controller.detail.editController'
            onClick={() => history.push(`/controller/${detail.id}/edit`)}
          />
        )}

        {type === 'controllers' && (
          <Button className='delete' iconOnly icon='delete' type='delete' onClick={() => deleteControllerRef.current.openModal()} />
        )}

        {type !== 'controllers' &&
          type !== 'basic' &&
          type !== 'company-list' &&
          type !== 'company-add' &&
          type !== 'profile' &&
          type !== 'excellency' &&
          type !== 'dealer' &&
          type !== 'inbox' &&
          type !== 'profile-edit' &&
          type !== 'change-password' &&
          type !== 'setting' &&
          type !== 'grey-fixed' &&
          type !== 'profile-setting' &&
          type !== 'repair-history' &&
          type !== 'controller-add' &&
          type !== 'til-refinance' && (
            <ButtonFav type={type} id={detail.id} isFavorite={detail.is_favorite} className='button-fav' disabled={isDemoUser} />
          )}
        {type === 'basic' && !!title && (
          <div className='detail-header__title'>
            <FormattedMessage id={title} defaultMessage={title} />
          </div>
        )}
        {type === 'grey-fixed' && !!title && (
          <div className='detail-header__grey-fixed__title'>
            <FormattedMessage id={title} defaultMessage={title} />
          </div>
        )}
        {type === 'dealer' && (
          <div className='detail-header__title'>
            {window.previousLocation === '/isp' ? (
              <FormattedMessage id='dealerSearch.title' defaultMessage='dealerSearch.title' />
            ) : (
              <FormattedMessage id='dealerSearch.title2' defaultMessage='dealerSearch.title2' />
            )}
          </div>
        )}
        {type === 'company-list' && (
          <div className='detail-header__title'>
            <FormattedMessage id='company.list.title' defaultMessage='company.list.title' />
          </div>
        )}
        {type === 'profile' && (
          <div className='detail-header__title'>
            <FormattedMessage id='myProfile.labels.title' defaultMessage='company.list.title' />
          </div>
        )}
        {type === 'repair-history' && (
          <div className='detail-header__title'>
            <FormattedMessage id='vehicle.detail.repairHist' defaultMessage='vehicle.detail.repairHist' />
          </div>
        )}
        {type === 'company-add' && (
          <div className='detail-header__title_company-add'>
            <FormattedMessage id='company.list.add' defaultMessage='company.list.title' />
          </div>
        )}
        {type === 'profile-edit' && (
          <div className='detail-header__title'>
            <div className='detail-header__title__profile'>
              <FormattedMessage id='myProfile.labels.profile' defaultMessage='myProfile.labels.profile' />
            </div>
          </div>
        )}
        {type === 'change-password' && (
          <div className='detail-header__title'>
            <div className='detail-header__title__profile'>
              <FormattedMessage id='myProfile.labels.settings' defaultMessage='myProfile.labels.settings' />
            </div>
          </div>
        )}

        {type === 'setting' && (
          <div className='detail-header__title'>
            <FormattedMessage id='myProfile.settings.title' defaultMessage='company.list.title' />
          </div>
        )}
        {type === 'profile-setting' && (
          <div className='detail-header__title'>
            <FormattedMessage id='menus.setting' defaultMessage='menus.setting' />
          </div>
        )}
        {type === 'controller-add' && (
          <div className='detail-header__title'>
            <FormattedMessage id='controller.list.title' defaultMessage='controller.list.title' />
          </div>
        )}
        {type === 'til-refinance' && (
          <div className='detail-header__title'>
            <div className='detail-header__title__til-refinance'>
              <FormattedMessage id='tilRefinance.title' defaultMessage='tilRefinance.title' />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        deleteController,
        deleteVehicles
      },
      dispatch
    )

export default connect(null, mapDispatchToProps)(withRouter(DetailHeader))
