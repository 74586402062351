import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './_index.scss'
import Button from '../Button'

const Chip = ({ className, label, onButtonClick }) => {
  const classes = classnames('chip', className)

  return (
    <div className={classes}>
      <span>{label}</span>
      <Button iconOnly type='button-close' icon='clear' onClick={onButtonClick} />
    </div>
  )
}

Chip.propTypes = {
  className: PropTypes.any, //TODO: fix type
  label: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func
}

export default Chip
