import { FILTER, CLEAR_THIS_STATE } from 'store/definitions'

const initialState = {
  keyword: '',
  status: 'all',
  assignment: 'all',
  orderBy: 21,
  sortBy: 1
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER.CONTROLLER.KEYWORD:
      return Object.assign({}, state, {
        ...state,
        keyword: action.keyword
      })

    case FILTER.CONTROLLER.ORDER: {
      let sortBy

      if (action.orderBy === 21) {
        sortBy = 1
      }

      if (action.orderBy === 23) {
        sortBy = 0
      }

      return {
        ...state,
        orderBy: action.orderBy,
        sortBy
      }
    }

    case FILTER.CONTROLLER.SORT:
      return Object.assign({}, state, {
        ...state,
        sortBy: state.sortBy ? 0 : 1
      })

    case FILTER.CONTROLLER.STATUS: {
      return {
        ...state,
        status: action.activationStatus
      }
    }

    case FILTER.CONTROLLER.ASSIGNMENT: {
      return {
        ...state,
        assignment: action.assignment
      }
    }

    case CLEAR_THIS_STATE:
      return initialState

    default:
      return state
  }
}
