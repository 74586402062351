import React from 'react'

import Button from 'components/elements/Button'

const MenuModal = (props) => {
  return (
    <div className={`modal_edit ${!props.state.coverImage ? 'first' : 'notCover'} `}>
      {!props.state.coverImage && (
        <Button
          color='transparent'
          type='set-cover'
          label='common.button.changeCover'
          className='changeCover'
          onClick={async () => {
            try {
              await props.setCoverImage(props.state.userVehicleId, props.state.imageUrl)
              props.closeModal()
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }}
        />
      )}
      {/* We no longer support multiple images, but existing users may still have multiple images.
          So we need to keep this code for now. */}
      {props.imageCount > 1 && (
        <Button
          color='transparent'
          type='modal-confirm'
          label='common.button.delete'
          className={`delete`}
          onClick={() => {
            props.setState.setConfirm(true)
          }}
        />
      )}
      {/* for cancel button */}
      <div className='modal-second'>
        <div className='modal_edit'>
          <Button
            color='transparent'
            type='modal-confirm'
            label='common.button.cancel'
            className='cancel'
            onClick={() => {
              props.closeModal()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MenuModal
